export const PortalPrefix = 'portal';
export const ClientPrefix = 'client';

export const PublicRoutes = {
	HOME: '/',
	POLICY: '/policy',
	TERMS_CONDITIONS: '/terms',
	NOT_FOUND: '/404',
	CONTACTS: '/contacts',
};

export const AuthRoutes = {
	SIGN_UP: '/signup',
	CONFIRM: '/confirm',
	ERROR: '/error',
	LOGIN: '/login',
	FORGOT_PASSWORD: '/forgot',
	RESET_PASSWORD: '/reset',
};

export const PortalRoutes = {
	DASHBOARD: `/${PortalPrefix}`,
	MY_CLIENTS: `/${PortalPrefix}/clients`,
	CONTACT: `/${PortalPrefix}/contact`,
	ACCOUNT_SETTINGS: `/${PortalPrefix}/settings`,
	NOT_FOUND: `/${PortalPrefix}/404`,
	APPROVAL: `/${PortalPrefix}/approval`,
};

export const ReportRoutes = {
	REPORT_LIST: `/${PortalPrefix}/reports`,
	CLIENT_STATUSES: `/${PortalPrefix}/reports/clients`,
	PAYMENT_PLANS: `/${PortalPrefix}/reports/plans`,
	HARDSHIPS: `/${PortalPrefix}/reports/hardships`,
};

export const ClientRoutes = {
	DASHBOARD: `/${PortalPrefix}/${ClientPrefix}/dashboard`,
	PAYMENTS: `/${PortalPrefix}/${ClientPrefix}/payments`,
	MORATORIUM: `/${PortalPrefix}/${ClientPrefix}/moratorium`,
	DEBT_WAIVER: `/${PortalPrefix}/${ClientPrefix}/debt-waiver`,
	BALANCE_REDUCTION: `/${PortalPrefix}/${ClientPrefix}/balance-reduction`,
	ACCOUNT: `/${PortalPrefix}/${ClientPrefix}/account`,
	HARDSHIP: `/${PortalPrefix}/${ClientPrefix}/hardship`,
	PAYMENT_PLANS: `/${PortalPrefix}/${ClientPrefix}/plans`,
	DOCUMENTS: `/${PortalPrefix}/${ClientPrefix}/documents`,
	TRANSACTIONS: `/${PortalPrefix}/${ClientPrefix}/transactions`,
	CONTACT: `/${PortalPrefix}/${ClientPrefix}/contact`,
	NOT_FOUND: `/${PortalPrefix}/${ClientPrefix}/404`,
};
