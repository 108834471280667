import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileUpload } from '../../../../components/UploadComponent';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { setErrorSnackbarOpen } from '../../app/appSlice';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientBalanceReductionAPI, IBalanceReduction } from './types';

export const clientBalanceReductionPost = createAsyncThunk(
	'client/balance-reduction',
	async (input: BalanceReductionHardshipDetails & FileUpload, { rejectWithValue, dispatch, getState }) => {
		const { DebtID, DebtorEntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
		try {
			const formData = new FormData();
			formData.append('entityID', DebtorEntityID.toString());
			formData.append('debtID', DebtID.toString());
			formData.append('hardshipCategoryID', input.hardshipCategoryID);
			formData.append('amount', input.amount);
			formData.append('note', input.note);
			input.files?.forEach((file) => {
				formData.append('files', file);
			});
			const { data } = await fcAPI.post<IBalanceReduction>(ClientBalanceReductionAPI.BALANCE_REDUCTION, formData, {
				headers: { 'content-type': 'multipart/form-data' },
			});
			if (data.success) {
				return data.data;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export interface BalanceReductionHardshipDetails {
	hardshipCategoryID: string;
	amount: string;
	note: string;
}

export interface BalanceReductionState extends BalanceReductionHardshipDetails {
	activeStep: number;
	status: Status;
	outcome: string | undefined;
}

const initialState: BalanceReductionState = {
	activeStep: 0,
	status: Status.IDLE,
	hardshipCategoryID: '',
	amount: '',
	note: '',
	outcome: undefined,
};

export const balanceReductionSlice = createSlice({
	name: 'clientBalanceReduction',
	initialState,
	reducers: {
		setBalanceReductionActiveStep: (state, action: PayloadAction<number>) => {
			state.activeStep = action.payload;
		},
		setBalanceReductionHardshipDetails: (state, action: PayloadAction<BalanceReductionHardshipDetails>) => {
			return {
				...state,
				...action.payload,
			};
		},
		resetBalanceReduction: () => {
			return initialState;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientBalanceReductionPost.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientBalanceReductionPost.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.outcome = action.payload.outcome;
			})
			.addCase(clientBalanceReductionPost.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export const { setBalanceReductionActiveStep, setBalanceReductionHardshipDetails, resetBalanceReduction } = balanceReductionSlice.actions;

export default balanceReductionSlice.reducer;

export const selectClientBalanceReductionActiveStep = (state: RootState) => state.clientBalanceReduction.activeStep;
export const selectClientBalanceReductionStatus = (state: RootState) => state.clientBalanceReduction.status;
export const selectClientBalanceReductionDetails = (state: RootState) => state.clientBalanceReduction;
export const selectClientBalanceReductionOutcome = (state: RootState) => state.clientBalanceReduction.outcome;
