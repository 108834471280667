import { Container, Button, AppBar, Toolbar, Box, Link, useMediaQuery, useTheme, useScrollTrigger, Slide } from '@mui/material';
import { LogoHorizontal } from '../../../icons/branding/LogoHorizontal';

import { LinkButton } from '../../../components/StyledLinkButton';

import { AuthRoutes, PortalRoutes, PublicRoutes } from '../../../constants/routes.constant';
import { styled } from '@mui/material/styles';
import { DrawerComp } from './DrawerComp';
import { selectAuthenticated } from '../../../store/features/auth/authSlice';
import { useAppSelector } from '../../../store/hooks';

type NavbarProps = {
	bgColor?: React.CSSProperties['backgroundColor'];
	boxShadow?: React.CSSProperties['boxShadow'];
};

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		paddingLeft: '0',
		paddingRight: '0',
	},
}));

const WhiteLink = styled(Link)(({ theme }) => ({
	color: 'rgba(255, 255, 255, 0.8)',
	display: 'inline-block',
	textDecoration: 'none',

	'&:hover,&:active': {
		textDecoration: 'underline',
		textDecorationColor: '#34C07D',
	},
}));

interface HideOnScrollProps {
	children: React.ReactElement;
}

const HideOnScroll = ({ children }: HideOnScrollProps) => {
	const trigger = useScrollTrigger();
	return (
		<Slide in={!trigger} appear={false} direction={'down'}>
			{children}
		</Slide>
	);
};

export const Navbar: React.FC<NavbarProps> = ({
	bgColor = '#062239',
	boxShadow = '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%',
}) => {
	const theme = useTheme();
	const isMatch = useMediaQuery(theme.breakpoints.down('md'));
	const isAuthenticated = useAppSelector(selectAuthenticated);

	return (
		<>
			<HideOnScroll>
				<AppBar sx={{ backgroundColor: bgColor, paddingLeft: '0', paddingRight: '0', boxShadow: boxShadow }}>
					<Container sx={{ paddingLeft: '0', paddingRight: '0' }}>
						<CustomToolbar>
							<a href={PublicRoutes.HOME}>
								<LogoHorizontal height={42} fill="#FFFFFF" />
							</a>

							{isMatch ? (
								<>
									<DrawerComp />
								</>
							) : (
								<>
									<Box sx={{ marginLeft: '40%' }}>
										<WhiteLink href={`${PublicRoutes.HOME}#how`}>How it works</WhiteLink>
										<WhiteLink sx={{ ml: '2vw' }} href={PublicRoutes.CONTACTS}>
											Contact us
										</WhiteLink>
									</Box>

									{!isAuthenticated ? (
										<>
											<Button
												sx={{ marginLeft: 'auto' }}
												color="secondary"
												variant="outlined"
												component={LinkButton}
												to={AuthRoutes.SIGN_UP}
												size="medium"
											>
												SIGN UP
											</Button>
											<Button
												sx={{ marginLeft: '1rem' }}
												color="secondary"
												variant="contained"
												component={LinkButton}
												to={AuthRoutes.LOGIN}
												size="medium"
											>
												LOG IN
											</Button>
										</>
									) : (
										<Button
											sx={{ marginLeft: 'auto' }}
											color="secondary"
											variant="outlined"
											component={LinkButton}
											to={PortalRoutes.DASHBOARD}
											size="medium"
										>
											GO TO PORTAL
										</Button>
									)}
								</>
							)}
						</CustomToolbar>
					</Container>
				</AppBar>
			</HideOnScroll>
		</>
	);
};
