import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileUpload } from '../../../../components/UploadComponent';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { setErrorSnackbarOpen } from '../../app/appSlice';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientDebtWaiverAPI, IPostClientDebtWaiver } from './types';

export const clientDebtWaiverPost = createAsyncThunk(
	'client/debt-waiver',
	async (debtWaiverDetails: DebtWaiverHardshipDetails & FileUpload, { rejectWithValue, dispatch, getState }) => {
		const { DebtID, DebtorEntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
		try {
			const formData = new FormData();
			formData.append('entityID', DebtorEntityID.toString());
			formData.append('debtID', DebtID.toString());
			formData.append('hardshipCategoryID', debtWaiverDetails.hardshipCategoryID);
			formData.append('note', debtWaiverDetails.note);
			debtWaiverDetails.files?.forEach((file) => {
				formData.append('files', file);
			});
			const { data } = await fcAPI.post<IPostClientDebtWaiver>(ClientDebtWaiverAPI.DEBT_WAIVER, formData, {
				headers: { 'content-type': 'multipart/form-data' },
			});
			if (data.success) {
				return data.success;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export interface DebtWaiverHardshipDetails {
	hardshipCategoryID: string;
	note: string;
}

export interface DebtWaiverState extends DebtWaiverHardshipDetails {
	activeStep: number;
	status: Status;
}

const initialState: DebtWaiverState = {
	activeStep: 0,
	status: Status.IDLE,
	hardshipCategoryID: '',
	note: '',
};

export const debtWaiverSlice = createSlice({
	name: 'clientDebtWaiver',
	initialState,
	reducers: {
		setDebtWaiverActiveStep: (state, action: PayloadAction<number>) => {
			state.activeStep = action.payload;
		},
		setDebtWaiverHardshipDetails: (state, action: PayloadAction<DebtWaiverHardshipDetails>) => {
			return {
				...state,
				...action.payload,
			};
		},
		resetDebtWaiver: () => {
			return initialState;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientDebtWaiverPost.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientDebtWaiverPost.fulfilled, (state) => {
				state.status = Status.SUCCEEDED;
			})
			.addCase(clientDebtWaiverPost.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export const { setDebtWaiverActiveStep, setDebtWaiverHardshipDetails, resetDebtWaiver } = debtWaiverSlice.actions;

export default debtWaiverSlice.reducer;

export const selectClientDebtWaiverActiveStep = (state: RootState) => state.clientDebtWaiver.activeStep;
export const selectClientDebtWaiverStatus = (state: RootState) => state.clientDebtWaiver.status;
export const selectClientDebtWaiverDetails = (state: RootState) => state.clientDebtWaiver;
