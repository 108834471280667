import { Container, Grid, Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Footer } from './components/Footer';
import { Navbar } from './components/Nav';
import { Fade } from 'react-awesome-reveal';

const StyledTitle = styled(Typography)(({ theme }) => ({
	mt: 1,
	textDecoration: 'underline',
	textDecorationThickness: '5px',
	textUnderlineOffset: '1px',
	textDecorationColor: '#34C07D',
}));

export const Policy = () => {
	return (
		<>
			<Navbar />

			<Box
				sx={{
					backgroundColor: '#ffffff',
					paddingTop: '25vh',
					paddingBottom: '15vh',
				}}
			>
				<Container>
					<Grid container columns={{ md: 2 }} spacing={{ xs: 3, md: 6 }}>
						<Grid md={1} item>
							<Fade delay={6}>
								<Typography variant="h4">Privacy policy</Typography>
							</Fade>
						</Grid>
						<Grid md={1} item></Grid>
						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Our Commitment to Protect Your Privacy</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								We are committed to protecting your privacy. This Privacy Policy outlines how we (PF Group Holdings Pty Ltd and our
								related companies) manage your personal information. For Australia, we comply with the Privacy Act 1988 (Cth) and the
								Australian Privacy Principles.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">What kind of personal information do we collect and hold?</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
								The kinds of personal information that we collect and hold include:
							</Typography>
							<ul>
								<li>your identity and contact information such as name, date of birth, address, phone number and email address;</li>
								<li>
									information about your accounts and products with us such as the balance, the type of product, creditor/s,
									repayment schedules and history, and communication history;
								</li>
								<li>
									your financial information such as your occupation or business activities, employment status, income, expenses,
									repayment capacity, assets, liabilities and other personal information related to this;
								</li>
								<li>
									your credit information and credit eligibility information, further information about this process is outlined in
									our Credit Reporting Policy
								</li>
								<li>
									your payment information such as BSB and account number, credit card number or alternative payment method details;
								</li>
								<li>
									information about your activities on our websites, portals, social media, live chat or other digital platforms;
									and
								</li>
								<li>
									for employment purposes, details about your experience, skills, qualifications, background and other related
									matters.
								</li>
							</ul>
							<Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
								Inbound and outbound phone calls to and from our call centres are recorded and may be monitored or reviewed for
								compliance, record-keeping and training purposes. We periodically delete call-recordings.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
								As our interactions with you are most likely to relate to your account or product, it is not possible for you to
								interact with us anonymously or using a pseudonym unless the interaction is a general inquiry or does not relate to
								your account.
							</Typography>

							<Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
								There may be occasions where you voluntarily disclose sensitive information to in your communications with us.
								Examples of sensitive information include:
							</Typography>
							<ul>
								<li>information about your race or ethnic origin;</li>
								<li>information about your political opinions or associations;</li>
								<li>information about your religion or philosophical beliefs;</li>
								<li>information about trade union membership or associations;</li>
								<li>information about your sexual information or practices;</li>
								<li>information about your criminal history;</li>
								<li>your health or genetic information</li>
							</ul>
							<Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
								We will only collect sensitive information about you if you consent to us collecting this information. We will also
								only collect sensitive information if it is directly relevant to our functions or activities. For example, we will
								only collect this information if it impacts your ability to make repayments.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">How do we collect and hold personal information?</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
								We may collect and hold personal information in ways that include:
							</Typography>
							<ul>
								<li>
									from you, during our interactions with you or when you access or use our online services such as our website,
									portal or social media and through the use of cookies;
								</li>
								<li>
									from your creditors and financial institutions and related service providers when we purchase an account from
									them, act on their behalf as their agent or service provider, are engaged by them to manage or recover an account
									or provide a service relating to this, and are assisting you with a financial solution such as assisting with a
									loan application or other credit product or solution;
								</li>
								<li>from credit reporting bodies or other information providers</li>
								<li>from third parties that you have authorised to act on your behalf in your interactions with us;</li>
								<li>from other third parties if we are having difficulty locating or contacting you;</li>
								<li>from your employer about your employment and income;</li>
								<li>from third party information service providers;</li>
								<li>from applications such as TICA; and</li>
								<li>We store any information that we collect electronically in our secure internal customer management system.</li>
							</ul>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">For what purposes do we collect, hold, use and disclose personal information?</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
								We collect, hold, use and disclose your personal information for the purpose of efficiently and effectively carrying
								out our functions and activities including:
							</Typography>
							<ul>
								<li>assisting you with financial solutions such as credit products and services;</li>
								<li>
									buying and recovering accounts, debts and credit facilities from creditors such as banks, finance companies, and
									utility and telecommunication providers;
								</li>
								<li>
									acting as an agent for our clients such as banks, insurers, and utility providers to recover defaulted, cancelled
									and/or terminated credit facilities;
								</li>
								<li>providing accounts receivable management services for our clients;</li>
								<li>recruiting and employing staff;</li>
								<li>monitoring, developing, improving and marketing our products and services;</li>
								<li>other related functions and activities.</li>
							</ul>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">How can you update your personal information that we hold?</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								It is important to us that the personal information we hold about you is accurate and up to date. During the course of
								our relationship with you we request that you inform us if any of your personal information has changed. If you wish
								to make any changes to your personal information, you may contact us or alternatively log in to our customer portal
								(if applicable) to access and update the relevant information.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">How can you access your personal information that we hold?</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								You can make an access request to us for the personal information we hold about you. We will not charge you a fee for
								making an access request, but we may charge a reasonable fee for supplying access to the information we hold about
								you. When this applies, we will advise you upfront that a fee may apply and the basis for the calculation of the fee.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Depending on the type of request that you make we may respond to your request immediately, otherwise we will respond
								to you as soon as we reasonably can, but within 30 days. If we are unable to meet this timeframe, we will advise you.
								There may be situations where we are unable to provide you with access to your information, or provide you with the
								information in the form that you request. If this applies, we will advise you of this and the reasons (if we are able
								to).
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">How can you seek correction of your personal information that we hold?</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								If any of the personal information we hold about you is incorrect, inaccurate, or out of date, you may request that we
								correct the information. We may need to consult with other organisations as part of our investigation. If we refuse to
								correct information, we will advise you of this and provide you with the reasons for not correcting the information
								(if we are able to).
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Depending on the type of correction request that you make we may respond to your request immediately, otherwise we
								will respond to you as soon as we reasonably can, but within 30 days. If we are unable to meet this timeframe, we will
								advise you.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Our contact details are:
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Phone:{' '}
								<a href={window.config.REACT_APP_CONTACT_NUMBER_HREF} style={{ color: '#3F51B5' }}>
									{window.config.REACT_APP_CONTACT_NUMBER}
								</a>
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Email:{' '}
								<a href={window.config.REACT_APP_CONTACT_EMAIL_HREF} style={{ color: '#3F51B5' }}>
									{window.config.REACT_APP_CONTACT_EMAIL}
								</a>
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Complaints Manager:
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Phone:{' '}
								<a href="tel:07 3535 9034" style={{ color: '#3F51B5' }}>
									(07) 3535 9034
								</a>
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Email:{' '}
								<a href={window.config.REACT_APP_CONTACT_EMAIL_HREF} style={{ color: '#3F51B5' }}>
									{window.config.REACT_APP_CONTACT_EMAIL}
								</a>
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">How can you make a complaint to an external body?</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								If you believe that we are in breach of any of our obligations under the Australian Privacy Principles or the Privacy
								Act, you may lodge a complaint in writing with our Internal Dispute Resolution Department. Once your complaint has
								been submitted, we will endeavour to provide a response within 30 days. If we are unable to provide a response within
								30 days, or if you are dissatisfied with our response to your complaint, you may lodge a complaint with the following
								external bodies:
							</Typography>
							<Typography variant="subtitle2" sx={{ mt: 2, mb: 1, textDecoration: 'underline' }}>
								For Australia:
							</Typography>
							<Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
								Australian Financial Complaints Authority
							</Typography>
							<Typography>
								Phone:{' '}
								<a href="tel:1800 931 678" style={{ color: '#3F51B5' }}>
									1800 931 678
								</a>{' '}
								(Free call)
							</Typography>
							<Typography sx={{ mt: 1 }}>
								Email:{' '}
								<a href="mailto:info@afca.org.au" style={{ color: '#3F51B5' }}>
									info@afca.org.au
								</a>
							</Typography>
							<Typography sx={{ mt: 1 }}>
								Website:{' '}
								<a href="https://www.afca.org.au/" style={{ color: '#3F51B5' }}>
									www.afca.org.au
								</a>
							</Typography>
							<Typography sx={{ mt: 1 }}>Postal Address: GPO Box 3, Melbourne VIC 3001</Typography>

							<Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
								Office of the Australian Information Commissioner
							</Typography>
							<Typography>
								Phone:{' '}
								<a href="tel:1300 363 992" style={{ color: '#3F51B5' }}>
									1300 363 992
								</a>
							</Typography>
							<Typography sx={{ mt: 1 }}>
								Website:{' '}
								<a href="https://www.oaic.gov.au/" style={{ color: '#3F51B5' }}>
									www.oaic.gov.au
								</a>
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Do we disclose your personal information?</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
								We may disclose your personal information to organisations that include:
							</Typography>
							<ul>
								<li>our related companies;</li>
								<li>
									our authorised agents, legal advisers, and service providers (such as technology service providers, identity
									verification service providers, bulk mail handling providers, document storage providers information or data
									providers and process servers);
								</li>
								<li>your current or former credit providers and their authorised service providers and agents;</li>
								<li>your authorised representative;</li>
								<li>courts, tribunals and external dispute resolution schemes;</li>
								<li>where we are required to do so by law, such as under anti-money laundering legislation;</li>
								<li>
									to investors, agents or advisers, or any entity that has or is considering a financial interest in our business or
									part of our business such as a debt portfolio;
								</li>
								<li>
									to third parties and employers when we are conducting employment or income enquiries or attempting to make contact
									with you;
								</li>
								<li>
									in relation to employment and recruitment, your referees, education provider, employers and our background
									verification service provider to verify information about your employment application; and
								</li>
								<li>
									We may also disclose certain personal information to credit reporting bodies. For example, if you fail to meet
									your repayment obligations, we may be entitled to disclose that information to such a body. When such information
									is disclosed to a credit reporting body, that body may include such information in reports that are subsequently
									provided to credit providers to assist them in assessing your credit worthiness. We may disclose information to
									the following credit reporting bodies
								</li>
							</ul>

							<Typography variant="body1" sx={{ mt: 3, mb: 3, textDecoration: 'underline' }}>
								For Australia:
							</Typography>
							<Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>
								Illion
							</Typography>
							<Typography>Phone: 13 23 33</Typography>
							<Typography sx={{ mt: 1 }}>
								Website:{' '}
								<a href="https://www.illion.com.au/" style={{ color: '#3F51B5' }}>
									www.illion.com.au
								</a>
							</Typography>

							<Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
								Experian
							</Typography>
							<Typography>Phone: 1300 783 684</Typography>
							<Typography sx={{ mt: 1 }}>
								Website:{' '}
								<a href="https://www.experian.com.au/" style={{ color: '#3F51B5' }}>
									www.experian.com.au
								</a>
							</Typography>

							<Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
								Equifax
							</Typography>
							<Typography>Phone: 13 83 32</Typography>
							<Typography sx={{ mt: 1 }}>
								Website:{' '}
								<a href="https://www.equifax.com.au/personal/" style={{ color: '#3F51B5' }}>
									www.equifax.com.au
								</a>
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Do we disclose personal information to overseas recipients?</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Like most businesses, we may need to disclose certain information to overseas recipients from time to time to perform
								certain functions and activities. We take the necessary steps to ensure that personal information is protected when it
								is disclosed overseas. We may disclose your information to our clients, contractors or service providers in overseas
								countries including (but not limited to) New Zealand (for those not in New Zealand), Australia (for those not in
								Australia) and the Philippines. If we have reason to believe that you are located overseas, we may disclose your
								information to our overseas agent in that country to assist us in performing our functions and activities.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Direct marketing</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Occasionally we may use your personal information to provide you with current information about offers you may find of
								interest, changes to our organisation, or new products or services being offered by us. If you do not wish to receive
								marketing information, you may at any time contact us to request that you no longer receive direct marketing. We will
								give effect to your request at the earliest possible opportunity.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Statement of Notifiable Matters</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Our Statement of Notifiable Matters is contained in our{' '}
								<Link href="https://aueprodpublicassets.blob.core.windows.net/documents/Credit%20Reporting%20Policy.pdf">
									Credit Reporting Policy
								</Link>{' '}
								and provides information on the entities to whom we may disclose your credit information as well as information on
								certain rights you have in relation to your credit information .
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Footer />
		</>
	);
};
