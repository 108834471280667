import { Box, Card, Container, Grid, styled, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { Page } from '../../components/Page';
import { TabPanel } from '../../components/TabPanel';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import { UserDetails } from './components/UserDetails';
import { UpdatePassword } from './components/UpdatePassword';

enum AccountSettingsTabs {
	General,
	Security,
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
	minHeight: 48,
	'& .MuiButtonBase-root': {
		minHeight: 48,
		fontSize: theme.typography.body1.fontSize,
		textTransform: 'none',
		'&.Mui-selected': {
			color: theme.palette.secondary.contrastText,
		},
		paddingLeft: 0,
		paddingRight: 0,
		'&:not(:last-of-type)': {
			marginRight: theme.spacing(4),
		},
	},
	'& .MuiTabs-indicator': {
		height: 3,
	},
	marginBottom: theme.spacing(4),
}));

export const AccountSettings = () => {
	const [value, setValue] = useState<AccountSettingsTabs>(AccountSettingsTabs.General);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Page title="Account Settings">
			<Container maxWidth="md">
				<Box sx={{ pb: 5 }}>
					<Grid container>
						<Grid item>
							<Typography variant="h5">Account Settings</Typography>
						</Grid>
					</Grid>
				</Box>
				<Grid container>
					<Grid item xs={12}>
						<StyledTabs value={value} onChange={handleChange} textColor="secondary" indicatorColor="secondary">
							<Tab label="General" iconPosition="start" icon={<AccountBoxOutlinedIcon />} />
							<Tab label="Change Password" iconPosition="start" icon={<KeyOutlinedIcon />} />
						</StyledTabs>
						<Card elevation={0}>
							<TabPanel value={value} index={0}>
								<UserDetails />
							</TabPanel>
							<TabPanel value={value} index={1}>
								<UpdatePassword />
							</TabPanel>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
};
