import { FCResponse } from '../../../../services/api/base.api.response';

export enum ClientBalanceReductionAPI {
	BALANCE_REDUCTION = '/api/client/balance-reduction',
}

export interface IClientBalanceReductionOutput {
	hardshipID: number;
	outcome: string;
}

export type IBalanceReduction = FCResponse<IClientBalanceReductionOutput>;
