import React from 'react';

export const WalletIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M21.392 9.559c-.354 4.2-4.732 4.864-4.732 4.864 3.759.177 4.696 5.107 4.696 5.107.08-3.515 4.839-5.16 4.839-5.16-3.565-.213-4.803-4.811-4.803-4.811Z"
				fill="#FFD452"
			/>
			<path
				d="M26.23 13.828c-3.127-.188-4.3-4.369-4.31-4.411a.544.544 0 0 0-1.07.096c-.312 3.712-4.11 4.347-4.27 4.372a.545.545 0 0 0 .057 1.083c3.28.155 4.177 4.62 4.186 4.666a.546.546 0 0 0 1.08-.09c.07-3.091 4.429-4.642 4.473-4.657a.545.545 0 0 0-.146-1.06ZM21.3 17.586c-.493-1.137-1.347-2.51-2.756-3.225 1.065-.51 2.328-1.421 2.98-3.005.563 1.115 1.525 2.5 3.027 3.169-1.093.595-2.515 1.6-3.25 3.061Z"
				fill="#000"
				fillOpacity={0.6}
			/>
			<path
				d="M13.51 15.727c-.25 2.972-3.346 3.44-3.346 3.44 2.659.126 3.321 3.613 3.321 3.613.057-2.486 3.423-3.649 3.423-3.649-2.522-.15-3.397-3.404-3.397-3.404Z"
				fill="#FFD452"
			/>
			<path
				d="M16.938 18.588c-2.094-.126-2.896-2.974-2.904-3.003a.545.545 0 0 0-1.069.097c-.21 2.496-2.777 2.93-2.885 2.947a.545.545 0 0 0 .057 1.083c2.192.104 2.806 3.14 2.811 3.17a.545.545 0 0 0 1.08-.089c.048-2.074 3.027-3.136 3.056-3.146a.545.545 0 0 0-.146-1.059Zm-3.482 2.468c-.335-.685-.85-1.433-1.615-1.918.674-.378 1.343-.955 1.77-1.786.384.68.964 1.437 1.794 1.898-.671.4-1.449.995-1.949 1.806Z"
				fill="#000"
				fillOpacity={0.6}
			/>
			<path
				d="M13.497 6.044c-.239 2.972-3.333 3.454-3.333 3.454 2.659.114 3.335 3.6 3.335 3.6.047-2.487 3.409-3.663 3.409-3.663-2.523-.142-3.41-3.391-3.41-3.391Z"
				fill="#FFD452"
			/>
			<path
				d="M16.936 8.89c-2.086-.117-2.9-2.937-2.915-2.99a.545.545 0 0 0-1.069.1c-.2 2.497-2.766 2.941-2.873 2.959a.546.546 0 0 0 .06 1.083c2.193.094 2.818 3.129 2.823 3.158a.545.545 0 0 0 1.08-.093c.04-2.074 3.015-3.149 3.044-3.159a.545.545 0 0 0-.15-1.058Zm-3.472 2.482c-.338-.684-.856-1.43-1.623-1.912.673-.38 1.339-.96 1.763-1.793.386.68.97 1.433 1.802 1.89-.67.403-1.445 1.002-1.942 1.815Z"
				fill="#000"
				fillOpacity={0.6}
			/>
			<path
				d="M70.343 17.475 50.37 6.361a2.87 2.87 0 0 0-3.902 1.112l-3.198 5.748-2.462 4.424L22.87 49.882a2.87 2.87 0 0 0 1.112 3.902l19.973 11.114a2.87 2.87 0 0 0 3.903-1.112l23.597-42.409a2.87 2.87 0 0 0-1.112-3.902Z"
				fill="#87796F"
			/>
			<path
				d="M72.23 19.047A3.393 3.393 0 0 0 70.608 17L50.634 5.885a3.39 3.39 0 0 0-2.596-.3 3.391 3.391 0 0 0-2.047 1.624l-3.199 5.747a.545.545 0 1 0 .952.53l3.199-5.747a2.31 2.31 0 0 1 1.394-1.105 2.307 2.307 0 0 1 1.767.204l19.973 11.114c.542.302.935.797 1.105 1.394.17.598.098 1.225-.204 1.767L47.38 63.522a2.31 2.31 0 0 1-1.394 1.105 2.31 2.31 0 0 1-1.767-.205L24.246 53.308a2.327 2.327 0 0 1-.9-3.16L41.282 17.91a.545.545 0 1 0-.952-.53L22.393 49.617a3.39 3.39 0 0 0-.3 2.596c.25.877.827 1.605 1.624 2.048L43.69 65.375a3.393 3.393 0 0 0 2.596.3 3.39 3.39 0 0 0 2.047-1.623l23.598-42.409a3.39 3.39 0 0 0 .3-2.596Z"
				fill="#000"
				fillOpacity={0.6}
			/>
			<path
				d="m79.462 29.481-5.21-8.036-2.118-3.268L67.03 10.3a2.87 2.87 0 0 0-3.969-.847L22.336 35.849a2.87 2.87 0 0 0-.847 3.969l12.432 19.18a2.87 2.87 0 0 0 3.968.847L78.615 33.45a2.87 2.87 0 0 0 .847-3.969Z"
				fill="#FCE8CB"
			/>
			<path
				d="m79.916 29.185-7.327-11.304-5.105-7.876a3.391 3.391 0 0 0-2.152-1.482 3.39 3.39 0 0 0-2.57.473L22.038 35.392a3.418 3.418 0 0 0-1.008 4.722l12.431 19.18a3.414 3.414 0 0 0 4.722 1.009l40.726-26.396a3.419 3.419 0 0 0 1.008-4.722Zm-1.6 3.807L37.588 59.388a2.327 2.327 0 0 1-3.215-.687l-12.431-19.18a2.328 2.328 0 0 1 .686-3.215L63.355 9.91a2.307 2.307 0 0 1 1.75-.321c.607.129 1.127.487 1.464 1.008l12.432 19.18a2.328 2.328 0 0 1-.686 3.215Z"
				fill="#000"
				fillOpacity={0.6}
			/>
			<path
				d="M54.136 44.307c5.333-2.022 8.017-7.984 5.995-13.316-2.021-5.333-7.983-8.017-13.315-5.995-5.333 2.021-8.017 7.983-5.995 13.316 2.021 5.332 7.983 8.016 13.315 5.995Z"
				fill="#F1F9F1"
			/>
			<path
				d="M59.595 28.738c-3.26-5.03-10.004-6.47-15.034-3.21-5.03 3.26-6.47 10.004-3.21 15.034a10.871 10.871 0 0 0 9.139 4.955c2.025 0 4.073-.564 5.895-1.745a10.8 10.8 0 0 0 4.719-6.853c.606-2.84.07-5.745-1.51-8.181Zm-3.803 14.12C51.266 45.79 45.2 44.495 42.266 39.97c-2.933-4.526-1.638-10.594 2.887-13.527a9.722 9.722 0 0 1 5.305-1.57 9.783 9.783 0 0 1 8.223 4.457c2.933 4.526 1.637 10.594-2.889 13.528Z"
				fill="#000"
				fillOpacity={0.6}
			/>
			<path
				d="M55.146 34.172c-.866-1.336-2.108-1.691-4.279-1.17-1.57.343-2.291.368-2.609-.122-.27-.415-.227-1.033.738-1.659 1.069-.693 1.973-.794 2.465-.88l-.657-1.957c-.644.08-1.447.305-2.47.905l-.847-1.307-1.455.944.914 1.41c-1.386 1.341-1.643 2.962-.796 4.269.934 1.44 2.498 1.48 4.433.985 1.34-.341 2.046-.293 2.411.27.385.595.017 1.297-.829 1.845-.965.626-2.043.882-2.887.944l.68 2.026c.776-.04 1.914-.398 2.938-.997l.914 1.41 1.455-.943-.982-1.515c1.515-1.402 1.72-3.137.863-4.458Z"
				fill="#FFD452"
			/>
			<path
				d="M54.98 38.703c1.348-1.466 1.601-3.32.623-4.828-.996-1.54-2.496-1.97-4.852-1.406-.86.189-1.869.371-2.035.114a.296.296 0 0 1-.036-.278c.038-.11.168-.339.612-.627.88-.57 1.626-.695 2.12-.777l.144-.025a.545.545 0 0 0 .422-.71l-.657-1.957a.545.545 0 0 0-.584-.367c-.724.09-1.455.328-2.221.724l-.571-.88a.546.546 0 0 0-.754-.162l-1.455.943a.545.545 0 0 0-.16.754l.679 1.049c-1.278 1.441-1.5 3.183-.562 4.63 1.233 1.903 3.334 1.65 5.025 1.217 1.54-.392 1.729-.1 1.82.04.058.09.105.21.01.41-.104.223-.345.464-.679.68-1.004.651-2.08.818-2.631.858a.545.545 0 0 0-.477.717l.68 2.026a.546.546 0 0 0 .545.37c.785-.039 1.794-.346 2.727-.82l.64.985a.545.545 0 0 0 .753.161l1.455-.943a.545.545 0 0 0 .16-.753l-.74-1.145Zm-1.01 1.63-.617-.952a.545.545 0 0 0-.733-.174c-.74.434-1.593.758-2.285.877l-.329-.978a6.656 6.656 0 0 0 2.456-.944c.51-.33.88-.72 1.073-1.13.235-.5.206-1.022-.083-1.468-.62-.956-1.809-.807-3.004-.503-2.041.523-3.155.304-3.84-.753-.72-1.111-.452-2.45.717-3.581a.545.545 0 0 0 .078-.688l-.617-.953.54-.35.55.849c.16.245.483.32.734.174.644-.378 1.246-.628 1.827-.759l.307.916a5.954 5.954 0 0 0-2.044.848c-.55.355-.903.755-1.05 1.188-.144.418-.09.852.151 1.224.554.855 1.718.677 3.194.355 2.217-.533 3.08-.01 3.694.936.76 1.173.463 2.615-.776 3.763a.545.545 0 0 0-.087.696l.685 1.057-.54.35Z"
				fill="#565656"
			/>
			<path
				d="M77.192 40.376H23.759a5.65 5.65 0 0 0-5.65 5.65V81.35A5.65 5.65 0 0 0 23.76 87h53.433a5.65 5.65 0 0 0 5.65-5.65V46.025a5.65 5.65 0 0 0-5.65-5.649Z"
				fill="#EDF7FE"
			/>
			<path
				d="M77.19 39.831H23.757a6.202 6.202 0 0 0-6.195 6.194v15.552a.545.545 0 0 0 1.09 0V46.025a5.11 5.11 0 0 1 5.105-5.104H77.19a5.11 5.11 0 0 1 5.105 5.104v35.326a5.11 5.11 0 0 1-5.105 5.105H23.757a5.11 5.11 0 0 1-5.105-5.105V66.74a.545.545 0 0 0-1.09 0v14.61a6.202 6.202 0 0 0 6.195 6.196H77.19a6.202 6.202 0 0 0 6.195-6.195V46.025a6.202 6.202 0 0 0-6.195-6.194Z"
				fill="#000"
				fillOpacity={0.6}
			/>
			<path d="M66.066 55.263a8.425 8.425 0 1 0 0 16.85h16.772v-16.85H66.066Z" fill="#FFD452" />
			<path
				d="M82.84 54.719H66.067c-4.946 0-8.97 4.023-8.97 8.97 0 4.945 4.024 8.969 8.97 8.969H82.84c.3 0 .545-.244.545-.545v-16.85c0-.3-.244-.544-.545-.544Zm-.545 16.85H66.067c-4.345 0-7.88-3.535-7.88-7.88s3.535-7.88 7.88-7.88h16.228v15.76Z"
				fill="#000"
				fillOpacity={0.6}
			/>
			<path d="M66.765 60.86a2.828 2.828 0 1 0 0 5.656 2.828 2.828 0 0 0 0-5.656Z" fill="#87796F" />
			<path
				d="M66.764 60.315a3.377 3.377 0 0 0-3.373 3.373 3.377 3.377 0 0 0 3.373 3.373 3.377 3.377 0 0 0 3.372-3.373 3.377 3.377 0 0 0-3.372-3.373Zm0 5.657a2.286 2.286 0 0 1-2.283-2.283 2.286 2.286 0 0 1 2.283-2.283 2.285 2.285 0 0 1 2.282 2.282 2.286 2.286 0 0 1-2.282 2.284Z"
				fill="#000"
				fillOpacity={0.6}
			/>
			<path
				d="M77.188 40.92h-7.266a5.109 5.109 0 0 1 5.104 5.105v8.693h7.266v-8.693a5.109 5.109 0 0 0-5.105-5.104ZM75.026 72.657v8.694a5.109 5.109 0 0 1-5.104 5.104h7.266a5.109 5.109 0 0 0 5.104-5.105v-8.693h-7.266Z"
				fill="#BADDF5"
			/>
			<path d="M82.293 55.809h-7.266v15.759h7.266v-15.76Z" fill="#DBB74C" />
		</svg>
	);
};
