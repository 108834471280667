import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientArrangementsAPI, IClientArrangementSchedule, IGetScheduleOutput } from './types';

export const getUpcomingPaymentSchedule = createAsyncThunk(
	'client/arrangement/schedule',
	async (input: { arrangementIDs: number[]; pageNumber: number; pageSize: number }, { rejectWithValue, dispatch, getState }) => {
		try {
			const { DebtID, DebtorEntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
			const { data } = await fcAPI.get<IClientArrangementSchedule>(ClientArrangementsAPI.SCHEDULE, {
				params: {
					debtID: DebtID,
					entityID: DebtorEntityID,
					arrangementIDs: JSON.stringify(input.arrangementIDs),
					pageNumber: input.pageNumber,
					pageSize: input.pageSize,
					uncOnly: 'true',
				},
			});
			if (data.success) {
				return data.data.schedule;
			}
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export const getPaymentScheduleHistory = createAsyncThunk(
	'client/arrangement/schedule/history',
	async (input: { arrangementIDs: number[]; pageNumber: number; pageSize: number }, { rejectWithValue, dispatch, getState }) => {
		try {
			const { DebtID, DebtorEntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
			const { data } = await fcAPI.get<IClientArrangementSchedule>(ClientArrangementsAPI.SCHEDULE, {
				params: {
					debtID: DebtID,
					entityID: DebtorEntityID,
					arrangementIDs: JSON.stringify(input.arrangementIDs),
					pageNumber: input.pageNumber,
					pageSize: input.pageSize,
					uncOnly: 'false',
				},
			});
			if (data.success) {
				return data.data.schedule;
			}
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export interface ClientArrangementScheduleState {
	upcoming: IGetScheduleOutput[];
	history: IGetScheduleOutput[];
	status: Status;
	historyStatus: Status;
}

const initialState: ClientArrangementScheduleState = {
	upcoming: [],
	history: [],
	status: Status.IDLE,
	historyStatus: Status.IDLE,
};

const clientArrangementScheduleSlice = createSlice({
	name: 'clientArrangementSchedule',
	initialState,
	reducers: {
		resetScheduleHistory: (state) => {
			state.historyStatus = Status.IDLE;
			state.history = [];
		},
	},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(getUpcomingPaymentSchedule.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(getUpcomingPaymentSchedule.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.upcoming = action.payload;
			})
			.addCase(getUpcomingPaymentSchedule.rejected, (state) => {
				state.status = Status.FAILED;
			})
			.addCase(getPaymentScheduleHistory.pending, (state) => {
				state.historyStatus = Status.LOADING;
			})
			.addCase(getPaymentScheduleHistory.fulfilled, (state, action) => {
				state.historyStatus = Status.SUCCEEDED;
				state.history = action.payload;
			})
			.addCase(getPaymentScheduleHistory.rejected, (state) => {
				state.historyStatus = Status.FAILED;
			});
	},
});

export default clientArrangementScheduleSlice.reducer;
export const { resetScheduleHistory } = clientArrangementScheduleSlice.actions;

export const selectUpcomingPayments = (state: RootState) => state.clientArrangementSchedule.upcoming;
export const selectPaymentHistory = (state: RootState) => state.clientArrangementSchedule.history;
export const selectUpcomingPaymentsStatus = (state: RootState) => state.clientArrangementSchedule.status;
export const selectPaymentHistoryStatus = (state: RootState) => state.clientArrangementSchedule.historyStatus;
