import {
	Card,
	CardContent,
	CardHeader,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState, useEffect } from 'react';
import { PerfectScrollbar } from '../../../components/PerfectScrollbar';
import { TableEmpty } from '../../../components/TableEmpty';
import { TableLoader } from '../../../components/TableLoader';
import { dateFormats } from '../../../constants/dateFormat.constant';
import { Status } from '../../../enums/status.enum';
import {
	getClientArrangementsInActive,
	selectInactiveArrangements,
	selectInactiveArrangementsStatus,
} from '../../../store/features/client/arrangements/arrangementsSlice';
import { resetScheduleHistory } from '../../../store/features/client/arrangements/scheduleSlice';
import { IInactiveArrangements } from '../../../store/features/client/arrangements/types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getComparator, Order } from '../../../utils/table.util';
import { formatMoney } from '../../../utils/text.utill';
import { PaymentPlanHistoryModal } from './PaymentPlanHistoryModal';

const pageTitle = 'Inactive Payment Plans';

interface HeadCell {
	id: keyof IInactiveArrangements;
	label: string;
	canSort: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'Amount',
		label: 'Amount',
		canSort: true,
	},
	{
		id: 'AddedDate',
		label: 'Date Added',
		canSort: true,
	},
	{
		id: 'CommencementDate',
		label: 'Start Date',
		canSort: true,
	},
	{
		id: 'CompletionDate',
		label: 'Inactive Date',
		canSort: true,
	},
	{
		id: 'ArrangementCompletionCodeName',
		label: 'Inactive Reason',
		canSort: true,
	},
	{
		id: 'PaymentMethodDesc',
		label: 'Payment Type',
		canSort: true,
	},
	{
		id: 'PeriodicFrequencyDesc',
		label: 'Frequency',
		canSort: true,
	},
	{
		id: 'PaymentArrangementTypeDesc',
		label: 'Arrangement Type',
		canSort: true,
	},
];

export const PaymentPlansInactive: React.FC = () => {
	const dispatch = useAppDispatch();
	const arrangements = useAppSelector(selectInactiveArrangements);
	const loadStatus = useAppSelector(selectInactiveArrangementsStatus);

	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<keyof IInactiveArrangements>('CompletionDate');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [historyOpen, setHistoryOpen] = useState(false);
	const [selectedPAID, setSelectedPAID] = useState<number | undefined>(undefined);
	const count = arrangements.length;

	useEffect(() => {
		dispatch(getClientArrangementsInActive());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleRequestSort = (property: keyof IInactiveArrangements) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const modalClose = () => {
		dispatch(resetScheduleHistory());
		setSelectedPAID(undefined);
		setHistoryOpen(false);
	};

	return (
		<Card elevation={0}>
			<CardHeader title={pageTitle} />
			<CardContent sx={{ pt: 0 }}>
				<TableContainer component={PerfectScrollbar}>
					<Table sx={{ minWidth: 800 }}>
						<TableHead>
							<TableRow>
								{headCells.map((headCell) => (
									<TableCell
										key={headCell.id}
										align="left"
										padding="normal"
										sortDirection={orderBy === headCell.id ? order : false}
									>
										{headCell.canSort ? (
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={() => (headCell.canSort ? handleRequestSort(headCell.id) : undefined)}
											>
												{headCell.label}
											</TableSortLabel>
										) : (
											headCell.label
										)}
									</TableCell>
								))}
								<TableCell key="Actions">Actions</TableCell>
							</TableRow>
						</TableHead>
						{loadStatus === Status.LOADING && <TableLoader rows={5} colSpan={headCells.length} />}
						{(loadStatus === Status.SUCCEEDED || loadStatus === Status.FAILED) && count === 0 && (
							<TableEmpty text="No inactive payment plans found." colSpan={headCells.length} />
						)}
						{loadStatus === Status.SUCCEEDED && count > 0 && (
							<TableBody>
								{arrangements
									.slice()
									.sort(getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										return (
											<TableRow key={row.ArrangementID}>
												<TableCell component="th" scope="row">
													{formatMoney(row.Amount)}
												</TableCell>
												<TableCell>{format(parseISO(row.AddedDate), dateFormats.prettyShort)}</TableCell>
												<TableCell>{format(parseISO(row.CommencementDate), dateFormats.prettyShort)}</TableCell>
												<TableCell>{format(parseISO(row.CompletionDate), dateFormats.prettyShort)}</TableCell>
												<TableCell>{row.ArrangementCompletionCodeName}</TableCell>
												<TableCell>{row.PaymentMethodDesc}</TableCell>
												<TableCell>{row.PeriodicFrequencyDesc}</TableCell>
												<TableCell>{row.PaymentArrangementTypeDesc}</TableCell>
												<TableCell sx={{ whiteSpace: 'nowrap' }}>
													<Link
														sx={{ cursor: 'pointer' }}
														onClick={() => {
															setSelectedPAID(row.ArrangementID);
															setHistoryOpen(true);
														}}
													>
														View history
													</Link>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						)}
					</Table>
				</TableContainer>
				{count > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={count}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="Per page:"
						sx={{ mt: 2 }}
					/>
				)}
			</CardContent>
			{historyOpen && selectedPAID && <PaymentPlanHistoryModal onClose={modalClose} arrangementID={selectedPAID} />}
		</Card>
	);
};
