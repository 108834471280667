import { Card, CardContent, Typography, Divider, Skeleton, Stack, Box } from '@mui/material';
import { Status } from '../../../enums/status.enum';
import { selectClientDebtAmounts, selectClientDebtAmountsStatus } from '../../../store/features/client/debt/amountSlice';
import { selectClientDebt, selectClientDebtStatus } from '../../../store/features/client/debt/debtSlice';
import { useAppSelector } from '../../../store/hooks';
import { formatMoney } from '../../../utils/text.utill';

export const AccountInformationSidebar: React.FC = () => {
	const debtStatus = useAppSelector(selectClientDebtStatus);
	const debtAmountsStatus = useAppSelector(selectClientDebtAmountsStatus);
	const outstanding = useAppSelector(selectClientDebtAmounts)?.Outstanding ?? 0;
	const debt = useAppSelector(selectClientDebt);
	return (
		<Card elevation={0} sx={{ backgroundColor: '#FEF2E8' }}>
			<CardContent>
				<Typography variant="h6" gutterBottom>
					Account Info
				</Typography>
				<Divider />
				<Stack direction="column" spacing={2} mt={2}>
					<Box>
						<Typography variant="subtitle1">
							{debtAmountsStatus === Status.LOADING ? <Skeleton width={100} /> : formatMoney(outstanding)}
						</Typography>
						<Typography variant="body2">Outstanding amount</Typography>
					</Box>
					<Box>
						<Typography variant="subtitle1">{debtStatus === Status.LOADING ? <Skeleton width={100} /> : debt?.DebtStatusName}</Typography>
						<Typography variant="body2">Debt status</Typography>
					</Box>
				</Stack>
			</CardContent>
		</Card>
	);
};
