import React from 'react';

export const ContactUsImg = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg fill="none" viewBox="0 0 106 106" {...props}>
			<g clipPath="url(#contact_us)">
				<path
					fill="#163A59"
					d="M76.295 57.253h-50.33a4.963 4.963 0 00-4.958 4.957v32.433a4.963 4.963 0 004.957 4.958h50.33a4.963 4.963 0 004.959-4.958V62.21a4.963 4.963 0 00-4.958-4.957zm3.633 37.39a3.638 3.638 0 01-3.633 3.634h-50.33a3.638 3.638 0 01-3.634-3.634V62.21a3.638 3.638 0 013.633-3.633h50.33a3.638 3.638 0 013.635 3.633v32.433z"
				></path>
				<path
					fill="#DFDFDF"
					d="M76.294 60.122h-50.33a2.09 2.09 0 00-2.089 2.089v32.433a2.09 2.09 0 002.088 2.088h50.331a2.091 2.091 0 002.089-2.088V62.21a2.091 2.091 0 00-2.089-2.089z"
				></path>
				<path
					fill="#163A59"
					d="M21.007 98.277v2.514A5.215 5.215 0 0026.216 106h49.827a5.215 5.215 0 005.21-5.209v-2.514H21.006zm58.921 2.514a3.89 3.89 0 01-3.885 3.885H26.216a3.889 3.889 0 01-3.885-3.885v-1.19h57.597v1.19z"
				></path>
				<path fill="#DFDFDF" d="M23.901 101.146a2.344 2.344 0 002.314 1.985h49.827c1.17 0 2.142-.863 2.314-1.985H23.9z"></path>
				<path fill="#23DE84" d="M51.129 85.635a6.002 6.002 0 100-12.003 6.002 6.002 0 000 12.003z"></path>
				<path
					fill="#163A59"
					d="M51.129 72.97a6.672 6.672 0 00-6.664 6.664 6.672 6.672 0 006.664 6.664 6.671 6.671 0 006.664-6.664 6.671 6.671 0 00-6.664-6.664zm0 12.003a5.346 5.346 0 01-5.34-5.34 5.346 5.346 0 015.34-5.34 5.346 5.346 0 015.34 5.34 5.346 5.346 0 01-5.34 5.34z"
				></path>
				<path
					fill="#163A59"
					d="M94.094 67.497L75.713 48.669a13.558 13.558 0 00-9.567-3.992H36.114a13.562 13.562 0 00-9.572 3.993L7.962 67.488a11.447 11.447 0 00-2.473 3.733 11.553 11.553 0 004.157 13.924l12.685 8.704V62.212a3.639 3.639 0 013.632-3.637h50.33a3.641 3.641 0 013.636 3.637v32.734l12.887-9.794a11.626 11.626 0 004.57-9.209v-.366c0-3.037-1.169-5.906-3.291-8.08zm1.968 8.446c0 3.183-1.513 6.23-4.047 8.155l-10.761 8.179V62.212a4.967 4.967 0 00-4.961-4.96h-50.33a4.964 4.964 0 00-4.956 4.96v29.123l-10.612-7.282a10.23 10.23 0 01-3.68-12.33 10.136 10.136 0 012.188-3.303L27.484 49.6a12.23 12.23 0 018.63-3.6h30.032c3.215 0 6.36 1.313 8.623 3.597l18.378 18.825a10.189 10.189 0 012.915 7.155v.366z"
				></path>
				<path
					fill="#DFDFDF"
					d="M92.041 69.5l-9.904-10.145a6.47 6.47 0 01.661 2.858v26.95l8.283-6.295a8.741 8.741 0 003.436-6.925v-.366c0-2.283-.88-4.441-2.475-6.077zM10 69.508a8.663 8.663 0 00-2.507 6.104 8.685 8.685 0 003.777 7.166l8.192 5.622V62.212a6.47 6.47 0 01.768-3.067L10 69.508zM73.674 50.688a10.67 10.67 0 00-7.529-3.142h-30.03a10.674 10.674 0 00-7.531 3.14l-5.755 5.828a6.46 6.46 0 013.134-.807h50.33c1.08 0 2.099.264 2.996.732l-5.615-5.75z"
				></path>
				<path
					fill="#163A59"
					d="M52.18 12.808c-9.151 0-16.597 7.445-16.597 16.598 0 9.152 7.446 16.597 16.598 16.597s16.598-7.445 16.598-16.598c0-9.152-7.446-16.597-16.598-16.597zm0 31.871c-8.421 0-15.273-6.852-15.273-15.274 0-8.422 6.852-15.273 15.274-15.273 8.422 0 15.274 6.851 15.274 15.274 0 8.422-6.852 15.273-15.274 15.273z"
				></path>
				<path
					fill="#C6E1FF"
					d="M52.181 15.675c-7.57 0-13.729 6.159-13.729 13.729 0 7.57 6.159 13.729 13.73 13.729 7.57 0 13.728-6.159 13.728-13.73 0-7.57-6.158-13.728-13.729-13.728z"
				></path>
				<path
					fill="#163A59"
					d="M33.574 24.575a3.338 3.338 0 00-3.334 3.334v2.835a3.338 3.338 0 003.334 3.334 3.337 3.337 0 003.334-3.334V27.91a3.338 3.338 0 00-3.334-3.334zm2.01 6.17c0 1.107-.902 2.009-2.01 2.009a2.012 2.012 0 01-2.01-2.01V27.91c0-1.108.902-2.01 2.01-2.01 1.108 0 2.01.902 2.01 2.01v2.835zM70.789 24.575a3.337 3.337 0 00-3.334 3.334v2.835a3.337 3.337 0 003.334 3.334 3.337 3.337 0 003.333-3.334V27.91a3.337 3.337 0 00-3.333-3.334zm2.01 6.17c0 1.107-.902 2.009-2.01 2.009a2.012 2.012 0 01-2.01-2.01V27.91c0-1.108.902-2.01 2.01-2.01 1.108 0 2.01.902 2.01 2.01v2.835z"
				></path>
				<path
					fill="#163A59"
					d="M70.797 24.152a18.515 18.515 0 00-5.327-9.406c-3.615-3.416-8.212-5.176-13.279-5.097-5.093-.08-9.685 1.68-13.3 5.097a18.518 18.518 0 00-5.328 9.406l-.015 1.62h1.324v-1.406a17.183 17.183 0 014.928-8.658c3.359-3.174 7.638-4.813 12.391-4.735 4.728-.081 9.01 1.561 12.37 4.735a17.183 17.183 0 014.928 8.658v1.368h1.324v-1.44l-.016-.142zM70.126 33.416v1.214c0 1.99-1.62 3.61-3.61 3.61H52.41v1.324h14.106a4.94 4.94 0 004.935-4.934v-1.214h-1.325z"
				></path>
				<path
					fill="#163A59"
					d="M50.467 35.8v3.764h4.806v-3.763h-4.806zm3.481 2.44h-2.157v-1.115h2.157v1.115zM100.081 2.12H84.246a5.264 5.264 0 00-5.258 5.257v7.365c-1.729 2.05-3.883 4.69-3.905 4.717l-.882 1.08h25.88c2.9 0 5.259-2.358 5.259-5.258V7.377c0-2.9-2.359-5.258-5.259-5.258zm3.934 13.161a3.938 3.938 0 01-3.934 3.935H76.994a377.537 377.537 0 013.161-3.804l.157-.185v-7.85a3.939 3.939 0 013.934-3.934h15.835a3.939 3.939 0 013.935 3.934v7.904h-.001z"
				></path>
				<path
					fill="#71CCE0"
					d="M100.081 4.987H84.246a2.392 2.392 0 00-2.39 2.39v8.414l-.521.617c-.34.402-.7.831-1.059 1.262h19.805a2.392 2.392 0 002.389-2.39V7.378a2.392 2.392 0 00-2.389-2.39z"
				></path>
				<path
					fill="#163A59"
					d="M23.974 19.744a2.518 2.518 0 00-2.516 2.516 2.519 2.519 0 002.516 2.516 2.518 2.518 0 002.515-2.516 2.519 2.519 0 00-2.515-2.516zm0 3.708a1.193 1.193 0 01-1.192-1.192 1.193 1.193 0 111.192 1.192zM20.832 13.314c.296-.972.446-1.98.446-3.006C21.278 4.624 16.654 0 10.97 0S.66 4.624.66 10.309c0 5.684 4.624 10.308 10.309 10.308 1.769 0 3.497-.452 5.031-1.312a3.85 3.85 0 002.478.907 3.855 3.855 0 003.851-3.85c0-1.2-.563-2.324-1.498-3.048zm-2.353 5.574a2.53 2.53 0 01-1.887-.851l-.372-.42-.475.3a8.954 8.954 0 01-4.776 1.376c-4.954 0-8.984-4.03-8.984-8.984 0-4.955 4.03-8.985 8.984-8.985 4.955 0 8.985 4.03 8.985 8.985a8.96 8.96 0 01-.532 3.05l-.19.527.487.275a2.533 2.533 0 011.287 2.2 2.53 2.53 0 01-2.527 2.527z"
				></path>
				<path
					fill="#71CCE0"
					d="M18.959 15.507l-1.623-.918.633-1.753a7.41 7.41 0 00.44-2.526c0-4.103-3.337-7.44-7.44-7.44-4.101 0-7.439 3.337-7.439 7.44 0 4.102 3.338 7.439 7.44 7.439 1.402 0 2.769-.394 3.953-1.138l1.582-.996 1.242 1.396c.11.125.352.333.732.333.542 0 .982-.44.982-.982a.972.972 0 00-.502-.855z"
				></path>
				<path
					fill="#163A59"
					d="M8.245 9.646H6.637v1.325h1.608V9.646zM11.7 9.646h-1.608v1.325H11.7V9.646zM15.155 9.646h-1.61v1.325h1.61V9.646zM89.372 10.667h-1.608v1.324h1.608v-1.324zM92.827 10.667H91.22v1.324h1.608v-1.324zM96.281 10.668h-1.608v1.324h1.608v-1.324z"
				></path>
			</g>
			<defs>
				<clipPath id="contact_us">
					<path fill="#fff" d="M0 0H106V106H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
};
