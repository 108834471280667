import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { IActiveClientsOutput } from '../../clients/types';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientDebtorAPI, IGetClientDebtorMatch } from './types';

export const clientFuzzyEntityGet = createAsyncThunk('client/debtor/match', async (_, { rejectWithValue, dispatch, getState }) => {
	try {
		const { DebtorEntityID: entityID, DebtID: debtID } = (getState() as RootState).client.viewingClient as ViewingClient;
		const { data } = await fcAPI.get<IGetClientDebtorMatch>(ClientDebtorAPI.DEBTOR_MATCH, {
			params: { entityID, debtID },
		});
		if (data.success) {
			return data.data.matched;
		}
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export interface DebtorMatchState {
	match: IActiveClientsOutput[];
	status: Status;
}

const initialState: DebtorMatchState = {
	match: [],
	status: Status.IDLE,
};

const clientDebtorMatchSlice = createSlice({
	name: 'clientDebtorMatch',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientFuzzyEntityGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientFuzzyEntityGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.match = action.payload;
			})
			.addCase(clientFuzzyEntityGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default clientDebtorMatchSlice.reducer;

export const selectClientDebtorMatch = (state: RootState) => state.clientDebtorMatch.match;
export const selectClientDebtorMatchStatus = (state: RootState) => state.clientDebtorMatch.status;
