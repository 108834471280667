import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../enums/status.enum';
import { fcAPI } from '../../../services/api/api';
import { handleAsyncThunkError } from '../../hooks';
import { RootState } from '../../store';
import { setErrorSnackbarOpen } from '../app/appSlice';
import { DashboardAPI, IRecentActivity, IRecentActivityOutput } from './types';

export const recentActivityGet = createAsyncThunk(
	'dashboard/recentActivity',
	async (tableActions: { pageNumber: number; pageSize: number }, { rejectWithValue, dispatch }) => {
		try {
			const { data } = await fcAPI.get<IRecentActivity>(DashboardAPI.RECENT_ACTIVITY, {
				params: tableActions,
			});
			if (data.success) {
				return data.data.activity;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export interface RecentActivityState {
	activity: IRecentActivityOutput[];
	totalCount: number;
	status: Status;
}

const initialState: RecentActivityState = {
	activity: [],
	totalCount: 0,
	status: Status.IDLE,
};

const recentActivitySlice = createSlice({
	name: 'recentActivity',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(recentActivityGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(recentActivityGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.totalCount = action.payload[0]?.TotalCount ?? 0;
				state.activity = action.payload;
			})
			.addCase(recentActivityGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default recentActivitySlice.reducer;

export const selectRecentActivity = (state: RootState) => state.recentActivity.activity;
export const selectRecentActivityStatus = (state: RootState) => state.recentActivity.status;
export const selectRecentActivityCount = (state: RootState) => state.recentActivity.totalCount;
