import React from 'react';

export const MyClientsImg = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg fill="none" viewBox="0 0 106 106" {...props}>
			<path
				fill="#23DE84"
				d="M69.942 35.236v6.915c-2.77 2.568-6.126 4.472-9.903 5.549l2.854-21.076h.041c4.113.911 7.008 4.555 7.008 8.613zM47.786 47.99a24.491 24.491 0 01-11.12-5.921v-6.832c0-3.892 3.567-7.702 8.141-8.654h.042l2.937 21.407z"
			></path>
			<path
				fill="#163A59"
				d="M53.87 24.844c-5.664 0-10.28-4.555-10.28-10.145S48.206 4.555 53.87 4.555c5.666 0 10.282 4.554 10.282 10.144s-4.616 10.145-10.281 10.145zm0-19.006c-4.95 0-8.98 3.975-8.98 8.861 0 4.886 4.03 8.861 8.98 8.861 4.953 0 8.981-3.975 8.981-8.86 0-4.887-4.028-8.862-8.98-8.862z"
			></path>
			<path
				fill="#C6E1FF"
				d="M53.871 21.904c-4.029 0-7.344-3.23-7.344-7.246 0-3.975 3.273-7.246 7.344-7.246 4.029 0 7.344 3.23 7.344 7.246 0 4.017-3.316 7.246-7.344 7.246z"
			></path>
			<path
				fill="#163A59"
				d="M72.921 39.709h-1.3v-4.14c0-5.839-4.826-10.642-10.743-10.725H45.939c-6 0-10.869 4.803-10.869 10.724v4.141h-1.3v-4.14c0-6.626 5.455-12.009 12.169-12.009H60.92c6.63.083 12.001 5.466 12.001 12.008v4.141z"
			></path>
			<path
				fill="#163A59"
				d="M49.338 48.487l-1.847-14.16h-.042v-.042L46.065 23.56h3.357l3.399 4.057-4.029 6.957 1.804 13.746-1.258.166zm-1.805-23.643l1.007 7.619 2.728-4.72-2.434-2.899h-1.3zM58.403 48.322l-1.3-.166 1.762-13.623-3.693-7.08 3.399-3.892h2.937v.745l-1.3 10.02.041.042h-.041L58.403 48.32zm-1.636-20.704l2.433 4.638.965-7.453H59.2l-2.434 2.815zM54.541 29.854h-1.3V48.61h1.3V29.854z"
			></path>
			<path
				fill="#163A59"
				d="M53.87 30.806l-2.307-2.733 1.007-.828 1.343 1.615 1.594-1.739.965.828-2.602 2.857zM56.599 36.603a1 1 0 001.007-.994 1 1 0 00-1.007-.994 1 1 0 00-1.007.994 1 1 0 001.007.994zM56.599 43.767a1 1 0 001.007-.994 1 1 0 00-1.007-.994 1 1 0 00-1.007.994 1 1 0 001.007.994z"
			></path>
			<path
				fill="#163A59"
				d="M53.367 49.273c-13.764 0-24.969-11.055-24.969-24.636C28.398 11.055 39.603 0 53.367 0 67.13 0 78.335 11.056 78.335 24.637c0 13.581-11.204 24.636-24.968 24.636zm0-47.99c-13.05 0-23.668 10.476-23.668 23.354 0 12.877 10.617 23.353 23.668 23.353 13.05 0 23.667-10.476 23.667-23.353 0-12.878-10.617-23.353-23.667-23.353z"
			></path>
			<path
				fill="#E0E0E0"
				d="M23.741 83.765v4.679a12.894 12.894 0 01-3.86 2.112l1.427-10.559a4.115 4.115 0 012.433 3.768zM11.11 90.763a14.084 14.084 0 01-4.575-2.36v-4.638c0-1.573 1.301-3.146 3.063-3.809l1.511 10.807z"
			></path>
			<path
				fill="#163A59"
				d="M15.472 78.093c-3.357 0-6.126-2.692-6.126-6.046s2.727-6.045 6.126-6.045c3.4 0 6.085 2.733 6.085 6.045 0 3.313-2.727 6.046-6.085 6.046zm0-10.807c-2.643 0-4.825 2.153-4.825 4.761 0 2.609 2.182 4.762 4.825 4.762 2.644 0 4.826-2.153 4.826-4.762 0-2.608-2.182-4.761-4.826-4.761z"
			></path>
			<path
				fill="#C6E1FF"
				d="M15.473 75.319c-1.846 0-3.315-1.45-3.315-3.272 0-1.821 1.469-3.27 3.315-3.27 1.847 0 3.315 1.449 3.315 3.27-.041 1.822-1.51 3.272-3.315 3.272z"
			></path>
			<path
				fill="#163A59"
				d="M26.594 86.29h-1.301v-2.36c0-3.188-2.602-5.796-5.833-5.838h-8.477c-3.273 0-5.917 2.609-5.917 5.838v2.36h-1.3v-2.36c0-3.933 3.23-7.121 7.217-7.121h8.477c3.945.041 7.134 3.23 7.134 7.121v2.36z"
			></path>
			<path
				fill="#163A59"
				d="M12.578 91.3L10.69 76.81h2.35l2.14 2.567-2.35 4.058 1.007 7.743-1.26.124zm-.42-13.208l.42 3.189 1.049-1.781-1.175-1.408h-.294zM18.326 91.219l-1.3-.166 1.007-7.66-2.14-4.1 2.14-2.442h2.182l-1.889 14.368zm-.88-11.76l.88 1.698.378-3.023h-.084l-1.175 1.325z"
			></path>
			<path fill="#163A59" d="M16.147 80.66h-1.301v10.683h1.3V80.66z"></path>
			<path fill="#163A59" d="M15.475 81.654l-1.553-1.822 1.007-.828.546.704.713-.787.965.87-1.678 1.863z"></path>
			<path
				fill="#163A59"
				d="M15.18 92.005C7.208 92.005.704 85.587.704 77.72s6.504-14.285 14.478-14.285c7.973 0 14.477 6.418 14.477 14.285s-6.504 14.285-14.477 14.285zm0-27.287c-7.26 0-13.176 5.838-13.176 13.002 0 7.163 5.917 13.001 13.177 13.001s13.176-5.838 13.176-13.001c0-7.164-5.917-13.002-13.176-13.002z"
			></path>
			<path
				fill="#E0E0E0"
				d="M100.786 83.765v4.679a12.887 12.887 0 01-3.86 2.112l1.427-10.559a4.115 4.115 0 012.433 3.768zM88.154 90.763a14.084 14.084 0 01-4.574-2.36v-4.638c0-1.573 1.301-3.146 3.063-3.809l1.511 10.807z"
			></path>
			<path
				fill="#163A59"
				d="M92.52 78.093c-3.358 0-6.127-2.692-6.127-6.046s2.727-6.045 6.126-6.045c3.357 0 6.127 2.691 6.127 6.045s-2.77 6.046-6.127 6.046zm0-10.807c-2.644 0-4.826 2.153-4.826 4.761 0 2.609 2.182 4.762 4.825 4.762 2.644 0 4.826-2.153 4.826-4.762 0-2.608-2.182-4.761-4.826-4.761z"
			></path>
			<path
				fill="#C6E1FF"
				d="M92.52 75.319c-1.846 0-3.315-1.45-3.315-3.272 0-1.821 1.469-3.27 3.315-3.27 1.847 0 3.315 1.449 3.315 3.27 0 1.822-1.51 3.272-3.315 3.272z"
			></path>
			<path
				fill="#163A59"
				d="M103.641 86.29h-1.301v-2.36c0-3.188-2.602-5.796-5.833-5.838H88.03c-3.273 0-5.917 2.609-5.917 5.838v2.36h-1.3v-2.36c0-3.933 3.23-7.121 7.217-7.121h8.477c3.944.041 7.134 3.23 7.134 7.121v2.36z"
			></path>
			<path
				fill="#163A59"
				d="M89.666 91.3L87.777 76.81h2.35l2.14 2.567-2.35 4.016 1.008 7.743-1.26.166zm-.42-13.208l.42 3.189 1.049-1.781-1.175-1.408h-.294zM95.373 91.219l-1.3-.166 1.007-7.66-2.14-4.1 2.14-2.442h2.182l-1.889 14.368zm-.88-11.76l.88 1.698.378-3.023h-.084l-1.175 1.325z"
			></path>
			<path fill="#163A59" d="M93.192 80.66H91.89v10.683h1.3V80.66z"></path>
			<path fill="#163A59" d="M92.52 81.654l-1.51-1.822.965-.828.587.704.672-.787.965.87-1.678 1.863z"></path>
			<path
				fill="#163A59"
				d="M92.225 92.005c-7.973 0-14.477-6.418-14.477-14.285s6.504-14.285 14.477-14.285c7.974 0 14.478 6.418 14.478 14.285s-6.504 14.285-14.478 14.285zm0-27.287c-7.26 0-13.176 5.838-13.176 13.002 0 7.163 5.917 13.001 13.176 13.001 7.26 0 13.177-5.838 13.177-13.001 0-7.164-5.917-13.002-13.177-13.002z"
			></path>
			<path
				fill="#E0E0E0"
				d="M62.474 97.76v4.679a12.897 12.897 0 01-3.86 2.112l1.426-10.559c1.469.704 2.434 2.153 2.434 3.768zM49.842 104.758a14.089 14.089 0 01-4.574-2.36v-4.637c0-1.574 1.3-3.147 3.063-3.81l1.51 10.807z"
			></path>
			<path
				fill="#163A59"
				d="M54.166 92.088c-3.357 0-6.127-2.692-6.127-6.046 0-3.353 2.728-6.045 6.127-6.045 3.399 0 6.126 2.692 6.126 6.045 0 3.354-2.727 6.046-6.126 6.046zm0-10.807c-2.644 0-4.826 2.153-4.826 4.761 0 2.609 2.182 4.762 4.826 4.762 2.643 0 4.826-2.153 4.826-4.762.041-2.608-2.14-4.761-4.826-4.761z"
			></path>
			<path
				fill="#C6E1FF"
				d="M54.167 89.314c-1.847 0-3.315-1.45-3.315-3.271 0-1.822 1.468-3.272 3.315-3.272 1.846 0 3.315 1.45 3.315 3.272 0 1.822-1.469 3.27-3.315 3.27z"
			></path>
			<path
				fill="#163A59"
				d="M65.328 100.286h-1.3v-2.36c0-3.189-2.602-5.797-5.834-5.839h-8.476c-3.273 0-5.917 2.609-5.917 5.839v2.36H42.5v-2.36c0-3.934 3.231-7.122 7.218-7.122h8.476c3.945.041 7.134 3.23 7.134 7.122v2.36z"
			></path>
			<path
				fill="#163A59"
				d="M51.984 105.876h-.588l-.084-.538-1.888-14.492h2.35l2.14 2.567-2.35 4.016 1.007 7.743-.63.083.043.621zm-1.091-13.788l.42 3.188 1.048-1.78-1.175-1.408h-.293zM57.06 105.214l-1.3-.166 1.007-7.66-2.14-4.1 2.14-2.442h2.182l-1.888 14.368zm-.88-11.76l.88 1.698.378-3.064h-.083l-1.175 1.366z"
			></path>
			<path fill="#163A59" d="M54.879 94.696h-1.3v10.683h1.3V94.696z"></path>
			<path fill="#163A59" d="M54.207 95.648l-1.553-1.78 1.007-.828.546.662.713-.745.965.828-1.678 1.863z"></path>
			<path
				fill="#163A59"
				d="M53.913 106c-7.973 0-14.477-6.418-14.477-14.285S45.94 77.43 53.912 77.43c7.973 0 14.477 6.418 14.477 14.285S61.886 106 53.913 106zm0-27.287c-7.26 0-13.177 5.839-13.177 13.002s5.917 13.001 13.177 13.001S67.09 98.878 67.09 91.715c0-7.163-5.917-13.002-13.177-13.002zM23.32 64.47l-.88-.953.965-.87.88.953-.964.87zm1.931-1.74l-.881-.952.965-.87.881.953-.965.87zm1.888-1.697l-.88-.953 1.006-.87.881.953-1.007.87zm1.93-1.698l-.88-.952.965-.87.88.953-.964.87zM31 57.596l-.881-.952.965-.87.881.953-.965.87zm1.93-1.698l-.88-.952.964-.87.881.953-.965.87zm1.93-1.739l-.88-.952.965-.87.88.953-.964.87zm1.931-1.697l-.881-.953.965-.87.881.953-.965.87zm1.93-1.698l-.88-.952.964-.87.882.953-.966.87zm1.93-1.739l-.88-.952.965-.87.88.953-.964.869zM84.168 63.766l-1.007-.828.839-.994 1.007.828-.84.994zm-1.973-1.615l-1.007-.828.84-.994 1.007.828-.84.994zm-2.014-1.657l-1.007-.828.84-.993 1.007.828-.84.994zM78.21 58.88l-1.007-.828.84-.994 1.006.828-.84.994zm-2.014-1.657l-1.007-.828.839-.993 1.007.828-.84.993zm-1.973-1.656l-1.007-.828.84-.994 1.007.828-.84.994zm-1.972-1.615l-1.007-.828.84-.994 1.006.829-.839.993zm-2.014-1.656l-1.007-.828.839-.994 1.007.828-.84.994zm-1.972-1.656l-1.008-.828.84-.994 1.007.828-.84.994zm-2.015-1.615l-1.007-.828.84-.994 1.007.828-.84.994zM54.5 74.904h-1.3v-1.283h1.3v1.283zm0-2.526h-1.3v-1.283h1.3v1.283zm0-2.567h-1.3v-1.284h1.3v1.284zm0-2.567h-1.3V65.96h1.3v1.284zm0-2.567h-1.3v-1.284h1.3v1.284zm.042-2.567h-1.3v-1.284h1.3v1.284zm0-2.526h-1.3V58.3h1.3v1.284zm0-2.567h-1.3v-1.284h1.3v1.284zm0-2.568h-1.3v-1.283h1.3v1.283zm0-2.567h-1.3V50.6h1.3v1.283z"
			></path>
		</svg>
	);
};
