import { Box, Container } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { PublicRoutes } from '../../../constants/routes.constant';
import { LogoHorizontal } from '../../../icons/branding/LogoHorizontal';
import { ChathamsBlue } from '../../../theme/colours';

export const AuthWrapper: React.FC<{ helperLink?: React.ReactNode }> = ({ helperLink, children }) => (
	<Box
		component="main"
		sx={{
			py: { xs: 4, md: 8 },
			px: 3,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			minHeight: {
				sm: '100%',
			},
		}}
	>
		<Box
			sx={{
				px: { lg: 4 },
				display: 'flex',
				alignItems: 'center',
				width: '100%',
				flexWrap: 'wrap',
				marginTop: -3,
			}}
		>
			<Box
				sx={{
					alignItems: 'center',
					display: {
						xs: 'flex',
						lg: 'none',
					},
					marginTop: 3,
				}}
			>
				<Link to={PublicRoutes.HOME}>
					<LogoHorizontal height={46} fill={ChathamsBlue} />
				</Link>
			</Box>
			<Box sx={{ flexGrow: 1 }} />
			<Box sx={{ marginTop: 3 }}>{helperLink}</Box>
		</Box>
		<Container maxWidth="xs" disableGutters sx={{ display: 'flex', flexGrow: 1 }}>
			<Box
				sx={{
					mt: 14,
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				{children}
			</Box>
		</Container>
	</Box>
);
