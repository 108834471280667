import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation, useRoutes } from 'react-router-dom';
import { routes } from './routes';
import { theme } from './theme';
import { GlobalSnackbar } from './components/GlobalSnackbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { selectApproved, selectAuthenticated } from './store/features/auth/authSlice';
import { SessionTimeoutSnackbar } from './components/SessionTimeoutSnackbar';
import { ScrollToTop } from './components/ScrollToTop';
import { useEffect, useState } from 'react';
import { ClientPrefix, PortalPrefix } from './constants/routes.constant';
import { SessionTimeout } from './components/SessionTimeout';
import { resetViewingClient, selectIsClientSelected } from './store/features/client/clientSlice';
import { GlobalModal } from './components/GlobalModal';
import { reactPlugin } from './services/ai/reactPlugin';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { PageTracking } from './services/ai/pageTracking';

const App = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const isAuthenticated = useAppSelector(selectAuthenticated);
	const isApproved = useAppSelector(selectApproved);
	const isClientSelected = useAppSelector(selectIsClientSelected);
	const routing = useRoutes(routes(isAuthenticated, isApproved, isClientSelected));

	const [sessionTimerEnabled, SetSessionTimerEnabled] = useState(false);

	useEffect(() => {
		SetSessionTimerEnabled(location.pathname.includes(`/${PortalPrefix}`));
	}, [location]);

	// Reset Viewing Client when navigating away from client paths
	useEffect(() => {
		if (!location.pathname.includes(`/${PortalPrefix}/${ClientPrefix}/`)) {
			dispatch(resetViewingClient());
		}
	}, [location, dispatch]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalModal />
				<GlobalSnackbar />
				<SessionTimeoutSnackbar />
				{sessionTimerEnabled && <SessionTimeout />}
				<ScrollToTop />
				<PageTracking />
				{routing}
			</ThemeProvider>
		</LocalizationProvider>
	);
};

const ClientApp = reactPlugin ? withAITracking(reactPlugin, App) : App;

export default ClientApp;
