/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
	interface CustomTheme {
		widths: {
			sidebar: { xs: number; sm: number; md: number; lg: number; xl: number };
		};
		heights: {
			appBar: { xs: number; sm: number; md: number; lg: number; xl: number };
		};
	}
	interface Palette {
		white: Palette['primary'];
		textSecondary: Palette['primary'];
	}
	interface PaletteOptions {
		white: PaletteOptions['primary'];
		textSecondary: PaletteOptions['primary'];
	}

	interface Theme extends CustomTheme {}
	interface ThemeOptions extends CustomTheme {}

	interface TypographyVariants {
		h7: React.CSSProperties;
	}
	interface TypographyVariantsOptions {
		h7?: React.CSSProperties;
	}
}
declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		white: true;
		textSecondary: true;
	}
}
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		h7: true;
	}
}

export const theme = responsiveFontSizes(
	createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 1000,
				lg: 1200,
				xl: 1920,
			},
		},
		widths: {
			sidebar: {
				xs: 225,
				sm: 225,
				md: 225,
				lg: 290,
				xl: 290,
			},
		},
		heights: {
			appBar: {
				xs: 64,
				sm: 92,
				md: 92,
				lg: 92,
				xl: 92,
			},
		},
		components: {
			MuiUseMediaQuery: {
				defaultProps: {
					noSsr: true,
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: 'transparent',
					},
				},
			},
			MuiButton: {
				defaultProps: {
					disableElevation: true,
				},
				styleOverrides: {
					root: {
						textTransform: 'none',
					},
					sizeSmall: {
						padding: '6px 16px',
					},
					sizeMedium: {
						padding: '8px 20px',
					},
					sizeLarge: {
						padding: '11px 24px',
					},
					textSizeSmall: {
						padding: '7px 12px',
					},
					textSizeMedium: {
						padding: '9px 16px',
					},
					textSizeLarge: {
						padding: '12px 16px',
					},
					containedSecondary: {
						'&:hover': {
							color: '#FFFFFF',
						},
					},
				},
			},
			MuiButtonBase: {
				defaultProps: {
					disableRipple: true,
				},
			},
			MuiCardContent: {
				styleOverrides: {
					root: {
						padding: '32px 24px',
						'&:last-child': {
							paddingBottom: '32px',
						},
					},
				},
			},
			MuiCardHeader: {
				defaultProps: {
					titleTypographyProps: {
						variant: 'h6',
					},
					subheaderTypographyProps: {
						variant: 'body2',
					},
				},
				styleOverrides: {
					root: {
						padding: '32px 24px',
					},
				},
			},
			MuiCssBaseline: {
				styleOverrides: {
					'*': {
						boxSizing: 'border-box',
						margin: 0,
						padding: 0,
					},
					html: {
						MozOsxFontSmoothing: 'grayscale',
						WebkitFontSmoothing: 'antialiased',
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100%',
						width: '100%',
					},
					body: {
						display: 'flex',
						flex: '1 1 auto',
						flexDirection: 'column',
						minHeight: '100%',
						width: '100%',
						'& .grecaptcha-badge': {
							visibility: 'hidden',
						},
					},
					'#root': {
						display: 'flex',
						flex: '1 1 auto',
						flexDirection: 'column',
						height: '100%',
						width: '100%',
					},
					form: {
						width: '100%',
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					notchedOutline: {
						borderColor: '#E6E8F0',
					},
				},
			},
		},
		palette: {
			background: {
				default: '#F1F1F1',
				paper: '#FFFFFF',
			},
			primary: {
				main: '#3F51B5',
				dark: '#303F9F',
			},
			secondary: {
				main: '#23DE84',
			},
			text: {
				primary: 'rgba(0, 0, 0, 0.87)',
				secondary: 'rgba(0, 0, 0, 0.6)',
				disabled: 'rgba(0, 0, 0, 0.38)',
			},
			white: {
				main: '#FFFFFF',
			},
			textSecondary: {
				main: 'rgba(0, 0, 0, 0.6)',
			},
		},
		shape: {
			borderRadius: 8,
		},
		typography: {
			button: {
				fontWeight: 600,
			},
			fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
			body1: {
				fontWeight: 400,
			},
			body2: {
				fontWeight: 400,
			},
			subtitle1: {
				fontWeight: 500,
			},
			subtitle2: {
				fontWeight: 500,
			},
			overline: {
				fontWeight: 600,
			},
			caption: {
				fontWeight: 400,
			},
			h1: {
				fontWeight: 600,
			},
			h2: {
				fontWeight: 600,
			},
			h3: {
				fontWeight: 600,
			},
			h4: {
				fontWeight: 600,
			},
			h5: {
				fontWeight: 600,
			},
			h6: {
				fontWeight: 600,
			},
			h7: {
				fontSize: '1rem',
				fontWeight: 600,
			},
		},
		mixins: {
			toolbar: {
				minHeight: 64,
				'@media (min-width:600px)': {
					minHeight: 92,
				},
			},
		},
	}),
);
