import { PaymentMethodID, PaymentMethodString, PeriodicFrequencyDescString, PeriodicFrequencyID } from '../enums/arrangement.enum';
import { HardshipCategory, HardshipCategoryString } from '../enums/hardship.enum';

export const formatMoney = (amount: number, hideSymbol = false, minimumFractionDigits?: number) =>
	amount.toLocaleString('en-US', {
		style: hideSymbol ? 'decimal' : 'currency',
		currency: 'USD',
		...(minimumFractionDigits && { minimumFractionDigits }),
	});

export const getFullName = (firstName: string | undefined | null, lastName: string | undefined | null): string => {
	return [firstName, lastName].filter(Boolean).join(' ');
};

export const convertHardshipCategoryToString = (hardshipCategoryID: number): string => {
	const id: number = hardshipCategoryID;
	let str = '';
	switch (id) {
		case HardshipCategory.UNEMPLOYMENT:
			str = HardshipCategoryString.UNEMPLOYMENT;
			break;
		case HardshipCategory.OVER_COMMITTED:
			str = HardshipCategoryString.OVER_COMMITTED;
			break;
		case HardshipCategory.ILLNESS:
			str = HardshipCategoryString.ILLNESS;
			break;
		case HardshipCategory.DISABILITY:
			str = HardshipCategoryString.DISABILITY;
			break;
		case HardshipCategory.INJURY:
			str = HardshipCategoryString.INJURY;
			break;
		case HardshipCategory.DOMESTIC_FAMILY_VIOLENCE:
			str = HardshipCategoryString.DOMESTIC_FAMILY_VIOLENCE;
			break;
		case HardshipCategory.COVID:
			str = HardshipCategoryString.COVID;
			break;
		case HardshipCategory.ELDER_ABUSE:
			str = HardshipCategoryString.ELDER_ABUSE;
			break;
		case HardshipCategory.FINANCIAL_ABUSE:
			str = HardshipCategoryString.FINANCIAL_ABUSE;
			break;
		case HardshipCategory.OTHER:
			str = HardshipCategoryString.OTHER;
			break;
		default:
			break;
	}
	return str;
};

export const convertPaymentFreqToDescString = (frequencyID: number | null | undefined): string => {
	if (frequencyID) {
		switch (frequencyID) {
			case PeriodicFrequencyID.ONE_OFF:
				return PeriodicFrequencyDescString.ONE_OFF;
			case PeriodicFrequencyID.WEEKLY:
				return PeriodicFrequencyDescString.WEEKLY;
			case PeriodicFrequencyID.FORTNIGHTLY:
				return PeriodicFrequencyDescString.FORTNIGHTLY;
			case PeriodicFrequencyID.MONTHLY:
				return PeriodicFrequencyDescString.MONTHLY;
			default:
				break;
		}
	}
	return '';
};

export const convertPaymentMethodToString = (paymentMethodID: number | null | undefined): string => {
	if (paymentMethodID) {
		switch (paymentMethodID) {
			case PaymentMethodID.DEBIT_CREDITCARD:
				return PaymentMethodString.DEBIT_CREDITCARD;
			case PaymentMethodID.BPAY:
				return PaymentMethodString.BPAY;
			case PaymentMethodID.DIRECT_DEBIT:
				return PaymentMethodString.DIRECT_DEBIT;
			case PaymentMethodID.DEPOSIT:
				return PaymentMethodString.DEPOSIT;
			case PaymentMethodID.EXTERNAL:
				return PaymentMethodString.EXTERNAL;
			default:
				return PaymentMethodString.OTHER;
		}
	}
	return '';
};
