import { FCResponse } from '../../../services/api/base.api.response';

export enum UserAPI {
	DETAILS = '/api/user/details',
	UPDATE_PASSWORD = '/api/user/password',
}

export interface IFCUserOutput {
	FCUserID: number;
	FCCompanyID: number;
	FCCompanyName: string;
	FCRegistrationNumber: string;
	Email: string;
	ContactPhone: string;
	FirstName: string;
	Surname: string;
}

export type IGetUserDetails = FCResponse<{ details: IFCUserOutput; jwt: string | null }>;
