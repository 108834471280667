import { Alert, Button, Dialog, DialogContent, DialogTitle, Stack, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { clearModal, selectModal } from '../store/features/app/appSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { theme } from '../theme';

export const GlobalModal = () => {
	const dispatch = useAppDispatch();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const modal = useAppSelector(selectModal);

	const [modalIsOpen, setModalIsOpen] = useState(false);

	useEffect(() => {
		setModalIsOpen(!!modal);
	}, [modal]);

	const onClose = () => setModalIsOpen(false);

	return (
		<Dialog
			open={modalIsOpen}
			onClose={onClose}
			fullScreen={fullScreen}
			maxWidth="xs"
			TransitionProps={{ onExited: () => dispatch(clearModal()) }}
		>
			<DialogContent>
				<Stack spacing={3}>
					{modal?.title && <DialogTitle sx={{ textAlign: 'center', paddingBottom: 0 }}>{modal.title}</DialogTitle>}
					{modal?.alertText && modal?.severity && (
						<Alert severity={modal.severity} style={{ fontWeight: '500' }}>
							{modal.alertText}
						</Alert>
					)}
					<Button color="primary" size="large" variant="outlined" onClick={onClose}>
						Close
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
