import { Step, StepLabel, Stepper, StepperProps, styled } from '@mui/material';

const StyledStepper = styled(Stepper)(() => ({
	'& .MuiStepConnector-lineVertical': {
		height: '100%',
	},
}));

export const VerticalStepper: React.FC<{ steps: string[] | number[]; activeStep: number; showLabel?: boolean; sx?: StepperProps['sx'] }> = ({
	steps,
	activeStep,
	showLabel = true,
	sx,
}) => (
	<StyledStepper orientation="vertical" activeStep={activeStep} sx={sx}>
		{steps.map((label) => (
			<Step key={label}>
				<StepLabel>{showLabel && label}</StepLabel>
			</Step>
		))}
	</StyledStepper>
);
