import { Box, Container, Tab } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Page } from '../../components/Page';
import { StyledTabs } from '../../components/StyledTabs';
import { TabPanel } from '../../components/TabPanel';
import { ClientRoutes } from '../../constants/routes.constant';
import { AccountHolds } from './components/AccountHolds';
import { AccountInformation } from './components/AccountInformation';
import { ClientBreadCrumbs } from './components/ClientBreadCrumbs';

export enum ClientAccountTabs {
	INFORMATION = 'information',
	HOLDS = 'holds',
}

const pageTitle = 'Account';

export const ClientAccount = () => {
	const { tab } = useParams<'tab'>();

	return (
		<Page title={pageTitle}>
			<Container maxWidth="xl">
				<ClientBreadCrumbs childCrumb={pageTitle} />
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={3}>
					<StyledTabs value={tab || ClientAccountTabs.INFORMATION} textColor="secondary" indicatorColor="secondary">
						<Tab
							label="Account Information"
							value={ClientAccountTabs.INFORMATION}
							to={`${ClientRoutes.ACCOUNT}/${ClientAccountTabs.INFORMATION}`}
							component={Link}
						/>
						<Tab
							label="Account Holds"
							value={ClientAccountTabs.HOLDS}
							to={`${ClientRoutes.ACCOUNT}/${ClientAccountTabs.HOLDS}`}
							component={Link}
						/>
					</StyledTabs>
				</Box>
				<Box sx={{ pb: 5 }}>
					<TabPanel value={tab || ClientAccountTabs.INFORMATION} index={ClientAccountTabs.INFORMATION}>
						<AccountInformation />
					</TabPanel>
					<TabPanel value={tab} index={ClientAccountTabs.HOLDS}>
						<AccountHolds />
					</TabPanel>
				</Box>
			</Container>
		</Page>
	);
};
