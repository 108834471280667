import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Fuse from 'fuse.js';

type ClientSearchProps = {
	searchValue: string;
	disabled?: boolean;
	onChange: (searchValue: string) => void;
};

export const MIN_SEARCH_CHARS = 2;

export const fuseOptions = <T extends {}>(): Fuse.IFuseOptions<T> => ({
	isCaseSensitive: false,
	minMatchCharLength: MIN_SEARCH_CHARS,
	shouldSort: true,
	findAllMatches: true,
	threshold: 0.0,
	ignoreLocation: true,
	keys: [
		{
			name: 'DebtID',
			weight: 1,
		},
		{
			name: 'Fullname',
			weight: 0.3,
		},
	],
});

export const ClientSearch: React.FC<ClientSearchProps> = ({ searchValue, disabled = false, onChange }) => {
	return (
		<TextField
			name="clientSearch"
			fullWidth
			label="Client search"
			placeholder="Name or Reference"
			onChange={(e) => onChange(e.currentTarget.value)}
			value={searchValue}
			variant="outlined"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchOutlinedIcon />
					</InputAdornment>
				),
				endAdornment: searchValue.length > 0 && (
					<InputAdornment position="end">
						<IconButton aria-label="clear search" onClick={() => onChange('')} onMouseDown={() => onChange('')} edge="end">
							<ClearOutlinedIcon />
						</IconButton>
					</InputAdornment>
				),
			}}
			disabled={disabled}
		/>
	);
};
