import { Alert, Box, CardContent, darken, Grid, lighten, Stack, styled, Typography } from '@mui/material';
import { selectUserDetails } from '../../../store/features/user/userSlice';
import { useAppSelector } from '../../../store/hooks';

const StyledAlert = styled(Alert)(({ theme }) => ({
	color: darken(theme.palette.grey[500], 0.6),
	backgroundColor: lighten(theme.palette.grey[500], 0.9),
	'& .MuiAlert-icon': {
		color: theme.palette.grey[500],
	},
}));

export const UserDetails = () => {
	const user = useAppSelector(selectUserDetails);
	return (
		<CardContent>
			<Stack spacing={4}>
				<Typography variant="h6">General</Typography>
				<StyledAlert severity="info">
					Please contact our financial counsellor liaison team if you need to change any of your details.
				</StyledAlert>
				<Box sx={{ width: '100%' }}>
					<Grid container rowSpacing={{ xs: 4, md: 5 }} columnSpacing={5}>
						<Grid item xs={12} md={6}>
							<Typography variant="subtitle1">{user?.FirstName}</Typography>
							<Typography variant="body2" sx={{ mt: 1 }}>
								First name
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="subtitle1">{user?.Surname}</Typography>
							<Typography variant="body2" sx={{ mt: 1 }}>
								Surname
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="subtitle1">{user?.FCRegistrationNumber}</Typography>
							<Typography variant="body2" sx={{ mt: 1 }}>
								FCA Number
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="subtitle1">{user?.FCCompanyName}</Typography>
							<Typography variant="body2" sx={{ mt: 1 }}>
								Business name
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="subtitle1" noWrap>
								{user?.Email}
							</Typography>
							<Typography variant="body2" sx={{ mt: 1 }}>
								Email
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="subtitle1">{user?.ContactPhone}</Typography>
							<Typography variant="body2" sx={{ mt: 1 }}>
								Phone
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Stack>
		</CardContent>
	);
};
