import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ClientRoutes } from '../constants/routes.constant';
import { BlockedDebtStatuses } from '../enums/debtStatus.enum';
import { Status } from '../enums/status.enum';
import { setModalOpen } from '../store/features/app/appSlice';
import { getClientArrangementsActive } from '../store/features/client/arrangements/arrangementsSlice';
import { selectViewingClient } from '../store/features/client/clientSlice';
import { clientDebtAmountsGet } from '../store/features/client/debt/amountSlice';
import { clientDebtGet, selectClientDebt } from '../store/features/client/debt/debtSlice';
import { clientDebtorGet } from '../store/features/client/debtor/debtorSlice';
import { clientFuzzyEntityGet } from '../store/features/client/debtor/linkedEntitySlice';
import { clientHardshipGet } from '../store/features/client/hardship/hardshipSlice';
import { clientLedgerGet } from '../store/features/client/ledger/ledgerSlice';
import { selectUserDetailsStatus, userDetailsGet } from '../store/features/user/userSlice';
import { useAppSelector } from '../store/hooks';
import { DashboardNavbar } from './components/DashboardNavbar';
import { DashboardSidebar } from './components/DashboardSidebar';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
	display: 'flex',
	flex: '1 1 auto',
	maxWidth: '100%',
	paddingTop: theme.heights.appBar.xs,
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.widths.sidebar.sm,
		paddingTop: theme.heights.appBar.sm,
	},
	[theme.breakpoints.up('lg')]: {
		paddingLeft: theme.widths.sidebar.lg,
	},
}));

const MainWrapper = styled('main')(({ theme }) => ({
	flexGrow: 1,
	overflow: 'auto',
	minHeight: '100%',
	paddingTop: theme.spacing(3),
	paddingBottom: theme.spacing(10),
	[theme.breakpoints.up('lg')]: {
		paddingTop: theme.spacing(3),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));

type DashboardLayoutProps = {
	isclientview?: boolean;
};

const blockedRoutes = [ClientRoutes.MORATORIUM, ClientRoutes.DEBT_WAIVER, ClientRoutes.BALANCE_REDUCTION, ClientRoutes.PAYMENTS];

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({ isclientview = false }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [isSidebarOpen, setSidebarOpen] = useState(false);
	const client = useAppSelector(selectViewingClient);
	const userDetailsStatus = useAppSelector(selectUserDetailsStatus);
	const debt = useAppSelector(selectClientDebt);
	const isBlockedStatus = BlockedDebtStatuses.some((blockedName) => blockedName === debt?.DebtStatusName);

	useEffect(() => {
		if (userDetailsStatus === Status.IDLE) {
			dispatch(userDetailsGet());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDetailsStatus, dispatch]);

	// Do not allow FC to perform any actions if file is in a blocked status
	useEffect(() => {
		if (blockedRoutes.includes(location.pathname) && isBlockedStatus) {
			navigate(ClientRoutes.DASHBOARD);
			dispatch(
				setModalOpen({
					title: 'Cannot perform account action',
					alertText: `Sorry, you cannot action this account while it is in ${debt?.DebtStatusDescription} status.`,
					severity: 'warning',
				}),
			);
		}
	}, [location, dispatch, navigate, isBlockedStatus, debt?.DebtStatusDescription]);

	useEffect(() => {
		if (isclientview) {
			dispatch(clientDebtGet());
			dispatch(clientDebtAmountsGet());
			dispatch(clientLedgerGet());
			dispatch(clientDebtorGet());
			dispatch(clientFuzzyEntityGet());
			dispatch(clientHardshipGet());
			dispatch(getClientArrangementsActive());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isclientview, client, dispatch]);

	return (
		<>
			<DashboardLayoutRoot>
				<MainWrapper>
					{userDetailsStatus !== Status.SUCCEEDED ? (
						<Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, minHeight: '100%', width: '100%', justifyContent: 'center' }}>
							<CircularProgress color="secondary" size={150} />
						</Box>
					) : (
						<Outlet />
					)}
				</MainWrapper>
			</DashboardLayoutRoot>
			<DashboardNavbar onSidebarOpen={() => setSidebarOpen(!isSidebarOpen)} isclientview={isclientview} />
			<DashboardSidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} isclientview={isclientview} />
		</>
	);
};
