import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ClientRoutes } from '../constants/routes.constant';
import { setViewingClient } from '../store/features/client/clientSlice';
import { useAppDispatch } from '../store/hooks';

export const ViewDebtLink: React.FC<{ DebtID: number; DebtorEntityID: number; FCAccessID: number; to?: string }> = ({
	DebtID,
	DebtorEntityID,
	FCAccessID,
	to,
	children,
}) => {
	const dispatch = useAppDispatch();
	return (
		<Link
			component={RouterLink}
			to={to ?? ClientRoutes.DASHBOARD}
			onClick={() =>
				dispatch(
					setViewingClient({
						DebtID,
						DebtorEntityID,
						FCAccessID,
					}),
				)
			}
		>
			{children}
		</Link>
	);
};
