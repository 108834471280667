import React from 'react';

export const NewClientImg = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg fill="none" viewBox="0 0 106 106" {...props}>
			<path
				fill="#E0E0E0"
				d="M35.402 105.71l3.106-23.27c3.478.786 6.086 3.933 6.086 7.618v15.652h-9.192zM15.444 105.71V90.059c0-3.52 3.271-6.832 7.122-7.66l3.147 23.311H15.444z"
			></path>
			<path
				fill="#C6E1FF"
				d="M30.516 78.217a6.468 6.468 0 01-6.46-6.46c0-3.56 2.9-6.46 6.46-6.46a6.468 6.468 0 016.46 6.46 6.468 6.468 0 01-6.46 6.46z"
			></path>
			<path
				fill="#163A59"
				d="M30.516 80.825a9.065 9.065 0 01-9.068-9.068 9.065 9.065 0 019.068-9.068 9.065 9.065 0 019.068 9.068c-.041 5.01-4.099 9.068-9.068 9.068zm0-16.935a7.864 7.864 0 00-7.867 7.867 7.864 7.864 0 007.867 7.868 7.864 7.864 0 007.867-7.868c0-4.347-3.56-7.867-7.867-7.867z"
			></path>
			<path
				fill="#163A59"
				d="M47.286 105.71h-1.2V90.307c0-5.258-4.266-9.523-9.524-9.523H23.56c-5.258 0-9.523 4.265-9.523 9.523v15.403h-1.201V90.307c0-5.92 4.803-10.724 10.724-10.724h13.002c5.92 0 10.724 4.803 10.724 10.724v15.403z"
			></path>
			<path
				fill="#163A59"
				d="M26.997 105.794L24.844 89.19l-1.242-9.565h3.022l3.023 3.644-3.561 6.17 2.07 16.189-1.16.166zm-1.988-24.968l.87 6.666 2.36-4.099-2.112-2.567H25.01zM33.995 105.794l-1.2-.166 2.07-16.231-3.23-6.294 3.022-3.478h2.692l-1.242 9.565v.041h-.042l-2.07 16.563zm-.911-22.525l2.07 4.016.828-6.5h-.786l-2.112 2.484zM31.137 85.256h-1.2v20.455h1.2V85.256z"
			></path>
			<path
				fill="#163A59"
				d="M30.517 86.166l-2.07-2.443.91-.786 1.201 1.407 1.366-1.531.911.786-2.318 2.567zM32.876 91.26a.87.87 0 100-1.74.87.87 0 000 1.74zM32.876 97.636a.87.87 0 100-1.739.87.87 0 000 1.74zM32.876 103.764a.87.87 0 10.001-1.738.87.87 0 000 1.738zM43.145 95.897h-1.2v9.813h1.2v-9.813zM18.55 95.897h-1.201v9.813h1.2v-9.813z"
			></path>
			<path
				fill="#E0E0E0"
				d="M88.402 43.021l3.106-23.27c3.478.787 6.087 3.934 6.087 7.619V43.02h-9.193zM68.444 43.02V27.37c0-3.52 3.271-6.833 7.122-7.661l3.147 23.312H68.444z"
			></path>
			<path
				fill="#C6E1FF"
				d="M83.516 15.527a6.468 6.468 0 01-6.46-6.46c0-3.56 2.9-6.459 6.46-6.459s6.46 2.899 6.46 6.46c0 3.56-2.9 6.46-6.46 6.46z"
			></path>
			<path
				fill="#163A59"
				d="M83.516 18.095a9.065 9.065 0 01-9.068-9.068c0-5.01 4.058-9.027 9.068-9.027a9.065 9.065 0 019.068 9.068c0 5.01-4.058 9.027-9.068 9.027zm0-16.894a7.864 7.864 0 00-7.867 7.867 7.864 7.864 0 007.867 7.867 7.864 7.864 0 007.867-7.867 7.864 7.864 0 00-7.867-7.867z"
			></path>
			<path
				fill="#163A59"
				d="M100.286 43.02h-1.201V27.619c0-5.259-4.265-9.524-9.524-9.524h-12.96c-5.258 0-9.523 4.265-9.523 9.524V43.02h-1.201V27.618c0-5.921 4.803-10.724 10.724-10.724h13.002c5.92 0 10.724 4.803 10.724 10.724V43.02h-.041z"
			></path>
			<path
				fill="#163A59"
				d="M79.997 43.104L77.844 26.5l-1.242-9.564h3.022l3.023 3.643-3.561 6.17 2.07 16.19-1.16.165zm-1.988-25.01l.87 6.667 2.36-4.099-2.112-2.567H78.01zM86.994 43.104l-1.2-.165 2.07-16.232-3.23-6.293 3.022-3.479h2.692L89.106 26.5v.042h-.042l-2.07 16.562zm-.911-22.525l2.07 4.017.828-6.501h-.786l-2.112 2.484zM84.137 22.566h-1.2v20.455h1.2V22.566z"
			></path>
			<path
				fill="#163A59"
				d="M83.516 23.477l-2.07-2.484.952-.745 1.159 1.366 1.366-1.532.911.828-2.318 2.567zM85.917 28.53a.87.87 0 100-1.74.87.87 0 000 1.74zM85.917 34.947a.87.87 0 100-1.739.87.87 0 000 1.74zM85.917 41.075a.87.87 0 100-1.739.87.87 0 000 1.739zM96.186 33.208h-1.2v9.813h1.2v-9.813zM71.55 33.208h-1.201v9.813h1.2v-9.813z"
			></path>
			<path
				fill="#E0E0E0"
				d="M89.313 105.917l4.347-31.966.621.166c5.01 1.118 8.654 5.672 8.654 10.807v20.993H89.312zM62.648 105.917V84.924c0-4.928 4.389-9.69 10.02-10.849l.62-.124 4.39 31.966h-15.03z"
			></path>
			<path
				fill="#C6E1FF"
				d="M83.433 69.646c-5.093 0-9.275-4.14-9.275-9.275 0-5.093 4.14-9.275 9.275-9.275 5.093 0 9.275 4.14 9.275 9.275 0 5.093-4.14 9.275-9.275 9.275z"
			></path>
			<path
				fill="#163A59"
				d="M83.433 72.295c-6.584 0-11.925-5.341-11.925-11.925 0-6.583 5.341-11.925 11.925-11.925 6.583 0 11.925 5.342 11.925 11.925.041 6.584-5.342 11.925-11.925 11.925zm0-22.649c-5.921 0-10.724 4.803-10.724 10.724s4.803 10.724 10.724 10.724c5.92 0 10.724-4.803 10.724-10.724.041-5.92-4.803-10.724-10.724-10.724z"
			></path>
			<path
				fill="#163A59"
				d="M105.751 105.917h-1.201v-20.62c0-7.164-5.838-12.96-12.96-12.96H74.158c-7.163 0-12.96 5.838-12.96 12.96v20.62h-1.2v-20.62c0-7.826 6.376-14.161 14.16-14.161H91.59c7.826 0 14.161 6.376 14.161 14.16v20.621z"
			></path>
			<path
				fill="#163A59"
				d="M78.92 106l-2.857-22.11-.041-.042.041-.083-1.615-12.67h3.685l3.893 4.72-4.72 8.24 2.815 21.821-1.2.124zm-3.105-33.704l1.242 9.771 3.52-6.128-2.982-3.643h-1.78zM87.946 106l-1.2-.166 2.815-21.82-4.306-8.365 3.892-4.513h3.313l-1.615 12.67.041.124-.083.042L87.946 106zm-1.283-30.227l3.147 6.128 1.242-9.606h-1.408l-2.981 3.478zM84.095 78.465h-1.2v27.452h1.2V78.465z"
			></path>
			<path
				fill="#163A59"
				d="M83.475 79.376l-2.609-3.105.911-.787 1.74 2.07 1.987-2.236.87.787-2.9 3.271zM86.662 86.498a1.16 1.16 0 100-2.32 1.16 1.16 0 000 2.32zM86.662 95.069a1.16 1.16 0 100-2.319 1.16 1.16 0 000 2.319zM86.662 103.309a1.16 1.16 0 100-2.319 1.16 1.16 0 000 2.319zM67.202 92.75H66v13.167h1.2V92.75z"
			></path>
			<path
				fill="#71CDE1"
				d="M5.01 55.857l-3.85 3.851c-.373.373-.373.994 0 1.408l4.223 4.224c.372.372.994.372 1.408 0l3.85-3.851-2.153-2.153-3.478-3.479z"
			></path>
			<path
				fill="#23DE84"
				d="M26.748 40.413l-.663-.663a3.524 3.524 0 00-4.968 0L5.01 55.857l3.478 3.479-3.478-3.479 3.478 3.479 2.153 2.153 16.107-16.107c1.366-1.408 1.366-3.603 0-4.97z"
			></path>
			<path
				fill="#163A59"
				d="M40.04 40.744c-3.81 0-7.412-1.49-10.104-4.182-5.59-5.59-5.59-14.658 0-20.206a14.222 14.222 0 0110.104-4.182c3.809 0 7.411 1.49 10.103 4.182a14.221 14.221 0 014.182 10.103c0 3.81-1.49 7.412-4.182 10.103a14.222 14.222 0 01-10.103 4.182zm0-27.37a12.9 12.9 0 00-9.234 3.851c-5.134 5.093-5.134 13.375-.041 18.509 2.484 2.484 5.755 3.85 9.233 3.85a12.9 12.9 0 009.234-3.85c2.484-2.484 3.85-5.756 3.85-9.234 0-3.478-1.366-6.79-3.85-9.275-2.402-2.484-5.714-3.85-9.192-3.85z"
			></path>
			<path
				fill="#71CDE1"
				d="M53.62 12.877c-7.494-7.536-19.667-7.536-27.203-.042-7.495 7.495-7.536 19.71 0 27.204 7.494 7.495 19.71 7.495 27.204 0 7.536-7.494 7.536-19.668 0-27.162zm-23.27 23.27c-5.341-5.341-5.341-14.037 0-19.378 5.342-5.341 13.996-5.341 19.378 0 5.342 5.341 5.342 13.995 0 19.378-5.341 5.383-14.036 5.342-19.378 0z"
			></path>
			<path
				fill="#163A59"
				d="M6.086 66.25a1.6 1.6 0 01-1.118-.456L.704 61.53a1.6 1.6 0 010-2.236l19.999-19.957a4.073 4.073 0 012.898-1.201c1.118 0 2.153.414 2.899 1.2l.662.663a4.123 4.123 0 010 5.838l-20 20c-.248.248-.662.414-1.076.414zm17.515-26.956c-.787 0-1.49.29-2.07.87L1.573 60.163a.376.376 0 00-.124.29c0 .082.041.207.124.29l4.223 4.182c.166.165.415.165.539 0l19.999-20c1.118-1.118 1.118-2.98 0-4.14l-.662-.621a2.948 2.948 0 00-2.07-.87z"
			></path>
			<path
				fill="#163A59"
				d="M40.04 46.292c-5.3 0-10.27-2.07-14.037-5.797-7.743-7.743-7.743-20.33 0-28.073 3.726-3.727 8.736-5.797 14.036-5.797 5.3 0 10.269 2.07 14.037 5.797 7.743 7.743 7.743 20.33 0 28.073-3.768 3.727-8.737 5.797-14.037 5.797zm0-38.466c-4.97 0-9.648 1.946-13.168 5.465-7.287 7.288-7.287 19.089 0 26.376 3.52 3.52 8.199 5.466 13.167 5.466 4.969 0 9.648-1.946 13.167-5.466 7.246-7.246 7.246-19.088 0-26.376-3.52-3.52-8.198-5.465-13.167-5.465zm0 32.918c-3.81 0-7.413-1.49-10.104-4.182-5.59-5.59-5.59-14.658 0-20.206a14.222 14.222 0 0110.103-4.183c3.81 0 7.412 1.491 10.103 4.182a14.221 14.221 0 014.182 10.104c0 3.809-1.49 7.411-4.182 10.103a14.222 14.222 0 01-10.103 4.182zm0-27.37a12.9 12.9 0 00-9.234 3.851c-5.135 5.093-5.135 13.374-.042 18.509 2.485 2.484 5.756 3.85 9.275 3.85 3.52 0 6.79-1.366 9.275-3.85 2.485-2.485 3.851-5.756 3.851-9.275 0-3.479-1.366-6.791-3.85-9.275a13.201 13.201 0 00-9.276-3.81z"
			></path>
			<path
				fill="#163A59"
				d="M40.62 30.971h-1.201v-1.035c0-1.408.828-2.608 2.112-3.064 1.987-.662 3.23-2.526 3.188-4.596-.083-2.443-2.112-4.513-4.555-4.555-1.284-.041-2.484.456-3.395 1.325A4.736 4.736 0 0035.36 22.4h-1.2c0-1.614.62-3.105 1.78-4.223a5.835 5.835 0 014.264-1.656c3.065.082 5.673 2.65 5.756 5.755a5.841 5.841 0 01-4.017 5.756c-.786.248-1.283 1.035-1.283 1.904v1.035h-.041zM40.619 32.835h-1.201v2.277h1.2v-2.277zM11.068 61.054l-5.622-5.621-.849.849 5.622 5.621.849-.849z"
			></path>
		</svg>
	);
};
