import { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { clearTimedOut, selectTimedOut } from '../store/features/auth/authSlice';
import { Alert, Portal, Snackbar } from '@mui/material';

export const SessionTimeoutSnackbar = () => {
	const dispatch = useAppDispatch();

	const timedOut = useAppSelector(selectTimedOut);

	const handleClearTimedOut = useCallback(() => {
		dispatch(clearTimedOut());
		document.body.removeEventListener('click', handleClearTimedOut);
	}, [dispatch]);

	useEffect(() => {
		if (timedOut) {
			document.body.addEventListener('click', handleClearTimedOut);
		}
		return () => {
			document.body.removeEventListener('click', handleClearTimedOut);
		};
	}, [handleClearTimedOut, timedOut]);

	return (
		<Portal>
			<Snackbar open={timedOut} autoHideDuration={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Alert variant="filled" severity="warning">
					You have been automatically logged out after inactivity. Please login again.
				</Alert>
			</Snackbar>
		</Portal>
	);
};
