import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { clearStorage } from '../../../actions/clearStorage';
import { FCTokenName } from '../../../constants/token.constant';
import { appInsights } from '../../../services/ai/reactPlugin';
// eslint-disable-next-line import/no-cycle
import { fcAPI } from '../../../services/api/api';
import { FCResponse } from '../../../services/api/base.api.response';
import { handleAsyncThunkError } from '../../hooks';
import { RootState } from '../../store';
import { setErrorSnackbarOpen } from '../app/appSlice';
import { resetViewingClient, setViewingClient } from '../client/clientSlice';
import { AuthAPI, ILogin, IResetConfirm } from './types';

let existingUser: JWTUser | undefined;
let rememberMe: boolean = localStorage.getItem('rememberMe') ? !!JSON.parse(localStorage.getItem('rememberMe') as string) : false;
let storage: Storage = rememberMe ? localStorage : sessionStorage;

// Clear invalid storage
if (rememberMe) {
	sessionStorage.removeItem(FCTokenName);
} else {
	localStorage.removeItem(FCTokenName);
}

// Check storage for unexpired token
if (storage.getItem(FCTokenName)) {
	const decodedToken = jwt_decode(storage.getItem(FCTokenName) as string) as JWTUser;
	if (Date.now() <= decodedToken.exp * 1000) {
		existingUser = decodedToken;
	} else {
		// Clean up expired tokens
		storage.removeItem(FCTokenName);
	}
}

export interface JWTUser {
	userID: number;
	companyID: number;
	authenticated: boolean;
	approved: boolean;
	iat: number;
	exp: number;
	aud: string;
}

export interface AuthState {
	user: JWTUser | undefined;
	timedOut: boolean;
}

const initialState: AuthState = {
	user: existingUser,
	timedOut: false,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<string>) => {
			const decodedToken = jwt_decode(action.payload) as JWTUser;
			if (appInsights) {
				appInsights.setAuthenticatedUserContext(decodedToken.userID.toString(), undefined, true);
			}
			storage.setItem(FCTokenName, action.payload);
			state.user = decodedToken;
		},
		logout: (state) => {
			clearStorage();
			state.user = undefined;
		},
		timedOut: (state) => {
			clearStorage();
			state.user = undefined;
			state.timedOut = true;
		},
		clearTimedOut: (state) => {
			state.timedOut = false;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(setViewingClient, (state, action) => {
				if (appInsights && state.user) {
					appInsights.setAuthenticatedUserContext(state.user.userID.toString(), action.payload.DebtID.toString(), true);
				}
			})
			.addCase(resetViewingClient, (state) => {
				if (appInsights && state.user) {
					appInsights.setAuthenticatedUserContext(state.user.userID.toString(), undefined, true);
				}
			});
	},
});

export const { setUser, logout, timedOut, clearTimedOut } = authSlice.actions;

export default authSlice.reducer;

export const selectAuthenticated = (state: RootState) => state.auth.user?.authenticated ?? false;
export const selectTimedOut = (state: RootState) => state.auth.timedOut;
export const selectApproved = (state: RootState) => !!(state.auth.user?.authenticated && state.auth.user?.approved);

export const loginPost = createAsyncThunk(
	'auth/login',
	async (loginDetails: { email: string; password: string; captchaToken: string; submittedRememberMe: boolean }, { rejectWithValue, dispatch }) => {
		const { submittedRememberMe, ...userLogin } = loginDetails;
		try {
			rememberMe = submittedRememberMe;
			storage = submittedRememberMe ? localStorage : sessionStorage;
			const { data } = await fcAPI.post<ILogin>(AuthAPI.LOGIN, userLogin);
			if (data.success && data.data.jwt) {
				dispatch(setUser(data.data.jwt));
				return data.success;
			} else if (!data.success && data.data.forceReset) {
				return data.success;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export const passwordForgotPost = createAsyncThunk('auth/forgot', async (forgotPasswordDetails: { email: string }, { rejectWithValue, dispatch }) => {
	try {
		const { data } = await fcAPI.post<FCResponse>(AuthAPI.RESET_PASSWORD, forgotPasswordDetails);
		if (data.success) {
			return data.message;
		}
		dispatch(setErrorSnackbarOpen({ message: data.message }));
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export const passwordResetPost = createAsyncThunk(
	'auth/reset',
	async (confirmDetails: { email: string; password: string; code: string }, { rejectWithValue, dispatch }) => {
		try {
			const { data } = await fcAPI.post<IResetConfirm>(AuthAPI.CONFIRM_PASSWORD, confirmDetails);
			if (data.success && data.data.jwt) {
				dispatch(setUser(data.data.jwt));
				return data.success;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);
