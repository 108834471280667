import { Palette, PaletteColor, styled, lighten, darken, SxProps, Theme } from '@mui/material/styles';

const RootStyle = styled('span')<{
	ownerState: { color: keyof Palette | 'default'; variant: 'filled' | 'outlined' | 'ghost'; size: 'small' | 'medium' };
}>(({ theme, ownerState }) => {
	const { color, variant, size } = ownerState;

	const styleFilled = (paletteColor: keyof Palette) => ({
		color: (theme.palette[paletteColor] as PaletteColor).contrastText,
		backgroundColor: (theme.palette[paletteColor] as PaletteColor).main,
	});

	const styleOutlined = (paletteColor: keyof Palette) => ({
		color: darken((theme.palette[paletteColor] as PaletteColor).light, 0.6),
		backgroundColor: 'transparent',
		border: `1px solid ${(theme.palette[paletteColor] as PaletteColor).light}`,
	});

	const styleGhost = (paletteColor: keyof Palette) => ({
		color: darken((theme.palette[paletteColor] as PaletteColor).light, 0.6),
		backgroundColor: lighten((theme.palette[paletteColor] as PaletteColor).light, 0.9),
	});

	return {
		height: 32,
		minWidth: 32,
		lineHeight: 0,
		borderRadius: 32 / 2,
		cursor: 'default',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		display: 'inline-flex',
		justifyContent: 'center',
		padding: theme.spacing(0, 1.5),
		color: theme.palette.grey[800],
		fontSize: theme.typography.pxToRem(13),
		fontFamily: theme.typography.fontFamily,
		backgroundColor: theme.palette.grey[300],

		...(size === 'small' && {
			height: 24,
			minWidth: 24,
			padding: theme.spacing(0, 1),
		}),

		...(color !== 'default'
			? {
					...(variant === 'filled' && { ...styleFilled(color) }),
					...(variant === 'outlined' && { ...styleOutlined(color) }),
					...(variant === 'ghost' && { ...styleGhost(color) }),
			  }
			: {
					...(variant === 'outlined' && {
						backgroundColor: 'transparent',
						color: theme.palette.text.primary,
						border: `1px solid ${theme.palette.grey[500]}`,
					}),
					...(variant === 'ghost' && {
						color: theme.palette.text.primary,
						backgroundColor: lighten(theme.palette.grey[500], 0.9),
					}),
			  }),
	};
});

type LabelProps = {
	color?: keyof Palette | 'default';
	variant?: 'filled' | 'outlined' | 'ghost';
	size?: 'small' | 'medium';
	sx?: SxProps<Theme>;
};

export const Label: React.FC<LabelProps> = ({ color = 'default', variant = 'ghost', size = 'medium', children, ...other }) => {
	return (
		<RootStyle ownerState={{ color, variant, size }} {...other}>
			{children}
		</RootStyle>
	);
};
