import React from 'react';

export const Benefit2 = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg width={106} height={106} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#a)">
			<path
				d="m35.402 105.71 3.106-23.27c3.478.786 6.086 3.933 6.086 7.618v15.652h-9.192ZM15.445 105.71V90.059c0-3.52 3.271-6.832 7.122-7.66l3.147 23.311H15.445Z"
				fill="#E0E0E0"
			/>
			<path
				d="M30.518 78.217a6.468 6.468 0 0 1-6.46-6.46c0-3.56 2.899-6.46 6.46-6.46 3.56 0 6.46 2.9 6.46 6.46a6.468 6.468 0 0 1-6.46 6.46Z"
				fill="#C6E1FF"
			/>
			<path
				d="M30.517 80.825a9.065 9.065 0 0 1-9.068-9.068 9.065 9.065 0 0 1 9.068-9.068 9.065 9.065 0 0 1 9.068 9.068c-.041 5.01-4.1 9.068-9.068 9.068Zm0-16.935a7.864 7.864 0 0 0-7.867 7.867 7.864 7.864 0 0 0 7.867 7.868 7.864 7.864 0 0 0 7.867-7.868c0-4.347-3.56-7.867-7.867-7.867Z"
				fill="#163A59"
			/>
			<path
				d="M47.286 105.71h-1.2V90.307c0-5.258-4.266-9.523-9.524-9.523H23.56c-5.258 0-9.523 4.265-9.523 9.523v15.403h-1.201V90.307c0-5.92 4.803-10.724 10.724-10.724h13.002c5.92 0 10.724 4.803 10.724 10.724v15.403Z"
				fill="#163A59"
			/>
			<path
				d="M26.997 105.794 24.844 89.19l-1.242-9.565h3.022l3.023 3.644-3.561 6.17 2.07 16.189-1.16.166Zm-1.988-24.968.87 6.666 2.36-4.099-2.112-2.567H25.01ZM33.993 105.794l-1.2-.166 2.07-16.231-3.23-6.294 3.023-3.478h2.69l-1.241 9.565v.041h-.042l-2.07 16.563Zm-.911-22.525 2.07 4.016.828-6.5h-.786l-2.112 2.484ZM31.138 85.256h-1.2v20.455h1.2V85.256Z"
				fill="#163A59"
			/>
			<path
				d="m30.516 86.166-2.07-2.443.91-.786 1.201 1.407 1.366-1.531.911.786-2.318 2.567ZM32.877 91.26a.87.87 0 1 0 0-1.74.87.87 0 0 0 0 1.74ZM32.877 97.636a.87.87 0 1 0 0-1.739.87.87 0 0 0 0 1.74ZM32.877 103.764a.87.87 0 1 0 .001-1.738.87.87 0 0 0 0 1.738ZM43.146 95.897h-1.2v9.813h1.2v-9.813ZM18.552 95.897h-1.2v9.813h1.2v-9.813Z"
				fill="#163A59"
			/>
			<path
				d="m88.402 43.021 3.106-23.27c3.478.787 6.087 3.934 6.087 7.619V43.02h-9.193ZM68.445 43.02V27.37c0-3.52 3.271-6.833 7.122-7.661l3.147 23.312H68.445Z"
				fill="#E0E0E0"
			/>
			<path
				d="M83.518 15.527a6.468 6.468 0 0 1-6.46-6.46c0-3.56 2.899-6.459 6.46-6.459 3.56 0 6.46 2.899 6.46 6.46 0 3.56-2.9 6.46-6.46 6.46Z"
				fill="#C6E1FF"
			/>
			<path
				d="M83.517 18.095a9.065 9.065 0 0 1-9.068-9.068C74.45 4.017 78.507 0 83.517 0a9.065 9.065 0 0 1 9.068 9.068c0 5.01-4.058 9.027-9.068 9.027Zm0-16.894a7.864 7.864 0 0 0-7.867 7.867 7.864 7.864 0 0 0 7.867 7.867 7.864 7.864 0 0 0 7.867-7.867 7.864 7.864 0 0 0-7.867-7.867Z"
				fill="#163A59"
			/>
			<path
				d="M100.287 43.02h-1.2V27.619c0-5.259-4.265-9.524-9.524-9.524h-12.96c-5.258 0-9.523 4.265-9.523 9.524V43.02h-1.201V27.618c0-5.921 4.803-10.724 10.724-10.724h13.002c5.92 0 10.724 4.803 10.724 10.724V43.02h-.042Z"
				fill="#163A59"
			/>
			<path
				d="M79.997 43.104 77.844 26.5l-1.242-9.564h3.022l3.023 3.643-3.561 6.17 2.07 16.19-1.16.165Zm-1.988-25.01.87 6.667 2.36-4.099-2.112-2.567H78.01ZM86.997 43.104l-1.2-.165 2.07-16.232-3.23-6.293 3.022-3.479h2.692L89.109 26.5v.042h-.042l-2.07 16.562Zm-.911-22.525 2.07 4.017.828-6.501h-.786l-2.112 2.484ZM84.138 22.566h-1.2v20.455h1.2V22.566Z"
				fill="#163A59"
			/>
			<path
				d="m83.52 23.477-2.07-2.484.952-.745 1.159 1.366 1.366-1.532.911.828-2.319 2.567ZM85.92 28.53a.87.87 0 1 0 0-1.74.87.87 0 0 0 0 1.74ZM85.92 34.947a.87.87 0 1 0 0-1.739.87.87 0 0 0 0 1.74ZM85.92 41.075a.87.87 0 1 0 0-1.739.87.87 0 0 0 0 1.739ZM96.19 33.208h-1.202v9.813h1.201v-9.813ZM71.552 33.208h-1.2v9.813h1.2v-9.813Z"
				fill="#163A59"
			/>
			<path
				d="m89.316 105.917 4.348-31.966.621.166c5.01 1.118 8.654 5.672 8.654 10.807v20.993H89.316ZM62.648 105.917V84.924c0-4.928 4.39-9.69 10.02-10.849l.622-.124 4.389 31.966h-15.03Z"
				fill="#E0E0E0"
			/>
			<path
				d="M83.435 69.646c-5.093 0-9.275-4.14-9.275-9.275 0-5.093 4.14-9.275 9.275-9.275 5.093 0 9.275 4.14 9.275 9.275 0 5.093-4.14 9.275-9.275 9.275Z"
				fill="#C6E1FF"
			/>
			<path
				d="M83.437 72.295c-6.584 0-11.925-5.341-11.925-11.925 0-6.583 5.341-11.925 11.925-11.925 6.583 0 11.925 5.342 11.925 11.925.041 6.584-5.342 11.925-11.925 11.925Zm0-22.649c-5.921 0-10.724 4.803-10.724 10.724s4.803 10.724 10.724 10.724c5.92 0 10.724-4.803 10.724-10.724.041-5.92-4.803-10.724-10.724-10.724Z"
				fill="#163A59"
			/>
			<path
				d="M105.754 105.917h-1.201v-20.62c0-7.164-5.838-12.96-12.96-12.96H74.161c-7.163 0-12.96 5.838-12.96 12.96v20.62H60v-20.62c0-7.826 6.377-14.161 14.16-14.161h17.433c7.826 0 14.161 6.376 14.161 14.16v20.621Z"
				fill="#163A59"
			/>
			<path
				d="m78.921 106-2.857-22.11-.041-.042.041-.083-1.615-12.67h3.685l3.893 4.72-4.72 8.24 2.815 21.821-1.2.124Zm-3.105-33.704 1.242 9.771 3.52-6.128-2.982-3.643h-1.78ZM87.95 106l-1.202-.166 2.816-21.82-4.306-8.365 3.892-4.513h3.313l-1.615 12.67.041.124-.083.042L87.95 106Zm-1.284-30.227 3.147 6.128 1.242-9.606h-1.408l-2.981 3.478ZM84.1 78.465h-1.202v27.452H84.1V78.465Z"
				fill="#163A59"
			/>
			<path
				d="m83.476 79.376-2.609-3.105.911-.787 1.74 2.07 1.987-2.236.87.787-2.9 3.271ZM86.667 86.498a1.16 1.16 0 1 0 0-2.32 1.16 1.16 0 0 0 0 2.32ZM86.667 95.069a1.16 1.16 0 1 0 0-2.319 1.16 1.16 0 0 0 0 2.319ZM86.667 103.309a1.16 1.16 0 1 0 0-2.319 1.16 1.16 0 0 0 0 2.319ZM67.205 92.75h-1.201v13.167h1.2V92.75Z"
				fill="#163A59"
			/>
			<path
				d="m5.013 55.857-3.85 3.851c-.373.373-.373.994 0 1.408l4.223 4.224c.372.372.993.372 1.408 0l3.85-3.851-2.153-2.153-3.478-3.479Z"
				fill="#71CDE1"
			/>
			<path
				d="m26.75 40.413-.663-.663a3.524 3.524 0 0 0-4.968 0L5.012 55.857l3.478 3.479-3.478-3.479 3.478 3.479 2.153 2.153L26.75 45.382c1.366-1.408 1.366-3.603 0-4.97Z"
				fill="#23DE84"
			/>
			<path
				d="M40.042 40.744c-3.81 0-7.412-1.49-10.104-4.182-5.59-5.59-5.59-14.658 0-20.206a14.222 14.222 0 0 1 10.104-4.182c3.809 0 7.411 1.49 10.103 4.182a14.222 14.222 0 0 1 4.182 10.103c0 3.81-1.49 7.412-4.182 10.103a14.221 14.221 0 0 1-10.103 4.182Zm0-27.37a12.9 12.9 0 0 0-9.234 3.851c-5.134 5.093-5.134 13.375-.041 18.509 2.484 2.484 5.755 3.85 9.233 3.85a12.9 12.9 0 0 0 9.234-3.85c2.484-2.484 3.85-5.756 3.85-9.234 0-3.478-1.366-6.79-3.85-9.275-2.402-2.484-5.714-3.85-9.192-3.85Z"
				fill="#163A59"
			/>
			<path
				d="M53.622 12.877c-7.495-7.536-19.668-7.536-27.204-.042-7.495 7.495-7.536 19.71 0 27.204 7.494 7.495 19.71 7.495 27.204 0 7.535-7.494 7.535-19.668 0-27.162Zm-23.27 23.27c-5.342-5.341-5.342-14.037 0-19.378 5.34-5.341 13.995-5.341 19.377 0 5.342 5.341 5.342 13.995 0 19.378-5.341 5.383-14.036 5.342-19.378 0Z"
				fill="#71CDE1"
			/>
			<path
				d="M6.088 66.25a1.6 1.6 0 0 1-1.118-.456L.705 61.53a1.6 1.6 0 0 1 0-2.236l20-19.957a4.073 4.073 0 0 1 2.898-1.201c1.118 0 2.153.414 2.899 1.2l.662.663a4.124 4.124 0 0 1 0 5.838l-20 20c-.248.248-.662.414-1.076.414Zm17.515-26.956c-.787 0-1.49.29-2.07.87L1.575 60.163a.376.376 0 0 0-.124.29c0 .082.041.207.124.29l4.223 4.182c.166.165.415.165.539 0l19.999-20c1.118-1.118 1.118-2.98 0-4.14l-.663-.621a2.948 2.948 0 0 0-2.07-.87Z"
				fill="#163A59"
			/>
			<path
				d="M40.043 46.292c-5.3 0-10.269-2.07-14.037-5.797-7.742-7.743-7.742-20.33 0-28.073 3.727-3.727 8.737-5.797 14.037-5.797 5.3 0 10.269 2.07 14.037 5.797 7.743 7.743 7.743 20.33 0 28.073-3.768 3.727-8.737 5.797-14.037 5.797Zm0-38.466c-4.969 0-9.648 1.946-13.167 5.465-7.288 7.288-7.288 19.089 0 26.376 3.52 3.52 8.198 5.466 13.167 5.466 4.969 0 9.648-1.946 13.167-5.466 7.246-7.246 7.246-19.088 0-26.376-3.52-3.52-8.198-5.465-13.167-5.465Zm0 32.918c-3.81 0-7.412-1.49-10.103-4.182-5.59-5.59-5.59-14.658 0-20.206a14.222 14.222 0 0 1 10.103-4.183c3.81 0 7.412 1.491 10.103 4.182a14.221 14.221 0 0 1 4.182 10.104c0 3.809-1.49 7.411-4.182 10.103a14.222 14.222 0 0 1-10.103 4.182Zm0-27.37a12.9 12.9 0 0 0-9.233 3.851c-5.135 5.093-5.135 13.374-.042 18.509 2.484 2.484 5.756 3.85 9.275 3.85 3.52 0 6.79-1.366 9.275-3.85 2.485-2.485 3.85-5.756 3.85-9.275 0-3.479-1.365-6.791-3.85-9.275a13.201 13.201 0 0 0-9.275-3.81Z"
				fill="#163A59"
			/>
			<path
				d="M40.62 30.971h-1.201v-1.035c0-1.408.828-2.608 2.112-3.064 1.987-.662 3.23-2.526 3.188-4.596-.083-2.443-2.112-4.513-4.555-4.555-1.284-.041-2.484.456-3.395 1.325A4.736 4.736 0 0 0 35.36 22.4h-1.2c0-1.614.62-3.105 1.78-4.223a5.835 5.835 0 0 1 4.264-1.656c3.065.082 5.673 2.65 5.756 5.755a5.841 5.841 0 0 1-4.017 5.756c-.786.248-1.283 1.035-1.283 1.904v1.035h-.041ZM40.623 32.835h-1.201v2.277h1.2v-2.277ZM11.067 61.054l-5.622-5.621-.849.849 5.622 5.621.849-.849Z"
				fill="#163A59"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h106v106H0z" />
			</clipPath>
		</defs>
	</svg>
);
