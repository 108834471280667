import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientArrangementsAPI, IClientArrangements, IGetArrangementsOutput } from './types';

export const getClientArrangementsActive = createAsyncThunk('client/arrangements', async (_, { rejectWithValue, dispatch, getState }) => {
	try {
		const { DebtID, DebtorEntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
		const { data } = await fcAPI.get<IClientArrangements>(ClientArrangementsAPI.ARRANGEMENTS, {
			params: { debtID: DebtID, entityID: DebtorEntityID, active: 'true' },
		});
		if (data.success) {
			return data.data.arrangements;
		}
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export const getClientArrangementsInActive = createAsyncThunk('client/arrangements/inactive', async (_, { rejectWithValue, dispatch, getState }) => {
	try {
		const { DebtID, DebtorEntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
		const { data } = await fcAPI.get<IClientArrangements>(ClientArrangementsAPI.ARRANGEMENTS, {
			params: { debtID: DebtID, entityID: DebtorEntityID, active: 'false' },
		});
		if (data.success) {
			return data.data.arrangements;
		}
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export interface ClientarrangementsState {
	active: IGetArrangementsOutput[];
	inactive: IGetArrangementsOutput[];
	status: Status;
	inactiveStatus: Status;
}

const initialState: ClientarrangementsState = {
	active: [],
	inactive: [],
	status: Status.IDLE,
	inactiveStatus: Status.IDLE,
};

const clientArrangementsSlice = createSlice({
	name: 'clientArrangements',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(getClientArrangementsActive.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(getClientArrangementsActive.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.active = action.payload;
			})
			.addCase(getClientArrangementsActive.rejected, (state) => {
				state.status = Status.FAILED;
			})
			.addCase(getClientArrangementsInActive.pending, (state) => {
				state.inactiveStatus = Status.LOADING;
			})
			.addCase(getClientArrangementsInActive.fulfilled, (state, action) => {
				state.inactiveStatus = Status.SUCCEEDED;
				state.inactive = action.payload;
			})
			.addCase(getClientArrangementsInActive.rejected, (state) => {
				state.inactiveStatus = Status.FAILED;
			});
	},
});

export default clientArrangementsSlice.reducer;

export const selectActiveArrangements = (state: RootState) => state.clientArrangements.active;
export const selectInactiveArrangements = (state: RootState) => state.clientArrangements.inactive;
export const selectArrangementsStatus = (state: RootState) => state.clientArrangements.status;
export const selectInactiveArrangementsStatus = (state: RootState) => state.clientArrangements.inactiveStatus;
