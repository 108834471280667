import { FCResponse } from '../../../../services/api/base.api.response';

export enum ClientHardshipAPI {
	HARDSHIP = '/api/client/hardship',
}

export interface IGetDebtorHardshipOutput {
	HardshipID: number;
	EffectiveDate: string;
	EndDate: string | null;
	FollowupDate: string | null;
	HardshipStateID: number;
	HardshipStateName: string;
	HardshipCategoryID: number;
	HardshipCategoryName: string;
	ApprovedDate: string | null;
	AssignedAgent: string | null;
	HardshipResolutionTypeID: number | null;
	HardshipResolutionName: string | null;
	AddedDate: string;
	ExtendedBy: string | null;
	ExtendedDate: string | null;
}

export type IGetClientHardship = FCResponse<{ hardships: IGetDebtorHardshipOutput[] }>;
