import { Avatar, Box, Button, Divider, IconButton, MenuItem, Skeleton, Typography } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { stringAvatar } from '../../utils/avatar.util';
import { alpha } from '@mui/material/styles';
import { MenuPopover } from '../../components/MenuPopover';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logout } from '../../store/features/auth/authSlice';
import { PortalRoutes } from '../../constants/routes.constant';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { selectUserDetails } from '../../store/features/user/userSlice';
import { getFullName } from '../../utils/text.utill';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

export const AccountPopover: FC<{ isclientview?: boolean }> = ({ isclientview = false }) => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUserDetails);
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<IconButton
				ref={anchorRef}
				onClick={handleOpen}
				sx={{
					ml: 1,
					padding: 0,
					width: 44,
					height: 44,
					...(open && {
						'&:before': {
							zIndex: 1,
							content: "''",
							width: '100%',
							height: '100%',
							borderRadius: '50%',
							position: 'absolute',
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.3),
						},
					}),
				}}
			>
				<Avatar
					sx={{
						bgcolor: 'common.white',
						color: 'text.primary',
						height: 40,
						width: 40,
					}}
				>
					{user && stringAvatar(getFullName(user.FirstName, user.Surname))}
				</Avatar>
			</IconButton>

			<MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<Typography variant="subtitle1" noWrap>
						{!user ? <Skeleton /> : getFullName(user.FirstName, user.Surname)}
					</Typography>
					<Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
						{!user ? <Skeleton /> : user.Email}
					</Typography>
				</Box>

				<Divider sx={{ my: 1 }} />
				{isclientview && (
					<MenuItem
						to={PortalRoutes.MY_CLIENTS}
						component={RouterLink}
						onClick={handleClose}
						sx={{
							typography: 'body2',
							py: 1,
							px: 2.5,
							'&:hover': {
								backgroundColor: (theme) => theme.palette.grey[50],
							},
							fontWeight: 600,
						}}
					>
						<ArrowBackOutlinedIcon
							sx={{
								mr: 1.5,
							}}
							fontSize="small"
						/>
						Back to My Clients
					</MenuItem>
				)}

				<MenuItem
					to={PortalRoutes.ACCOUNT_SETTINGS}
					component={RouterLink}
					onClick={handleClose}
					sx={{
						typography: 'body2',
						py: 1,
						px: 2.5,
						'&:hover': {
							backgroundColor: (theme) => theme.palette.grey[50],
						},
						fontWeight: 600,
					}}
				>
					<SettingsOutlinedIcon
						sx={{
							mr: 1.5,
						}}
						fontSize="small"
					/>
					Account Settings
				</MenuItem>
				<MenuItem
					to={PortalRoutes.CONTACT}
					component={RouterLink}
					onClick={handleClose}
					sx={{
						typography: 'body2',
						py: 1,
						px: 2.5,
						'&:hover': {
							backgroundColor: (theme) => theme.palette.grey[50],
						},
						fontWeight: 600,
					}}
				>
					<ContactSupportOutlinedIcon
						sx={{
							mr: 1.5,
						}}
						fontSize="small"
					/>
					Contact
				</MenuItem>
				<Box sx={{ p: 2 }}>
					<Button
						fullWidth
						color="inherit"
						variant="outlined"
						onClick={() => dispatch(logout())}
						sx={{ borderColor: (theme) => theme.palette.grey[300] }}
					>
						Logout
					</Button>
				</Box>
			</MenuPopover>
		</>
	);
};
