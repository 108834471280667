import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addMonths, startOfDay } from 'date-fns';
import { FileUpload } from '../../../../components/UploadComponent';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { setErrorSnackbarOpen } from '../../app/appSlice';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientMoratoriumAPI, IPostClientMoratorium } from './types';

export const clientMoratoriumPost = createAsyncThunk(
	'client/moratorium',
	async (moratoriumDetails: MoratoriumHardshipDetails & MoratoriumPaymentDetails & FileUpload, { rejectWithValue, dispatch, getState }) => {
		const { DebtID, DebtorEntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
		try {
			const formData = new FormData();
			formData.append('entityID', DebtorEntityID.toString());
			formData.append('debtID', DebtID.toString());
			formData.append('hardshipCategoryID', moratoriumDetails.hardshipCategoryID);
			formData.append('note', moratoriumDetails.note);
			formData.append('endDate', moratoriumDetails.endDate);
			if (moratoriumDetails.pausePayments && moratoriumDetails.arrangementID) {
				formData.append('pausePayments', moratoriumDetails.pausePayments.toString());
				formData.append('arrangementID', moratoriumDetails.arrangementID.toString());
			}
			moratoriumDetails.files?.forEach((file) => {
				formData.append('files', file);
			});
			const { data } = await fcAPI.post<IPostClientMoratorium>(ClientMoratoriumAPI.MORATORIUM, formData, {
				headers: { 'content-type': 'multipart/form-data' },
			});
			if (data.success) {
				return data.data;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export interface MoratoriumLengthDetails {
	moratoriumMonths: string;
}

export interface MoratoriumHardshipDetails {
	endDate: string;
	hardshipCategoryID: string;
	note: string;
}

export interface MoratoriumPaymentDetails {
	pausePayments: boolean;
	arrangementID: number | undefined;
}

export interface MoratoriumState extends MoratoriumLengthDetails, MoratoriumHardshipDetails, MoratoriumPaymentDetails {
	activeStep: number;
	status: Status;
	outcome: string | undefined;
}

const initialState: MoratoriumState = {
	activeStep: 0,
	status: Status.IDLE,
	moratoriumMonths: '',
	endDate: '',
	hardshipCategoryID: '',
	note: '',
	pausePayments: false,
	arrangementID: undefined,
	outcome: undefined,
};

export const moratoriumSlice = createSlice({
	name: 'clientMoratorium',
	initialState,
	reducers: {
		setMoratoriumActiveStep: (state, action: PayloadAction<number>) => {
			state.activeStep = action.payload;
		},
		setMoratoriumLengthDetails: (state, action: PayloadAction<MoratoriumLengthDetails>) => {
			const endDate = startOfDay(addMonths(new Date(), Number(action.payload.moratoriumMonths))).toISOString();
			return {
				...state,
				endDate,
				...action.payload,
			};
		},
		setMoratoriumHardshipDetails: (state, action: PayloadAction<MoratoriumHardshipDetails>) => {
			return {
				...state,
				...action.payload,
			};
		},
		setMoratoriumPaymentDetails: (state, action: PayloadAction<MoratoriumPaymentDetails>) => {
			return {
				...state,
				...action.payload,
			};
		},
		resetMoratorium: () => {
			return initialState;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientMoratoriumPost.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientMoratoriumPost.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.outcome = action.payload.outcome;
			})
			.addCase(clientMoratoriumPost.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export const { setMoratoriumActiveStep, setMoratoriumLengthDetails, setMoratoriumHardshipDetails, setMoratoriumPaymentDetails, resetMoratorium } =
	moratoriumSlice.actions;

export default moratoriumSlice.reducer;

export const selectClientMoratoriumActiveStep = (state: RootState) => state.clientMoratorium.activeStep;
export const selectClientMoratoriumStatus = (state: RootState) => state.clientMoratorium.status;
export const selectClientMoratoriumDetails = (state: RootState) => state.clientMoratorium;
export const selectClientMoratoriumOutcome = (state: RootState) => state.clientMoratorium.outcome;
