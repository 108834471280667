import { Container, Grid, Button, Box, Typography } from '@mui/material';
import { Benefit1 } from '../../static/images/Benefit1';
import { Benefit2 } from '../../static/images/Benefit2';
import { Benefit3 } from '../../static/images/Benefit3';
import { LinkButton } from '../../components/StyledLinkButton';
import { styled } from '@mui/material/styles';
import { AuthRoutes } from '../../constants/routes.constant';
import { useState } from 'react';
import { Footer } from './components/Footer';
import { Join } from './components/Join';
import { Navbar } from './components/Nav';
import { Fade } from 'react-awesome-reveal';
import CoverMobile from '../../static/images/cover.jpg';
import CoverDesktop from '../../static/images/cover-desktop.jpg';
import About from '../../static/images/about.png';
import How from '../../static/images/how.png';

const CoverContainer = styled(Box)(({ theme }) => ({
	position: 'absolute',
	height: '100vh',
	[theme.breakpoints.up('xs')]: {
		left: '2vw',
		top: '260px',
	},
	[theme.breakpoints.up('sm')]: {
		top: '60vh',
		left: '4vw',
		width: '80%',
	},
	[theme.breakpoints.up('md')]: {
		top: '32vh',
		left: '53vw',
		width: '36%',
	},
}));

const CoverImage = styled('div')(({ theme }) => ({
	width: '100%',
	boxSizing: 'border-box',
	backgroundSize: 'cover',
	height: '600px',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: '50% 0',
	[theme.breakpoints.up('xs')]: {
		height: '600px',
		backgroundImage: `url(${CoverMobile})`,
	},
	[theme.breakpoints.up('sm')]: {
		width: '100%',
		height: '900px',
	},
	[theme.breakpoints.up('lg')]: {
		width: '50%',
		height: '100vh',
		backgroundImage: `url(${CoverDesktop})`,
	},
}));

const BenefitContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	boxSizing: 'border-box',
	padding: '25px',
	borderRadius: '8px',
	border: '2px solid #22D07D',
	boxShadow: '6px 6px #22D07D',
	[theme.breakpoints.up('xs')]: {
		flexDirection: 'column',
	},
	[theme.breakpoints.up('sm')]: {
		flexDirection: 'row',
	},
	[theme.breakpoints.up('md')]: {
		flexDirection: 'column',
	},
}));

const BenefitContent = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('xs')]: {
		marginLeft: 0,
		width: '100%',
	},
	[theme.breakpoints.up('sm')]: {
		width: '70%',
		marginLeft: '40px',
	},
	[theme.breakpoints.up('md')]: {
		marginLeft: 0,
		width: '100%',
	},
}));

const AboutImage = styled('div')(({ theme }) => ({
	width: '100%',
	height: '400px',
	boxSizing: 'border-box',
	marginTop: '10vh',
	backgroundRepeat: 'no-repeat',
	[theme.breakpoints.up('xs')]: {
		backgroundPosition: '30% 0',
		backgroundSize: 'cover',
		backgroundImage: `url(${About})`,
	},
	[theme.breakpoints.up('md')]: {
		backgroundPosition: '0 0',
		height: '600px',
		backgroundSize: 'auto',
	},
}));

const HowImage = styled('div')(({ theme }) => ({
	width: '100%',
	height: '326px',
	boxSizing: 'border-box',
	marginTop: '10vh',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	[theme.breakpoints.up('xs')]: {
		backgroundPosition: '0 0',
		backgroundImage: `url(${How})`,
	},
	[theme.breakpoints.up('md')]: {
		backgroundPosition: '0 0',
		height: '684px',
	},
}));

const HowContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	[theme.breakpoints.up('xs')]: {
		flexDirection: 'column-reverse',
	},
	[theme.breakpoints.up('md')]: {
		flexDirection: 'column-reverse',
	},
}));

const HowStep = styled(Typography)(({ theme }) => ({
	position: 'relative',
	[theme.breakpoints.up('xs')]: {
		top: '-5vh',
		left: '7vh',
	},
	[theme.breakpoints.up('sm')]: {
		left: '9vh',
	},
	[theme.breakpoints.up('md')]: {
		top: '-6vh',
		left: '10vh',
	},
}));

export const Home = () => {
	const [navBar, setNavbar] = useState(false);

	const changeNavBackground = () => {
		if ((window.scrollY >= 700 && window.screen.width > 768) || (window.scrollY >= 600 && window.screen.width <= 768)) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};

	window.addEventListener('scroll', changeNavBackground);

	return (
		<>
			{navBar ? <Navbar /> : <Navbar bgColor={'transparent'} boxShadow={'none'} />}

			<Box sx={{ position: 'relative', backgroundColor: '#062239' }}>
				<CoverImage></CoverImage>

				<CoverContainer>
					<Fade>
						<Typography variant="h2" sx={{ color: '#ffffff' }}>
							Support your client in reaching their goals
						</Typography>
						<Typography variant="body1" sx={{ mt: 2, color: '#ffffff' }}>
							Our services aim to equip Financial Counsellors with the necessary tools to effectively manage their Panthera Finance
							clients.
						</Typography>
						<Button color="secondary" variant="contained" component={LinkButton} to={AuthRoutes.LOGIN} size="large" sx={{ mt: 2 }}>
							Get started
						</Button>
					</Fade>
				</CoverContainer>
			</Box>

			<Box
				sx={{
					backgroundColor: '#F4F9FF',
					paddingTop: '15vh',
					paddingBottom: '15vh',
				}}
			>
				<Container
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Fade delay={6}>
						<Typography variant="h4" sx={{ marginBottom: '10vh', textAlign: 'center' }}>
							Our platform allows effortless management of client accounts.
						</Typography>
					</Fade>

					<Grid container columns={{ md: 3 }} spacing={{ xs: 3, md: 6 }}>
						<Grid md={1} item>
							<BenefitContainer
								sx={{
									boxSizing: 'border-box',
									padding: '25px',
									borderRadius: '8px',
									border: '2px solid #22D07D',
									boxShadow: '6px 6px #22D07D',
								}}
							>
								<Benefit1 />
								<BenefitContent>
									<Typography variant="h5" sx={{ mt: 1 }}>
										Manage all of your Panthera Finance accounts online, in one easy place
									</Typography>
									<Typography variant="body1" sx={{ mt: 1 }}>
										Self-manage all of your Panthera Finance clients in one easy spot &amp; have unlimited access to client
										overviews to understand the full picture.
									</Typography>
								</BenefitContent>
							</BenefitContainer>
						</Grid>

						<Grid md={1} item>
							<BenefitContainer>
								<Benefit2 />
								<BenefitContent>
									<Typography variant="h5" sx={{ mt: 1 }}>
										Set up &amp; manage payment plans and reduced settlements
									</Typography>
									<Typography variant="body1" sx={{ mt: 1 }}>
										Fast-track in-person negotiations with our tailored outcome-driven solutions. Negotiate, pay &amp; resolve
										your clients accounts in our multi-functioning platform.
									</Typography>
								</BenefitContent>
							</BenefitContainer>
						</Grid>

						<Grid md={1} item>
							<BenefitContainer>
								<Benefit3 />
								<BenefitContent>
									<Typography variant="h5" sx={{ mt: 1 }}>
										Apply for Hardship and Moratoriums on behalf of your client
									</Typography>
									<Typography variant="body1" sx={{ mt: 1 }}>
										Provide your clients with their desired outcomes promptly, and with care. This platform was designed to make
										the hardship process easy for them, and you.
									</Typography>
								</BenefitContent>
							</BenefitContainer>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				sx={{
					backgroundColor: '#ffffff',
					paddingTop: '15vh',
					paddingBottom: '15vh',
				}}
			>
				<Container
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Grid container columns={{ md: 2 }} spacing={{ md: 6 }}>
						<Grid md={1} item>
							<Fade delay={6}>
								<Typography variant="h5" sx={{ mt: 1 }}>
									Manage your Panthera Finance clients from anywhere, anytime.
								</Typography>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography
								variant="h6"
								sx={{
									mt: 1,
									textDecoration: 'underline',
									textDecorationThickness: '5px',
									textUnderlineOffset: '1px',
									textDecorationColor: '#34C07D',
								}}
							>
								About
							</Typography>

							<Typography variant="body1" sx={{ mt: 1 }}>
								Our newly designed platform, made specifically for Financial Counsellors, will provide seamless and efficient ways to
								manage Panthera Finance clients online.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								With carefully tailored solutions, our platform aims to increase proficiency in achieving desirable outcomes for our
								clients.
							</Typography>
						</Grid>
					</Grid>
					<Fade delay={20} style={{ width: '100%' }}>
						<AboutImage />
					</Fade>
				</Container>
			</Box>

			<Box
				sx={{
					backgroundColor: '#ffffff',
					paddingBottom: '15vh',
				}}
			>
				<Container
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Fade delay={6}>
						<Typography
							variant="h6"
							sx={{
								mt: 1,
								textDecoration: 'underline',
								textDecorationThickness: '5px',
								textUnderlineOffset: '1px',
								textDecorationColor: '#34C07D',
							}}
						>
							How it works
						</Typography>
					</Fade>

					<HowContainer id="how">
						<Fade delay={20}>
							<HowImage />
						</Fade>
						<Grid container columns={{ md: 3 }} spacing={{ md: 6 }}>
							<Grid md={1} item>
								<Box sx={{ display: 'inline-block' }}>
									<Typography variant="h1" sx={{ mt: 1, color: 'rgba(0, 0, 0, 0.1)' }}>
										01
									</Typography>
									<HowStep variant="h5">Sign up</HowStep>
								</Box>
								<Typography variant="body1" sx={{ mt: 1 }}>
									Signing up to the Platform is easy! All you will need is your FCA registration number, along with some basic
									contact information. Once you’ve been authorised using your nominated email address, we can start assigning you
									with your clients.
								</Typography>
							</Grid>
							<Grid md={1} item>
								<Box sx={{ display: 'inline-block' }}>
									<Typography variant="h1" sx={{ mt: 1, color: 'rgba(0, 0, 0, 0.1)' }}>
										02
									</Typography>
									<HowStep variant="h5">Add clients</HowStep>
								</Box>
								<Typography variant="body1" sx={{ mt: 1 }}>
									With an emphasis on simplicity, adding/uploading clients to your list of clients has been made simple and easy!
								</Typography>
								<Typography variant="body1" sx={{ mt: 1 }}>
									Once the appropriate documents have been received &amp; approved by one of our dedicated Hardship Officers, we
									will automatically assign to you your clients account. We've even enabled the option to upload a Letter of
									Assignment to help fast-track this process.
								</Typography>
							</Grid>
							<Grid md={1} item>
								<Box sx={{ display: 'inline-block' }}>
									<Typography variant="h1" sx={{ mt: 1, color: 'rgba(0, 0, 0, 0.1)' }}>
										03
									</Typography>
									<HowStep variant="h5">Manage accounts</HowStep>
								</Box>
								<Typography variant="body1" sx={{ mt: 1 }}>
									Once you've gained visual over your clients, experience the assistance of our intuitively designed dashboards,
									insights &amp; actionable options.
								</Typography>
								<Typography variant="body1" sx={{ mt: 1 }}>
									With just the touch of a button, you can manage client outcomes with confidence and control.
								</Typography>
							</Grid>
						</Grid>
					</HowContainer>
				</Container>
			</Box>

			<Join />
			<Footer />
		</>
	);
};
