import React from 'react';
import { Button, ButtonProps, ListItem, styled } from '@mui/material';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type BaseNavItemProps = {
	icon?: React.ReactNode;
	title: string;
	isclientview?: boolean;
	end?: boolean;
};

type NavItemLinkProps = {
	href?: string;
	onClick?: () => void;
};

type NavItemActionProps = {
	action?: () => void;
};

export const SidebarButton = styled(Button)<{ isclientview?: string }>(({ theme, startIcon, isclientview }) => ({
	textAlign: 'left',
	width: '100%',
	fontSize: theme.typography.body1.fontSize,
	fontWeight: 500,
	justifyContent: 'flex-start',
	color: theme.palette.text.secondary,
	paddingLeft: startIcon ? theme.spacing(3) : theme.spacing(6.5),
	paddingRight: theme.spacing(3),
	'.MuiButton-startIcon': {
		marginRight: theme.spacing(1.5),
	},
	'&:hover': {
		backgroundColor: isclientview ? '#CFE3F3' : theme.palette.grey[50],
	},
	'&.isActive': {
		color: theme.palette.text.primary,
		backgroundColor: isclientview ? '#CFE3F3' : theme.palette.grey[50],
	},
	borderRadius: 0,
})) as React.ComponentType<
	ButtonProps & { component?: React.ForwardRefExoticComponent<Omit<NavLinkProps, 'to'> & React.RefAttributes<HTMLAnchorElement>> } & {
		isclientview?: string;
	}
>;

export type NavItemProps = BaseNavItemProps & NavItemLinkProps & NavItemActionProps;

export const NavItem: React.FC<NavItemProps> = ({ isclientview = false, action, onClick, href = '', icon, title, end = true }) => {
	const CustomLink = React.useMemo(
		() =>
			React.forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>(function Link(linkProps, ref) {
				const { className: previousClasses, ...rest } = linkProps;
				const elementClasses = previousClasses?.toString() ?? '';
				return (
					<NavLink
						end={end}
						{...rest}
						ref={ref}
						to={href}
						className={({ isActive }) => (isActive ? elementClasses + ' isActive' : elementClasses)}
					/>
				);
			}),
		[href, end],
	);
	return (
		<ListItem
			sx={{
				display: 'flex',
				mb: 0.5,
				py: 0,
			}}
			disableGutters
		>
			{href && (
				<SidebarButton
					disableRipple
					startIcon={icon}
					component={CustomLink}
					size="large"
					onClick={() => (onClick ? onClick() : undefined)}
					isclientview={isclientview ? isclientview.toString() : undefined}
				>
					{title}
				</SidebarButton>
			)}
			{action && (
				<SidebarButton
					disableRipple
					startIcon={icon}
					onClick={action}
					size="large"
					isclientview={isclientview ? isclientview.toString() : undefined}
				>
					{title}
				</SidebarButton>
			)}
		</ListItem>
	);
};
