import axios, { AxiosError } from 'axios';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { setErrorSnackbarOpen } from './features/app/appSlice';
import { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const handleAsyncThunkError = (err: Error | AxiosError, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
	if (axios.isAxiosError(err)) {
		// These errors are handled in the interceptor for displaying snackbar/modal
		if (err.response?.status !== 503 && err.response?.status !== 401) {
			dispatch(setErrorSnackbarOpen({ message: err.response?.data.message ?? 'An unknown error occurred.' }));
		}
		return err.response?.data.message;
	}
	dispatch(setErrorSnackbarOpen({ message: 'An unknown error occurred.' }));
	return err;
};
