import { Box, Container, Tab } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Page } from '../../components/Page';
import { StyledTabs } from '../../components/StyledTabs';
import { TabPanel } from '../../components/TabPanel';
import { ClientRoutes } from '../../constants/routes.constant';
import { ClientBreadCrumbs } from './components/ClientBreadCrumbs';
import { PaymentPlansActive } from './components/PaymentPlansActive';
import { PaymentPlansInactive } from './components/PaymentPlansInactive';

export enum PaymentPlanTabs {
	Active = 'active',
	Inactive = 'inactive',
}

const pageTitle = 'Payment Plans';

export const ClientPaymentPlans = () => {
	const { tab } = useParams<'tab'>();

	return (
		<Page title={pageTitle}>
			<Container maxWidth="xl">
				<ClientBreadCrumbs childCrumb={pageTitle} />
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={3}>
					<StyledTabs value={tab || PaymentPlanTabs.Active} textColor="secondary" indicatorColor="secondary">
						<Tab
							label="Active"
							value={PaymentPlanTabs.Active}
							to={`${ClientRoutes.PAYMENT_PLANS}/${PaymentPlanTabs.Active}`}
							component={Link}
						/>
						<Tab
							label="Inactive"
							value={PaymentPlanTabs.Inactive}
							to={`${ClientRoutes.PAYMENT_PLANS}/${PaymentPlanTabs.Inactive}`}
							component={Link}
						/>
					</StyledTabs>
				</Box>
				<Box sx={{ pb: 5 }}>
					<TabPanel value={tab || PaymentPlanTabs.Active} index={PaymentPlanTabs.Active}>
						<PaymentPlansActive />
					</TabPanel>
					<TabPanel value={tab} index={PaymentPlanTabs.Inactive}>
						<PaymentPlansInactive />
					</TabPanel>
				</Box>
			</Container>
		</Page>
	);
};
