import { FCResponse } from '../../../../services/api/base.api.response';

export enum ClientLedgerAPI {
	LEDGER = '/api/client/ledger',
}

export interface IGetLedgerOutput {
	DebtID: number;
	LedgerID: number;
	ClientID: number;
	LedgerName: string;
	LedgerDisplayName: string;
	ClientLedgerName: string;
	LedgerABN: string;
	ClientName: string;
	ClientDisplayName: string;
	ClientPhone: string;
	MaxArrangementPeriod: number;
	DebtTypeName: string;
	DebtTypeDescription: string;
	LogoBlob: string;
}

export type IGetClientLedger = FCResponse<{ ledger: IGetLedgerOutput }>;
