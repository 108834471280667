import { Alert, Button, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Page } from '../../components/Page';
import { AuthRoutes } from '../../constants/routes.constant';
import { AuthWrapper } from './components/AuthWrapper';
import { Form, Formik, FormikHelpers } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../store/hooks';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { passwordForgotPost } from '../../store/features/auth/authSlice';
import { setSuccessSnackbarOpen } from '../../store/features/app/appSlice';

interface ForgotPasswordState {
	forceReset: boolean;
}

interface ResetPasswordDetails {
	email: string;
}

export const ForgotPassword = () => {
	const dispatch = useAppDispatch();
	const { state } = useLocation();

	const forceReset = (state as ForgotPasswordState)?.forceReset ?? false;

	const initialValues: ResetPasswordDetails = {
		email: '',
	};

	const loginDetailsSchema = Yup.object().shape({
		email: Yup.string().email('Must be a valid email').max(512).required('Email is required'),
	});

	const resetPasswordSubmit = async (values: ResetPasswordDetails, { resetForm }: FormikHelpers<ResetPasswordDetails>) => {
		try {
			const resetSuccess = await dispatch(passwordForgotPost({ email: values.email })).unwrap();
			if (resetSuccess) {
				dispatch(setSuccessSnackbarOpen({ message: resetSuccess }));
				resetForm();
			}
		} catch {
			/* Handled in Thunk */
		}
	};

	return (
		<Page title="Forgot your password">
			<AuthWrapper
				helperLink={
					<Typography variant="subtitle1" component="p">
						Don't have an account? &nbsp;
						<Link underline="hover" component={RouterLink} to={AuthRoutes.SIGN_UP}>
							Sign up
						</Link>
					</Typography>
				}
			>
				<Typography variant="h4" textAlign="center" sx={{ mb: 8 }}>
					Forgot your password?
				</Typography>
				{forceReset && (
					<Alert severity="warning" sx={{ mb: 4 }}>
						Our records indicate that you must reset your password!
					</Alert>
				)}
				<Typography variant="body1" sx={{ mb: 2 }}>
					Enter your email address and we'll send you a link to reset your password.
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={loginDetailsSchema}
					onSubmit={resetPasswordSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
						<Form>
							<TextField
								error={Boolean(touched.email && errors.email)}
								fullWidth
								helperText={touched.email && errors.email}
								label="Email Address"
								margin="normal"
								name="email"
								onBlur={handleBlur}
								onChange={handleChange}
								type="email"
								value={values.email}
								variant="outlined"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<MailOutlinedIcon />
										</InputAdornment>
									),
								}}
							/>
							<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
								<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} component={RouterLink} to={AuthRoutes.LOGIN}>
									Login
								</Button>
								<LoadingButton loading={isSubmitting} size="large" color="secondary" type="submit" variant="contained">
									Reset Password
								</LoadingButton>
							</Stack>
						</Form>
					)}
				</Formik>
			</AuthWrapper>
		</Page>
	);
};
