import {
	Card,
	CardContent,
	TableContainer,
	Table,
	TableCell,
	TableHead,
	TableRow,
	TableBody,
	TablePagination,
	Palette,
	CardHeader,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { PerfectScrollbar } from '../../../../components/PerfectScrollbar';
import { TableEmpty } from '../../../../components/TableEmpty';
import { TableLoader } from '../../../../components/TableLoader';
import { formatMoney } from '../../../../utils/text.utill';
import { dateFormats } from '../../../../constants/dateFormat.constant';
import { IReportDebtStatus } from './types';
import { DebtStatusGroup } from '../../../../enums/debtStatus.enum';
import { Label } from '../../../../components/Label';

type TableResultsProps = {
	data: IReportDebtStatus[] | undefined;
	page: number;
	rowsPerPage: number;
	count: number;
	handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
	handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	loading: boolean;
};

const COL_LENGTH = 9;

const getStatusColor = (status: DebtStatusGroup): keyof Palette | 'default' => {
	if (status.toUpperCase() === DebtStatusGroup.ACTIVE.toUpperCase()) {
		return 'success';
	} else if (status.toUpperCase() === DebtStatusGroup.REVIEW.toUpperCase()) {
		return 'warning';
	} else if (status.toUpperCase() === DebtStatusGroup.ARRANGEMENT.toUpperCase()) {
		return 'info';
	}
	return 'default';
};

export const TableResults: React.FC<TableResultsProps> = ({ data, page, rowsPerPage, count, handleChangePage, handleChangeRowsPerPage, loading }) => {
	return (
		<Card elevation={0}>
			<CardHeader title="Details" />
			<CardContent sx={{ pt: 0 }}>
				<TableContainer component={PerfectScrollbar}>
					<Table sx={{ minWidth: 600 }}>
						<TableHead>
							<TableRow>
								<TableCell>Reference</TableCell>
								<TableCell>Customer</TableCell>
								<TableCell>Client</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Description</TableCell>
								<TableCell>Amount Owed</TableCell>
								<TableCell>Amount Paid</TableCell>
								<TableCell>Amount Outstanding</TableCell>
								<TableCell>Date Added</TableCell>
							</TableRow>
						</TableHead>
						{loading && <TableLoader rows={5} colSpan={COL_LENGTH} />}
						{!loading && (data?.length ?? 0) === 0 && <TableEmpty text="No active clients found." colSpan={COL_LENGTH} />}
						{!loading && (data?.length ?? 0) > 0 && (
							<TableBody>
								{data
									?.slice()
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((client, index) => (
										// eslint-disable-next-line react/no-array-index-key
										<TableRow key={index}>
											<TableCell component="th" scope="row">
												{client.Reference}
											</TableCell>
											<TableCell>{client.Fullname}</TableCell>
											<TableCell>{client.ClientName}</TableCell>
											<TableCell>
												<Label
													size="small"
													variant="ghost"
													color={getStatusColor(client.Status as DebtStatusGroup)}
													sx={{ minWidth: 120 }}
												>
													{client.Status}
												</Label>
											</TableCell>
											<TableCell>{client.StatusDescription}</TableCell>
											<TableCell>{formatMoney(client.AmtOwed)}</TableCell>
											<TableCell>{formatMoney(client.AmtPaid)}</TableCell>
											<TableCell>{formatMoney(client.AmtOutstanding)}</TableCell>
											<TableCell>{format(parseISO(client.DateAdded), dateFormats.prettyShort)}</TableCell>
										</TableRow>
									))}
							</TableBody>
						)}
					</Table>
				</TableContainer>
				{count > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={count}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="Per page:"
						sx={{ mt: 2 }}
					/>
				)}
			</CardContent>
		</Card>
	);
};
