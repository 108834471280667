import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../enums/status.enum';
import { fcAPI } from '../../../services/api/api';
import { handleAsyncThunkError } from '../../hooks';
import { RootState } from '../../store';
import { setErrorSnackbarOpen } from '../app/appSlice';
import { ClientsAPI, IActiveClients, IActiveClientsOutput } from './types';

export const activeClientsGet = createAsyncThunk('clients/active', async (_, { rejectWithValue, dispatch }) => {
	try {
		const { data } = await fcAPI.get<IActiveClients>(ClientsAPI.ACTIVE);
		if (data.success) {
			return data.data.clients;
		}
		dispatch(setErrorSnackbarOpen({ message: data.message }));
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});
export interface ActiveClientsState {
	clients: IActiveClientsOutput[];
	totalCount: number;
	status: Status;
}

const initialState: ActiveClientsState = {
	clients: [],
	totalCount: 0,
	status: Status.IDLE,
};

const activeClientsSlice = createSlice({
	name: 'activeClients',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(activeClientsGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(activeClientsGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.totalCount = action.payload.length ?? 0;
				state.clients = action.payload;
			})
			.addCase(activeClientsGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default activeClientsSlice.reducer;

export const selectActiveClients = (state: RootState) => state.activeClients.clients;
export const selectActiveClientsStatus = (state: RootState) => state.activeClients.status;
export const selectActiveClientsCount = (state: RootState) => state.activeClients.totalCount;
