import { FCResponse } from '../../../../services/api/base.api.response';

export enum ClientTransactionAPI {
	TRANSACTIONS = '/api/client/transactions',
}

export interface IGetClientTransactionsOutput {
	TransactionID: number;
	TransactionDate: string;
	TransactionTypeName: string;
	Amount: number;
	PaymentMethodName: string;
	PaymentResponseExplanation: string | null;
	TotalCount: number;
}

export type IClientTransactions = FCResponse<{ transactions: IGetClientTransactionsOutput[] }>;
