import { Container, Grid, Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Footer } from './components/Footer';
import { Navbar } from './components/Nav';
import { Fade } from 'react-awesome-reveal';

const StyledTitle = styled(Typography)(({ theme }) => ({
	mt: 1,
	textDecoration: 'underline',
	textDecorationThickness: '5px',
	textUnderlineOffset: '1px',
	textDecorationColor: '#34C07D',
}));

export const TermsConditions = () => {
	return (
		<>
			<Navbar />

			<Box
				sx={{
					backgroundColor: '#ffffff',
					paddingTop: '25vh',
					paddingBottom: '15vh',
				}}
			>
				<Container>
					<Grid container columns={{ md: 2 }} spacing={{ xs: 3, md: 6 }}>
						<Grid md={2} item>
							<Fade delay={6}>
								<Typography variant="h4" width={'100%'}>
									Terms Conditions
								</Typography>
							</Fade>
						</Grid>
						<Grid md={2} item></Grid>
						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">How it works</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the
								www.pantherafinance.com.au website (the "Site") operated by Panthera Finance ("us", "we", or "our").
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Your access to and use of the Site is conditioned on your acceptance of and compliance with these Terms. These Terms
								apply to all visitors, users and others who access or use the Site.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								By accessing or using the Site you agree to be bound by these Terms. If you disagree with any part of the terms then
								you may not access the Site.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Disclaimer</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								The materials on the Site are provided “as is”. We make no expressed or implied warranties, and hereby disclaim and
								negate all other warranties including without limitation but not limited to, warranties of title, non-infringement or
								fitness for a particular purpose. We will not be responsible for any loss or damage that could result from
								interception by third parties of any information or services made available to you via the Site. The material on the
								Site is based on data from multiple sources and although we believe those to be reliable, we cannot and do not
								guarantee the accuracy, validity, timeliness or completeness of any information made available to you for any
								particular purpose.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								We will not be liable for any loss or damage that you incur in the event of any failure or interruption of the Site,
								or resulting from the act or omission of any other party involved in making the Site or providing information
								contained in or accessed through the Site, or from any other cause relating to your access to or your inability to
								access or use the Site whether or not the circumstances were within our control. We do not guarantee continuous,
								uninterrupted or secure access to the Site.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Links To Other Websites</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Our Site may contain links to third-party web sites or services that are not owned or controlled by us. The inclusion
								of any link is not and does not imply an affiliation, sponsorship, endorsement, approval, investigation, verification
								or monitoring by us of any information contained in any third-party website
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any
								third-party web sites or services. You further acknowledge and agree that we shall not be responsible or liable,
								directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or
								reliance on any such content, goods or services available on or through any such web sites or services. We strongly
								advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you
								visit.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services
								that you visit.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Sign up</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								By signing up for and using this platform you consent to Panthera Finance collecting and holding personal information
								about you. This data will be stored and used in conjunction with our privacy policy which can be viewed{' '}
								<Link href="https://pantherafinance.com.au/privacy">here</Link>. This data is stored for the purposes of
								identification and enabling access to your client’s data. You also understand and agree to Panthera Finances{' '}
								<Link href="https://pantherafinance.com.au/terms">terms and conditions</Link>.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								You agree that you are duly authorised to act on behalf of any clients assigned to your portfolio, and your clients
								have provided confirmation that they consent to Panthera Finance providing you with access to their personal
								information.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								You agree that you will only upload information and documentation belonging to your clients which contain sensitive
								information if your clients have confirmed that they consent to Panthera Finance collecting and holding this
								information. Please refer to our Privacy Policy for the definition of sensitive information.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								You agree to request that Panthera Finance remove access and any other documentation belonging to your clients that
								you have uploaded which contains sensitive information if you cease to act on their behalf. You understand and agree
								that you are able to enter into agreements on behalf of your client.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								You agree that any arrangement that you organise will constitute the acknowledgment of the debt, and the limitation
								period being restarted.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								You agree that any payment arrangements or settlements that you organise on behalf of your clients are authorised and
								agreed to by your clients
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								You agree that you will provide your clients with the terms and conditions of any payment arrangement or settlement
								that you organise on behalf of your clients.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								You agree that access to your portal space will be validated by your specific Financial Counsellors Identification
								number, confirmation of your email address and your own Password. No liability is accepted for any data loss event or
								harm caused by unauthorised access via your credentials.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Privacy</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								By accessing or using the Site you consent to us collecting, storing, processing and using personal information as
								detailed in our Privacy Policy.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Copyright</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								All right, title and interest in the Site and its content is our exclusive property except as otherwise stated. The
								Site is for your personal and non-commercial use only and you may print, copy and download information from the Site
								for your personal use only. Without our prior written approval you may not modify, copy, distribute, transmit,
								display, perform, reproduce, publish, license, frame, create derivative works from, transfer, or otherwise use in any
								other way for commercial or public purposes in whole or in part any information or software obtained from the Site.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Termination</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								We may terminate or suspend access to our Site immediately, without prior notice or liability, for any reason
								whatsoever, including without limitation if you breach the Terms.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								All provisions of the Terms which by their nature should survive termination shall survive termination, including,
								without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Governing Law</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								These Terms shall be governed and construed in accordance with the laws of Australia, without regard to its conflict
								of law provisions.
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any
								provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms
								will remain in effect. These Terms constitute the entire agreement between us regarding our Site, and supersede and
								replace any prior agreements we might have between us regarding the Site
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Changes</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material
								we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material
								change will be determined at our sole discretion.
							</Typography>

							<Typography variant="body1" sx={{ mt: 1 }}>
								By continuing to access or use our Site after those revisions become effective, you agree to be bound by the revised
								terms. If you do not agree to the new terms, please stop using the Site.
							</Typography>
						</Grid>

						<Grid md={1} item>
							<Fade>
								<StyledTitle variant="h6">Contact Us</StyledTitle>
							</Fade>
						</Grid>

						<Grid md={1} item>
							<Typography variant="body1" sx={{ mt: 1 }}>
								Any questions or concerns should be raised by contacting{' '}
								<a href={window.config.REACT_APP_CONTACT_EMAIL_HREF} style={{ color: '#3F51B5' }}>
									{window.config.REACT_APP_CONTACT_EMAIL}
								</a>{' '}
								or calling{' '}
								<a href={window.config.REACT_APP_CONTACT_NUMBER_HREF} style={{ color: '#3F51B5' }}>
									{window.config.REACT_APP_CONTACT_NUMBER}
								</a>
								.
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Footer />
		</>
	);
};
