import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import app from './features/app/appSlice';
import auth from './features/auth/authSlice';
import register from './features/auth/registerSlice';
import recentActivity from './features/dashboard/recentActivity';
import activeClients from './features/clients/activeClients';
import pendingClients from './features/clients/pendingClients';
import user from './features/user/userSlice';
import client from './features/client/clientSlice';
import addClient from './features/clients/addClientSlice';
import clientLedger from './features/client/ledger/ledgerSlice';
import clientDebt from './features/client/debt/debtSlice';
import clientDebtAmounts from './features/client/debt/amountSlice';
import clientDebtor from './features/client/debtor/debtorSlice';
import clientDebtorMatch from './features/client/debtor/linkedEntitySlice';
import clientTransactions from './features/client/transactions/transactionsSlice';
import clientDebtWaiver from './features/client/debtWaiver/debtWaiverSlice';
import clientHardship from './features/client/hardship/hardshipSlice';
import clientArrangement from './features/client/arrangement/arrangementSlice';
import clientArrangements from './features/client/arrangements/arrangementsSlice';
import clientArrangementSchedule from './features/client/arrangements/scheduleSlice';
import clientBalanceReduction from './features/client/balanceReduction/balanceReductionSlice';
import clientMoratorium from './features/client/moratorium/moratoriumSlice';
import clientHolds from './features/client/holds/holdsSlice';
import clientAttachments from './features/client/attachments/attachmentsSlice';

const combinedReducer = combineReducers({
	app,
	auth,
	user,
	register,
	recentActivity,
	activeClients,
	pendingClients,
	addClient,
	client,
	clientLedger,
	clientDebt,
	clientDebtAmounts,
	clientDebtor,
	clientDebtorMatch,
	clientTransactions,
	clientDebtWaiver,
	clientBalanceReduction,
	clientMoratorium,
	clientHardship,
	clientHolds,
	clientArrangement,
	clientArrangements,
	clientArrangementSchedule,
	clientAttachments,
});

const rootReducer = (state: ReturnType<typeof combinedReducer> | undefined | Partial<ReturnType<typeof combinedReducer>>, action: AnyAction) => {
	if (action.type === 'auth/logout') {
		return combinedReducer({ app: { snackbar: state?.app?.snackbar } } as ReturnType<typeof combinedReducer>, action);
	}
	if (action.type === 'auth/timedOut') {
		return combinedReducer({ auth: { timedOut: true } } as ReturnType<typeof combinedReducer>, action);
	}
	return combinedReducer(state as ReturnType<typeof combinedReducer>, action);
};

export default rootReducer;
