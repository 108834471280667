import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export let reactPlugin: ReactPlugin | null = null;
export let appInsights: ApplicationInsights | null = null;

if (process.env.NODE_ENV !== 'development') {
	reactPlugin = new ReactPlugin();
	appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: window.config.REACT_APP_INSIGHTS_KEY,
			enableAutoRouteTracking: false,
			extensions: [reactPlugin],
		},
	});
	appInsights.loadAppInsights();
}
