import {
	Box,
	CardContent,
	Chip,
	Grid,
	Palette,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography,
} from '@mui/material';
import Fuse from 'fuse.js';
import { useEffect, useMemo, useState } from 'react';
import { PerfectScrollbar } from '../../../components/PerfectScrollbar';
import {
	activeClientsGet,
	selectActiveClients,
	selectActiveClientsCount,
	selectActiveClientsStatus,
} from '../../../store/features/clients/activeClients';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { getComparator, Order } from '../../../utils/table.util';
import { Status } from '../../../enums/status.enum';
import { TableLoader } from '../../../components/TableLoader';
import { formatDistance, parseISO } from 'date-fns';
import { formatMoney } from '../../../utils/text.utill';
import { DebtStatusGroup } from '../../../enums/debtStatus.enum';
import { Label } from '../../../components/Label';
import { TableEmpty } from '../../../components/TableEmpty';
import { ViewDebtLink } from '../../../components/ViewDebtLink';
import { ClientSearch, fuseOptions, MIN_SEARCH_CHARS } from './ClientSearch';
import { IActiveClientsOutput } from '../../../store/features/clients/types';

interface HeadCell {
	id: keyof IActiveClientsOutput;
	label: string;
	canSort: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'DebtID',
		label: 'Reference',
		canSort: true,
	},
	{
		id: 'Fullname',
		label: 'Customer',
		canSort: true,
	},
	{
		id: 'Outstanding',
		label: 'Balance',
		canSort: true,
	},
	{
		id: 'DebtStatus',
		label: 'Status',
		canSort: true,
	},
	{
		id: 'ClientName',
		label: 'Client',
		canSort: true,
	},
	{
		id: 'EffectiveDate',
		label: 'Date added',
		canSort: true,
	},
];

const today = new Date();

export const AvailableClients = () => {
	const dispatch = useAppDispatch();
	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<keyof IActiveClientsOutput>('EffectiveDate');
	const [filter, setFilter] = useState<DebtStatusGroup | null>(null);
	const [search, setSearch] = useState<string>('');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [count, setCount] = useState(0);
	const [filteredClients, setFilteredClients] = useState<IActiveClientsOutput[]>([]);
	const clients = useAppSelector(selectActiveClients);
	const activeClientStatus = useAppSelector(selectActiveClientsStatus);
	const unfilteredActiveClientsCount = useAppSelector(selectActiveClientsCount);

	const fuse = useMemo(() => new Fuse<IActiveClientsOutput>(clients, fuseOptions<IActiveClientsOutput>()), [clients]);

	useEffect(() => {
		dispatch(activeClientsGet());
	}, [dispatch]);

	useEffect(() => {
		const activeClients = search.length > MIN_SEARCH_CHARS ? fuse.search(search).map((filtered) => filtered.item) : clients;
		const filtered = activeClients.slice().filter((row) => filter === null || row.DebtStatus.toLowerCase() === filter.toLowerCase());
		setCount(filtered.length);
		setFilteredClients(filtered);
	}, [clients, filter, search, fuse]);

	useEffect(() => {
		if (search === '' || search.length > 2) {
			setPage(0);
		}
	}, [search]);

	const handleRequestSort = (property: keyof IActiveClientsOutput) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChipClick = (newFilter: DebtStatusGroup | null) => {
		if (filter !== newFilter) {
			setFilter(newFilter);
			setPage(0);
		}
	};

	const getStatusColor = (status: DebtStatusGroup): keyof Palette | 'default' => {
		if (status.toUpperCase() === DebtStatusGroup.ACTIVE.toUpperCase()) {
			return 'success';
		} else if (status.toUpperCase() === DebtStatusGroup.REVIEW.toUpperCase()) {
			return 'warning';
		} else if (status.toUpperCase() === DebtStatusGroup.ARRANGEMENT.toUpperCase()) {
			return 'info';
		}
		return 'default';
	};

	return (
		<CardContent>
			<Grid container mb={3} spacing={5} alignItems="center">
				<Grid item xs={12} lg={4} xl={4}>
					<ClientSearch searchValue={search} onChange={(value) => setSearch(value)} disabled={activeClientStatus !== Status.SUCCEEDED} />
				</Grid>
				<Grid item xs={12} lg={8} xl={8}>
					<Box sx={{ mb: 0.5, mt: -4 }}>
						<Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
							Status
						</Typography>
					</Box>
					<Grid container spacing={1.5}>
						<Grid item>
							<Chip
								label="All"
								variant={filter === null ? 'filled' : 'outlined'}
								color="primary"
								onClick={() => handleChipClick(null)}
								{...(filter === null && { onDelete: () => handleChipClick(null) })}
								deleteIcon={<DoneOutlinedIcon />}
							/>
						</Grid>
						<Grid item>
							<Chip
								label={DebtStatusGroup.ACTIVE}
								variant={filter === DebtStatusGroup.ACTIVE ? 'filled' : 'outlined'}
								color="success"
								onClick={() => handleChipClick(DebtStatusGroup.ACTIVE)}
								{...(filter === DebtStatusGroup.ACTIVE && { onDelete: () => handleChipClick(DebtStatusGroup.ACTIVE) })}
								deleteIcon={<DoneOutlinedIcon />}
							/>
						</Grid>
						<Grid item>
							<Chip
								label={DebtStatusGroup.REVIEW}
								variant={filter === DebtStatusGroup.REVIEW ? 'filled' : 'outlined'}
								color="warning"
								onClick={() => handleChipClick(DebtStatusGroup.REVIEW)}
								{...(filter === DebtStatusGroup.REVIEW && { onDelete: () => handleChipClick(DebtStatusGroup.REVIEW) })}
								deleteIcon={<DoneOutlinedIcon />}
							/>
						</Grid>
						<Grid item>
							<Chip
								label={DebtStatusGroup.ARRANGEMENT}
								variant={filter === DebtStatusGroup.ARRANGEMENT ? 'filled' : 'outlined'}
								color="info"
								onClick={() => handleChipClick(DebtStatusGroup.ARRANGEMENT)}
								{...(filter === DebtStatusGroup.ARRANGEMENT && { onDelete: () => handleChipClick(DebtStatusGroup.ARRANGEMENT) })}
								deleteIcon={<DoneOutlinedIcon />}
							/>
						</Grid>
						<Grid item>
							<Chip
								label={DebtStatusGroup.CLOSED}
								variant={filter === DebtStatusGroup.CLOSED ? 'filled' : 'outlined'}
								onClick={() => handleChipClick(DebtStatusGroup.CLOSED)}
								{...(filter === DebtStatusGroup.CLOSED && { onDelete: () => handleChipClick(DebtStatusGroup.CLOSED) })}
								deleteIcon={<DoneOutlinedIcon />}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<TableContainer component={PerfectScrollbar}>
				<Table sx={{ minWidth: 800 }}>
					<TableHead>
						<TableRow>
							{headCells.map((headCell) => (
								<TableCell key={headCell.id} align="left" padding="normal" sortDirection={orderBy === headCell.id ? order : false}>
									{headCell.canSort ? (
										<TableSortLabel
											active={orderBy === headCell.id}
											direction={orderBy === headCell.id ? order : 'asc'}
											onClick={() => (headCell.canSort ? handleRequestSort(headCell.id) : undefined)}
										>
											{headCell.label}
										</TableSortLabel>
									) : (
										headCell.label
									)}
								</TableCell>
							))}
							<TableCell key="Actions" align="right">
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					{activeClientStatus === Status.LOADING && <TableLoader rows={5} colSpan={7} />}
					{activeClientStatus === Status.SUCCEEDED && unfilteredActiveClientsCount > 0 && count === 0 && (
						<TableEmpty
							text={
								<>
									No <strong>{filter}</strong> clients found
								</>
							}
							colSpan={headCells.length + 1}
						/>
					)}
					{(activeClientStatus === Status.SUCCEEDED || activeClientStatus === Status.FAILED) && unfilteredActiveClientsCount === 0 && (
						<TableEmpty text="You don't have any available clients" colSpan={headCells.length + 1} />
					)}
					{activeClientStatus === Status.SUCCEEDED && count > 0 && (
						<TableBody>
							{filteredClients
								.slice()
								.sort(getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										<TableRow key={row.FCAccessID}>
											<TableCell component="th" scope="row">
												{row.DebtID}
											</TableCell>
											<TableCell>{row.Fullname}</TableCell>
											<TableCell>{formatMoney(row.Outstanding)}</TableCell>
											<TableCell>
												<Label
													size="small"
													variant="ghost"
													color={getStatusColor(row.DebtStatus as DebtStatusGroup)}
													sx={{ minWidth: 120 }}
												>
													{row.DebtStatus}
												</Label>
											</TableCell>
											<TableCell>{row.ClientName}</TableCell>
											<TableCell sx={{ color: 'text.secondary' }}>
												{formatDistance(parseISO(row.EffectiveDate), today, { addSuffix: true })}
											</TableCell>
											<TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
												<ViewDebtLink DebtID={row.DebtID} DebtorEntityID={row.DebtorEntityID} FCAccessID={row.FCAccessID}>
													View profile
												</ViewDebtLink>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					)}
				</Table>
			</TableContainer>
			{count > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={count}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Per page:"
					sx={{ mt: 2 }}
				/>
			)}
		</CardContent>
	);
};
