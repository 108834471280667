import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import { FCTokenName } from '../../constants/token.constant';
import { setModalOpen, setWarningSnackbarOpen } from '../../store/features/app/appSlice';
// eslint-disable-next-line import/no-cycle
import { logout } from '../../store/features/auth/authSlice';
import { AppStore } from '../../store/store';

export const fcAPI = axios.create({
	baseURL: window.config.REACT_APP_API_URL + (process.env.NODE_ENV === 'development' ? '' : '/fc'),
});

let store: AppStore;

export const injectStore = (_store: AppStore) => {
	store = _store;
};

fcAPI.interceptors.request.use(
	(config) => {
		const rememberMe: boolean = localStorage.getItem('rememberMe') ? !!JSON.parse(localStorage.getItem('rememberMe') as string) : false;
		const storage: Storage = rememberMe ? localStorage : sessionStorage;
		const accessToken = storage.getItem(FCTokenName);
		if (config.headers === undefined) {
			config.headers = {};
		}
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		config.headers['Content-Type'] = 'application/json';
		return config;
	},
	(error) => {
		Promise.reject(error);
	},
);

fcAPI.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		// if there is no response, then most likely a CORS error
		if (!error.response) {
			error.message = 'An unknown error occurred.';
			if (error.response?.data) {
				error.response.data.message = 'An unknown error occurred.';
			}
		}
		// Brute force protection limit reached
		if ((error.response.status === 429 || error.response.status === 403) && error.response.headers['retry-after']) {
			const t = new Date();
			t.setSeconds(t.getSeconds() + Number(error.response.headers['retry-after']));
			const msg = `Sorry, this request has been blocked for security reasons. Please try again ${formatDistanceToNow(t, {
				includeSeconds: true,
				addSuffix: true,
			})}.`;
			error.message = msg;
			if (error.response?.data) {
				error.response.data.message = msg;
			}
		}
		// DB Connection issue - Display as modal instead of snackbar
		if (error.response?.status === 503) {
			store.dispatch(
				setModalOpen({
					title: 'An error has occurred',
					alertText:
						"Sorry we're currently experiencing a technical issue, please try again in 5 minutes. If your issue is urgent you can contact us via phone.",
					severity: 'error',
				}),
			);
		}
		if (error.response?.status === 401) {
			store.dispatch(setWarningSnackbarOpen({ message: 'Your session has expired and have been logged out. Please login again.' }));
			store.dispatch(logout());
		}
		return Promise.reject(error);
	},
);
