import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Button, Drawer, List, ListSubheader, styled, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import { PerfectScrollbar } from '../../components/PerfectScrollbar';
import { ClientRoutes, PortalRoutes, PublicRoutes, ReportRoutes } from '../../constants/routes.constant';
import { LogoHorizontal } from '../../icons/branding/LogoHorizontal';
import { logout } from '../../store/features/auth/authSlice';
import { useAppDispatch } from '../../store/hooks';
import { ChathamsBlue } from '../../theme/colours';
import { NavItem } from './NavItem';

type DashboardSidebarProps = {
	onClose: () => void;
	open: boolean;
	isclientview?: boolean;
};

const SidebarSubHeader = styled(ListSubheader)<{ isclientview?: string }>(({ theme, isclientview }) => ({
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	lineHeight: 'normal',
	textTransform: 'uppercase',
	color: isclientview ? theme.palette.text.secondary : theme.palette.text.disabled,
	backgroundColor: 'inherit',
}));

export const DashboardSidebar: React.FC<DashboardSidebarProps> = ({ open, onClose, isclientview }) => {
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'));

	const renderNavItems = !isclientview ? (
		<>
			<List component="nav" subheader={<SidebarSubHeader>General</SidebarSubHeader>}>
				<NavItem title="Dashboard" href={PortalRoutes.DASHBOARD} icon={<CasesOutlinedIcon fontSize="medium" />} onClick={onClose} />
				<NavItem
					title="My clients"
					href={PortalRoutes.MY_CLIENTS}
					icon={<HomeOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					end={false}
				/>
				<NavItem
					title="Reports"
					href={ReportRoutes.REPORT_LIST}
					icon={<AnalyticsOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					end={false}
				/>
			</List>
			<List component="nav" subheader={<SidebarSubHeader>Other</SidebarSubHeader>}>
				<NavItem
					title="Account settings"
					href={PortalRoutes.ACCOUNT_SETTINGS}
					icon={<SettingsOutlinedIcon fontSize="medium" />}
					onClick={onClose}
				/>
				<NavItem title="Contact" href={PortalRoutes.CONTACT} icon={<ContactSupportOutlinedIcon fontSize="medium" />} onClick={onClose} />
			</List>
		</>
	) : (
		<>
			<List component="nav">
				<NavItem
					title="Dashboard"
					href={ClientRoutes.DASHBOARD}
					icon={<CasesOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
				<NavItem
					title="Setup Payments"
					href={ClientRoutes.PAYMENTS}
					icon={<CreditScoreOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
				<NavItem
					title="Moratorium"
					href={ClientRoutes.MORATORIUM}
					icon={<PauseCircleOutlineOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
				<NavItem
					title="Debt Waiver"
					href={ClientRoutes.DEBT_WAIVER}
					icon={<ContentCutOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
				<NavItem
					title="Balance Reduction"
					href={ClientRoutes.BALANCE_REDUCTION}
					icon={<BalanceOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
			</List>
			<List component="nav" subheader={<SidebarSubHeader isclientview={isclientview.toString()}>Client Account</SidebarSubHeader>}>
				<NavItem
					title="Account Information"
					href={ClientRoutes.ACCOUNT}
					icon={<AccountBoxOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
				<NavItem
					title="Hardship Requests"
					href={ClientRoutes.HARDSHIP}
					icon={<HistoryOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
				<NavItem
					title="Payment Plans"
					href={ClientRoutes.PAYMENT_PLANS}
					icon={<EventAvailableOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
				<NavItem
					title="Documents"
					href={ClientRoutes.DOCUMENTS}
					icon={<PlagiarismOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
				<NavItem
					title="Transactions"
					href={ClientRoutes.TRANSACTIONS}
					icon={<ReceiptLongOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
			</List>
			<List component="nav" subheader={<SidebarSubHeader isclientview={isclientview.toString()}>Other</SidebarSubHeader>}>
				<NavItem
					title="Contact"
					href={ClientRoutes.CONTACT}
					icon={<ContactSupportOutlinedIcon fontSize="medium" />}
					onClick={onClose}
					isclientview={true}
				/>
			</List>
		</>
	);

	const content = (
		<PerfectScrollbar>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<Box
					sx={{
						alignItems: 'center',
						justifyContent: 'flex-start',
						display: 'flex',
						py: 3,
						px: 2,
					}}
				>
					<Link to={PublicRoutes.HOME}>
						<LogoHorizontal height={46} fill={ChathamsBlue} />
					</Link>
				</Box>
				{isclientview && (
					<Box sx={{ display: 'flex', mx: 3, mb: 1 }}>
						<Button
							sx={{
								boxShadow: 1,
								borderRadius: '4px',
							}}
							size="large"
							color="white"
							variant="contained"
							component={Link}
							to={PortalRoutes.MY_CLIENTS}
							startIcon={<ArrowBackOutlinedIcon fontSize="medium" />}
						>
							Back
						</Button>
					</Box>
				)}
				<Box sx={{ flexGrow: 1 }}>{renderNavItems}</Box>
				<Box>
					{!isclientview && (
						<List component="nav">
							<NavItem title="Logout" action={() => dispatch(logout())} icon={<LogoutOutlinedIcon fontSize="medium" />} />
						</List>
					)}
				</Box>
			</Box>
		</PerfectScrollbar>
	);

	if (smUp) {
		return (
			<Drawer
				anchor="left"
				open
				PaperProps={{
					sx: {
						width: {
							xs: theme.widths.sidebar.sm,
							lg: theme.widths.sidebar.lg,
						},
						border: 'none',
						...(isclientview && { backgroundColor: '#B4D2ED' }),
					},
				}}
				variant="permanent"
			>
				{content}
			</Drawer>
		);
	}

	return (
		<Drawer
			anchor="left"
			onClose={onClose}
			open={open}
			PaperProps={{
				sx: {
					width: theme.widths.sidebar.sm,
					border: 'none',
				},
				...(isclientview && { backgroundColor: '#123F66' }),
			}}
			sx={{ zIndex: theme.zIndex.drawer + 2 }}
			variant="temporary"
		>
			{content}
		</Drawer>
	);
};
