import { FCResponse } from '../../../../services/api/base.api.response';

export enum ClientMoratoriumAPI {
	MORATORIUM = '/api/client/moratorium',
}

export interface IClientMoratoriumOutput {
	hardshipID: number;
	outcome: string;
}

export type IPostClientMoratorium = FCResponse<IClientMoratoriumOutput>;
