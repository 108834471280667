import { ButtonProps } from '@mui/material';
import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type StyledLinkButton = React.ComponentType<
	ButtonProps & LinkProps & { component?: React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>> }
>;

export const LinkButton = forwardRef<HTMLAnchorElement, LinkProps>(function RouterLink({ to, ...props }, ref) {
	return <Link {...props} ref={ref} to={to} />;
});
