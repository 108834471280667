import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { setErrorSnackbarOpen } from '../../app/appSlice';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientDebtAPI, IGetClientDebt, IGetDebtOutput } from './types';

export const clientDebtGet = createAsyncThunk('client/debt', async (_, { rejectWithValue, dispatch, getState }) => {
	try {
		const { DebtID, DebtorEntityID: EntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
		const { data } = await fcAPI.get<IGetClientDebt>(ClientDebtAPI.DEBT, {
			params: { DebtID, EntityID },
		});
		if (data.success) {
			return data.data.debt;
		}
		dispatch(setErrorSnackbarOpen({ message: data.message }));
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export interface DebtState {
	debt: IGetDebtOutput | undefined;
	status: Status;
}

const initialState: DebtState = {
	debt: undefined,
	status: Status.IDLE,
};

const clientDebtSlice = createSlice({
	name: 'clientDebt',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientDebtGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientDebtGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.debt = action.payload;
			})
			.addCase(clientDebtGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default clientDebtSlice.reducer;

export const selectClientDebt = (state: RootState) => state.clientDebt.debt;
export const selectClientDebtStatus = (state: RootState) => state.clientDebt.status;
