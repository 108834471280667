import CircleIcon from '@mui/icons-material/Circle';
import {
	Box,
	Button,
	ButtonProps,
	Card,
	CardContent,
	CardHeader,
	Container,
	Grid,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';
import { Chart as ChartJS, ChartData, ChartOptions, ArcElement, Tooltip, Legend } from 'chart.js';
import { format, parseISO } from 'date-fns';
import { Doughnut } from 'react-chartjs-2';
import { Link as RouterLink } from 'react-router-dom';
import { DashboardCard } from '../../components/DashboardCard';
import { Page } from '../../components/Page';
import { dateFormats } from '../../constants/dateFormat.constant';
import { ClientRoutes } from '../../constants/routes.constant';
import { Status } from '../../enums/status.enum';
import { HandCircleIcon } from '../../icons/HandCircleIcon';
import { MoneyGrowIcon } from '../../icons/MoneyGrowIcon';
import { ThumbShieldIcon } from '../../icons/ThumbShieldIcon';
import { ContactUsImg } from '../../static/images/ContactUs';
import { selectActiveArrangements, selectArrangementsStatus } from '../../store/features/client/arrangements/arrangementsSlice';
import { selectClientDebtAmounts, selectClientDebtAmountsStatus } from '../../store/features/client/debt/amountSlice';
import { selectClientDebt, selectClientDebtStatus } from '../../store/features/client/debt/debtSlice';
import { selectClientDebtor, selectClientDebtorStatus } from '../../store/features/client/debtor/debtorSlice';
import { selectClientHardship, selectClientHardshipStatus } from '../../store/features/client/hardship/hardshipSlice';
import { selectClientLedger, selectClientLedgerStatus } from '../../store/features/client/ledger/ledgerSlice';
import { useAppSelector } from '../../store/hooks';
import { formatMoney } from '../../utils/text.utill';
import { DebtToManyDebtorsTooltip } from './components/DebtToManyDebtorsTooltip';

type StyledButtonProps = ButtonProps & { component?: typeof RouterLink; to?: string };

const DashboardButton = styled(Button)<StyledButtonProps>(({ theme }) => ({
	color: theme.palette.text.primary,
	background: '#C6E1FF',
	boxShadow: shadows[1],
	borderRadius: '4px',
	'&:hover': {
		backgroundColor: '#CFE3F3',
		boxShadow: shadows[1],
	},
}));

const skelW = 100;

ChartJS.register(ArcElement, Tooltip, Legend);

export const ClientDashboard = () => {
	const ledger = useAppSelector(selectClientLedger);
	const ledgerStatus = useAppSelector(selectClientLedgerStatus);
	const debt = useAppSelector(selectClientDebt);
	const debtStatus = useAppSelector(selectClientDebtStatus);
	const debtAmounts = useAppSelector(selectClientDebtAmounts);
	const debtAmountsStatus = useAppSelector(selectClientDebtAmountsStatus);
	const debtor = useAppSelector(selectClientDebtor);
	const debtorStatus = useAppSelector(selectClientDebtorStatus);
	const hardship = useAppSelector(selectClientHardship);
	const hardshipStatus = useAppSelector(selectClientHardshipStatus);
	const activeArrangements = useAppSelector(selectActiveArrangements);
	const activeArrangementsStatus = useAppSelector(selectArrangementsStatus);

	const chartData = [
		{
			label: 'Outstanding',
			value: debtAmounts?.Outstanding ?? 0,
			background: '#F0F3FF',
		},
		{
			label: 'Paid',
			value: debtAmounts?.TotalPayments ?? 0,
			background: '#22D07D',
		},
	];

	const options: ChartOptions<'doughnut'> = {
		responsive: false,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						return `${context.label}: ${formatMoney(context.parsed)}`;
					},
				},
			},
		},
	};

	const chartDoughnutData: ChartData<'doughnut'> = {
		labels: chartData.map((c) => c.label),
		datasets: [
			{
				data: chartData.map((c) => c.value),
				backgroundColor: chartData.map((c) => c.background),
				borderWidth: 0,
			},
		],
	};

	return (
		<Page title="Client Dashboard">
			<Container maxWidth="xl">
				<Box sx={{ pb: 5 }}>
					<Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
						{/* GENERAL CARD SECTION */}
						<Grid item xs={12} md={6}>
							<Card elevation={0} sx={{ height: '100%' }}>
								<CardHeader
									sx={{ paddingBottom: 0 }}
									title="General"
									action={
										<DashboardButton variant="contained" component={RouterLink} to={ClientRoutes.ACCOUNT}>
											View more
										</DashboardButton>
									}
								/>
								<CardContent>
									<Table>
										<TableBody sx={{ '& td, & th': { border: 0 } }}>
											<TableRow>
												<TableCell>
													<Typography variant="body2" color="text.disabled" sx={{ mb: 2 }}>
														ACCOUNT OWNER
													</Typography>
													<Typography variant="h7">
														{debtorStatus === Status.LOADING ? (
															<Skeleton width={skelW} />
														) : (
															<>
																{debtor?.Fullname}{' '}
																{!!(debt?.IsDebtLinked || debt?.IsLinkedDebtFCManaged) && (
																	<DebtToManyDebtorsTooltip
																		isLinked={debt.IsDebtLinked}
																		isFcManaged={debt.IsLinkedDebtFCManaged}
																	/>
																)}
															</>
														)}
													</Typography>
													<Typography variant="body2">Name</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="body2" color="text.disabled" sx={{ mb: 2 }}>
														DEBT
													</Typography>
													<Typography variant="h7">
														{ledgerStatus === Status.LOADING ? <Skeleton width={skelW} /> : ledger?.ClientDisplayName}
													</Typography>
													<Typography variant="body2">Original client</Typography>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="h7">
														{debtStatus === Status.LOADING ? <Skeleton width={skelW} /> : debt?.ClientReference}
													</Typography>
													<Typography variant="body2">Original client reference</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="h7">
														{debtStatus === Status.LOADING ? <Skeleton width={skelW} /> : debt?.DebtStatusDescription}
													</Typography>
													<Typography variant="body2">Debt status</Typography>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													<Typography variant="h7">
														{debtStatus === Status.LOADING ? <Skeleton width={skelW} /> : debt?.DebtID}
													</Typography>
													<Typography variant="body2">Panthera reference</Typography>
												</TableCell>
												<TableCell>
													<Typography variant="h7">
														{debtStatus === Status.LOADING ? (
															<Skeleton width={skelW} />
														) : debt?.DateAdded ? (
															format(parseISO(debt.DateAdded), dateFormats.prettyShort)
														) : (
															'-'
														)}
													</Typography>
													<Typography variant="body2">Date added</Typography>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Grid>
						{/* HARDSHIP CARD SECTION */}
						<Grid item xs={12} md={6}>
							<Card elevation={0} sx={{ height: '100%' }}>
								<CardHeader
									sx={{ paddingBottom: 0 }}
									title="Hardship status"
									action={
										<DashboardButton variant="contained" component={RouterLink} to={ClientRoutes.HARDSHIP}>
											View more
										</DashboardButton>
									}
								/>
								<CardContent>
									{hardshipStatus === Status.LOADING && <Skeleton width={skelW} />}
									{(hardshipStatus === Status.SUCCEEDED || hardshipStatus === Status.FAILED) && !hardship && (
										<Typography variant="body1">No active hardship requests.</Typography>
									)}
									{hardshipStatus === Status.SUCCEEDED && hardship && (
										<Table>
											<TableBody sx={{ '& td, & th': { border: 0 } }}>
												<TableRow>
													<TableCell>
														<Typography variant="h7">{hardship.HardshipResolutionName || 'No Resolution'}</Typography>
														<Typography variant="body2">Resolution Type</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h7">{hardship.HardshipCategoryName}</Typography>
														<Typography variant="body2">Reason</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography variant="h7">{hardship?.AssignedAgent || 'Unassigned'}</Typography>
														<Typography variant="body2">Assigned agent</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h7">
															{format(parseISO(hardship.AddedDate), dateFormats.prettyDatetime)}
														</Typography>
														<Typography variant="body2">Date added</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														{hardship?.ApprovedDate ? (
															<Typography variant="h7">
																{format(parseISO(hardship.ApprovedDate), dateFormats.prettyShort)}
															</Typography>
														) : (
															<Typography variant="h7" sx={{ color: '#FFB400' }}>
																Awaiting Action
															</Typography>
														)}
														<Typography variant="body2">Approved date</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h7">{hardship.HardshipStateName}</Typography>
														<Typography variant="body2">State</Typography>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									)}
								</CardContent>
							</Card>
						</Grid>
						{/* CHART SECTION */}
						<Grid item xs={12} md={6}>
							<Card elevation={0} sx={{ height: '100%' }}>
								<CardHeader
									sx={{ paddingBottom: 0 }}
									title="Balance Chart"
									action={
										<DashboardButton variant="contained" component={RouterLink} to={ClientRoutes.TRANSACTIONS}>
											View more
										</DashboardButton>
									}
								/>
								<CardContent>
									<Grid container spacing={2}>
										<Grid item xs>
											<Grid container direction="row" spacing={1}>
												<Grid item>
													<CircleIcon width={20} height={20} sx={{ color: chartData[0].background }} />
												</Grid>
												<Grid item>
													<Typography variant="h7">
														{debtAmountsStatus === Status.LOADING ? (
															<Skeleton width={skelW} />
														) : (
															formatMoney(debtAmounts?.Outstanding || 0)
														)}
													</Typography>
													<Typography variant="body1" color="text.secondary" gutterBottom>
														Outstanding balance
													</Typography>
												</Grid>
											</Grid>
											{!!debtAmounts?.TotalPayments && (
												<Grid container direction="row" spacing={1}>
													<Grid item>
														<CircleIcon width={20} height={20} sx={{ color: chartData[1].background }} />
													</Grid>
													<Grid item>
														<Typography variant="h7">
															{debtAmountsStatus === Status.LOADING ? (
																<Skeleton width={skelW} />
															) : (
																formatMoney(debtAmounts.TotalPayments)
															)}
														</Typography>
														<Typography variant="body1" color="text.secondary">
															Paid amount
														</Typography>
													</Grid>
												</Grid>
											)}
										</Grid>
										<Grid item xs>
											{debtAmountsStatus === Status.LOADING ? (
												<Skeleton
													sx={{
														ml: 1,
														border: '30px solid rgba(0, 0, 0, 0.11)',
														backgroundColor: (theme) => theme.palette.common.white,
													}}
													variant="circular"
													width={140}
													height={140}
												/>
											) : (
												<Doughnut data={chartDoughnutData} options={options} style={{ height: 150 }} />
											)}
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						{/* PA CARD SECTION */}
						<Grid item xs={12} md={6}>
							<Card elevation={0} sx={{ height: '100%' }}>
								<CardHeader
									sx={{ paddingBottom: 0 }}
									title="Payment Plan"
									action={
										<DashboardButton variant="contained" component={RouterLink} to={ClientRoutes.PAYMENT_PLANS}>
											View more
										</DashboardButton>
									}
								/>
								<CardContent>
									{activeArrangementsStatus === Status.LOADING && <Skeleton width={skelW} />}
									{(activeArrangementsStatus === Status.SUCCEEDED || activeArrangementsStatus === Status.FAILED) &&
										activeArrangements.length < 1 && <Typography variant="body1">No active arrangement.</Typography>}
									{activeArrangementsStatus === Status.SUCCEEDED && activeArrangements.length > 0 && (
										<Table>
											<TableBody sx={{ '& td, & th': { border: 0 } }}>
												<TableRow>
													<TableCell>
														<Typography variant="h7">{formatMoney(activeArrangements[0].Amount)}</Typography>
														<Typography variant="body2">{activeArrangements[0].PeriodicFrequencyDesc}</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h7">
															{format(parseISO(activeArrangements[0].AddedDate), dateFormats.prettyDatetime)}
														</Typography>
														<Typography variant="body2">Date Added</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography variant="h7">{activeArrangements[0].PaymentMethodDesc}</Typography>
														<Typography variant="body2">Payment method</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h7">{activeArrangements[0].AddedByUser}</Typography>
														<Typography variant="body2">Created by</Typography>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									)}
								</CardContent>
							</Card>
						</Grid>
						{/* CALL TO ACTION CARDS */}
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={3}
							sx={{
								display: 'flex',
								'& .MuiBox-root': {
									justifyContent: 'center',
								},
							}}
						>
							<DashboardCard
								title="Setup payments"
								text="Choose an amount that suits you at a frequency that meets your needs."
								to={ClientRoutes.PAYMENTS}
								backgroundColour="rgba(245, 0, 87, 0.08)"
								icon={<MoneyGrowIcon width={95} height={95} />}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={3}
							sx={{
								display: 'flex',
								'& .MuiBox-root': {
									justifyContent: 'center',
								},
							}}
						>
							<DashboardCard
								title="Approve a Moratorium"
								text="Use the following process to suspend all actions on your account."
								to={ClientRoutes.MORATORIUM}
								backgroundColour="#FEF2E8"
								icon={<ThumbShieldIcon width={95} height={95} />}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={3} sx={{ display: 'flex' }}>
							<DashboardCard
								title="Reduce settlement"
								text="Pay back a portion of a loan balance, while the remainder of the debt is forgiven."
								to={ClientRoutes.BALANCE_REDUCTION}
								backgroundColour="rgba(33, 150, 243, 0.08)"
								icon={<ContactUsImg width="100%" height="100%" />}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={3}
							sx={{
								display: 'flex',
								'& .MuiBox-root': {
									justifyContent: 'center',
								},
							}}
						>
							<DashboardCard
								title="Request Debt waiver"
								text="The debt could be completely forgiven if client experiencing financial hardship."
								to={ClientRoutes.DEBT_WAIVER}
								backgroundColour="#F1F9F1"
								icon={<HandCircleIcon width={95} height={95} />}
							/>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Page>
	);
};
