import { FCResponse } from '../../../../services/api/base.api.response';

export enum ClientDebtAPI {
	DEBT = '/api/client/debt',
	DEBT_AMOUNTS = '/api/client/debt/amounts',
}
export interface IGetDebtAmountsOutput {
	DebtID: number;
	DebtAmount: number;
	Outstanding: number;
	TotalPayments: number;
	TotalFees: number;
	TotalLegalFees: number;
	TotalInterest: number;
	TotalAdjustments: number;
	TotalRefunds: number;
}

export type IGetClientDebtAmounts = FCResponse<{ amounts: IGetDebtAmountsOutput }>;

export interface IGetDebtOutput {
	DebtID: number;
	DebtStatusID: number;
	DateOfDebt: string;
	LastClientPaymentDate: string;
	DateAdded: string;
	FinalisedDate: Date;
	DebtAmount: number;
	InterestRate: number;
	InterestAccruedTo: string;
	ClientReference: string;
	AccountReference: string | null;
	AccountItems: string | null;
	StatBarredDate: string;
	DebtStatusName: string;
	DebtStatusDescription: string;
	IsDebtLinked: boolean;
	IsLinkedDebtFCManaged: boolean;
	InterestHold?: boolean;
	InterestHoldEndDate?: string | null;
}

export type IGetClientDebt = FCResponse<{ debt: IGetDebtOutput }>;
