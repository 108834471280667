import React from 'react';

export const DepositIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 33 33" {...props}>
		<path
			fill="#D3E8F1"
			d="M18.27 25.905h-3.69a.328.328 0 01-.328-.327V9.86c0-.181.147-.328.328-.328h3.69c.181 0 .328.147.328.328v15.716a.328.328 0 01-.328.328zM8.829 25.905h-3.69a.328.328 0 01-.328-.327V9.86c0-.181.146-.328.327-.328H8.83c.181 0 .328.147.328.328v15.716a.328.328 0 01-.328.328zM27.712 25.905h-3.69a.328.328 0 01-.329-.327V9.86c0-.181.147-.328.328-.328h3.69c.182 0 .329.147.329.328v15.716a.328.328 0 01-.328.328z"
		></path>
		<path
			fill="#BED8E4"
			d="M9.152 23.731H4.811v1.788h4.341v-1.788zM18.588 23.731h-4.342v1.788h4.342v-1.788zM28.035 23.731h-4.342v1.788h4.342v-1.788zM9.152 10.13H4.811v1.789h4.341V10.13zM18.588 10.13h-4.342v1.789h4.342V10.13zM28.035 10.13h-4.342v1.789h4.342V10.13z"
		></path>
		<path
			fill="#EBF9FE"
			d="M30.074 10.51H2.776c-.336 0-.455-.446-.164-.613l13.813-7.934 13.813 7.934c.29.167.172.612-.164.612zM3.592 28.57h25.665c.197 0 .356-.16.356-.356v-2.816a.356.356 0 00-.356-.355H3.592a.355.355 0 00-.356.355v2.816c0 .197.16.356.356.356z"
		></path>
		<path fill="#BED8E4" d="M29.651 27.104H3.21v2.278h26.442v-2.279z"></path>
		<path
			fill="#D3E8F1"
			d="M.814 31.037h31.22a.328.328 0 00.328-.328v-2.342a.328.328 0 00-.327-.327H.815a.328.328 0 00-.329.327v2.342c0 .181.147.328.328.328z"
		></path>
		<path
			fill="#000"
			fillOpacity="0.87"
			d="M32.438 27.478H30.25v-2.436a.562.562 0 00-.562-.562h-1.011V11.071h2.7a.562.562 0 00.28-1.05l-4.47-2.568-1.618-.929-.309-.177-.159-.091a.562.562 0 10-.56.974l4.73 2.717H3.73L16.5 2.611l4.188 2.406.403.231a.562.562 0 00.56-.974l-.402-.231-.001-.001-4.468-2.566a.562.562 0 00-.56 0L1.342 10.022a.562.562 0 00.28 1.05h2.701V24.48H3.312a.562.562 0 00-.562.562v2.436H.562A.562.562 0 000 28.04v2.997c0 .31.252.562.562.562h31.876c.31 0 .562-.251.562-.562V28.04a.562.562 0 00-.562-.562zm-4.885-2.998H24.33V11.217h3.223V24.48zm-4.347 0h-3.971V11.071h3.971v13.41zM14.89 11.217h3.222V24.48h-3.222V11.217zm-1.124-.146v13.41H9.793V11.07h3.972zm-8.318.146H8.67V24.48H5.447V11.217zM3.874 25.604h25.252v1.874H3.874v-1.874zm28.002 4.871H1.124v-1.873h30.752v1.873z"
		></path>
	</svg>
);
