import { persistRootKey } from '../constants/persist.constant';
import { FCTokenName } from '../constants/token.constant';
import { appInsights } from '../services/ai/reactPlugin';

export const clearStorage = (leaveAuthToken?: boolean) => {
	if (!leaveAuthToken) {
		localStorage.removeItem(FCTokenName);
		sessionStorage.removeItem(FCTokenName);
		if (appInsights) {
			appInsights.clearAuthenticatedUserContext();
		}
	}
	sessionStorage.removeItem(`persist:${persistRootKey}`);
};
