type TabPanelProps = {
	index: number | string;
	value: number | string | undefined;
};

export const TabPanel: React.FC<TabPanelProps> = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
			{value === index && <>{children}</>}
		</div>
	);
};
