import {
	CardContent,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography,
} from '@mui/material';
import Fuse from 'fuse.js';
import { useEffect, useMemo, useState } from 'react';
import { PerfectScrollbar } from '../../../components/PerfectScrollbar';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getComparator, Order } from '../../../utils/table.util';
import { Status } from '../../../enums/status.enum';
import { TableLoader } from '../../../components/TableLoader';
import { formatDistance, parseISO } from 'date-fns';
import { IPendingClientsOutput } from '../../../store/features/clients/types';
import {
	pendingClientsGet,
	selectPendingClients,
	selectPendingClientsCount,
	selectPendingClientsStatus,
} from '../../../store/features/clients/pendingClients';
import { TableEmpty } from '../../../components/TableEmpty';
import { ClientSearch, fuseOptions, MIN_SEARCH_CHARS } from './ClientSearch';

interface HeadCell {
	id: keyof IPendingClientsOutput | undefined;
	label: string;
	canSort: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'DebtID',
		label: 'Reference',
		canSort: true,
	},
	{
		id: 'AddedDate',
		label: 'Date requested',
		canSort: true,
	},
	{
		id: undefined,
		label: 'Status',
		canSort: false,
	},
];

export const PendingClients = () => {
	const dispatch = useAppDispatch();
	const today = new Date();
	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<keyof IPendingClientsOutput>('AddedDate');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [count, setCount] = useState(0);
	const [search, setSearch] = useState<string>('');
	const [filteredClients, setFilteredClients] = useState<IPendingClientsOutput[]>([]);
	const pendingClients = useAppSelector(selectPendingClients);
	const pendingClientsStatus = useAppSelector(selectPendingClientsStatus);
	const unfilteredPendingClientsCount = useAppSelector(selectPendingClientsCount);

	const fuse = useMemo(() => new Fuse<IPendingClientsOutput>(pendingClients, fuseOptions<IPendingClientsOutput>()), [pendingClients]);

	useEffect(() => {
		dispatch(pendingClientsGet());
	}, [dispatch]);

	useEffect(() => {
		const clients = search.length > MIN_SEARCH_CHARS ? fuse.search(search).map((filtered) => filtered.item) : pendingClients;
		setCount(clients.length);
		setFilteredClients(clients);
	}, [pendingClients, search, fuse]);

	const handleRequestSort = (property: keyof IPendingClientsOutput) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<CardContent>
			<Grid container mb={3} spacing={5} alignItems="center">
				<Grid item xs={12} lg={4} xl={4}>
					<ClientSearch searchValue={search} onChange={(value) => setSearch(value)} disabled={pendingClientsStatus !== Status.SUCCEEDED} />
				</Grid>
			</Grid>
			<TableContainer component={PerfectScrollbar}>
				<Table sx={{ minWidth: 600 }}>
					<TableHead>
						<TableRow>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id || Math.random()}
									align="left"
									padding="normal"
									sortDirection={orderBy === headCell.id ? order : false}
								>
									{headCell.canSort ? (
										<TableSortLabel
											active={orderBy === headCell.id}
											direction={orderBy === headCell.id ? order : 'asc'}
											onClick={() => (headCell.canSort && headCell.id ? handleRequestSort(headCell.id) : undefined)}
										>
											{headCell.label}
										</TableSortLabel>
									) : (
										headCell.label
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					{pendingClientsStatus === Status.LOADING && <TableLoader rows={5} colSpan={headCells.length} />}
					{pendingClientsStatus === Status.SUCCEEDED && unfilteredPendingClientsCount > 0 && count === 0 && (
						<TableEmpty
							text={
								<>
									No <strong>Pending</strong> clients found
								</>
							}
							colSpan={headCells.length + 1}
						/>
					)}
					{(pendingClientsStatus === Status.SUCCEEDED || pendingClientsStatus === Status.FAILED) && unfilteredPendingClientsCount === 0 && (
						<TableEmpty text="You don't have any pending clients" colSpan={headCells.length} />
					)}
					{pendingClientsStatus === Status.SUCCEEDED && count > 0 && (
						<TableBody>
							{filteredClients
								.slice()
								.sort(getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										// eslint-disable-next-line react/no-array-index-key
										<TableRow key={index}>
											<TableCell component="th" scope="row">
												{row.DebtID}
											</TableCell>
											<TableCell sx={{ color: 'text.secondary' }}>
												{formatDistance(parseISO(row.AddedDate), today, { addSuffix: true })}
											</TableCell>
											<TableCell>
												<Typography
													fontSize="inherit"
													sx={{
														whiteSpace: 'nowrap',
														color: 'warning.main',
														alignItems: 'center',
														display: 'flex',
														textTransform: 'uppercase',
													}}
												>
													Pending
												</Typography>
												<Typography
													fontSize="inherit"
													sx={{
														whiteSpace: 'nowrap',
														color: 'secondary',
													}}
												>
													takes up to 3 business days
												</Typography>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					)}
				</Table>
			</TableContainer>
			{count > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={count}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Per page:"
					sx={{ mt: 2 }}
				/>
			)}
		</CardContent>
	);
};
