import { Box, Grid, Typography } from '@mui/material';
import { ContactDetails } from '../../components/ContactDetails';
import { Page } from '../../components/Page';

export const Contact = () => (
	<Page title="Contact us">
		<Box sx={{ pb: 5, px: { xs: 2, sm: 3 } }}>
			<Grid container>
				<Grid item>
					<Typography variant="h5">Contact us</Typography>
				</Grid>
			</Grid>
		</Box>
		<ContactDetails />
	</Page>
);
