import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, CardContent, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { REGEX_PASSWORD_NUMBER, REGEX_PASSWORD_SPECIAL, REGEX_PASSWORD_UPPER } from '../../../constants/regex.constant';
import { setSuccessSnackbarOpen } from '../../../store/features/app/appSlice';
import { userUpdatePasswordPost } from '../../../store/features/user/userSlice';
import { useAppDispatch } from '../../../store/hooks';
import { PasswordRequirements } from '../../auth/components/PasswordRequirements';

interface UpdatePasswordDetails {
	currentPassword: string;
	password: string;
	confirmPassword: string;
}

export const UpdatePassword = () => {
	const dispatch = useAppDispatch();
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const [length, setLength] = useState(false);
	const [isUppercase, setUppercase] = useState(false);
	const [isNumber, setNumber] = useState(false);
	const [isSpecial, setSpecial] = useState(false);

	const initialValues: UpdatePasswordDetails = {
		currentPassword: '',
		password: '',
		confirmPassword: '',
	};

	const updatePasswordDetailsSchema = Yup.object().shape(
		{
			currentPassword: Yup.string().required('Current password is required'),
			password: Yup.string()
				.when('password', (password, field) => {
					if (password == null) {
						return field.required('Password is required');
					}
				})
				.when('password', (password, field) => {
					if (!password || password?.length < 12) {
						setLength(false);
						return field.min(12, 'Password must be 12 characters or more');
					} else if (password && password?.length > 50) {
						setLength(false);
						return field.max(50, 'Password must not be greater than 50 characters');
					} else {
						setLength(true);
					}
				})
				.when('password', (password, field) => {
					if (!REGEX_PASSWORD_UPPER.test(password)) {
						setUppercase(false);
						return field.matches(REGEX_PASSWORD_UPPER, 'Password must contain uppercase character');
					} else {
						setUppercase(true);
					}
				})
				.when('password', (password, field) => {
					if (!REGEX_PASSWORD_NUMBER.test(password)) {
						setNumber(false);
						return field.matches(REGEX_PASSWORD_NUMBER, 'Password must contain a number');
					} else {
						setNumber(true);
					}
				})
				.when('password', (password, field) => {
					if (!REGEX_PASSWORD_SPECIAL.test(password)) {
						setSpecial(false);
						return field.matches(REGEX_PASSWORD_SPECIAL, 'Password must contain a special character');
					} else {
						setSpecial(true);
					}
				}),
			confirmPassword: Yup.string()
				.required('Confirm password is required')
				.oneOf([Yup.ref('password'), null], 'Passwords must match'),
		},
		[['password', 'password']], // yup cyclic dependency
	);

	const updatePasswordSubmit = async (values: UpdatePasswordDetails, { resetForm }: FormikHelpers<UpdatePasswordDetails>) => {
		try {
			const updateSuccess = await dispatch(
				userUpdatePasswordPost({ currentPassword: values.currentPassword, password: values.password }),
			).unwrap();
			if (updateSuccess) {
				dispatch(setSuccessSnackbarOpen({ message: updateSuccess }));
				resetForm();
				setLength(false);
				setUppercase(false);
				setNumber(false);
				setSpecial(false);
			}
		} catch {
			/* Handled in Thunk */
		}
	};

	return (
		<CardContent>
			<Stack spacing={4}>
				<Typography variant="h6">Update password</Typography>
				<Box sx={{ width: '100%' }}>
					<Formik
						initialValues={initialValues}
						validationSchema={updatePasswordDetailsSchema}
						onSubmit={updatePasswordSubmit}
						validateOnChange={true}
						validateOnBlur={true}
					>
						{({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
							<Form>
								<TextField
									error={Boolean(touched.currentPassword && errors.currentPassword)}
									fullWidth
									helperText={touched.currentPassword && errors.currentPassword}
									label="Current password"
									margin="normal"
									name="currentPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									type={showCurrentPassword ? 'text' : 'password'}
									value={values.currentPassword}
									variant="outlined"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setShowCurrentPassword(!showCurrentPassword)}
													onMouseDown={(event) => event.preventDefault()}
													edge="end"
												>
													{showCurrentPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<TextField
									error={Boolean(touched.password && errors.password)}
									fullWidth
									helperText={touched.password && errors.password}
									label="Password"
									margin="normal"
									name="password"
									onBlur={handleBlur}
									onChange={handleChange}
									type={showPassword ? 'text' : 'password'}
									value={values.password}
									variant="outlined"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setShowPassword(!showPassword)}
													onMouseDown={(event) => event.preventDefault()}
													edge="end"
												>
													{showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<PasswordRequirements length={length} number={isNumber} uppercase={isUppercase} special={isSpecial} />
								<TextField
									error={Boolean(touched.confirmPassword && errors.confirmPassword)}
									fullWidth
									helperText={touched.confirmPassword && errors.confirmPassword}
									label="Confirm Password"
									margin="normal"
									name="confirmPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									type={showConfirmPassword ? 'text' : 'password'}
									value={values.confirmPassword}
									variant="outlined"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setShowConfirmPassword(!showConfirmPassword)}
													onMouseDown={(event) => event.preventDefault()}
													edge="end"
												>
													{showConfirmPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
									<LoadingButton loading={isSubmitting} size="large" color="secondary" type="submit" variant="contained">
										Update password
									</LoadingButton>
								</Stack>
							</Form>
						)}
					</Formik>
				</Box>
			</Stack>
		</CardContent>
	);
};
