import { Box, Button, Grid, Paper } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import { PublicRoutes } from '../constants/routes.constant';
import { LogoHorizontal } from '../icons/branding/LogoHorizontal';
import { QuoteImg } from '../static/images/Quote';
import { ShipCove } from '../theme/colours';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { LinkButton } from '../components/StyledLinkButton';

export const AuthLayout = () => {
	return (
		<Grid container component="main" sx={{ height: '100vh' }}>
			<Grid item lg={6} sx={{ backgroundColor: ShipCove, display: { xs: 'none', lg: 'flex' } }}>
				<Box
					component="main"
					sx={{
						py: { xs: 4, md: 8 },
						px: 3,
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						minHeight: {
							sm: '100%',
						},
					}}
				>
					<Box
						sx={{
							px: { lg: 4 },
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							flexWrap: 'wrap',
							marginTop: -3,
						}}
					>
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								marginTop: 3,
							}}
						>
							<Link to={PublicRoutes.HOME}>
								<LogoHorizontal height={46} fill="#FFFFFF" />
							</Link>
						</Box>
					</Box>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							flexGrow: 1,
							width: '100%',
							maxWidth: 489,
						}}
					>
						<QuoteImg width="100%" height="100%" fill="#FFFFFF" />
					</Box>
					<Box sx={{ maxWidth: 489, width: '100%' }}>
						<Button
							color="white"
							variant="outlined"
							startIcon={<ArrowBackOutlinedIcon />}
							component={LinkButton}
							to={PublicRoutes.HOME}
							size="large"
						>
							Home
						</Button>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} lg={6} component={Paper} square>
				<Outlet />
			</Grid>
		</Grid>
	);
};
