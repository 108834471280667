import { FCResponse } from '../../../../services/api/base.api.response';

export enum ClientArrangementsAPI {
	ARRANGEMENTS = '/api/client/arrangements',
	SCHEDULE = '/api/client/arrangement/schedule',
}

export interface IInactiveArrangements {
	ArrangementID: number;
	AddedDate: string;
	PaymentArrangementTypeID: number;
	PaymentArrangementTypeDesc: string;
	CommencementDate: string;
	CompletionDate: string;
	ArrangementCompletionCodeID: number;
	ArrangementCompletionCodeName: string;
	Amount: number;
	PaymentMethodID: number;
	PaymentMethodDesc: string;
	PeriodicFrequencyID: number;
	PeriodicFrequencyDesc: string;
	AddedByUser: string;
}

export interface IGetArrangementsOutput extends IInactiveArrangements {
	DebtorAccountID: number | null;
	LastPaymentDate?: string;
	NumberOfPaymentsLeft?: number;
}

export type IClientArrangements = FCResponse<{ arrangements: IGetArrangementsOutput[] }>;

export interface IGetScheduleOutput {
	ScheduleID: number;
	ArrangementID: number;
	Date: string;
	ScheduledPaymentStatusID: number;
	ScheduledPaymentStatusDesc: string;
	Amount: number;
	TotalRows: number;
}

export type IClientArrangementSchedule = FCResponse<{ schedule: IGetScheduleOutput[] }>;
