import { Navigate, RouteObject } from 'react-router-dom';
import { PublicRoutes, AuthRoutes, PortalPrefix, PortalRoutes, ClientPrefix, ClientRoutes, ReportRoutes } from './constants/routes.constant';
import { AuthLayout } from './layouts/AuthLayout';
import { DashboardLayout } from './layouts/DashboardLayout';
import { MainLayout } from './layouts/MainLayout';
import { Confirm } from './pages/auth/Confirm';
import { ForgotPassword } from './pages/auth/ForgotPassword';
import { LogIn } from './pages/auth/Login';
import { SignUp } from './pages/auth/SignUp';
import { AuthError } from './pages/auth/AuthError';
import { NotFound } from './pages/NotFound';
import { AccountSettings } from './pages/portal/AccountSettings';
import { Contact } from './pages/portal/Contact';
import { MyClients } from './pages/portal/MyClients';
import { Reports } from './pages/portal/Reports';
import { getChildRoute } from './utils/routes.util';
import { ResetPassword } from './pages/auth/ResetPassword';
import { Dashboard } from './pages/portal/Dashboard';
import { PendingApproval } from './pages/portal/PendingApproval';
import { ClientDashboard } from './pages/client/Dashboard';
import { Home } from './pages/home/Home';
import { Policy } from './pages/home/Policy';
import { TermsConditions } from './pages/home/TermsConditions';
import { ContactUs } from './pages/home/ContactUs';
import { ClientPayments } from './pages/client/Payments';
import { ClientMoratorium } from './pages/client/Moratorium';
import { ClientDebtWaiver } from './pages/client/DebtWaiver';
import { ClientBalanceReduction } from './pages/client/BalanceReduction';
import { ClientPaymentPlans } from './pages/client/PaymentPlans';
import { ClientDocuments } from './pages/client/Documents';
import { ClientTransactions } from './pages/client/Transactions';
import { ClientContact } from './pages/client/Contact';
import { ClientAccount } from './pages/client/Account';
import { ClientHardshipInformation } from './pages/client/HardshipRequests';
import { ReportClientStatuses } from './pages/portal/reports/ClientStatuses/ReportClientStatuses';
import { ReportArrangements } from './pages/portal/reports/Arrangements/ReportArrangements';
import { ReportHardships } from './pages/portal/reports/Hardships/ReportHardships';

export const routes = (isAuthenticated: boolean, isApproved: boolean, isClientSelected: boolean): RouteObject[] => [
	{
		path: PublicRoutes.NOT_FOUND,
		element: <NotFound />,
	},
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{ path: PublicRoutes.HOME, element: <Home /> },
			{ path: PublicRoutes.POLICY, element: <Policy /> },
			{ path: PublicRoutes.TERMS_CONDITIONS, element: <TermsConditions /> },
			{ path: PublicRoutes.CONTACTS, element: <ContactUs /> },
			{ path: PublicRoutes.NOT_FOUND, element: <Navigate to={PublicRoutes.NOT_FOUND} /> },
		],
	},
	{
		path: '/',
		element: <AuthLayout />,
		children: [
			{ path: AuthRoutes.SIGN_UP, element: <SignUp /> },
			{ path: AuthRoutes.CONFIRM, element: <Confirm /> },
			{ path: AuthRoutes.ERROR, element: <AuthError /> },
			{ path: AuthRoutes.LOGIN, element: !isAuthenticated ? <LogIn /> : <Navigate to={PortalRoutes.DASHBOARD} replace /> },
			{ path: AuthRoutes.FORGOT_PASSWORD, element: <ForgotPassword /> },
			{ path: AuthRoutes.RESET_PASSWORD, element: <ResetPassword /> },
		],
	},
	{
		path: `/${PortalPrefix}`,
		element: isAuthenticated ? <DashboardLayout /> : <Navigate to={AuthRoutes.LOGIN} replace />,
		children: [
			{ index: true, element: isApproved ? <Dashboard /> : <Navigate to={PortalRoutes.APPROVAL} replace /> },
			{ path: getChildRoute(PortalRoutes.APPROVAL), element: isApproved ? <Dashboard /> : <PendingApproval /> },
			{
				path: `${getChildRoute(PortalRoutes.MY_CLIENTS)}/:tab`,
				element: isApproved ? <MyClients /> : <Navigate to={PortalRoutes.APPROVAL} replace />,
			},
			{ path: getChildRoute(PortalRoutes.MY_CLIENTS), element: isApproved ? <MyClients /> : <Navigate to={PortalRoutes.APPROVAL} replace /> },
			{ path: getChildRoute(PortalRoutes.CONTACT), element: <Contact /> },
			{ path: getChildRoute(PortalRoutes.ACCOUNT_SETTINGS), element: <AccountSettings /> },
			{
				path: getChildRoute(ReportRoutes.REPORT_LIST),
				children: [
					{ index: true, element: isApproved ? <Reports /> : <Navigate to={PortalRoutes.APPROVAL} replace /> },
					{
						path: getChildRoute(ReportRoutes.CLIENT_STATUSES),
						element: <ReportClientStatuses />,
					},
					{
						path: getChildRoute(ReportRoutes.PAYMENT_PLANS),
						element: <ReportArrangements />,
					},
					{
						path: getChildRoute(ReportRoutes.HARDSHIPS),
						element: <ReportHardships />,
					},
				],
			},
			{ path: getChildRoute(PortalRoutes.NOT_FOUND), element: <NotFound /> },
			{ path: '*', element: <Navigate to={PortalRoutes.NOT_FOUND} replace /> },
		],
	},
	{
		path: `/${PortalPrefix}/${ClientPrefix}`,
		element: isClientSelected ? <DashboardLayout isclientview={true} /> : <Navigate to={PortalRoutes.DASHBOARD} replace />,
		children: [
			{ path: getChildRoute(ClientRoutes.DASHBOARD), element: <ClientDashboard /> },
			{ path: getChildRoute(ClientRoutes.PAYMENTS), element: <ClientPayments /> },
			{ path: getChildRoute(ClientRoutes.MORATORIUM), element: <ClientMoratorium /> },
			{ path: getChildRoute(ClientRoutes.DEBT_WAIVER), element: <ClientDebtWaiver /> },
			{ path: getChildRoute(ClientRoutes.BALANCE_REDUCTION), element: <ClientBalanceReduction /> },
			{ path: getChildRoute(ClientRoutes.HARDSHIP), element: <ClientHardshipInformation /> },
			{ path: `${getChildRoute(ClientRoutes.ACCOUNT)}/:tab`, element: <ClientAccount /> },
			{ path: getChildRoute(ClientRoutes.ACCOUNT), element: <ClientAccount /> },
			{ path: `${getChildRoute(ClientRoutes.PAYMENT_PLANS)}/:tab`, element: <ClientPaymentPlans /> },
			{ path: getChildRoute(ClientRoutes.PAYMENT_PLANS), element: <ClientPaymentPlans /> },
			{ path: getChildRoute(ClientRoutes.DOCUMENTS), element: <ClientDocuments /> },
			{ path: getChildRoute(ClientRoutes.TRANSACTIONS), element: <ClientTransactions /> },
			{ path: getChildRoute(ClientRoutes.CONTACT), element: <ClientContact /> },
			{ path: getChildRoute(ClientRoutes.NOT_FOUND), element: <NotFound /> },
			{ path: '*', element: <Navigate to={ClientRoutes.NOT_FOUND} replace /> },
		],
	},
];
