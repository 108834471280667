export enum DebtStatusName {
	UNK = 'UNK',
	REP = 'REP',
	DIS = 'DIS',
	DIS2 = 'DIS2',
	DS = 'DS',
	LAP = 'LAP',
	LOC = 'LOC',
	MR = 'MR',
	NEG = 'NEG',
	NEW = 'NEW',
	PA = 'PA',
	PIF = 'PIF',
	PIX = 'PIX',
	SRCH = 'SRCH',
	UNR = 'UNR',
	REC = 'REC',
	BLO = 'BLO',
	RET = 'RET',
	COM = 'COM',
	OUT = 'OUT',
	OUP = 'OUP',
	PABR = 'PABR',
	SOLD = 'SOLD',
	DSR = 'DSR',
	BAR = 'BAR',
	DR = 'DR',
	INS = 'INS',
}

export enum DebtStatusID {
	UNK = 0,
	REP = 1,
	DIS = 2,
	DIS2 = 3,
	DS = 4,
	LAP = 5,
	LOC = 6,
	MR = 7,
	NEG = 8,
	NEW = 9,
	PA = 10,
	PIF = 11,
	PIX = 12,
	SRCH = 13,
	UNR = 14,
	REC = 15,
	BLO = 16,
	RET = 17,
	COM = 18,
	OUT = 19,
	OUP = 20,
	PABR = 21,
	SOLD = 22,
	DSR = 23,
	BAR = 24,
	DR = 25,
	INS = 26,
}

export enum DebtStatusGroup {
	ACTIVE = 'Active',
	ARRANGEMENT = 'Arrangement',
	CLOSED = 'Closed',
	REVIEW = 'Under Review',
}

export const BlockedDebtStatuses = [
	DebtStatusName.UNR,
	DebtStatusName.REC,
	DebtStatusName.RET,
	DebtStatusName.SOLD,
	DebtStatusName.BAR,
	DebtStatusName.BLO,
	DebtStatusName.PIX,
	DebtStatusName.INS,
	DebtStatusName.OUT,
	DebtStatusName.LAP,
	DebtStatusName.PIF,
	DebtStatusName.DS,
];
