import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fcAPI } from '../../../services/api/api';
import { handleAsyncThunkError } from '../../hooks';
import { RootState } from '../../store';
import { setErrorSnackbarOpen } from '../app/appSlice';
import { ClientsAPI, IClientAdd } from './types';

export interface AddClientState {
	activeStep: number;
	loading: boolean;
}

const initialState: AddClientState = {
	activeStep: 0,
	loading: false,
};

export const clientAddNewPost = createAsyncThunk(
	'addClient/request',
	async (input: { debtID: string; file: File }, { rejectWithValue, dispatch }) => {
		try {
			const formData = new FormData();
			formData.append('debtID', input.debtID);
			formData.append('file', input.file);
			const { data } = await fcAPI.post<IClientAdd>(ClientsAPI.ADD, formData, { headers: { 'content-type': 'multipart/form-data' } });
			if (data.success) {
				return data.success;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export const addClientSlice = createSlice({
	name: 'addClient',
	initialState,
	reducers: {
		setAddClientStep: (state, action: PayloadAction<number>) => {
			state.activeStep = action.payload;
		},
		resetAddClient: () => {
			return initialState;
		},
	},
});

export const { setAddClientStep, resetAddClient } = addClientSlice.actions;

export default addClientSlice.reducer;

export const selectAddClientActiveStep = (state: RootState) => state.addClient.activeStep;
