import React from 'react';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';

export const TableEmpty: React.FC<{ text: React.ReactNode; colSpan: number }> = ({ text, colSpan }) => {
	return (
		<TableBody>
			<TableRow>
				<TableCell colSpan={colSpan} sx={{ borderBottom: 'none', py: 8 }}>
					<Typography component="p" variant="subtitle1" width="100%" textAlign="center">
						{text}
					</Typography>
				</TableCell>
			</TableRow>
		</TableBody>
	);
};
