import { PeriodicFrequencyID } from '../enums/arrangement.enum';

const periodicFrequencyToPeriodsAnnum = (periodicFrequency: PeriodicFrequencyID) => {
	switch (periodicFrequency) {
		case PeriodicFrequencyID.WEEKLY:
			return 52;
		case PeriodicFrequencyID.FORTNIGHTLY:
			return 26;
		case PeriodicFrequencyID.MONTHLY:
			return 12;
		default:
			return 1;
	}
};

export const calculatePaymentInYears = (outstanding: number, paymentAmount: number, periodicFrequency: PeriodicFrequencyID) => {
	return outstanding / (paymentAmount * periodicFrequencyToPeriodsAnnum(periodicFrequency));
};
