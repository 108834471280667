import { Box, Card, CardContent, Container, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Page } from '../../components/Page';
import { PerfectScrollbar } from '../../components/PerfectScrollbar';
import { ReportRoutes } from '../../constants/routes.constant';
import { clientArrangementsReportDescription, clientArrangementsReportName } from './reports/Arrangements/ReportArrangements';
import { clientStatusReportDescription, clientStatusReportName } from './reports/ClientStatuses/ReportClientStatuses';
import { clientHardshipsReportDescription, clientHardshipsReportName } from './reports/Hardships/ReportHardships';

type ReportsList = {
	name: string;
	description: string;
	routeLink: string;
};

const reports: ReportsList[] = [
	{
		name: clientStatusReportName,
		description: clientStatusReportDescription,
		routeLink: ReportRoutes.CLIENT_STATUSES,
	},
	{
		name: clientArrangementsReportName,
		description: clientArrangementsReportDescription,
		routeLink: ReportRoutes.PAYMENT_PLANS,
	},
	{
		name: clientHardshipsReportName,
		description: clientHardshipsReportDescription,
		routeLink: ReportRoutes.HARDSHIPS,
	},
];

export const Reports = () => (
	<Page title="Reports">
		<Container maxWidth="xl">
			<Box sx={{ pb: 5 }}>
				<Typography variant="h5">Reports</Typography>
			</Box>
			<Card elevation={0}>
				<CardContent>
					<TableContainer component={PerfectScrollbar}>
						<Table sx={{ minWidth: 600 }}>
							<TableHead>
								<TableRow>
									<TableCell>Report Name</TableCell>
									<TableCell>Description</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{reports.map((report, index) => (
									<TableRow key={report.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell component="th" scope="row">
											{report.name}
										</TableCell>
										<TableCell>{report.description}</TableCell>
										<TableCell>
											<Link component={RouterLink} to={report.routeLink}>
												View Report
											</Link>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>
		</Container>
	</Page>
);
