import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethodID, PeriodicFrequencyID } from '../../../../enums/arrangement.enum';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { setErrorSnackbarOpen } from '../../app/appSlice';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientArrangementAPI, ClientArrangementOutput, IPostClientArrangement } from './type';

export const clientArrangementPost = createAsyncThunk(
	'client/arrangement',
	async (arrangementDetails: ArrangementScheduleDetails & { directDebit: IDirectDebit | undefined }, { rejectWithValue, dispatch, getState }) => {
		const { DebtID, DebtorEntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
		try {
			const postData = {
				debtID: DebtID,
				entityID: DebtorEntityID,
				amount: parseFloat(arrangementDetails.paymentAmount),
				commencementDate: arrangementDetails.paymentDate,
				paymentMethodID: arrangementDetails.paymentMethodID,
				periodicFrequencyID: arrangementDetails.periodicFrequencyID,
				directDebit: arrangementDetails.directDebit,
			};
			const { data } = await fcAPI.post<IPostClientArrangement>(ClientArrangementAPI.ARRANGEMENT, postData);
			if (data.success) {
				return data.data;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export interface IDirectDebit {
	bsb: string;
	accountNumber: string;
	suffix: string;
	accountName: string;
}

export interface ArrangementScheduleDetails {
	paymentAmount: string;
	periodicFrequencyID: PeriodicFrequencyID | undefined;
	paymentDate: string;
	paymentMethodID: PaymentMethodID | undefined;
}

export interface ArrangementPaymentDetails {
	termsAccepted: boolean;
	authorityAccepted: boolean;
	authorityBankAccepted: boolean;
}

export interface ArrangementState extends ArrangementScheduleDetails, ArrangementPaymentDetails {
	activeStep: number;
	status: Status;
	directDebit: IDirectDebit | undefined;
	outcome: ClientArrangementOutput | undefined;
}

const initialState: ArrangementState = {
	activeStep: 0,
	status: Status.IDLE,
	paymentAmount: '',
	periodicFrequencyID: undefined,
	paymentDate: '',
	paymentMethodID: undefined,
	directDebit: undefined,
	termsAccepted: false,
	authorityAccepted: false,
	authorityBankAccepted: false,
	outcome: undefined,
};

export const arrangementSlice = createSlice({
	name: 'clientArrangement',
	initialState,
	reducers: {
		setArrangementActiveStep: (state, action: PayloadAction<number>) => {
			state.activeStep = action.payload;
		},
		setPaymentScheduleDetails: (state, action: PayloadAction<ArrangementScheduleDetails>) => {
			return {
				...state,
				...action.payload,
			};
		},
		setPaymentDetails: (state, action: PayloadAction<ArrangementPaymentDetails & { directDebit: IDirectDebit | undefined }>) => {
			return {
				...state,
				...action.payload,
			};
		},
		resetArrangement: () => {
			return initialState;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientArrangementPost.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientArrangementPost.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.outcome = action.payload;
			})
			.addCase(clientArrangementPost.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export const { setArrangementActiveStep, setPaymentScheduleDetails, setPaymentDetails, resetArrangement } = arrangementSlice.actions;

export default arrangementSlice.reducer;

export const selectClientArrangementActiveStep = (state: RootState) => state.clientArrangement.activeStep;
export const selectClientArrangementStatus = (state: RootState) => state.clientArrangement.status;
export const selectClientArrangementDetails = (state: RootState) => state.clientArrangement;
export const selectClientArrangementOutcome = (state: RootState) => state.clientArrangement.outcome;
