import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ReportRoutes } from '../../../constants/routes.constant';

export type BreadCrumbsProps = {
	childCrumb: string;
};

export const ReportBreadCrumbs: React.FC<BreadCrumbsProps> = ({ childCrumb }) => {
	const breadcrumbs = [
		<Link key={1} component={RouterLink} to={ReportRoutes.REPORT_LIST} underline="hover" color="inherit">
			Reports
		</Link>,
		<Typography key={2} color="text.primary" variant="subtitle1">
			{childCrumb}
		</Typography>,
	];

	return (
		<Stack spacing={2} mb={3}>
			<Breadcrumbs separator="&#8250;" aria-label="breadcrumb">
				{breadcrumbs}
			</Breadcrumbs>
		</Stack>
	);
};
