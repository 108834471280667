import { Box, Card, CardContent, Container, Grid, Link, styled, Typography } from '@mui/material';

const StyledList = styled('ul')(() => ({
	listStyleType: 'none',
	marginBottom: 0,

	'& li': {
		'& > span': {
			display: 'inline-block',
			width: 45,
		},
		marginBottom: '0.35em',
	},
}));

export const ContactDetails = () => (
	<Container maxWidth="xl">
		<Grid container>
			<Grid item xs={12}>
				<Card elevation={0}>
					<CardContent sx={{ padding: 8, paddingBottom: '64px !important' }}>
						<Box marginBottom={10}>
							<Typography variant="body1">
								Need to call us about your account? Have a complaint or feedback? Get in touch and we'll assist you with your enquiry.
							</Typography>
						</Box>
						<Grid container alignItems="stretch" spacing={10}>
							<Grid item xs={12} sm={12} md={12} lg={8} sx={{ display: 'flex' }}>
								<Grid container spacing={10}>
									<Grid item xs={12} md={6}>
										<Typography variant="h6" sx={{ mb: 1.5 }}>
											Phone
										</Typography>
										<Typography variant="body1" gutterBottom>
											Within Australia
										</Typography>
										<Typography variant="body1" sx={{ mb: 1 }}>
											<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
												{window.config.REACT_APP_CONTACT_NUMBER}
											</Link>
										</Typography>
										<Typography variant="body1" gutterBottom>
											Overseas
										</Typography>
										<Typography variant="body1">
											<Link underline="hover" href="tel: +61735359053">
												+61{window.config.REACT_APP_CONTACT_NUMBER.substring(1)}
											</Link>
										</Typography>
									</Grid>
									<Grid item xs={12} md={6}>
										<Typography variant="h6" sx={{ mb: 1.5 }}>
											Address
										</Typography>
										<Typography variant="body1" gutterBottom>
											555 Coronation Dr,
										</Typography>
										<Typography variant="body1">Toowong QLD 4066</Typography>
									</Grid>
									<Grid item xs={12} md={6}>
										<Typography variant="h6" sx={{ mb: 1.5 }}>
											Email
										</Typography>
										<Typography variant="body1">
											<Link underline="hover" href={window.config.REACT_APP_CONTACT_EMAIL_HREF}>
												{window.config.REACT_APP_CONTACT_EMAIL}
											</Link>
										</Typography>
									</Grid>
									<Grid item xs={12} md={6}>
										<Typography variant="h6" sx={{ mb: 1.5 }}>
											Mailing address
										</Typography>
										<Typography variant="body1" gutterBottom>
											PO BOX 388
										</Typography>
										<Typography variant="body1">Toowong DC QLD 4066</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={4} sx={{ display: 'flex', alignItems: 'center' }}>
								<Grid container spacing={10}>
									<Grid item xs={12}>
										<Typography variant="h6" sx={{ mb: 1.5 }}>
											Working hours
										</Typography>
										<Typography variant="body1" sx={{ mb: 1.5 }}>
											Open hours QLD (AEST)
										</Typography>
										<StyledList>
											<li>
												<span>Mon:</span> 7:30am - 5:00pm
											</li>
											<li>
												<span>Tue:</span> 7:30am - 5:00pm
											</li>
											<li>
												<span>Wed:</span> 7:30am - 5:00pm
											</li>
											<li>
												<span>Thu:</span> 7:30am - 5:00pm
											</li>
											<li>
												<span>Fri:</span> 7:30am - 5:00pm
											</li>
											<li>
												<span>Sat:</span> Closed
											</li>
											<li>
												<span>Sun:</span> Closed
											</li>
										</StyledList>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	</Container>
);
