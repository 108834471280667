import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../enums/status.enum';
import { fcAPI } from '../../../services/api/api';
import { handleAsyncThunkError } from '../../hooks';
import { RootState } from '../../store';
import { setErrorSnackbarOpen } from '../app/appSlice';
import { ClientsAPI, IPendingClients, IPendingClientsOutput } from './types';

export const pendingClientsGet = createAsyncThunk('clients/pending', async (_, { rejectWithValue, dispatch }) => {
	try {
		const { data } = await fcAPI.get<IPendingClients>(ClientsAPI.PENDING);
		if (data.success) {
			return data.data.pending;
		}
		dispatch(setErrorSnackbarOpen({ message: data.message }));
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export interface PendingClientsState {
	clients: IPendingClientsOutput[];
	totalCount: number;
	status: Status;
}

const initialState: PendingClientsState = {
	clients: [],
	totalCount: 0,
	status: Status.IDLE,
};

const pendingClientsSlice = createSlice({
	name: 'pendingClients',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(pendingClientsGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(pendingClientsGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.totalCount = action.payload.length ?? 0;
				state.clients = action.payload;
			})
			.addCase(pendingClientsGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default pendingClientsSlice.reducer;

export const selectPendingClients = (state: RootState) => state.pendingClients.clients;
export const selectPendingClientsStatus = (state: RootState) => state.pendingClients.status;
export const selectPendingClientsCount = (state: RootState) => state.pendingClients.totalCount;
