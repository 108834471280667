import { Check } from '@mui/icons-material';
import { Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper, StepperProps, styled } from '@mui/material';

const Connector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: 'calc(-50% + 16px)',
		right: 'calc(50% + 16px)',
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#784af4',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#784af4',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: '#eaeaf0',
		borderTopWidth: 3,
		borderRadius: 1,
	},
}));

const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

const StepIcon = (props: StepIconProps) => {
	const { active, completed, className } = props;

	return (
		<StepIconRoot ownerState={{ active }} className={className}>
			{completed ? <Check className="completedIcon" /> : <div className="circle" />}
		</StepIconRoot>
	);
};

export const HorizontalStepper: React.FC<{ steps: string[] | number[]; activeStep: number; showLabel?: boolean; sx?: StepperProps['sx'] }> = ({
	steps,
	activeStep,
	showLabel = false,
	sx,
}) => (
	<Stepper alternativeLabel activeStep={activeStep} connector={<Connector />} sx={sx}>
		{steps.map((label) => (
			<Step key={label}>
				<StepLabel StepIconComponent={StepIcon}>{showLabel && label}</StepLabel>
			</Step>
		))}
	</Stepper>
);
