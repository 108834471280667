import { Container, Box } from '@mui/material';
import { Footer } from './components/Footer';
import { Join } from './components/Join';
import { Navbar } from './components/Nav';

import { ContactDetails } from '../../components/ContactDetails';

export const ContactUs = () => {
	return (
		<>
			<Navbar />

			<Box
				sx={{
					backgroundColor: '#ffffff',
					paddingTop: '15vh',
					paddingBottom: '15vh',
				}}
			>
				<Container>
					<ContactDetails />
				</Container>
			</Box>

			<Join />
			<Footer />
		</>
	);
};
