import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { setErrorSnackbarOpen } from '../../app/appSlice';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientDebtorAPI, IGetClientDebtor, IGetDebtorOutput } from './types';

export const clientDebtorGet = createAsyncThunk('client/debtor', async (_, { rejectWithValue, dispatch, getState }) => {
	try {
		const { DebtorEntityID: EntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
		const { data } = await fcAPI.get<IGetClientDebtor>(ClientDebtorAPI.DEBTOR, {
			params: { EntityID },
		});
		if (data.success) {
			return data.data.debtor;
		}
		dispatch(setErrorSnackbarOpen({ message: data.message }));
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export interface DebtorState {
	debtor: IGetDebtorOutput | undefined;
	status: Status;
}

const initialState: DebtorState = {
	debtor: undefined,
	status: Status.IDLE,
};

const clientDebtorSlice = createSlice({
	name: 'clientDebtor',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientDebtorGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientDebtorGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.debtor = action.payload;
			})
			.addCase(clientDebtorGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default clientDebtorSlice.reducer;

export const selectClientDebtor = (state: RootState) => state.clientDebtor.debtor;
export const selectClientDebtorStatus = (state: RootState) => state.clientDebtor.status;
