import { LoadingButton } from '@mui/lab';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Page } from '../../../../components/Page';
import { ReportBreadCrumbs } from '../../components/ReportBreadcrumbs';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { fcAPI } from '../../../../services/api/api';
import { FCResponse } from '../../../../services/api/base.api.response';
import { setErrorSnackbarOpen } from '../../../../store/features/app/appSlice';
import { useAppDispatch } from '../../../../store/hooks';
import axios from 'axios';
import { parseAsync } from 'json2csv';
import saveAs from 'file-saver';
import { IReportDebtStatus } from './types';
import { TableResults } from './TableResults';
import { ChartResults } from './ChartResults';
import { format, parseISO } from 'date-fns';
import { dateFormats } from '../../../../constants/dateFormat.constant';

const DEFAULT_PAGE = 0;
const PAGE_SIZE = 5;

export const clientStatusReportName = 'Client Status Report';
export const clientStatusReportDescription =
	"This report will show active client's debt details including statuses, amounts owed, paid and outstanding.";

export const ReportClientStatuses = () => {
	const dispatch = useAppDispatch();
	const [data, setData] = useState<IReportDebtStatus[] | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [csvLoading, setCsvLoading] = useState<boolean>(false);
	const [page, setPage] = useState(DEFAULT_PAGE);
	const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
	const [count, setCount] = useState(0);

	async function getReportData() {
		const result = await fcAPI.get<FCResponse<IReportDebtStatus[]>>('/api/clients/reports/debt-statuses');
		return result;
	}

	const fetchData = async () => {
		setLoading(true);
		let result = null;
		try {
			result = await getReportData();
			if (result.data && result.data.success) {
				setData(result.data.data);
			} else {
				dispatch(setErrorSnackbarOpen({ message: result.data.message }));
			}
		} catch (err) {
			if (axios.isAxiosError(err)) {
				// These errors are handled in the interceptor for displaying snackbar/modal
				if (err.response?.status !== 503 && err.response?.status !== 401) {
					dispatch(setErrorSnackbarOpen({ message: err.response?.data.message ?? 'An unknown error occurred.' }));
				}
			} else {
				dispatch(setErrorSnackbarOpen({ message: 'An unknown error occurred.' }));
			}
		} finally {
			setLoading(false);
		}
	};

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newPage = 0;
		const newRows = Number(event.target.value);
		setRowsPerPage(newRows);
		setPage(newPage);
	};

	const handleClickDownloadCSV = async (event: React.MouseEvent<HTMLButtonElement>) => {
		setCsvLoading(true);
		try {
			if (data) {
				const formattedData = data.map((d) => ({ ...d, DateAdded: format(parseISO(d.DateAdded), dateFormats.datetimeDB) }));
				const fields = Object.keys(formattedData[0]);
				const opts = { fields };
				const csv = await parseAsync(formattedData, opts);
				const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
				saveAs(blob, 'client-status-report.csv');
			}
		} catch (err) {
			dispatch(setErrorSnackbarOpen({ message: 'Your CSV file could not be downloaded at this time.' }));
		} finally {
			setCsvLoading(false);
		}
	};

	useEffect(() => {
		if (!data) {
			fetchData();
		}
		if (data && data.length > 0) {
			setCount(data.length);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<Page title={clientStatusReportName}>
			<Container maxWidth="xl" sx={{ ml: 0 }}>
				<ReportBreadCrumbs childCrumb={clientStatusReportName} />
				<Grid container spacing={4} alignItems="center" marginBottom={5}>
					<Grid item xs={12} md={9}>
						<Typography variant="body1">{clientStatusReportDescription}</Typography>
					</Grid>
					<Grid item xs={12} md={3}>
						<Box display="flex" justifyContent="flex-end">
							<LoadingButton
								color="secondary"
								variant="contained"
								startIcon={<CloudDownloadOutlinedIcon />}
								loading={csvLoading}
								onClick={handleClickDownloadCSV}
								disabled={!data}
							>
								Export to CSV
							</LoadingButton>
						</Box>
					</Grid>
				</Grid>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<ChartResults loading={loading} data={data} />
					</Grid>
					<Grid item xs={12}>
						<TableResults
							data={data}
							count={count}
							page={page}
							rowsPerPage={rowsPerPage}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							loading={loading}
						/>
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
};
