export enum HardshipCategory {
	UNEMPLOYMENT = 1,
	OVER_COMMITTED = 2,
	ILLNESS = 3,
	DISABILITY = 4,
	INJURY = 5,
	DOMESTIC_FAMILY_VIOLENCE = 6,
	COVID = 8,
	FINANCIAL_COUNSELLOR = 9,
	ELDER_ABUSE = 10,
	FINANCIAL_ABUSE = 12,
	OTHER = 7,
}

export enum HardshipCategoryString {
	UNEMPLOYMENT = 'Unemployment',
	OVER_COMMITTED = 'Overcommitted',
	ILLNESS = 'Illness',
	DISABILITY = 'Disability',
	INJURY = 'Injury',
	DOMESTIC_FAMILY_VIOLENCE = 'Domestic/Family violence',
	COVID = 'COVID-19',
	FINANCIAL_COUNSELLOR = 'Financial Counsellor',
	ELDER_ABUSE = 'Elder Abuse',
	FINANCIAL_ABUSE = 'Financial Abuse',
	OTHER = 'Other',
}

export enum HardshipResult {
	MANUAL = 'manual',
	AUTO = 'auto',
}
