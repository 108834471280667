import {
	Card,
	CardContent,
	TableContainer,
	Table,
	TableCell,
	TableHead,
	TableRow,
	TableBody,
	TablePagination,
	CardHeader,
	Palette,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Label } from '../../../../components/Label';
import { PerfectScrollbar } from '../../../../components/PerfectScrollbar';
import { TableEmpty } from '../../../../components/TableEmpty';
import { TableLoader } from '../../../../components/TableLoader';
import { dateFormats } from '../../../../constants/dateFormat.constant';
import { IReportHardship } from './types';

type TableResultsProps = {
	data: IReportHardship[] | undefined;
	page: number;
	rowsPerPage: number;
	count: number;
	handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
	handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	loading: boolean;
};

const COL_LENGTH = 10;

export const getStateColor = (state: string): keyof Palette | 'default' => {
	if (['Finalised', 'Accepted'].includes(state)) {
		return 'success';
	} else if (['Pending', 'In Review'].includes(state)) {
		return 'warning';
	}
	return 'default';
};

export const TableResults: React.FC<TableResultsProps> = ({ data, page, rowsPerPage, count, handleChangePage, handleChangeRowsPerPage, loading }) => {
	return (
		<Card elevation={0}>
			<CardHeader title="Details" />
			<CardContent sx={{ pt: 0 }}>
				<TableContainer component={PerfectScrollbar}>
					<Table sx={{ minWidth: 600 }}>
						<TableHead>
							<TableRow>
								<TableCell>Reference</TableCell>
								<TableCell>Customer</TableCell>
								<TableCell>State</TableCell>
								<TableCell>Category</TableCell>
								<TableCell>Resolution</TableCell>
								<TableCell>Assigned Agent</TableCell>
								<TableCell align="right">Approved Date</TableCell>
								<TableCell align="right">Follow Up Date</TableCell>
								<TableCell align="right">End Date</TableCell>
								<TableCell align="right">Added Date</TableCell>
							</TableRow>
						</TableHead>
						{loading && <TableLoader rows={5} colSpan={COL_LENGTH} />}
						{!loading && (data?.length ?? 0) === 0 && <TableEmpty text="No active hardships found." colSpan={COL_LENGTH} />}
						{!loading && (data?.length ?? 0) > 0 && (
							<TableBody>
								{data
									?.slice()
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((hardship, index) => {
										return (
											// eslint-disable-next-line react/no-array-index-key
											<TableRow key={index}>
												<TableCell component="th" scope="row">
													{hardship.Reference}
												</TableCell>
												<TableCell>{hardship.Fullname}</TableCell>
												<TableCell>
													<Label size="small" variant="ghost" color={getStateColor(hardship.State)} sx={{ minWidth: 160 }}>
														{hardship.State}
													</Label>
												</TableCell>
												<TableCell>{hardship.Category}</TableCell>
												<TableCell>{hardship.Resolution}</TableCell>
												<TableCell>{hardship.AssignedAgent ?? '-'}</TableCell>
												<TableCell align="right">
													{hardship.ApprovedDate ? format(parseISO(hardship.ApprovedDate), dateFormats.prettyShort) : '-'}
												</TableCell>
												<TableCell align="right">
													{hardship.FollowupDate ? format(parseISO(hardship.FollowupDate), dateFormats.prettyShort) : '-'}
												</TableCell>
												<TableCell align="right">
													{hardship.EndDate ? format(parseISO(hardship.EndDate), dateFormats.prettyShort) : '-'}
												</TableCell>
												<TableCell align="right">{format(parseISO(hardship.AddedDate), dateFormats.prettyShort)}</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						)}
					</Table>
				</TableContainer>
				{count > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={count}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="Per page:"
						sx={{ mt: 2 }}
					/>
				)}
			</CardContent>
		</Card>
	);
};
