import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { setErrorSnackbarOpen } from '../../app/appSlice';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientLedgerAPI, IGetClientLedger, IGetLedgerOutput } from './types';
import { Buffer } from 'buffer';

export const clientLedgerGet = createAsyncThunk('client/ledger', async (_, { rejectWithValue, dispatch, getState }) => {
	try {
		const { DebtID } = (getState() as RootState).client.viewingClient as ViewingClient;
		const { data } = await fcAPI.get<IGetClientLedger>(ClientLedgerAPI.LEDGER, {
			params: { DebtID },
		});
		if (data.success) {
			return data.data.ledger;
		}
		dispatch(setErrorSnackbarOpen({ message: data.message }));
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

type ClientLogo = {
	LogoUrl: string | undefined;
};

export type LedgerWithLogoUrl = IGetLedgerOutput & ClientLogo;

export interface LedgerState {
	ledger: LedgerWithLogoUrl | undefined;
	status: Status;
}

const initialState: LedgerState = {
	ledger: undefined,
	status: Status.IDLE,
};

const clientLedgerSlice = createSlice({
	name: 'clientLedger',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientLedgerGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientLedgerGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				const ledger = action.payload;
				const LogoUrl = action.payload.LogoBlob
					? `data:image/png;base64,${Buffer.from(action.payload.LogoBlob).toString('base64')}`
					: undefined;
				state.ledger = { ...ledger, LogoUrl };
			})
			.addCase(clientLedgerGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default clientLedgerSlice.reducer;

export const selectClientLedger = (state: RootState) => state.clientLedger.ledger;
export const selectClientLedgerStatus = (state: RootState) => state.clientLedger.status;
