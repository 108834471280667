import React from 'react';

export const ChampionsCupIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M38.905 119.766c0-12.196 9.898-22.094 22.095-22.094s22.094 9.898 22.094 22.094H38.905ZM30.193 4.55c.412 16.988-.46 40.196.412 57.281 1.742 8.978 7.236 17.352 15.633 21.708.58.29 1.452.29 2.033.87.29.291.871.291 1.161.291.29.29.581.29.872.581 4.622 1.742 9.825 2.323 14.762 1.742 7.526-1.161 13.89-4.331 19.093-10.115 4.042-4.913 7.236-10.697 7.236-17.061.581-16.48.29-38.841.412-55.249"
				fill="#FDDF7F"
			/>
			<path
				d="M94.445 120.976H83.143c-.677 0-1.21-.533-1.21-1.21 0-.678.532-1.21 1.21-1.21h11.302c.677 0 1.21.532 1.21 1.21 0 .677-.533 1.21-1.21 1.21ZM108.578 120.976h-4.598c-.678 0-1.21-.533-1.21-1.21 0-.678.532-1.21 1.21-1.21h4.598c.677 0 1.21.532 1.21 1.21 0 .677-.533 1.21-1.21 1.21ZM38.857 120.976H27.556c-.678 0-1.21-.533-1.21-1.21 0-.678.532-1.21 1.21-1.21h11.301c.678 0 1.21.532 1.21 1.21 0 .677-.556 1.21-1.21 1.21ZM17.997 120.976h-4.598c-.678 0-1.21-.533-1.21-1.21 0-.678.532-1.21 1.21-1.21h4.598c.677 0 1.21.532 1.21 1.21 0 .677-.533 1.21-1.21 1.21ZM61 88.33c-17.641 0-32.016-14.35-32.016-32.016V4.913a2.479 2.479 0 0 0-2.469-2.469 2.479 2.479 0 0 0-2.468 2.469c0 .677-.532 1.21-1.21 1.21-.678 0-1.21-.533-1.21-1.21A4.89 4.89 0 0 1 26.515.024a4.89 4.89 0 0 1 4.889 4.889v51.401c0 16.31 13.261 29.596 29.596 29.596S90.597 72.65 90.597 56.314V4.913A4.89 4.89 0 0 1 95.485.024a4.89 4.89 0 0 1 4.889 4.889c0 .677-.533 1.21-1.21 1.21-.678 0-1.21-.533-1.21-1.21a2.479 2.479 0 0 0-2.469-2.469 2.479 2.479 0 0 0-2.468 2.469V56.314C93.017 73.98 78.642 88.33 61 88.33Z"
				fill="#1C1D21"
			/>
			<path
				d="M83.094 120.977H38.905c-.677 0-1.21-.533-1.21-1.21 0-12.851 10.455-23.305 23.305-23.305s23.305 10.454 23.305 23.305c0 .677-.557 1.21-1.21 1.21Zm-42.93-2.42H81.86c-.629-10.963-9.728-19.675-20.836-19.675-11.108 0-20.231 8.736-20.86 19.675Z"
				fill="#1C1D21"
			/>
			<path
				d="M61 98.906c-.678 0-1.21-.533-1.21-1.21V87.12c0-.677.532-1.21 1.21-1.21.678 0 1.21.533 1.21 1.21v10.576c0 .677-.532 1.21-1.21 1.21Z"
				fill="#1C1D21"
			/>
			<path
				d="M22.619 59.218c-11.52 0-20.909-9.39-20.909-20.909C1.71 26.79 11.1 17.4 22.619 17.4h7.574v8.543H22.62c-6.825 0-12.366 5.542-12.366 12.366 0 6.825 5.541 12.366 12.366 12.366h7.574v8.543H22.62Z"
				fill="#FDDF7F"
			/>
			<path
				d="M30.193 60.428H22.62C10.422 60.428.5 50.506.5 38.309S10.422 16.19 22.619 16.19h7.574c.678 0 1.21.533 1.21 1.21v8.543c0 .678-.532 1.21-1.21 1.21H22.62c-6.147 0-11.156 5.01-11.156 11.156 0 6.147 5.009 11.156 11.156 11.156h7.574c.678 0 1.21.533 1.21 1.21v8.543c0 .653-.532 1.21-1.21 1.21ZM22.62 18.586c-10.866 0-19.699 8.833-19.699 19.699 0 10.866 8.833 19.699 19.699 19.699h6.364V51.86H22.62c-7.478 0-13.576-6.098-13.576-13.576 0-7.478 6.098-13.576 13.576-13.576h6.364v-6.123H22.62Z"
				fill="#1C1D21"
			/>
			<path
				d="M99.381 59.218c11.519 0 20.909-9.39 20.909-20.909 0-11.519-9.39-20.909-20.909-20.909h-7.574v8.543h7.574c6.825 0 12.366 5.542 12.366 12.366 0 6.825-5.541 12.366-12.366 12.366h-7.574v8.543h7.574Z"
				fill="#FDDF7F"
			/>
			<path
				d="M99.381 60.428h-7.574c-.678 0-1.21-.533-1.21-1.21v-8.543c0-.677.532-1.21 1.21-1.21h7.574c6.147 0 11.156-5.01 11.156-11.156 0-6.147-5.009-11.156-11.156-11.156h-7.574c-.678 0-1.21-.533-1.21-1.21v-8.567c0-.678.532-1.21 1.21-1.21h7.574c12.197 0 22.119 9.922 22.119 22.119 0 12.22-9.922 22.143-22.119 22.143Zm-6.364-2.42h6.364c10.866 0 19.699-8.833 19.699-19.699 0-10.866-8.833-19.699-19.699-19.699h-6.364v6.123h6.364c7.478 0 13.576 6.098 13.576 13.576 0 7.478-6.098 13.576-13.576 13.576h-6.364v6.123ZM40.454 5.76h-2.686c-.678 0-1.21-.533-1.21-1.21 0-.678.532-1.21 1.21-1.21h2.71c.678 0 1.21.532 1.21 1.21-.024.653-.556 1.21-1.234 1.21ZM56.669 5.76h-9.826c-.677 0-1.21-.533-1.21-1.21 0-.678.533-1.21 1.21-1.21h9.825c.678 0 1.21.532 1.21 1.21a1.23 1.23 0 0 1-1.21 1.21ZM91.807 5.76H64.606c-.678 0-1.21-.533-1.21-1.21 0-.678.532-1.21 1.21-1.21h27.177c.677 0 1.21.532 1.21 1.21.024.653-.533 1.21-1.186 1.21Z"
				fill="#1C1D21"
			/>
			<path
				d="m75.568 39.543-7.986-1.162a1.848 1.848 0 0 1-1.38-.992l-3.557-7.236c-.677-1.355-2.613-1.355-3.29 0l-3.558 7.236c-.266.532-.775.92-1.38.992l-7.986 1.162c-1.5.218-2.105 2.057-1.016 3.121l5.784 5.64c.435.41.629 1.04.532 1.62l-1.355 7.938c-.266 1.5 1.307 2.638 2.662 1.936l7.139-3.75a1.803 1.803 0 0 1 1.718 0l7.14 3.75c1.354.702 2.927-.436 2.661-1.936l-1.355-7.938a1.82 1.82 0 0 1 .532-1.62l5.784-5.64c1.017-1.064.436-2.904-1.089-3.121Z"
				fill="#22D07D"
			/>
			<path
				d="M52.143 61.226a3.041 3.041 0 0 1-3.001-3.558l1.355-7.937a.674.674 0 0 0-.17-.557l-5.783-5.639a3.005 3.005 0 0 1-.774-3.121 3.042 3.042 0 0 1 2.468-2.081l7.986-1.162a.612.612 0 0 0 .46-.339l3.581-7.236A3.038 3.038 0 0 1 61 27.902c1.162 0 2.202.654 2.735 1.694l3.557 7.236a.597.597 0 0 0 .46.339l7.986 1.162a3.042 3.042 0 0 1 2.468 2.08 3.005 3.005 0 0 1-.774 3.123l-5.784 5.638a.585.585 0 0 0-.17.557l1.356 7.937c.193 1.162-.266 2.3-1.21 2.977a3.088 3.088 0 0 1-3.219.242l-7.139-3.751a.617.617 0 0 0-.58 0l-7.14 3.75c-.435.243-.92.34-1.403.34ZM61 30.322c-.121 0-.411.024-.557.34l-3.581 7.235a3.056 3.056 0 0 1-2.3 1.67l-7.985 1.161c-.339.049-.46.315-.508.436-.025.12-.097.387.169.63l5.784 5.638a3.022 3.022 0 0 1 .871 2.686l-1.355 7.938a.624.624 0 0 0 .242.605.58.58 0 0 0 .653.048l7.14-3.751a3.078 3.078 0 0 1 2.83 0l7.14 3.75a.58.58 0 0 0 .653-.047c.097-.073.315-.267.242-.605l-1.355-7.938c-.17-.992.17-1.985.871-2.686l5.784-5.639a.654.654 0 0 0 .17-.63c-.025-.12-.17-.362-.509-.435l-7.986-1.161a3.098 3.098 0 0 1-2.299-1.67l-3.557-7.236a.625.625 0 0 0-.557-.339Z"
				fill="#1C1D21"
			/>
		</svg>
	);
};
