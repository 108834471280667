import { Dialog, DialogContent, DialogProps, IconButton, useMediaQuery } from '@mui/material';
import { theme } from '../theme';
import CloseIcon from '@mui/icons-material/Close';

type FullScreenModalProps = {
	showCloseButton?: boolean;
	preventBackdropClose?: boolean;
	onClose: () => void;
};

export const FullScreenModal: React.FC<FullScreenModalProps & Omit<DialogProps, 'onClose'>> = ({
	fullScreen,
	onClose,
	open,
	children,
	showCloseButton = true,
	preventBackdropClose = false,
	...props
}) => {
	const fullScreenMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullScreen={fullScreenMobile}
			{...props}
			{...(preventBackdropClose && { onClick: (e) => e.stopPropagation() })}
		>
			{showCloseButton && (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			)}
			<DialogContent sx={{ py: 6 }}>{children}</DialogContent>
		</Dialog>
	);
};
