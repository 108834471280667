import InfoIcon from '@mui/icons-material/Info';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';

type TooltipProps = {
	isLinked: boolean;
	isFcManaged: boolean;
};

export const DebtToManyDebtorsTooltip: React.FC<TooltipProps> = ({ isLinked, isFcManaged }) => {
	let tooltipText = '';
	if (isLinked && isFcManaged) {
		tooltipText = 'There is another customer on this account who you are managing.';
	} else if (isLinked && !isFcManaged) {
		tooltipText = 'There is another customer on this account who you are not managing.';
	}

	return (
		<Tooltip title={<Typography variant="caption">{tooltipText}</Typography>} arrow>
			<InfoIcon color="secondary" fontSize="small" sx={{ verticalAlign: 'top' }} />
		</Tooltip>
	);
};
