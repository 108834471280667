import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Page } from '../../components/Page';
import { REGEX_PASSWORD_NUMBER, REGEX_PASSWORD_SPECIAL, REGEX_PASSWORD_UPPER } from '../../constants/regex.constant';
import { AuthRoutes, PortalRoutes } from '../../constants/routes.constant';
import { passwordResetPost } from '../../store/features/auth/authSlice';
import { useAppDispatch } from '../../store/hooks';
import { AuthErrors } from './AuthError';
import { AuthWrapper } from './components/AuthWrapper';
import { PasswordRequirements } from './components/PasswordRequirements';

interface ConfirmDetails {
	email: string;
	password: string;
	confirmPassword: string;
}

export const ResetPassword = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const code = searchParams.get('id');
	const email = searchParams.get('email');

	const [length, setLength] = useState(false);
	const [isUppercase, setUppercase] = useState(false);
	const [isNumber, setNumber] = useState(false);
	const [isSpecial, setSpecial] = useState(false);

	useEffect(() => {
		if (!code || !email) {
			return navigate(AuthRoutes.ERROR, { state: { errorType: AuthErrors.INVALID_PARAMS } });
		}
	}, [code, email, navigate]);

	const initialValues: ConfirmDetails = {
		email: email ?? '',
		password: '',
		confirmPassword: '',
	};

	const confirmDetailsSchema = Yup.object().shape(
		{
			password: Yup.string()
				.when('password', (password, field) => {
					if (password == null) {
						return field.required('Password is required');
					}
				})
				.when('password', (password, field) => {
					if (!password || password?.length < 12) {
						setLength(false);
						return field.min(12, 'Password must be 12 characters or more');
					} else if (password && password?.length > 50) {
						setLength(false);
						return field.max(50, 'Password must not be greater than 50 characters');
					} else {
						setLength(true);
					}
				})
				.when('password', (password, field) => {
					if (!REGEX_PASSWORD_UPPER.test(password)) {
						setUppercase(false);
						return field.matches(REGEX_PASSWORD_UPPER, 'Password must contain uppercase character');
					} else {
						setUppercase(true);
					}
				})
				.when('password', (password, field) => {
					if (!REGEX_PASSWORD_NUMBER.test(password)) {
						setNumber(false);
						return field.matches(REGEX_PASSWORD_NUMBER, 'Password must contain a number');
					} else {
						setNumber(true);
					}
				})
				.when('password', (password, field) => {
					if (!REGEX_PASSWORD_SPECIAL.test(password)) {
						setSpecial(false);
						return field.matches(REGEX_PASSWORD_SPECIAL, 'Password must contain a special character');
					} else {
						setSpecial(true);
					}
				}),
			confirmPassword: Yup.string()
				.required('Confirm password is required')
				.oneOf([Yup.ref('password'), null], 'Passwords must match'),
		},
		[['password', 'password']], // yup cyclic dependency
	);

	const resetSubmit = async (values: ConfirmDetails) => {
		try {
			const resetSuccess = await dispatch(passwordResetPost({ email: values.email, password: values.password, code: code ?? '' })).unwrap();
			if (resetSuccess) {
				navigate(PortalRoutes.MY_CLIENTS);
			}
		} catch {
			/* Handled in Thunk */
		}
	};

	return (
		<Page title="Reset Password">
			<AuthWrapper
				helperLink={
					<Typography variant="subtitle1" component="p">
						Already a member? &nbsp;
						<Link underline="hover" component={RouterLink} to={AuthRoutes.LOGIN}>
							Login
						</Link>
					</Typography>
				}
			>
				<Typography variant="h4" textAlign="center" sx={{ mb: 8 }}>
					Reset Password
				</Typography>
				<Typography variant="body1" sx={{ mb: 2 }}>
					Type and confirm a secure new password for the account.
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={confirmDetailsSchema}
					onSubmit={resetSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
						<Form>
							<TextField
								fullWidth
								label="Email Address"
								margin="normal"
								name="email"
								type="hidden"
								value={values.email}
								variant="outlined"
								sx={{ display: 'none' }}
								required={false}
							/>
							<TextField
								error={Boolean(touched.password && errors.password)}
								fullWidth
								helperText={touched.password && errors.password}
								label="Password"
								margin="normal"
								name="password"
								onBlur={handleBlur}
								onChange={handleChange}
								type={showPassword ? 'text' : 'password'}
								value={values.password}
								variant="outlined"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword(!showPassword)}
												onMouseDown={(event) => event.preventDefault()}
												edge="end"
											>
												{showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<PasswordRequirements length={length} number={isNumber} uppercase={isUppercase} special={isSpecial} />
							<TextField
								error={Boolean(touched.confirmPassword && errors.confirmPassword)}
								fullWidth
								helperText={touched.confirmPassword && errors.confirmPassword}
								label="Confirm Password"
								margin="normal"
								name="confirmPassword"
								onBlur={handleBlur}
								onChange={handleChange}
								type={showConfirmPassword ? 'text' : 'password'}
								value={values.confirmPassword}
								variant="outlined"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowConfirmPassword(!showConfirmPassword)}
												onMouseDown={(event) => event.preventDefault()}
												edge="end"
											>
												{showConfirmPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<Stack spacing={4} direction="row" sx={{ mt: 4 }} justifyContent="center">
								<LoadingButton loading={isSubmitting} size="large" color="secondary" type="submit" variant="contained">
									Login with new password
								</LoadingButton>
							</Stack>
						</Form>
					)}
				</Formik>
			</AuthWrapper>
		</Page>
	);
};
