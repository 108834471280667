import React from 'react';

export const PageIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M80.891 54.795 84.5 44.457V0H.3v110h84.2V61.376L75.31 87.685c-.017.05-1.781 4.999-5.543 8.325l-.474.45-.095-.672c-.865-4.914.826-9.873.849-9.938L80.89 54.795ZM67.745 96.018l.488 3.476 2.53-2.399c4.037-3.57 5.862-8.708 5.938-8.924l6.327-18.116v38.473H1.772V1.472h81.256v42.735l-14.37 41.158c-.076.217-1.844 5.37-.913 10.653Z"
				fill="#4A555F"
			/>
			<path
				d="M81.31 43.915V3.189H3.49v103.62h77.82V80.18l-2.988 8.557c-.084.231-2.041 5.751-6.389 9.618l-4.936 4.676-.947-6.732c-.996-5.732.903-11.266.987-11.502L81.31 43.916ZM65.957 20.426H18.838v-9.293h47.12v9.293Z"
				fill="#DFDFDF"
			/>
			<path
				d="M18.105 10.398V21.16h48.59V10.398h-48.59Zm47.118 9.291H19.576v-7.82h45.647v7.82ZM70.193 32.357h-54.51v1.472h54.51v-1.472ZM70.193 42.174h-54.51v1.472h54.51v-1.472ZM70.193 51.99h-54.51v1.473h54.51v-1.472ZM70.193 61.808h-54.51v1.472h54.51v-1.472ZM60.41 71.625H24.39v1.472H60.41v-1.472ZM66.13 97.583H49.108v1.472H66.13v-1.472ZM34.864 97.583H17.841v1.472h17.023v-1.472ZM79.742 53.617 68.415 86.06l8.044 2.808 11.327-32.443-8.044-2.808Zm-4.188 33.376-5.264-1.838 10.357-29.663 5.264 1.838-10.357 29.663Z"
				fill="#4A555F"
			/>
			<path d="m81.698 57.678-9.223 26.42 2.021.706 9.224-26.42-2.022-.706Z" fill="#F58E6F" />
			<path
				d="m85.707 36.53-6.45 18.477 8.044 2.808 6.45-18.476-8.044-2.808Zm.689 19.41-5.265-1.838 5.48-15.696 5.265 1.838-5.48 15.696Z"
				fill="#4A555F"
			/>
			<path d="m87.665 40.594-4.348 12.453 2.022.706 4.347-12.454-2.021-.705Z" fill="#F58E6F" />
			<path
				d="m86.606 33.957-1.14 3.268 1.389.486.656-1.879 8.314 2.903-8.267 23.678 1.39.486L97.7 37.83l-11.094-3.873ZM68.9 84.67l-.243.695c-.075.216-1.843 5.37-.907 10.68l.076.425 2.615.913.322-.286c4.038-3.572 5.863-8.707 5.938-8.924l.243-.695L68.9 84.67Zm1.204 11.037-.972-.34c-.543-3.688.287-7.322.702-8.812l5.206 1.818c-.6 1.423-2.209 4.78-4.936 7.334Z"
				fill="#4A555F"
			/>
			<path d="M71.07 88.805a21.693 21.693 0 0 0-.399 3.715 21.745 21.745 0 0 0 2.001-3.155l-1.602-.56Z" fill="#F58E6F" />
			<path d="m67.578 94.824.658 4.67 3.422-3.245-4.08-1.425Z" fill="#4A555F" />
			<path d="M63.504 13.587H21.292v4.386h42.212v-4.386Z" fill="#FFD452" />
		</svg>
	);
};
