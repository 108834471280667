export enum ReCaptchaAction {
	LOGIN = 'login',
	SIGN_UP = 'signup',
}

export const loadReCaptchaScript = () => {
	const scriptName = 'grecaptcha-script';
	const existingScript = document.getElementById(scriptName);

	if (!existingScript) {
		const script: HTMLScriptElement = document.createElement('script') as HTMLScriptElement;
		script.type = 'text/javascript';
		script.id = scriptName;
		script.src = `https://www.google.com/recaptcha/api.js?render=${window.config.REACT_APP_RECAPTCHA}`;
		document.body.appendChild(script);
	}
};
