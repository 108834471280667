import { Box, Container, Link, Typography } from '@mui/material';
import { Page } from '../../components/Page';
import { PendingApprovalImg } from '../../static/images/Pending';

export const PendingApproval = () => (
	<Page title="Pending Approval">
		<Box
			component="main"
			sx={{
				alignItems: 'center',
				display: 'flex',
				flexGrow: 1,
				minHeight: '100%',
				width: '100%',
			}}
		>
			<Container maxWidth="md">
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexGrow: 1,
						width: '100%',
						maxWidth: 500,
						marginLeft: 'auto',
						marginRight: 'auto',
						marginBottom: 5,
					}}
				>
					<PendingApprovalImg width="100%" height="100%" />
				</Box>
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Typography align="center" variant="h6">
						Your account is still pending authorisation from one of our financial counsellor account managers, this should be approved
						within 24 hours of signing up. Please call us on{' '}
						<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
							{window.config.REACT_APP_CONTACT_NUMBER}
						</Link>{' '}
						if you would like an update on this.
					</Typography>
				</Box>
			</Container>
		</Box>
	</Page>
);
