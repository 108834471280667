/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fcAPI } from '../../../services/api/api';
import { FCResponse } from '../../../services/api/base.api.response';
import { handleAsyncThunkError } from '../../hooks';
import { RootState } from '../../store';
import { setErrorSnackbarOpen } from '../app/appSlice';
import { setUser } from './authSlice';
import { ISignupConfirm, SignUpAPI } from './types';

export interface RegisterPersonDetails {
	firstName: string;
	surname: string;
	fcaNumber: string;
}

export interface RegisterBusinessDetails {
	abn: string;
	companyName: string;
}

export interface RegisterContactDetails {
	email: string;
	phoneNumber: string;
	termsAccepted: boolean;
}

export interface RegisterState extends RegisterPersonDetails, RegisterBusinessDetails, RegisterContactDetails {
	activeStep: number;
}

const initialState: RegisterState = {
	activeStep: 0,
	firstName: '',
	surname: '',
	fcaNumber: '',
	abn: '',
	companyName: '',
	email: '',
	phoneNumber: '',
	termsAccepted: false,
};

export const registerSlice = createSlice({
	name: 'register',
	initialState,
	reducers: {
		setRegisterActiveStep: (state, action: PayloadAction<number>) => {
			state.activeStep = action.payload;
		},
		setRegisterPersonDetails: (state, action: PayloadAction<RegisterPersonDetails>) => {
			return {
				...state,
				...action.payload,
			};
		},
		setRegisterBusinessDetails: (state, action: PayloadAction<RegisterBusinessDetails>) => {
			return {
				...state,
				...action.payload,
			};
		},
		setRegisterContactDetails: (state, action: PayloadAction<RegisterContactDetails>) => {
			return {
				...state,
				...action.payload,
			};
		},
		resetRegister: () => {
			return initialState;
		},
	},
});

export const { setRegisterActiveStep, setRegisterPersonDetails, setRegisterBusinessDetails, setRegisterContactDetails, resetRegister } =
	registerSlice.actions;

export default registerSlice.reducer;

export const selectRegisterActiveStep = (state: RootState) => state.register.activeStep;

export const selectRegisterDetails = (state: RootState) => state.register;

export const signUpPost = createAsyncThunk(
	'register/signup',
	async (
		registerDetails: RegisterPersonDetails & RegisterBusinessDetails & Omit<RegisterContactDetails, 'termsAccepted'> & { captchaToken: string },
		{ rejectWithValue, dispatch },
	) => {
		try {
			const { data } = await fcAPI.post<FCResponse>(SignUpAPI.SIGN_UP, registerDetails);
			if (data.success) {
				return data.success;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export const signUpConfirmPost = createAsyncThunk(
	'register/confirm',
	async (confirmDetails: { email: string; password: string; code: string }, { rejectWithValue, dispatch }) => {
		try {
			const { data } = await fcAPI.post<ISignupConfirm>(SignUpAPI.CONFIRM, confirmDetails);
			if (data.success && data.data.jwt) {
				dispatch(setUser(data.data.jwt));
				return data.success;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);
