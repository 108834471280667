import {
	Card,
	CardContent,
	CardHeader,
	Palette,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { PerfectScrollbar } from '../../../components/PerfectScrollbar';
import { TableLoader } from '../../../components/TableLoader';
import { dateFormats } from '../../../constants/dateFormat.constant';
import { Status } from '../../../enums/status.enum';
import {
	recentActivityGet,
	selectRecentActivity,
	selectRecentActivityCount,
	selectRecentActivityStatus,
} from '../../../store/features/dashboard/recentActivity';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Label } from '../../../components/Label';
import { ViewDebtLink } from '../../../components/ViewDebtLink';

export const RecentActivity = () => {
	const dispatch = useAppDispatch();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const activities = useAppSelector(selectRecentActivity);
	const activityStatus = useAppSelector(selectRecentActivityStatus);
	const count = useAppSelector(selectRecentActivityCount);

	useEffect(() => {
		if (activityStatus === Status.IDLE) {
			dispatch(recentActivityGet({ pageNumber: page, pageSize: rowsPerPage }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activityStatus, dispatch]);

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
		if (page !== newPage) {
			dispatch(recentActivityGet({ pageNumber: newPage, pageSize: rowsPerPage }));
		}
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newRows = parseInt(event.target.value, 10);
		setRowsPerPage(newRows);
		setPage(0);
		if (rowsPerPage !== newRows) {
			dispatch(recentActivityGet({ pageNumber: 0, pageSize: newRows }));
		}
	};

	const getActivityColor = (type: string): keyof Palette | 'default' => {
		if (type === 'New Client!' || type === 'New Arrangement') {
			return 'success';
		} else if (type === 'Pending Client') {
			return 'warning';
		} else if (type === 'Rejected Client' || type === 'Dishonour') {
			return 'error';
		}
		return 'default';
	};

	const blockedActivityTypes = ['Pending Client', 'Rejected Client'];

	return (
		<Card elevation={0}>
			<CardHeader title="Recent activity" />
			<CardContent sx={{ pt: 0 }}>
				<TableContainer component={PerfectScrollbar}>
					{(activityStatus === Status.SUCCEEDED || activityStatus === Status.FAILED) && count === 0 && (
						<Typography component="p" variant="subtitle1" width="100%" textAlign="center" sx={{ py: 3 }}>
							No recent activity
						</Typography>
					)}
					<Table sx={{ minWidth: 600 }}>
						{activityStatus === Status.LOADING && <TableLoader rows={5} colSpan={4} />}
						{activityStatus === Status.SUCCEEDED && count > 0 && (
							<TableBody>
								{activities.map((row, i) => (
									// eslint-disable-next-line react/no-array-index-key
									<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell component="th" scope="row">
											<Label size="small" variant="ghost" color={getActivityColor(row.ActivityType)} sx={{ minWidth: 120 }}>
												{row.ActivityType}
											</Label>
										</TableCell>
										<TableCell>{row.ActivityNote}</TableCell>
										<TableCell>
											<Typography
												fontSize="inherit"
												sx={{ whiteSpace: 'nowrap', color: 'text.secondary', alignItems: 'center', display: 'flex' }}
											>
												<AccessTimeOutlinedIcon sx={{ mr: 1, fontSize: 16 }} />
												{format(parseISO(row.ActivityDate), dateFormats.prettyShort)}
											</Typography>
										</TableCell>
										<TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
											{!blockedActivityTypes.includes(row.ActivityType) && (
												<ViewDebtLink DebtID={row.DebtID} DebtorEntityID={row.DebtorEntityID} FCAccessID={row.FCAccessID}>
													View profile
												</ViewDebtLink>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						)}
					</Table>
				</TableContainer>
				{count > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={count}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="Per page:"
						sx={{ mt: 2 }}
					/>
				)}
			</CardContent>
		</Card>
	);
};
