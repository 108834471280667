import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isFuture, parseISO } from 'date-fns';
import { HardshipCategory } from '../../../../enums/hardship.enum';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientHardshipAPI, IGetClientHardship, IGetDebtorHardshipOutput } from './types';

export const clientHardshipGet = createAsyncThunk('client/hardship', async (_, { rejectWithValue, dispatch, getState }) => {
	try {
		const { DebtorEntityID: EntityID, DebtID } = (getState() as RootState).client.viewingClient as ViewingClient;
		const { data } = await fcAPI.get<IGetClientHardship>(ClientHardshipAPI.HARDSHIP, {
			params: { EntityID, DebtID },
		});
		if (data.success) {
			return data.data.hardships;
		}
		return undefined;
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export interface HardshipState {
	hardships: IGetDebtorHardshipOutput[];
	hardship: IGetDebtorHardshipOutput | undefined;
	status: Status;
}

const initialState: HardshipState = {
	hardships: [],
	hardship: undefined,
	status: Status.IDLE,
};

const clientHardshipSlice = createSlice({
	name: 'clientHardship',
	initialState,
	reducers: {
		resetHardship: () => {
			return initialState;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientHardshipGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientHardshipGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.hardships = action.payload ?? [];
				if (action.payload && action.payload.length > 0) {
					state.hardship = action.payload.find(
						(x) => (!x.EndDate || isFuture(parseISO(x.EndDate))) && x.HardshipCategoryID !== HardshipCategory.FINANCIAL_COUNSELLOR,
					);
				}
			})
			.addCase(clientHardshipGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default clientHardshipSlice.reducer;

export const { resetHardship } = clientHardshipSlice.actions;

export const selectClientHardship = (state: RootState) => state.clientHardship.hardship;
export const selectClientHardshipList = (state: RootState) => state.clientHardship.hardships;
export const selectClientHardshipStatus = (state: RootState) => state.clientHardship.status;
