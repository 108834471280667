import { Container, Box, Typography, Link } from '@mui/material';
import { Arrow } from '../../../static/images/Arrow';
import { styled } from '@mui/material/styles';
import { AuthRoutes } from '../../../constants/routes.constant';
import { LinkButton } from '../../../components/StyledLinkButton';
import { Bounce } from 'react-awesome-reveal';

export const Join = () => {
	const JoinContainer = styled('div')(({ theme }) => ({
		display: 'flex',
		justifyContent: 'space-around',
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		[theme.breakpoints.up('md')]: {
			width: '55%',
			margin: 'auto',
			flexDirection: 'column',
			textAlign: 'center',
		},
	}));

	const JoinLink = styled(Box)(({ theme }) => ({
		display: 'flex',
		[theme.breakpoints.up('xs')]: {
			justifyContent: 'flex-start',
		},
		[theme.breakpoints.up('md')]: {
			justifyContent: 'center',
		},
	}));

	return (
		<>
			<Box
				sx={{
					backgroundColor: '#34C07D',
					paddingTop: '15vh',
					paddingBottom: '15vh',
				}}
			>
				<Container>
					<JoinContainer>
						<Box>
							<Typography variant="h4" sx={{ mb: 1 }}>
								Are you a financial counsellor?
							</Typography>

							<Typography variant="body1" sx={{ mb: 2 }}>
								If your client has a debt you can apply for access to the portal and manage it on their behalf.
							</Typography>
						</Box>

						<JoinLink>
							<Link sx={{ color: '#000000', mt: '40px', width: '120px', textAlign: 'center' }}>Get Started</Link>
							<Link sx={{ ml: '2vw' }} component={LinkButton} to={AuthRoutes.LOGIN}>
								<Bounce>
									<Arrow />
								</Bounce>
							</Link>
						</JoinLink>
					</JoinContainer>
				</Container>
			</Box>
		</>
	);
};
