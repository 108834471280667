import { Button, Link, Stack, Typography } from '@mui/material';
import { Page } from '../../components/Page';
import { AuthWrapper } from './components/AuthWrapper';
import { Link as RouterLink } from 'react-router-dom';
import { AuthRoutes } from '../../constants/routes.constant';
import { useLocation } from 'react-router-dom';

export enum AuthErrors {
	INVALID_PARAMS,
	FAILED,
}

interface AuthErrorState {
	errorType: AuthErrors;
}

export const AuthError = () => {
	const { state } = useLocation();

	const errorType = (state as AuthErrorState)?.errorType ?? undefined;

	return (
		<Page title="Sign Up Error">
			<AuthWrapper
				helperLink={
					<Typography variant="subtitle1" component="p">
						Already a member? &nbsp;
						<Link underline="hover" component={RouterLink} to={AuthRoutes.LOGIN}>
							Login
						</Link>
					</Typography>
				}
			>
				<Typography variant="h4" textAlign="center" sx={{ mb: 8 }}>
					Error...
				</Typography>
				{errorType === AuthErrors.FAILED && (
					<Typography variant="body1" textAlign="left" width="100%">
						We were unable to register your account. Your email may already be registered with us. Please give us a call{' '}
						<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
							{window.config.REACT_APP_CONTACT_NUMBER}
						</Link>{' '}
						for more information.
					</Typography>
				)}
				{errorType === AuthErrors.INVALID_PARAMS && (
					<Typography variant="body1" textAlign="left" width="100%">
						The link you clicked is not valid. Please try copying and pasting the link into the browser or please give us a call{' '}
						<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
							{window.config.REACT_APP_CONTACT_NUMBER}
						</Link>{' '}
						for more information.
					</Typography>
				)}
				<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
					<Button size="large" color="secondary" type="submit" variant="contained" component={RouterLink} to={AuthRoutes.SIGN_UP}>
						Try to sign up again
					</Button>
				</Stack>
			</AuthWrapper>
		</Page>
	);
};
