import React from 'react';

export const UploadIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
	return (
		<svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M37.046 15.889v-.391A12.54 12.54 0 0 0 24.273 3.276 12.797 12.797 0 0 0 12.54 10.73 11.904 11.904 0 0 0 1.956 22.49 11.758 11.758 0 0 0 13.75 34.222h4.583v-2.444H13.75a9.35 9.35 0 0 1-.306-18.651h.82l.28-.77a10.303 10.303 0 0 1 9.778-6.6 10.095 10.095 0 0 1 10.328 9.778 9.49 9.49 0 0 1 0 .977l-.098.88.795.367a7.333 7.333 0 0 1-3.105 14.019h-6.575v2.444h6.575a9.778 9.778 0 0 0 4.804-18.333Z"
				fill="#22D07D"
			/>
			<path
				d="M27.231 26.706a1.222 1.222 0 0 0 .88-2.09l-6.11-6.112-6.112 6.111a1.222 1.222 0 0 0 1.723 1.724l3.166-3.117v14.972a1.222 1.222 0 0 0 2.444 0V23.222l3.141 3.141c.233.225.545.348.868.343Z"
				fill="#22D07D"
			/>
		</svg>
	);
};
