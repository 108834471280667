/* eslint-disable @typescript-eslint/no-explicit-any */

import { useRef } from 'react';

// https://github.com/formium/formik/issues/512#issuecomment-666549238
export const useCacheAsync = (asyncCache: (value: any) => Promise<any>) => {
	const cached = useRef<Record<any, any>>({});

	return async (value: any) => {
		if (cached.current[value]) {
			return cached.current[value];
		} else {
			const response = await asyncCache(value);
			cached.current[value] = response;
			return response;
		}
	};
};
