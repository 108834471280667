import { Alert, Portal, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { clearSnackbar, selectSnackbar } from '../store/features/app/appSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { AuthRoutes } from '../constants/routes.constant';

const DEFAULT_SNACKBAR_DURATION = 6000;
const DEFAULT_SNACKBAR_SEVERITY = 'error';
const DEFAULT_SNACKBAR_ANCHOR_VERTICAL = 'bottom';
const DEFAULT_SNACKBAR_ANCHOR_HORIZONTAL = 'center';

export const GlobalSnackbar = () => {
	const dispatch = useAppDispatch();
	const snackbar = useAppSelector(selectSnackbar);
	const theme = useTheme();
	const { pathname } = useLocation();
	const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
	const signUpScreens = Object.values(AuthRoutes).includes(pathname);

	const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);

	useEffect(() => {
		setSnackbarIsOpen(!!snackbar);
	}, [snackbar]);

	const onClose = () => setSnackbarIsOpen(false);

	return (
		<Portal>
			<Snackbar
				open={snackbarIsOpen}
				autoHideDuration={snackbar?.autoHideDuration ?? DEFAULT_SNACKBAR_DURATION}
				anchorOrigin={{
					vertical: DEFAULT_SNACKBAR_ANCHOR_VERTICAL,
					horizontal: DEFAULT_SNACKBAR_ANCHOR_HORIZONTAL,
				}}
				onClose={onClose}
				sx={{ left: lgUp && signUpScreens ? '75%!important' : undefined }}
				TransitionProps={{ onExited: () => dispatch(clearSnackbar()) }}
			>
				<Alert onClose={onClose} variant="filled" severity={snackbar?.severity ?? DEFAULT_SNACKBAR_SEVERITY}>
					{snackbar?.message}
				</Alert>
			</Snackbar>
		</Portal>
	);
};
