import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import saveAs from 'file-saver';
import { useEffect, useState } from 'react';
import { Page } from '../../components/Page';
import { PerfectScrollbar } from '../../components/PerfectScrollbar';
import { TableLoader } from '../../components/TableLoader';
import { dateFormats } from '../../constants/dateFormat.constant';
import { Status } from '../../enums/status.enum';
import { fcAPI } from '../../services/api/api';
import { setErrorSnackbarOpen } from '../../store/features/app/appSlice';
import { selectViewingClient } from '../../store/features/client/clientSlice';
import {
	getClientTransactions,
	selectClientTransactions,
	selectClientTransactionsCount,
	selectClientTransactionsStatus,
} from '../../store/features/client/transactions/transactionsSlice';
import { ClientTransactionAPI, IClientTransactions } from '../../store/features/client/transactions/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { formatMoney } from '../../utils/text.utill';
import { ClientBreadCrumbs } from './components/ClientBreadCrumbs';

const pageTitle = 'Transactions';

export const ClientTransactions = () => {
	const dispatch = useAppDispatch();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [csvLoading, setCsvLoading] = useState(false);

	const { DebtID: debtID, DebtorEntityID: entityID } = useAppSelector(selectViewingClient);
	const transactions = useAppSelector(selectClientTransactions);
	const activityStatus = useAppSelector(selectClientTransactionsStatus);
	const count = useAppSelector(selectClientTransactionsCount);

	useEffect(() => {
		dispatch(getClientTransactions({ pageNumber: page, pageSize: rowsPerPage }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
		if (page !== newPage) {
			dispatch(getClientTransactions({ pageNumber: newPage, pageSize: rowsPerPage }));
		}
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newRows = parseInt(event.target.value, 10);
		setRowsPerPage(newRows);
		setPage(0);
		if (rowsPerPage !== newRows) {
			dispatch(getClientTransactions({ pageNumber: 0, pageSize: newRows }));
		}
	};

	async function getData() {
		const result = await fcAPI.get<IClientTransactions>(ClientTransactionAPI.TRANSACTIONS, {
			params: {
				debtID,
				entityID,
				pageNumber: 0,
				pageSize: count,
			},
		});
		return result;
	}

	const handleClickDownloadCSV = async (event: React.MouseEvent<HTMLButtonElement>) => {
		setCsvLoading(true);
		try {
			const result = await getData();
			if (result.data && result.data.success) {
				let csv = '"ReceiptNumber","TransactionDate","Amount","TransactionType","PaymentMethod","Response"\n';
				const array = result.data.data.transactions;
				for (let i = 0; i < array.length; i += 1) {
					const trans = array[i];
					csv += `"${trans.TransactionID}","${format(parseISO(trans.TransactionDate), dateFormats.datetime)}","${trans.Amount}","${
						trans.TransactionTypeName
					}","${trans.PaymentMethodName}","${trans.PaymentResponseExplanation || ''}"`;
					csv += '\n';
				}
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
				saveAs(blob, `${debtID}-${format(new Date(), dateFormats.compact)}-payment-history.csv`);
			} else {
				dispatch(setErrorSnackbarOpen({ message: result.data.message || 'An unknown error occurred.' }));
			}
		} catch (err) {
			dispatch(setErrorSnackbarOpen({ message: 'Your CSV file could not be downloaded at this time.' }));
		} finally {
			setCsvLoading(false);
		}
	};

	return (
		<Page title={pageTitle}>
			<Container maxWidth="xl">
				<ClientBreadCrumbs childCrumb={pageTitle} />
				<Box sx={{ pb: 5 }}>
					<Card elevation={0}>
						<CardHeader
							title={pageTitle}
							action={
								<LoadingButton
									color="secondary"
									variant="contained"
									startIcon={<FileDownloadIcon />}
									onClick={handleClickDownloadCSV}
									loading={csvLoading}
									disabled={count < 1}
									sx={{ boxShadow: 1 }}
								>
									EXPORT TO CSV
								</LoadingButton>
							}
						/>
						<CardContent sx={{ pt: 0 }}>
							<TableContainer component={PerfectScrollbar}>
								{(activityStatus === Status.SUCCEEDED || activityStatus === Status.FAILED) && count === 0 && (
									<Typography component="p" variant="subtitle1" width="100%" textAlign="center" sx={{ py: 3 }}>
										No transactions found.
									</Typography>
								)}
								<Table sx={{ minWidth: 600 }}>
									{activityStatus === Status.LOADING && <TableLoader rows={5} colSpan={4} />}
									{activityStatus === Status.SUCCEEDED && count > 0 && (
										<>
											<TableHead>
												<TableRow>
													<TableCell>
														<strong>Amount</strong>
													</TableCell>
													<TableCell>
														<strong>Date</strong>
													</TableCell>
													<TableCell>
														<strong>Transaction Type</strong>
													</TableCell>
													<TableCell>
														<strong>Payment Method</strong>
													</TableCell>
													<TableCell>
														<strong>Response</strong>{' '}
														<Tooltip title={<Typography variant="caption">For failed payments only</Typography>} arrow>
															<InfoIcon color="secondary" fontSize="small" sx={{ verticalAlign: 'top' }} />
														</Tooltip>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{transactions.map((tran, i) => (
													<TableRow key={tran.TransactionID}>
														<TableCell
															sx={{
																color: (theme) =>
																	tran.Amount < 0 ? theme.palette.error.main : theme.palette.success.main,
															}}
														>
															{formatMoney(tran.Amount)}
														</TableCell>
														<TableCell component="th" scope="row">
															{format(parseISO(tran.TransactionDate.toString()), dateFormats.prettyDatetime)}
														</TableCell>
														<TableCell>{tran.TransactionTypeName}</TableCell>
														<TableCell>{tran.PaymentMethodName}</TableCell>
														<TableCell component="th" scope="row">
															{tran.PaymentResponseExplanation ? tran.PaymentResponseExplanation : '-'}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</>
									)}
								</Table>
							</TableContainer>
							{count > 0 && (
								<TablePagination
									rowsPerPageOptions={[10, 25, 50]}
									component="div"
									count={count}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage="Per page:"
									sx={{ mt: 2 }}
								/>
							)}
						</CardContent>
					</Card>
				</Box>
			</Container>
		</Page>
	);
};
