import React from 'react';
import { Helmet } from 'react-helmet-async';

export type PageProps = {
	title: string;
	description?: string;
};

export const Page: React.FC<PageProps> = ({ children, title = '', description }) => {
	return (
		<>
			<Helmet defaultTitle={window.config.REACT_APP_COMPANY}>
				<title>{title}</title>
				{description && <meta name="description" content={description} />}
			</Helmet>
			{children}
		</>
	);
};
