import React, { useEffect } from 'react';
import PerfectScroll from 'perfect-scrollbar';
import { styled } from '@mui/material/styles';

const PerfectScrollBarContainer = styled('div')(() => ({
	position: 'relative',
	height: '100%',
	width: '100%',
	zIndex: 1000,
}));

export const PerfectScrollbar: React.FC = ({ children }) => {
	const scrollRef = React.useRef<PerfectScroll | null>();
	const containerRef = React.useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (containerRef.current) {
			scrollRef.current = new PerfectScroll(containerRef.current);
		}
		return () => {
			scrollRef.current?.destroy();
			scrollRef.current = null;
		};
	});
	return <PerfectScrollBarContainer ref={containerRef}>{children}</PerfectScrollBarContainer>;
};
