import CloseIcon from '@mui/icons-material/Close';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { PerfectScrollbar } from '../../../components/PerfectScrollbar';
import { TableLoader } from '../../../components/TableLoader';
import { dateFormats } from '../../../constants/dateFormat.constant';
import { Status } from '../../../enums/status.enum';
import {
	getPaymentScheduleHistory,
	selectPaymentHistory,
	selectPaymentHistoryStatus,
} from '../../../store/features/client/arrangements/scheduleSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { theme } from '../../../theme';
import { formatMoney } from '../../../utils/text.utill';

type PAHistoryProps = {
	arrangementID: number;
	onClose: () => void;
};

export const PaymentPlanHistoryModal: React.FC<PAHistoryProps> = ({ arrangementID, onClose }) => {
	const dispatch = useAppDispatch();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const schedule = useAppSelector(selectPaymentHistory);
	const scheduleStatus = useAppSelector(selectPaymentHistoryStatus);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [count, setCount] = useState(0);

	useEffect(() => {
		if (scheduleStatus === Status.IDLE) {
			dispatch(getPaymentScheduleHistory({ arrangementIDs: [arrangementID], pageNumber: page, pageSize: rowsPerPage }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (schedule && schedule.length > 0) {
			setCount(schedule[0].TotalRows);
		}
	}, [schedule, dispatch]);

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
		if (page !== newPage) {
			dispatch(getPaymentScheduleHistory({ arrangementIDs: [arrangementID], pageNumber: newPage, pageSize: rowsPerPage }));
		}
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newRows = parseInt(event.target.value, 10);
		setRowsPerPage(newRows);
		setPage(0);
		if (rowsPerPage !== newRows) {
			dispatch(getPaymentScheduleHistory({ arrangementIDs: [arrangementID], pageNumber: 0, pageSize: newRows }));
		}
	};

	return (
		<div>
			<Dialog
				open={true}
				onClose={onClose}
				fullScreen={fullScreen}
				PaperProps={{
					sx: {
						minWidth: {
							lg: '600px',
						},
						padding: {
							lg: 2,
						},
					},
				}}
			>
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogTitle>
					<Typography align="center" variant="h5" component="p">
						Payment Plan History
					</Typography>
					<Typography align="center" variant="body1" component="p">
						{`#${arrangementID}`}
					</Typography>
				</DialogTitle>
				<DialogContent>
					{' '}
					{(scheduleStatus === Status.SUCCEEDED || scheduleStatus === Status.FAILED) && schedule.length < 1 && (
						<Typography align="center" variant="body1" component="p">
							No payment history found for this payment plan.
						</Typography>
					)}
					<TableContainer component={PerfectScrollbar}>
						<Table>
							{scheduleStatus === Status.LOADING && <TableLoader rows={5} colSpan={4} />}
							{scheduleStatus === Status.SUCCEEDED && count > 0 && (
								<>
									<TableHead>
										<TableRow>
											<TableCell>
												<strong>Amount</strong>
											</TableCell>
											<TableCell>
												<strong>Date</strong>
											</TableCell>
											<TableCell>
												<strong>Status</strong>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{schedule.map((item, i) => (
											<TableRow key={item.ScheduleID}>
												<TableCell>{formatMoney(item.Amount)}</TableCell>
												<TableCell component="th" scope="row">
													{format(parseISO(item.Date.toString()), dateFormats.prettyShort)}
												</TableCell>
												<TableCell>{item.ScheduledPaymentStatusDesc}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</>
							)}
						</Table>
					</TableContainer>
					{count > 0 && (
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={count}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage="Per page:"
							sx={{ mt: 2 }}
						/>
					)}
				</DialogContent>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start" mt={4} mb={4}>
					<Grid item xs={8}>
						<Stack spacing={3}>
							<Button color="primary" variant="outlined" size="large" onClick={onClose}>
								Close
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</Dialog>
		</div>
	);
};
