import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, FormControl, Grid, IconButton, ListItemText, MenuItem, Select, Skeleton, Toolbar, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { ClientRoutes, PortalRoutes } from '../../constants/routes.constant';
import { Status } from '../../enums/status.enum';
import { resetViewingClient, setViewingClient } from '../../store/features/client/clientSlice';
import { selectClientDebtor, selectClientDebtorStatus } from '../../store/features/client/debtor/debtorSlice';
import { selectClientDebtorMatch } from '../../store/features/client/debtor/linkedEntitySlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AccountPopover } from './AccountPopover';

type DashboardNavbarProps = {
	onSidebarOpen: () => void;
	isclientview?: boolean;
};

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
	boxShadow: 'none',
	backdropFilter: 'blur(6px)',
	WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
	backgroundColor: alpha(theme.palette.background.default, 0.72),
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		width: `calc(100% - ${theme.widths.sidebar.sm + 1}px)`,
	},
	[theme.breakpoints.up('lg')]: {
		width: `calc(100% - ${theme.widths.sidebar.lg + 1}px)`,
	},
}));

export const DashboardNavbar: React.FC<DashboardNavbarProps> = ({ onSidebarOpen, isclientview }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const debtor = useAppSelector(selectClientDebtor);
	const debtorStatus = useAppSelector(selectClientDebtorStatus);
	const linkedAccounts = useAppSelector(selectClientDebtorMatch);

	const handleSelection = (DebtID: number, DebtorEntityID: number, FCAccessID: number) => {
		dispatch(resetViewingClient());
		dispatch(
			setViewingClient({
				DebtID,
				DebtorEntityID,
				FCAccessID,
			}),
		);
		navigate(ClientRoutes.DASHBOARD);
	};

	return (
		<DashboardNavbarRoot position="fixed">
			<Toolbar
				disableGutters
				sx={{
					left: 0,
					px: {
						xs: 2,
						sm: 3,
						lg: 5,
					},
				}}
			>
				<IconButton
					onClick={onSidebarOpen}
					sx={{
						display: {
							xs: 'inline-flex',
							sm: 'none',
						},
					}}
				>
					<MenuIcon fontSize="medium" />
				</IconButton>
				<Box sx={{ flexGrow: 1, color: (theme) => theme.palette.text.primary }}>
					<Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item>
							<Typography sx={(theme) => ({ [theme.breakpoints.down('sm')]: { fontSize: '1rem' } })} variant="h5">
								{debtorStatus === Status.LOADING ? <Skeleton width={150} /> : debtor?.Fullname}
							</Typography>
						</Grid>
						{linkedAccounts && linkedAccounts.length > 0 && (
							<Grid item>
								<FormControl sx={{ minWidth: 120 }}>
									<Select displayEmpty value="" sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
										<MenuItem disabled style={{ display: 'none' }} value="" autoFocus={false}>
											View other accounts
										</MenuItem>
										{linkedAccounts.map((item, i) => (
											<MenuItem
												key={`${item.DebtID}-${item.DebtorEntityID}`}
												value={item.DebtorEntityID}
												autoFocus={false}
												sx={{ '&.Mui-focusVisible': { backgroundColor: (theme) => theme.palette.common.white } }}
												onClick={() => handleSelection(item.DebtID, item.DebtorEntityID, item.FCAccessID)}
											>
												<ListItemText sx={{ mr: 1 }}>{item.Fullname}</ListItemText>
												<Typography variant="body2" color="text.secondary">
													{item.DebtID}
												</Typography>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						)}
					</Grid>
				</Box>
				<IconButton sx={{ ml: 1 }} component={Link} to={PortalRoutes.DASHBOARD}>
					<HomeOutlinedIcon fontSize="medium" />
				</IconButton>
				<IconButton sx={{ ml: 1 }} component="a" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
					<LocalPhoneOutlinedIcon fontSize="medium" />
				</IconButton>
				<AccountPopover isclientview={isclientview} />
			</Toolbar>
		</DashboardNavbarRoot>
	);
};
