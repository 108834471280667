import { FCResponse } from '../../../../services/api/base.api.response';
import { IActiveClientsOutput } from '../../clients/types';

export enum ClientDebtorAPI {
	DEBTOR = '/api/client/debtor',
	DEBTOR_MATCH = '/api/client/debtor/match',
}

export interface IGetDebtorOutput {
	EntityID: number;
	DOB: string | null;
	LicenceNo: string | null;
	CompanyName: string | null;
	CompanyABN: string | null;
	CompanyACN: string | null;
	FirstName: string | null;
	MiddleNames: string | null;
	Surname: string | null;
	Fullname: string;
}

export type IGetClientDebtor = FCResponse<{ debtor: IGetDebtorOutput }>;

export type IGetClientDebtorMatch = FCResponse<{ matched: IActiveClientsOutput[] }>;
