import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Container,
	Grid,
	Link,
	MenuItem,
	Stack,
	styled,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Link as routerLink } from 'react-router-dom';
import * as Yup from 'yup';
import { FullScreenModal } from '../../components/FullScreenModal';
import { HorizontalStepper } from '../../components/HorizontalStepper';
import { Page } from '../../components/Page';
import {
	checkIfFilesAreCorrectType,
	checkIfFilesAreNamedCorrectly,
	checkIfFilesAreTooBig,
	FileUpload,
	NO_FILES,
	UploadComponent,
} from '../../components/UploadComponent';
import { VerticalStepper } from '../../components/VerticalStepper';
import { ClientRoutes } from '../../constants/routes.constant';
import { HardshipCategory } from '../../enums/hardship.enum';
import { Status } from '../../enums/status.enum';
import { ChampionsCupIcon } from '../../icons/ChampionsIcon';
import { PageIcon } from '../../icons/PageIcon';
import {
	getClientArrangementsActive,
	selectActiveArrangements,
	selectArrangementsStatus,
} from '../../store/features/client/arrangements/arrangementsSlice';
import {
	clientDebtWaiverPost,
	DebtWaiverHardshipDetails,
	resetDebtWaiver,
	selectClientDebtWaiverActiveStep,
	selectClientDebtWaiverDetails,
	selectClientDebtWaiverStatus,
	setDebtWaiverActiveStep,
	setDebtWaiverHardshipDetails,
} from '../../store/features/client/debtWaiver/debtWaiverSlice';
import { clientHardshipGet, selectClientHardship, selectClientHardshipStatus } from '../../store/features/client/hardship/hardshipSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { convertHardshipCategoryToString } from '../../utils/text.utill';
import { ClientBreadCrumbs } from './components/ClientBreadCrumbs';
import { WarningIcon } from '../../icons/WarningIcon';
import { AccountInformationSidebar } from './components/AccountInformationSidebar';
import { selectUserDetails } from '../../store/features/user/userSlice';

const pageTitle = 'Request Debt Waiver';

const steps = ['Start application', 'Reason', 'Documents', 'Summary', 'Outcome'];

const StyledList = styled('ul')(({ theme }) => ({
	li: {
		paddingBottom: theme.spacing(2),
	},
}));

export const ClientDebtWaiver = () => {
	const dispatch = useAppDispatch();
	const activeStep = useAppSelector(selectClientDebtWaiverActiveStep);
	const debtWaiverStatus = useAppSelector(selectClientDebtWaiverStatus);
	const hardshipStatus = useAppSelector(selectClientHardshipStatus);
	const arrangementStatus = useAppSelector(selectArrangementsStatus);
	const hardship = useAppSelector(selectClientHardship);
	const arrangements = useAppSelector(selectActiveArrangements);
	const { hardshipCategoryID, note } = useAppSelector(selectClientDebtWaiverDetails);
	const user = useAppSelector(selectUserDetails);
	const theme = useTheme();
	const displayStepperHorizontal = useMediaQuery(theme.breakpoints.down('md'));

	const [fileWarningModalOpen, SetFileWarningModalOpen] = useState(false);
	const [files, SetFiles] = useState<File[] | null>(null);

	const isLoading = hardshipStatus !== Status.SUCCEEDED && arrangementStatus !== Status.SUCCEEDED;
	const isBlocked = (hardship || arrangements.length > 0) && debtWaiverStatus !== Status.SUCCEEDED;

	useEffect(() => {
		if (hardshipStatus === Status.IDLE) {
			dispatch(clientHardshipGet());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hardshipStatus, dispatch]);

	useEffect(() => {
		if (arrangementStatus === Status.IDLE) {
			dispatch(getClientArrangementsActive());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arrangementStatus, dispatch]);

	// Reset Debtwaiver on Unmount
	useLayoutEffect(() => {
		return () => {
			dispatch(resetDebtWaiver());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const menuItemFor = (category: HardshipCategory): JSX.Element => {
		return <MenuItem value={category.toString()}>{convertHardshipCategoryToString(category)}</MenuItem>;
	};

	const handleContinue = () => {
		dispatch(setDebtWaiverActiveStep(activeStep + 1));
	};

	const handleBack = () => {
		dispatch(setDebtWaiverActiveStep(activeStep - 1));
	};

	const debtWaiverHardshipDetailsSubmit = (values: DebtWaiverHardshipDetails) => {
		dispatch(setDebtWaiverHardshipDetails(values));
		handleContinue();
	};

	const debtWaiverSubmit = async () => {
		try {
			const clientDebtWaiverSuccess = await dispatch(clientDebtWaiverPost({ hardshipCategoryID, note, files })).unwrap();
			if (clientDebtWaiverSuccess) {
				dispatch(clientHardshipGet());
				handleContinue();
			}
		} catch {
			/* Handled in Thunk */
		}
	};

	const debtWaiverDocumentsSubmit = (values: FileUpload) => {
		SetFiles(values.files);
		if (!values.files?.length) {
			SetFileWarningModalOpen(true);
		} else {
			handleContinue();
		}
	};

	const Start = () => {
		return (
			<Stack spacing={3}>
				<Typography variant="h5" gutterBottom>
					Request Debt Waiver
				</Typography>
				<Typography variant="body1">If you believe your client is entitled to a Debt Waiver, please hit begin to get started!</Typography>
				<Typography variant="body1">
					Please note, a Debt Waiver is a 'last resort' option, which will generally only be considered after all available options have
					been tested with the debt holding agency. A decision may be made on the basis of the information you have submitted.
				</Typography>
				<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
					<Button size="large" color="secondary" variant="contained" onClick={handleContinue}>
						Begin
					</Button>
				</Box>
			</Stack>
		);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [activeStep]);

	const Reason = () => {
		const initialValues: DebtWaiverHardshipDetails = {
			hardshipCategoryID,
			note,
		};
		const debtWaiverHardshipDetailsSchema = Yup.object().shape({
			hardshipCategoryID: Yup.string().required('Please select the reason for debt waiver'),
			note: Yup.string().required('Details is required').max(845),
		});
		return (
			<Stack spacing={3}>
				<Box>
					<Typography variant="h5" gutterBottom>
						Request Debt Waiver
					</Typography>
					<Typography variant="subtitle1">Reason</Typography>
				</Box>
				<Typography variant="body1">
					Hi {user?.FirstName}, to figure out how we can provide the best possible support for your clients situation, we need to ask you a
					few things first.
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={debtWaiverHardshipDetailsSchema}
					onSubmit={debtWaiverHardshipDetailsSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, handleChange, touched, values }) => (
						<Form autoComplete="off">
							<Stack spacing={3}>
								<Box>
									<Typography variant="subtitle2" gutterBottom>
										Which option best describes why you are applying for a debt waiver?
									</Typography>
									<TextField
										select
										error={Boolean(touched.hardshipCategoryID && errors.hardshipCategoryID)}
										fullWidth
										helperText={touched.hardshipCategoryID && errors.hardshipCategoryID}
										value={values.hardshipCategoryID}
										name="hardshipCategoryID"
										onBlur={handleBlur}
										onChange={handleChange}
										SelectProps={{
											displayEmpty: true,
										}}
									>
										<MenuItem disabled style={{ display: 'none' }} value="">
											Please select one
										</MenuItem>
										{menuItemFor(HardshipCategory.UNEMPLOYMENT)}
										{menuItemFor(HardshipCategory.OVER_COMMITTED)}
										{menuItemFor(HardshipCategory.ILLNESS)}
										{menuItemFor(HardshipCategory.DISABILITY)}
										{menuItemFor(HardshipCategory.INJURY)}
										{menuItemFor(HardshipCategory.DOMESTIC_FAMILY_VIOLENCE)}
										{menuItemFor(HardshipCategory.COVID)}
										{menuItemFor(HardshipCategory.ELDER_ABUSE)}
										{menuItemFor(HardshipCategory.FINANCIAL_ABUSE)}
										{menuItemFor(HardshipCategory.OTHER)}
									</TextField>
								</Box>
								<Box>
									<Typography variant="subtitle2" gutterBottom>
										Please briefly describe the reason for submitting a Debt Waiver Request.
									</Typography>
									<TextField
										error={Boolean(touched.note && errors.note)}
										fullWidth
										helperText={touched.note && errors.note}
										name="note"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.note}
										variant="outlined"
										multiline
										rows={5}
										placeholder="Type in your message in here"
									/>
								</Box>
							</Stack>
							<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
								<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack}>
									Back
								</Button>
								<Button size="large" color="secondary" type="submit" variant="contained" endIcon={<ArrowForwardOutlinedIcon />}>
									Continue
								</Button>
							</Stack>
						</Form>
					)}
				</Formik>
			</Stack>
		);
	};

	const Documents = () => {
		const initialValues: FileUpload = {
			files,
		};
		const debtWaiverHardshipDetailsSchema = Yup.object().shape({
			files: Yup.array()
				.nullable()
				.max(
					NO_FILES,
					`Only a maximum of ${NO_FILES} files allowed, please email ${window.config.REACT_APP_CONTACT_EMAIL} any additional documents`,
				)
				.test(
					'fileSize',
					`Individual file size or total is too large, please email ${window.config.REACT_APP_CONTACT_EMAIL} documents`,
					(value) => checkIfFilesAreTooBig(value as File[]),
				)
				.test('fileType', `Unsupported file types, please email ${window.config.REACT_APP_CONTACT_EMAIL} documents`, (value) =>
					checkIfFilesAreCorrectType(value as File[]),
				)
				.test(
					'fileNames',
					`Unsupported file names, file name should only contain alpha numeric, space, underscore, hyphen, period and parentheses characters`,
					(value) => checkIfFilesAreNamedCorrectly(value as File[]),
				),
		});

		return (
			<Stack spacing={3}>
				<Box>
					<Typography variant="h5" gutterBottom>
						Request Debt Waiver
					</Typography>
					<Typography variant="subtitle1">Documents</Typography>
				</Box>
				<Typography variant="body1">Please upload any supporting documentation relevant to your request below.</Typography>
				<Typography variant="body1">
					Note: A decision may be made based on the documents submitted below. Please ensure you provide all relevant documentation
					necessary in substantiating your request.
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={debtWaiverHardshipDetailsSchema}
					onSubmit={debtWaiverDocumentsSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, setFieldValue, touched, values }) => (
						<Form autoComplete="off">
							<UploadComponent
								setFieldValue={setFieldValue}
								name="files"
								value={values.files}
								error={Boolean(touched.files && errors.files)}
								helperText={touched.files && errors.files}
							/>
							<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
								<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack}>
									Back
								</Button>
								<Button size="large" color="secondary" type="submit" variant="contained">
									Continue
								</Button>
							</Stack>
						</Form>
					)}
				</Formik>
			</Stack>
		);
	};

	const Summary = () => {
		return (
			<Stack spacing={3} width="100%">
				<Box>
					<Typography variant="h5" gutterBottom>
						Request Debt Waiver
					</Typography>
					<Typography variant="subtitle1">Summary</Typography>
				</Box>
				<StyledList>
					<li>
						You are submitting a Debt Waiver Request due to:{' '}
						<strong>{convertHardshipCategoryToString(Number(hardshipCategoryID))}</strong>
					</li>
					<li>
						You have provided the following additional text to support your request:
						<br />
						<br />
						<span style={{ marginLeft: 16 }}>{note}</span>
						<br />
						<br />
					</li>
					<li>
						You have chosen to provide the following documents:{' '}
						{(files?.length ?? 0) === 0 && (
							<Typography variant="subtitle1">
								<em>No Documents</em>
							</Typography>
						)}
						{files?.map((file) => (
							<Typography key={file.name} variant="subtitle1">
								&#8226; {file.name}
							</Typography>
						))}
					</li>
				</StyledList>
				<Typography variant="body1">
					<strong>Please click Finish to submit your request through to our Customer Care team.</strong> Alternatively, click back to edit
					your responses.
				</Typography>
				<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
					<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack}>
						Back
					</Button>
					<LoadingButton
						loading={debtWaiverStatus === Status.LOADING}
						size="large"
						color="secondary"
						variant="contained"
						onClick={debtWaiverSubmit}
					>
						Finish
					</LoadingButton>
				</Stack>
			</Stack>
		);
	};

	const Outcome = () => {
		return (
			<Stack spacing={3}>
				<Typography align="center" variant="h5" component="p">
					Thank you!
				</Typography>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start">
					<ChampionsCupIcon height={121} width={121} />
				</Grid>
				<Typography variant="body1">Thank you for submitting a Debt Waiver Request!</Typography>
				<Typography variant="body1">
					Please be advised, our hardship team will review and advise of a resolution or update within 21 days. In the meantime, feel free
					to contact one of our friendly staff on{' '}
					<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF} noWrap>
						{window.config.REACT_APP_CONTACT_NUMBER}
					</Link>{' '}
					for assistance.
				</Typography>
				<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
					<Button size="large" color="secondary" variant="contained" component={routerLink} to={ClientRoutes.DASHBOARD}>
						Back to Dashboard
					</Button>
				</Box>
			</Stack>
		);
	};

	const renderStepContent = (step: number) => {
		switch (step) {
			case 0:
				return <Start />;
			case 1:
				return <Reason />;
			case 2:
				return <Documents />;
			case 3:
				return <Summary />;
			case 4:
				return <Outcome />;
			default:
				return 'unknown step';
		}
	};

	return (
		<Page title={pageTitle}>
			<FullScreenModal
				open={fileWarningModalOpen}
				onClose={() => {
					SetFileWarningModalOpen(false);
				}}
			>
				<Stack spacing={3}>
					<Grid container direction="row" justifyContent="center" alignItems="flex-start">
						<PageIcon width={98} height={110} />
					</Grid>
					<Typography align="center" variant="h5" component="p">
						Warning!
					</Typography>
					<Typography variant="body1" textAlign="center">
						We notice you have not supplied supporting documentation with your Debt Waiver Request.
					</Typography>
					<Typography variant="body1" textAlign="center">
						Please ensure you are aware that your request will be assessed using the information provided by yourself and that by not
						providing supporting documentation, we may not gain a comprehensive understanding of the customers situation.
					</Typography>
					<Typography variant="body1" textAlign="center">
						Please click continue to confirm you understand the above. Otherwise, feel free to close window and upload the relevant
						documentation.
					</Typography>
				</Stack>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start" mt={6}>
					<Grid item xs={8}>
						<Stack spacing={3}>
							<Button
								size="large"
								color="secondary"
								variant="contained"
								onClick={() => {
									handleContinue();
									SetFileWarningModalOpen(false);
								}}
							>
								Continue
							</Button>
							<Button size="large" color="primary" variant="outlined" onClick={() => SetFileWarningModalOpen(false)}>
								Close
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</FullScreenModal>
			<Container maxWidth="xl" sx={{ ml: 0 }}>
				<ClientBreadCrumbs childCrumb={pageTitle} />
				{isLoading && (
					<Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, minHeight: '100%', width: '100%', justifyContent: 'center' }}>
						<CircularProgress color="secondary" size={150} />
					</Box>
				)}
				{!isLoading && isBlocked && (
					<Card elevation={0}>
						<CardContent>
							<Container maxWidth="sm">
								<Stack spacing={3}>
									<Grid container direction="row" justifyContent="center" alignItems="flex-start">
										<WarningIcon width={121} height={121} />
									</Grid>
									<Typography variant="h5" gutterBottom textAlign="center">
										Warning
									</Typography>
									{hardship && (
										<>
											<Typography variant="subtitle1" textAlign="center">
												Unfortunately, we cannot proceed with your Debt Waiver Request!
											</Typography>
											<Typography variant="subtitle1" textAlign="center">
												Your clients account already has a hardship request placed and is yet to be resolved. Feel free to
												contact one of our friendly staff on{' '}
												<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
													{window.config.REACT_APP_CONTACT_NUMBER}
												</Link>{' '}
												for further assistance.
											</Typography>
										</>
									)}
									{!hardship && (
										<>
											<Typography variant="subtitle1" textAlign="center">
												Unfortunately, we cannot proceed with your Debt Waiver Request!
											</Typography>
											<Typography variant="subtitle1" textAlign="center">
												Your clients account has an active payment plan. Feel free to contact one of our friendly staff on{' '}
												<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
													{window.config.REACT_APP_CONTACT_NUMBER}
												</Link>{' '}
												for further assistance.
											</Typography>
										</>
									)}
								</Stack>
								<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
									<Button size="large" color="secondary" variant="contained" component={routerLink} to={ClientRoutes.DASHBOARD}>
										Back to Dashboard
									</Button>
								</Box>
							</Container>
						</CardContent>
					</Card>
				)}
				{!isLoading && !isBlocked && (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={9} lg={9}>
							<Card elevation={0}>
								<CardContent>
									<Grid container alignItems="stretch" spacing={2}>
										<Grid item xs={12} sm={12} md={4} lg={3} sx={{ display: 'flex', order: { xs: 2, md: 1 } }}>
											{displayStepperHorizontal ? (
												<HorizontalStepper activeStep={activeStep} steps={steps} sx={{ marginTop: 8, width: '100%' }} />
											) : (
												<VerticalStepper activeStep={activeStep} steps={steps} sx={{ maxHeight: 280 }} />
											)}
										</Grid>
										<Grid item xs={12} sm={12} md={8} lg={9} sx={{ display: 'flex', order: { xs: 1, md: 2 } }}>
											{renderStepContent(activeStep)}
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} sm={12} md={3} lg={3}>
							<AccountInformationSidebar />
						</Grid>
					</Grid>
				)}
			</Container>
		</Page>
	);
};
