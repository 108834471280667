import { FCResponse } from '../../../../services/api/base.api.response';

export enum ClientArrangementAPI {
	ARRANGEMENT = '/api/client/arrangement',
}

export interface IBpayDetailsOutput {
	BillerCode: string;
	PaymentReferenceNumber: string;
}

export interface IDepositDetailsOutput {
	AccountName: string;
	BSB: string;
	AccountNumber: string;
	AccountNumberNZ: string | null;
}

export interface ClientArrangementOutput {
	arrangementID: number;
	commencementDate: string;
	bpayDetails: IBpayDetailsOutput | undefined;
	depositDetails: IDepositDetailsOutput | undefined;
}

export type IPostClientArrangement = FCResponse<ClientArrangementOutput>;
