export enum PeriodicFrequencyID {
	ONE_OFF = 1,
	WEEKLY = 2,
	FORTNIGHTLY = 3,
	MONTHLY = 4,
}

export enum PeriodicFrequencyString {
	ONE_OFF = 'one off',
	WEEKLY = 'week',
	FORTNIGHTLY = 'fortnight',
	MONTHLY = 'month',
}

export enum PeriodicFrequencyDescString {
	ONE_OFF = 'One Off',
	WEEKLY = 'Weekly',
	FORTNIGHTLY = 'Fortnightly',
	MONTHLY = 'Monthly',
}

export const enum PaymentMethodID {
	BPAY = 1,
	DEBIT_CREDITCARD = 2,
	DIRECT_DEBIT = 3,
	DEPOSIT = 4,
	EXTERNAL = 5,
	OTHER = 6,
}

export const enum PaymentMethodString {
	BPAY = 'BPay',
	DEBIT_CREDITCARD = 'Debit/Credit Card',
	DIRECT_DEBIT = 'Direct Debit',
	DEPOSIT = 'Deposit',
	EXTERNAL = 'External',
	OTHER = 'Other',
}
