import { AlertColor } from '@mui/material/Alert';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

type SnackbarDetails = {
	autoHideDuration?: number | null;
	severity?: AlertColor;
	message: string;
};

type ModalDetails = {
	title: string;
	subtitle?: string;
	alertText?: string;
	severity?: AlertColor;
};

type SnackbarDetailsInput = Omit<SnackbarDetails, 'severity'>;

export interface GlobalState {
	snackbar: SnackbarDetails | undefined;
	modal: ModalDetails | undefined;
}

const initialState: GlobalState = {
	snackbar: undefined,
	modal: undefined,
};

export const appSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		setSuccessSnackbarOpen: (state, action: PayloadAction<SnackbarDetailsInput>) => {
			state.snackbar = { ...action.payload, severity: 'success', autoHideDuration: 12000 };
		},
		setErrorSnackbarOpen: (state, action: PayloadAction<SnackbarDetailsInput>) => {
			state.snackbar = { ...action.payload, severity: 'error' };
		},
		setWarningSnackbarOpen: (state, action: PayloadAction<SnackbarDetailsInput>) => {
			state.snackbar = { ...action.payload, severity: 'warning' };
		},
		setInfoSnackbarOpen: (state, action: PayloadAction<SnackbarDetailsInput>) => {
			state.snackbar = { ...action.payload, severity: 'info' };
		},
		setModalOpen: (state, action: PayloadAction<ModalDetails>) => {
			state.modal = { ...action.payload, severity: action.payload.severity ?? 'error' };
		},
		clearSnackbar: (state) => {
			state.snackbar = undefined;
		},
		clearModal: (state) => {
			state.modal = undefined;
		},
	},
});

export const { setSuccessSnackbarOpen, setErrorSnackbarOpen, setWarningSnackbarOpen, setInfoSnackbarOpen, setModalOpen, clearSnackbar, clearModal } =
	appSlice.actions;

export default appSlice.reducer;

export const selectSnackbar = (state: RootState) => state.app.snackbar;
export const selectModal = (state: RootState) => state.app.modal;
