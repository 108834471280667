import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Link,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, Formik, FormikHelpers } from 'formik';
import prettyBytes from 'pretty-bytes';
import React from 'react';
import * as Yup from 'yup';
import { ACCEPT_FILETYPES, ACCEPT_FILETYPES_ARRAY } from '../../../components/UploadComponent';
import { ChampionsCupIcon } from '../../../icons/ChampionsIcon';
import { PageIcon } from '../../../icons/PageIcon';
import { UploadIcon } from '../../../icons/UploadIcon';
import { clientAddNewPost, selectAddClientActiveStep, setAddClientStep } from '../../../store/features/clients/addClientSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { theme } from '../../../theme';

type AddClientProps = {
	onClose: () => void;
	goToPending: () => void;
};

export const AddClientLockedModal: React.FC<AddClientProps> = ({ onClose, goToPending }) => {
	const dispatch = useAppDispatch();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const activeStep = useAppSelector(selectAddClientActiveStep);

	const FILE_SIZE = 5 * 1024 * 1024; // 5MB
	const Input = styled('input')({
		display: 'none',
	});
	const CustomErrorDisplay = styled('span')({
		color: '#d32f2f',
		fontWeight: '400',
		fontFamily: 'inherit',
		fontSize: '0.75rem',
		lineHeight: '1.66',
		textAlign: 'left',
		marginTop: '3px',
		marginRight: '14px',
		marginBottom: 0,
	});

	interface IAddClientSubmit {
		ref: string;
		loaFile: string;
	}

	const handleContinue = () => {
		dispatch(setAddClientStep(activeStep + 1));
	};

	const handleSubmit = async (values: IAddClientSubmit, actions: FormikHelpers<IAddClientSubmit>) => {
		try {
			const res = await dispatch(clientAddNewPost({ debtID: values.ref, file: values.loaFile as unknown as File })).unwrap();
			if (res) {
				handleContinue();
			}
		} catch (err: unknown) {
			if (typeof err === 'string') {
				actions.setFieldError('loaFile', err);
			}
		}
	};

	const AddClientForm = () => {
		const addClientSchema = Yup.object().shape({
			ref: Yup.string()
				.required('Reference number is required')
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(8, 'Invalid reference length')
				.max(9, 'Invalid reference length'),
			loaFile: Yup.mixed()
				.required('File upload is required.')
				.test('fileSize', 'File size is too large.', (value) => (value ? value.size <= FILE_SIZE : true))
				.test('fileType', 'Unsupported file type.', (value) => (value ? ACCEPT_FILETYPES_ARRAY.includes(value.type) : true)),
		});
		return (
			<>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start" mt={4}>
					<PageIcon width={98} height={110} />
				</Grid>
				<DialogTitle sx={{ pb: 0 }}>
					<Typography align="center" variant="h5" component="p">
						Add New Client
					</Typography>
					<Typography align="center" variant="body1" component="p">
						Please enter the reference number and upload the LOA of the client you wish to represent.
					</Typography>
				</DialogTitle>
				<Formik
					initialValues={{ ref: '', loaFile: '' }}
					validationSchema={addClientSchema}
					onSubmit={handleSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, handleChange, touched, values, isSubmitting, isValid, dirty, setFieldValue }) => (
						<Form autoComplete="on">
							<DialogContent>
								<DialogContentText component="span">
									<TextField
										fullWidth
										label="Reference number"
										margin="normal"
										name="ref"
										inputMode="numeric"
										type="tel"
										variant="outlined"
										inputProps={{
											maxLength: 10,
										}}
										InputProps={{
											sx: { pr: 1.2 },
										}}
										error={Boolean(touched.ref && errors.ref)}
										helperText={touched.ref && errors.ref}
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.ref}
									/>
									{values.loaFile ? (
										<>
											<Grid
												container
												direction="row"
												justifyContent="center"
												alignItems="center"
												mt={2}
												pt={2}
												pb={2}
												sx={{ border: '1px solid #F3F3F3', boxSizing: 'border-box', borderRadius: '10px' }}
											>
												<Grid item xs={2} sx={{ textAlign: 'center' }}>
													<AttachFileIcon height={15} />
												</Grid>
												<Grid item xs={8}>
													<Typography variant="body1">{(values.loaFile as unknown as File).name}</Typography>
													<Typography variant="body1">{prettyBytes((values.loaFile as unknown as File).size)}</Typography>
												</Grid>
												<Grid item xs={2}>
													<IconButton
														color="primary"
														aria-label="remove uploaded file"
														onClick={() => setFieldValue('loaFile', '')}
													>
														<CancelOutlinedIcon />
													</IconButton>
												</Grid>
											</Grid>
										</>
									) : (
										<Grid
											container
											direction="row"
											justifyContent="center"
											alignItems="center"
											mt={2}
											pt={2}
											pb={2}
											sx={{ border: '1px dashed #22D07D', boxSizing: 'border-box', borderRadius: '10px' }}
										>
											<Grid item xs={2} sx={{ textAlign: 'center' }}>
												<UploadIcon width={44} height={44} />
											</Grid>
											<Grid item xs={10}>
												<Typography variant="h6">
													<label htmlFor="loaFile">
														<Input
															accept={ACCEPT_FILETYPES}
															name="loaFile"
															id="loaFile"
															type="file"
															onChange={(e) => {
																setFieldValue(`loaFile`, e.currentTarget.files ? e.currentTarget.files[0] : '');
															}}
														/>
														<Link color="secondary" sx={{ cursor: 'pointer' }}>
															Upload Letter of Assignment (LOA)
														</Link>
													</label>
												</Typography>
												<Typography variant="body1">Only .pdf, .png and .jpg files. Maximum upload of 5MB.</Typography>
											</Grid>
										</Grid>
									)}
									{errors.loaFile && <CustomErrorDisplay>{errors.loaFile}</CustomErrorDisplay>}
								</DialogContentText>
							</DialogContent>
							<Grid container direction="row" justifyContent="center" alignItems="flex-start" mt={4}>
								<Grid item xs={8}>
									<Stack spacing={3}>
										<LoadingButton
											color="secondary"
											variant="contained"
											size="large"
											type="submit"
											loading={isSubmitting}
											disabled={!(isValid && dirty)}
										>
											Submit
										</LoadingButton>
										<Button color="primary" variant="outlined" size="large" onClick={onClose}>
											Close
										</Button>
									</Stack>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</>
		);
	};

	const ClientAddFinished = () => {
		return (
			<>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start" mt={4}>
					<ChampionsCupIcon height={121} width={121} />
				</Grid>
				<DialogTitle>
					<Typography align="center" variant="h5" component="p">
						New Client Requested!
					</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText component="span">
						<Typography variant="body1" component="span">
							Thank you for requesting a new client. A member of our hardship team will review your request shortly. In the meantime,
							feel free to contact one of our friendly staff on{' '}
							<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
								{window.config.REACT_APP_CONTACT_NUMBER}
							</Link>{' '}
							for assistance.
						</Typography>
					</DialogContentText>
				</DialogContent>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start" mt={4} mb={4}>
					<Grid item xs={8}>
						<Stack spacing={3}>
							<Button color="secondary" variant="contained" size="large" onClick={goToPending}>
								View pending clients
							</Button>
							<Button color="primary" variant="outlined" size="large" onClick={onClose}>
								Close
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</>
		);
	};

	return (
		<div>
			<Dialog
				open={true}
				onClose={onClose}
				fullScreen={fullScreen}
				PaperProps={{
					sx: {
						minWidth: {
							lg: '600px',
						},
						padding: {
							lg: 2,
						},
					},
				}}
			>
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				{activeStep === 0 && <AddClientForm />}
				{activeStep === 1 && <ClientAddFinished />}
			</Dialog>
		</div>
	);
};
