import {
	Alert,
	Box,
	FormHelperText,
	IconButton,
	lighten,
	List,
	ListItem,
	ListItemText,
	Portal,
	Snackbar,
	Stack,
	styled,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export interface FileUpload {
	files: null | File[];
}

export const ACCEPT_FILETYPES = 'application/pdf,image/png,image/jpeg';

export const ACCEPT_FILETYPES_ARRAY = ['application/pdf', 'image/png', 'image/jpeg'];

const TOTAL_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const FILE_SIZE = 5 * 1024 * 1024; // 10MB

export const NO_FILES = 5;

// eslint-disable-next-line no-useless-escape
const FILE_NAME_REGEX = /^[()a-zA-Z0-9\. \-_]*$/;

type UploadComponentProps = {
	value: null | File[];
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
	numberOfFiles?: number;
	error?: boolean | undefined;
	helperText?: React.ReactNode;
};

export function checkIfFilesAreTooBig(files?: File[]): boolean {
	let valid = true;
	if (files?.length) {
		const sum = files.reduce((total, file) => total + (file.size ?? 0), 0);
		if (sum > TOTAL_FILE_SIZE) {
			valid = false;
		}
		files.forEach((file) => {
			if (file.size > FILE_SIZE) {
				valid = false;
			}
		});
	}
	return valid;
}

export function checkIfFilesAreCorrectType(files?: File[]): boolean {
	let valid = true;
	if (files) {
		files.forEach((file) => {
			if (!ACCEPT_FILETYPES_ARRAY.includes(file.type)) {
				valid = false;
			}
		});
	}
	return valid;
}

export function checkIfFilesAreNamedCorrectly(files?: File[]): boolean {
	let valid = true;
	if (files) {
		files.forEach((file) => {
			if (!FILE_NAME_REGEX.test(file.name)) {
				valid = false;
			}
		});
	}
	return valid;
}

const DropzoneContainer = styled('div')(({ theme }) => ({
	flex: 1,
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 20,
	borderWidth: 2,
	borderRadius: 2,
	borderColor: theme.palette.secondary.main,
	borderStyle: 'dashed',
	backgroundColor: theme.palette.common.white,
	outline: 'none',
	cursor: 'pointer',
	'&.disabled': {
		opacity: 0.6,
	},
	'&.error': {
		borderColor: theme.palette.error.main,
	},
	'&.dragAccept': {
		backgroundColor: lighten(theme.palette.secondary.light, 0.9),
	},
	'&.dragReject': {
		borderColor: theme.palette.error.main,
		backgroundColor: lighten(theme.palette.error.main, 0.9),
	},
}));

export const UploadComponent: React.FC<UploadComponentProps & DropzoneOptions> = ({
	value = [],
	name,
	setFieldValue,
	error,
	helperText,
	numberOfFiles = NO_FILES,
	...props
}) => {
	const existingFiles = value ?? [];
	const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
		accept: props.accept ?? ACCEPT_FILETYPES,
		maxFiles: props.maxFiles ?? numberOfFiles,
		maxSize: props.maxSize ?? FILE_SIZE,
		onDropAccepted: (acceptedFiles) => {
			// Filter out files already in array
			const newFiles = acceptedFiles.filter((acceptedFile) => !existingFiles.some((existingFile) => existingFile.name === acceptedFile.name));
			setFieldValue(name, [...existingFiles, ...newFiles], true);
		},
		onDropRejected: () => setSnackbarIsOpen(true),
		disabled: props.disabled,
	});

	const onClose = () => setSnackbarIsOpen(false);

	const removeFile = (file: File) => () => {
		const newFiles = [...existingFiles];
		newFiles.splice(newFiles.indexOf(file), 1);
		setFieldValue(name, newFiles, true);
	};

	const generateHelperClasses = () => {
		const classesArray = [];
		if (props.disabled) {
			classesArray.push('disabled');
		}
		if (error) {
			classesArray.push('error');
		}
		if (isFocused) {
			classesArray.push('focused');
		}
		if (isDragAccept) {
			classesArray.push('dragAccept');
		}
		if (isDragReject) {
			classesArray.push('dragReject');
		}
		return classesArray.join(' ');
	};

	return (
		<>
			<Portal>
				<Snackbar
					open={snackbarIsOpen}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					onClose={onClose}
				>
					<Alert onClose={onClose} variant="filled" severity="error">
						Unable to add file/s due to file size, type or number of files
					</Alert>
				</Snackbar>
			</Portal>
			<div>
				<DropzoneContainer {...getRootProps({ className: generateHelperClasses() })}>
					<input {...getInputProps()} />
					<Box marginRight={4} textAlign="center">
						<BackupOutlinedIcon sx={{ fontSize: '3rem' }} />
					</Box>
					<Stack>
						<Typography variant="subtitle1">Drag 'n' drop some files here, or click to select files</Typography>
						<Typography variant="body2" color="text.secondary">
							<em>
								Only .pdf, .png and .jpg files.
								<br />
								Maximum of 5 Files.
								<br />
								Maximum upload of 10MB total and 5MB each.
							</em>
						</Typography>
						<Typography variant="subtitle2" color="warning.dark">
							Please ensure file names provide an accurate description of the document contents
						</Typography>
					</Stack>
				</DropzoneContainer>
				{error && helperText && <FormHelperText error>{helperText}</FormHelperText>}
				{existingFiles.length > 0 && (
					<Box display="inline-flex">
						<List dense>
							{existingFiles.map((file) => (
								<ListItem
									key={file.name}
									secondaryAction={
										<IconButton edge="end" aria-label="delete" onClick={removeFile(file)}>
											<CancelOutlinedIcon color="error" />
										</IconButton>
									}
								>
									<ListItemText primary={file.name} />
								</ListItem>
							))}
						</List>
					</Box>
				)}
			</div>
		</>
	);
};
