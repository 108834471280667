import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Container,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { format, parseISO } from 'date-fns';
import prettyBytes from 'pretty-bytes';
import { useEffect, useState } from 'react';
import { Page } from '../../components/Page';
import { PerfectScrollbar } from '../../components/PerfectScrollbar';
import { TableEmpty } from '../../components/TableEmpty';
import { TableLoader } from '../../components/TableLoader';
import { dateFormats } from '../../constants/dateFormat.constant';
import { Status } from '../../enums/status.enum';
import {
	getClientAttachments,
	selectClientAttachments,
	selectClientAttachmentsStatus,
} from '../../store/features/client/attachments/attachmentsSlice';
import { ClientAttachmentsAPI, IGetClientAttachmentsOutput, IWebDownloadAttachment } from '../../store/features/client/attachments/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getComparator, Order } from '../../utils/table.util';
import { ClientBreadCrumbs } from './components/ClientBreadCrumbs';
import saveAs from 'file-saver';
import { fcAPI } from '../../services/api/api';
import { setErrorSnackbarOpen } from '../../store/features/app/appSlice';
import { selectViewingClient } from '../../store/features/client/clientSlice';

const pageTitle = 'Documents';

interface HeadCell {
	id: keyof IGetClientAttachmentsOutput;
	label: string;
	canSort: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'AttachmentName',
		label: 'Document Name',
		canSort: true,
	},
	{
		id: 'AddedDate',
		label: 'Date Added',
		canSort: true,
	},
	{
		id: 'AttachmentSize',
		label: 'Size',
		canSort: true,
	},
];

export const ClientDocuments: React.FC = () => {
	const dispatch = useAppDispatch();
	const { DebtID: debtID, DebtorEntityID: entityID } = useAppSelector(selectViewingClient);
	const loadStatus = useAppSelector(selectClientAttachmentsStatus);
	const attachments = useAppSelector(selectClientAttachments);
	const count = attachments.length;
	const [order, setOrder] = useState<Order>('desc');
	const [orderBy, setOrderBy] = useState<keyof IGetClientAttachmentsOutput>('AddedDate');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [attachmentLoading, setAttachmentLoading] = useState<number | undefined>(undefined);

	useEffect(() => {
		dispatch(getClientAttachments());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleRequestSort = (property: keyof IGetClientAttachmentsOutput) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const downloadAttachment = async (attachmentID: number) => {
		try {
			if (attachmentLoading) return;
			setAttachmentLoading(attachmentID);
			const result = await fcAPI.get<IWebDownloadAttachment>(`${ClientAttachmentsAPI.ATTACHMENT}`, {
				params: {
					debtID,
					entityID,
					attachmentID,
				},
			});
			if (result.data.success) {
				const byteArray = new Uint8Array(
					atob(result.data.data.attachment.streamData.replace(/\s/g, ''))
						.split('')
						.map((char) => char.charCodeAt(0)),
				);
				const attachment = new Blob([byteArray], { type: `application/${result.data.data.attachment.fileExtension.replace('.', '')}` });
				saveAs(attachment, result.data.data.attachment.fileName);
			} else {
				dispatch(setErrorSnackbarOpen({ message: result.data.message || 'An unknown error occurred.' }));
			}
		} catch {
			dispatch(
				setErrorSnackbarOpen({
					message: 'Sorry we have been unable to download your document at this time, please contact us to request a copy of this.',
				}),
			);
		} finally {
			setAttachmentLoading(undefined);
		}
	};

	return (
		<Page title={pageTitle}>
			<Container maxWidth="xl">
				<ClientBreadCrumbs childCrumb={pageTitle} />
				<Box sx={{ pb: 5 }}>
					<Card elevation={0}>
						<CardHeader title={pageTitle} />
						<CardContent sx={{ pt: 0 }}>
							<TableContainer component={PerfectScrollbar}>
								<Table sx={{ minWidth: 800 }}>
									<TableHead>
										<TableRow>
											{headCells.map((headCell) => (
												<TableCell
													key={headCell.id}
													align="left"
													padding="normal"
													sortDirection={orderBy === headCell.id ? order : false}
												>
													{headCell.canSort ? (
														<TableSortLabel
															active={orderBy === headCell.id}
															direction={orderBy === headCell.id ? order : 'asc'}
															onClick={() => (headCell.canSort ? handleRequestSort(headCell.id) : undefined)}
														>
															{headCell.label}
														</TableSortLabel>
													) : (
														headCell.label
													)}
												</TableCell>
											))}
											<TableCell key="Actions">Actions</TableCell>
										</TableRow>
									</TableHead>
									{loadStatus === Status.LOADING && <TableLoader rows={5} colSpan={headCells.length} />}
									{(loadStatus === Status.SUCCEEDED || loadStatus === Status.FAILED) && count === 0 && (
										<TableEmpty text="No client documents found." colSpan={headCells.length} />
									)}
									{loadStatus === Status.SUCCEEDED && count > 0 && (
										<TableBody>
											{attachments
												.slice()
												.sort(getComparator(order, orderBy))
												.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
												.map((row, index) => {
													return (
														<TableRow key={row.AttachmentID}>
															<TableCell component="th" scope="row">
																{row.AttachmentName}
															</TableCell>
															<TableCell>{format(parseISO(row.AddedDate), dateFormats.prettyDatetime)}</TableCell>
															<TableCell>{prettyBytes(Number(row?.AttachmentSize || 0))}</TableCell>
															<TableCell sx={{ whiteSpace: 'nowrap' }}>
																{attachmentLoading === row.AttachmentID ? (
																	<CircularProgress color="secondary" size={18} />
																) : (
																	<Link
																		sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
																		onClick={() => downloadAttachment(row.AttachmentID)}
																	>
																		<DownloadIcon sx={{ mr: 0.5 }} color="secondary" />
																		<Typography component="span" variant="body2" color="primary.dark">
																			Download
																		</Typography>
																	</Link>
																)}
															</TableCell>
														</TableRow>
													);
												})}
										</TableBody>
									)}
								</Table>
							</TableContainer>
							{count > 0 && (
								<TablePagination
									rowsPerPageOptions={[10, 25, 50]}
									component="div"
									count={count}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage="Per page:"
									sx={{ mt: 2 }}
								/>
							)}
						</CardContent>
					</Card>
				</Box>
			</Container>
		</Page>
	);
};
