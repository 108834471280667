import { useState } from 'react';
import { Drawer, List, IconButton, Box } from '@mui/material';
import { AuthRoutes, PortalRoutes, PublicRoutes } from '../../../constants/routes.constant';
import MenuIcon from '@mui/icons-material/Menu';
import { styled, useTheme } from '@mui/material/styles';
import { useAppSelector } from '../../../store/hooks';
import { selectAuthenticated } from '../../../store/features/auth/authSlice';
import { NavItem } from '../../../layouts/components/NavItem';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const IconContainer = styled(IconButton)(({ theme }) => ({
	marginLeft: 'auto',
	color: '#FFFFFF',
	transition: 'all 0.3s linear',
	cursor: 'pointer',
	'&:hover': {
		transform: 'rotate(90deg)',
	},
}));

export const DrawerComp = () => {
	const [openDrawer, setOpenDrawer] = useState(false);
	const isAuthenticated = useAppSelector(selectAuthenticated);
	const theme = useTheme();

	return (
		<>
			<Drawer
				anchor="right"
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				PaperProps={{
					sx: {
						width: {
							xs: theme.widths.sidebar.sm,
							lg: theme.widths.sidebar.lg,
						},
						border: 'none',
					},
				}}
			>
				<Box display="flex" justifyContent="flex-end">
					<IconButton aria-label="close" onClick={() => setOpenDrawer(false)}>
						<CloseOutlinedIcon />
					</IconButton>
				</Box>
				<List component="nav">
					<NavItem
						title="How it works"
						href={PublicRoutes.HOME}
						icon={<HelpOutlineIcon fontSize="medium" />}
						onClick={() => setOpenDrawer(false)}
					/>
					<NavItem
						title="Contact us"
						href={PublicRoutes.CONTACTS}
						icon={<ContactSupportOutlinedIcon fontSize="medium" />}
						onClick={() => setOpenDrawer(false)}
					/>
					{!isAuthenticated ? (
						<>
							<NavItem
								title="Sign up"
								href={AuthRoutes.SIGN_UP}
								icon={<HowToRegOutlinedIcon fontSize="medium" />}
								onClick={() => setOpenDrawer(false)}
							/>
							<NavItem
								title="Login"
								href={AuthRoutes.LOGIN}
								icon={<LoginOutlinedIcon fontSize="medium" />}
								onClick={() => setOpenDrawer(false)}
							/>
						</>
					) : (
						<NavItem
							title="Go to Portal"
							href={PortalRoutes.DASHBOARD}
							icon={<LoginOutlinedIcon fontSize="medium" />}
							onClick={() => setOpenDrawer(false)}
						/>
					)}
				</List>
			</Drawer>
			<IconContainer sx={{ marginLeft: 'auto', color: '#FFFFFF' }} onClick={() => setOpenDrawer(!openDrawer)}>
				<MenuIcon />
			</IconContainer>
		</>
	);
};
