import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { appInsights } from './reactPlugin';

export const PageTracking: React.FC = () => {
	const location = useLocation();
	useEffect(() => {
		if (appInsights) {
			// Fixes helmet lag https://github.com/nfl/react-helmet/issues/189
			setTimeout(() => {
				appInsights?.trackPageView();
			}, 1000);
		}
	}, [location]);

	return null;
};
