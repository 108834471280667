import { Box, Card, CardContent, CardHeader, CircularProgress, Container, Divider, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { format, isFuture, parseISO } from 'date-fns';
import { useEffect } from 'react';
import { Page } from '../../components/Page';
import { dateFormats } from '../../constants/dateFormat.constant';
import { Status } from '../../enums/status.enum';
import { clientHardshipGet, selectClientHardshipList, selectClientHardshipStatus } from '../../store/features/client/hardship/hardshipSlice';
import { IGetDebtorHardshipOutput } from '../../store/features/client/hardship/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ClientBreadCrumbs } from './components/ClientBreadCrumbs';

const pageTitle = 'Hardship Request';

const HardshipDivider: React.FC = () => {
	return (
		<>
			<Grid item xs={12} md={4} />
			<Grid item xs={12} md={8}>
				<Divider sx={{ mb: 2, mt: 2 }} />
			</Grid>
		</>
	);
};

type HardshipRequestProps = {
	hardship: IGetDebtorHardshipOutput;
};

const HardshipRequest: React.FC<HardshipRequestProps> = ({ hardship }) => {
	const isHardshipActive = !hardship.EndDate || isFuture(parseISO(hardship.EndDate));
	return (
		<Card elevation={0} sx={{ mb: 2, backgroundColor: (theme) => (isHardshipActive ? theme.palette.white.main : grey[100]) }}>
			<CardHeader title={pageTitle} subheader={`#${hardship.HardshipID}`} />
			<CardContent sx={{ pt: 0 }}>
				<Grid container spacing={1}>
					{/* HARDSHIP DETAILS SECTION */}
					<Grid item xs={12} md={4}>
						<Typography variant="h7" color="text.disabled">
							HARDSHIP INFORMATION
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">{hardship.HardshipResolutionName || '-'}</Typography>
						<Typography variant="body2" color="text.disabled">
							Resolution Type
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">{hardship?.HardshipCategoryName || '-'}</Typography>
						<Typography variant="body2" color="text.disabled">
							Category
						</Typography>
					</Grid>
					<Grid item xs={6} md={4}>
						<Typography variant="h7">{hardship?.HardshipStateName || '-'}</Typography>
						<Typography variant="body2" color="text.disabled">
							State
						</Typography>
					</Grid>
					{/* END SECTION */}

					<HardshipDivider />

					{/* HARDSHIP DATES SECTION */}
					<Grid item xs={12} md={4}>
						<Typography variant="h7" color="text.disabled">
							DATES
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{hardship?.AddedDate ? format(parseISO(hardship.AddedDate), dateFormats.prettyShort) : '-'}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Date Added
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{hardship?.EffectiveDate ? format(parseISO(hardship.EffectiveDate), dateFormats.prettyShort) : '-'}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Effective Date
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{hardship?.ApprovedDate ? format(parseISO(hardship.ApprovedDate), dateFormats.prettyShort) : '-'}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Approved Date
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">{hardship?.EndDate ? format(parseISO(hardship.EndDate), dateFormats.prettyShort) : '-'}</Typography>
						<Typography variant="body2" color="text.disabled">
							End Date
						</Typography>
					</Grid>
					{/* END SECTION */}

					<HardshipDivider />

					{/* HARDSHIP ASSIGNED SECTION */}
					<Grid item xs={12} md={4}>
						<Typography variant="h7" color="text.disabled">
							ASSIGNED
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">{hardship?.AssignedAgent || '-'}</Typography>
						<Typography variant="body2" color="text.disabled">
							Assigned to
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{hardship?.FollowupDate ? format(parseISO(hardship.FollowupDate), dateFormats.prettyShort) : '-'}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Followup Date
						</Typography>
					</Grid>
					<Grid item xs={6} md={4} />
					{/* END SECTION */}

					<HardshipDivider />

					{/* HARDSHIP EXTENDED SECTION */}
					<Grid item xs={12} md={4}>
						<Typography variant="h7" color="text.disabled">
							EXTENDED
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">{hardship?.ExtendedBy || '-'}</Typography>
						<Typography variant="body2" color="text.disabled">
							Extended by
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{hardship?.ExtendedDate ? format(parseISO(hardship.ExtendedDate), dateFormats.prettyShort) : '-'}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Extended Date
						</Typography>
					</Grid>
					<Grid item xs={6} md={4} />
					{/* END SECTION */}

					<HardshipDivider />
				</Grid>
			</CardContent>
		</Card>
	);
};

export const ClientHardshipInformation = () => {
	const dispatch = useAppDispatch();
	const hardships = useAppSelector(selectClientHardshipList);
	const hardshipStatus = useAppSelector(selectClientHardshipStatus);

	useEffect(() => {
		if (hardshipStatus !== Status.LOADING) {
			dispatch(clientHardshipGet());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Page title={pageTitle}>
			<Container maxWidth="xl">
				<ClientBreadCrumbs childCrumb="Hardship Requests" />
				<Box sx={{ pb: 5 }}>
					{hardshipStatus === Status.LOADING && <CircularProgress color="secondary" />}
					{hardshipStatus === Status.SUCCEEDED &&
						hardships &&
						hardships.length > 0 &&
						hardships.map((hardship, i) => <HardshipRequest key={hardship.HardshipID} hardship={hardship} />)}
					{(hardshipStatus === Status.SUCCEEDED || hardshipStatus === Status.FAILED) && hardships.length < 1 && (
						<Card elevation={0}>
							<CardHeader title={pageTitle} />
							<CardContent sx={{ pt: 0 }}>
								<Typography variant="body1">No hardship requests found.</Typography>
							</CardContent>
						</Card>
					)}
				</Box>
			</Container>
		</Page>
	);
};
