import { Card, CardContent, CardHeader, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { dateFormats } from '../../../constants/dateFormat.constant';
import { Status } from '../../../enums/status.enum';
import { selectClientDebtAmounts, selectClientDebtAmountsStatus } from '../../../store/features/client/debt/amountSlice';
import { selectClientDebt, selectClientDebtStatus } from '../../../store/features/client/debt/debtSlice';
import { selectClientDebtor, selectClientDebtorStatus } from '../../../store/features/client/debtor/debtorSlice';
import { selectClientLedger, selectClientLedgerStatus } from '../../../store/features/client/ledger/ledgerSlice';
import { useAppSelector } from '../../../store/hooks';
import { formatMoney } from '../../../utils/text.utill';
import { DebtToManyDebtorsTooltip } from './DebtToManyDebtorsTooltip';

const pageTitle = 'Account Information';
const skelW = 100;

const AccountInfoDivider: React.FC = () => {
	return (
		<>
			<Grid item xs={12} md={4} />
			<Grid item xs={12} md={8}>
				<Divider sx={{ mb: 2, mt: 2 }} />
			</Grid>
		</>
	);
};

export const AccountInformation: React.FC = () => {
	const ledger = useAppSelector(selectClientLedger);
	const ledgerStatus = useAppSelector(selectClientLedgerStatus);
	const debt = useAppSelector(selectClientDebt);
	const debtStatus = useAppSelector(selectClientDebtStatus);
	const debtAmounts = useAppSelector(selectClientDebtAmounts);
	const debtAmountsStatus = useAppSelector(selectClientDebtAmountsStatus);
	const debtor = useAppSelector(selectClientDebtor);
	const debtorStatus = useAppSelector(selectClientDebtorStatus);

	return (
		<Card elevation={0}>
			<CardHeader title={pageTitle} />
			<CardContent sx={{ pt: 0 }}>
				<Grid container spacing={1}>
					{/* CUSTOMER DETAILS SECTION */}
					<Grid item xs={12} md={4}>
						<Typography variant="h7" color="text.disabled">
							CUSTOMER DETAILS{' '}
							{!!(debt?.IsDebtLinked || debt?.IsLinkedDebtFCManaged) && (
								<DebtToManyDebtorsTooltip isLinked={debt.IsDebtLinked} isFcManaged={debt.IsLinkedDebtFCManaged} />
							)}
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtorStatus === Status.LOADING ? <Skeleton width={skelW} /> : debtor?.FirstName || '-'}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							First Name
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtorStatus === Status.LOADING ? <Skeleton width={skelW} /> : debtor?.MiddleNames || '-'}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Middle Name
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">{debtorStatus === Status.LOADING ? <Skeleton width={skelW} /> : debtor?.Surname || '-'}</Typography>
						<Typography variant="body2" color="text.disabled">
							Surname
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtorStatus === Status.LOADING ? (
								<Skeleton width={skelW} />
							) : debtor?.DOB ? (
								format(parseISO(debtor.DOB), dateFormats.prettyShort)
							) : (
								'-'
							)}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Date of Birth
						</Typography>
					</Grid>
					{/* END SECTION */}

					{/* COMPANY DETAILS SECTION */}
					{debtor?.CompanyName && (
						<>
							<AccountInfoDivider />
							<Grid item xs={12} md={4}>
								<Typography variant="h7" color="text.disabled">
									COMPANY DETAILS
								</Typography>
							</Grid>
							<Grid item xs={6} md={2}>
								<Typography variant="h7">
									{debtorStatus === Status.LOADING ? <Skeleton width={skelW} /> : debtor?.CompanyName || '-'}
								</Typography>
								<Typography variant="body2" color="text.disabled">
									Name
								</Typography>
							</Grid>
							{debtor?.CompanyABN ? (
								<Grid item xs={6} md={2}>
									<Typography variant="h7">{debtor.CompanyABN}</Typography>
									<Typography variant="body2" color="text.disabled">
										ABN
									</Typography>
								</Grid>
							) : (
								<Grid item xs={6} md={2} />
							)}
							{debtor?.CompanyACN ? (
								<Grid item xs={6} md={2}>
									<Typography variant="h7">{debtor.CompanyACN}</Typography>
									<Typography variant="body2" color="text.disabled">
										ACN
									</Typography>
								</Grid>
							) : (
								<Grid item xs={6} md={2} />
							)}
							<Grid item xs={6} md={2} />
						</>
					)}
					{/* END SECTION */}

					<AccountInfoDivider />

					{/* CLIENT DETAILS SECTION */}
					<Grid item xs={12} md={4}>
						<Typography variant="h7" color="text.disabled">
							CLIENT ACCOUNT DETAILS
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{ledgerStatus === Status.LOADING ? <Skeleton width={skelW} /> : ledger?.DebtTypeDescription || '-'}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Debt Type
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{ledgerStatus === Status.LOADING ? <Skeleton width={skelW} /> : ledger?.ClientDisplayName}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Original Provider
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">{debtStatus === Status.LOADING ? <Skeleton width={skelW} /> : debt?.ClientReference}</Typography>
						<Typography variant="body2" color="text.disabled">
							Original client reference
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtStatus === Status.LOADING ? <Skeleton width={skelW} /> : debt?.AccountReference || '-'}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Original account reference
						</Typography>
					</Grid>
					<Grid item xs={12} md={4} />
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtStatus === Status.LOADING ? (
								<Skeleton width={skelW} />
							) : debt?.LastClientPaymentDate ? (
								format(parseISO(debt.LastClientPaymentDate), dateFormats.prettyShort)
							) : (
								'-'
							)}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Date of Last Payment to Client
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{ledgerStatus === Status.LOADING ? <Skeleton width={skelW} /> : ledger?.LedgerDisplayName}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Product Name
						</Typography>
					</Grid>
					<Grid item xs={6} md={4} />
					{/* END SECTION */}

					<AccountInfoDivider />

					{/* DEBT DETAILS SECTION */}
					<Grid item xs={12} md={4}>
						<Typography variant="h7" color="text.disabled">
							PANTHERA FINANCE DETAILS
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtStatus === Status.LOADING ? (
								<Skeleton width={skelW} />
							) : debt?.DateAdded ? (
								format(parseISO(debt.DateAdded), dateFormats.prettyShort)
							) : (
								'-'
							)}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Date Added to System
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">{debtStatus === Status.LOADING ? <Skeleton width={skelW} /> : debt?.DebtID}</Typography>
						<Typography variant="body2" color="text.disabled">
							Reference Number
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtStatus === Status.LOADING ? <Skeleton width={skelW} /> : formatMoney(debt?.DebtAmount || 0)}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Original Balance
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtAmountsStatus === Status.LOADING ? <Skeleton width={skelW} /> : formatMoney(debtAmounts?.Outstanding || 0)}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Current Balance
						</Typography>
					</Grid>
					<Grid item xs={12} md={4} />
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtStatus === Status.LOADING ? <Skeleton width={skelW} /> : <>{((debt?.InterestRate || 0) * 100).toFixed(2)}%</>}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Interest Rate
						</Typography>
						{debt?.InterestHold && (
							<Typography variant="body2" sx={{ color: '#FFB400' }}>
								On hold{' '}
								{debt?.InterestHoldEndDate
									? `until ${format(parseISO(debt.InterestHoldEndDate), dateFormats.prettyShort)}`
									: 'indefinitely'}
							</Typography>
						)}
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtStatus === Status.LOADING ? <Skeleton width={skelW} /> : debt?.DebtStatusDescription}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Status
						</Typography>
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography variant="h7">
							{debtStatus === Status.LOADING ? (
								<Skeleton width={skelW} />
							) : debt?.StatBarredDate ? (
								format(parseISO(debt.StatBarredDate), dateFormats.prettyShort)
							) : (
								'-'
							)}
						</Typography>
						<Typography variant="body2" color="text.disabled">
							Stat Barred Date
						</Typography>
					</Grid>
					<Grid item xs={6} md={2} />
					{/* END SECTION */}

					<AccountInfoDivider />
				</Grid>
			</CardContent>
		</Card>
	);
};
