import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientHoldsAPI, HoldType, IAllHoldsOutput, IGetClientHolds } from './types';

export const clientHoldsGet = createAsyncThunk('client/holds', async (_, { rejectWithValue, dispatch, getState }) => {
	try {
		const { DebtorEntityID: EntityID, DebtID } = (getState() as RootState).client.viewingClient as ViewingClient;
		const { data } = await fcAPI.get<IGetClientHolds>(ClientHoldsAPI.HOLD, {
			params: { EntityID, DebtID },
		});
		if (data.success) {
			return data.data.holds;
		}
		return undefined;
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export interface HoldsState {
	holds: IAllHoldsOutput[] | undefined;
	activeContactHold: boolean;
	status: Status;
}

const initialState: HoldsState = {
	holds: undefined,
	activeContactHold: false,
	status: Status.IDLE,
};

const clientHoldsSlice = createSlice({
	name: 'clientHolds',
	initialState,
	reducers: {
		resetHolds: () => {
			return initialState;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientHoldsGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientHoldsGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.holds = action.payload;
				if (action.payload && action.payload.length > 0) {
					const activeHold = action.payload.find((x) => x.Active && x.HoldType === HoldType.CONTACT);
					if (activeHold) {
						state.activeContactHold = true;
					}
				}
			})
			.addCase(clientHoldsGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default clientHoldsSlice.reducer;

export const { resetHolds } = clientHoldsSlice.actions;

export const selectClientContactHold = (state: RootState) => state.clientHolds.activeContactHold;
export const selectClientHolds = (state: RootState) => state.clientHolds.holds;
export const selectClientHoldsStatus = (state: RootState) => state.clientHolds.status;
