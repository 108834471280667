import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientTransactionAPI, IClientTransactions, IGetClientTransactionsOutput } from './types';

export const getClientTransactions = createAsyncThunk(
	'client/transactions',
	async (input: { pageNumber: number; pageSize: number }, { rejectWithValue, dispatch, getState }) => {
		try {
			const { DebtID, DebtorEntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
			const { data } = await fcAPI.get<IClientTransactions>(ClientTransactionAPI.TRANSACTIONS, {
				params: { debtID: DebtID, entityID: DebtorEntityID, pageNumber: input.pageNumber, pageSize: input.pageSize },
			});
			if (data.success) {
				return data.data.transactions;
			}
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export interface ClientTransactionsState {
	transactions: IGetClientTransactionsOutput[];
	totalCount: number;
	status: Status;
}

const initialState: ClientTransactionsState = {
	transactions: [],
	totalCount: 0,
	status: Status.IDLE,
};

const clientTransactionsSlice = createSlice({
	name: 'clientTransactions',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(getClientTransactions.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(getClientTransactions.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.totalCount = action.payload[0]?.TotalCount ?? 0;
				state.transactions = action.payload;
			})
			.addCase(getClientTransactions.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default clientTransactionsSlice.reducer;

export const selectClientTransactions = (state: RootState) => state.clientTransactions.transactions;
export const selectClientTransactionsStatus = (state: RootState) => state.clientTransactions.status;
export const selectClientTransactionsCount = (state: RootState) => state.clientTransactions.totalCount;
