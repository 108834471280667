import {
	Card,
	CardContent,
	CardHeader,
	Palette,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { Label } from '../../../components/Label';
import { PerfectScrollbar } from '../../../components/PerfectScrollbar';
import { TableEmpty } from '../../../components/TableEmpty';
import { TableLoader } from '../../../components/TableLoader';
import { dateFormats } from '../../../constants/dateFormat.constant';
import { Status } from '../../../enums/status.enum';
import { clientHoldsGet, selectClientHolds, selectClientHoldsStatus } from '../../../store/features/client/holds/holdsSlice';
import { IAllHoldsOutput } from '../../../store/features/client/holds/types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getComparator, Order } from '../../../utils/table.util';

const pageTitle = 'Account holds';

interface HeadCell {
	id: keyof IAllHoldsOutput;
	label: string;
	canSort: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'HoldType',
		label: 'Hold Type',
		canSort: true,
	},
	{
		id: 'AddedDate',
		label: 'Added Date',
		canSort: true,
	},
	{
		id: 'EffectiveDate',
		label: 'Effective Date',
		canSort: true,
	},
	{
		id: 'EndDate',
		label: 'End Date',
		canSort: true,
	},
	{
		id: 'HoldDays',
		label: 'Hold Days',
		canSort: true,
	},
	{
		id: 'HoldReason',
		label: 'Hold Reason',
		canSort: true,
	},
	{
		id: 'ActiveDesc',
		label: 'Status',
		canSort: true,
	},
];

export const AccountHolds: React.FC = () => {
	const dispatch = useAppDispatch();
	const holds = useAppSelector(selectClientHolds);
	const loadStatus = useAppSelector(selectClientHoldsStatus);

	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState<keyof IAllHoldsOutput>('ActiveDesc');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const count = holds?.length || 0;

	useEffect(() => {
		if (loadStatus !== Status.LOADING) {
			dispatch(clientHoldsGet());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleRequestSort = (property: keyof IAllHoldsOutput) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const getStatusColor = (active: boolean): keyof Palette | 'default' => {
		if (active) {
			return 'success';
		} else {
			return 'warning';
		}
	};

	return (
		<Card elevation={0}>
			<CardHeader title={pageTitle} />
			<CardContent sx={{ pt: 0 }}>
				<TableContainer component={PerfectScrollbar}>
					<Table sx={{ minWidth: 800 }}>
						<TableHead>
							<TableRow>
								{headCells.map((headCell) => (
									<TableCell
										key={headCell.id}
										align="left"
										padding="normal"
										sortDirection={orderBy === headCell.id ? order : false}
									>
										{headCell.canSort ? (
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												onClick={() => (headCell.canSort ? handleRequestSort(headCell.id) : undefined)}
											>
												{headCell.label}
											</TableSortLabel>
										) : (
											headCell.label
										)}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						{loadStatus === Status.LOADING && <TableLoader rows={5} colSpan={headCells.length} />}
						{(loadStatus === Status.SUCCEEDED || loadStatus === Status.FAILED) && count === 0 && (
							<TableEmpty text="No account holds found." colSpan={headCells.length} />
						)}
						{loadStatus === Status.SUCCEEDED && count > 0 && (
							<TableBody>
								{holds &&
									holds.length > 0 &&
									holds
										.slice()
										.sort(getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											return (
												// eslint-disable-next-line react/no-array-index-key
												<TableRow key={index}>
													<TableCell component="th" scope="row">
														{row.HoldType}
													</TableCell>
													<TableCell>{format(parseISO(row.AddedDate), dateFormats.prettyShort)}</TableCell>
													<TableCell>{format(parseISO(row.EffectiveDate), dateFormats.prettyShort)}</TableCell>
													<TableCell>
														{row.EndDate ? format(parseISO(row.EndDate), dateFormats.prettyShort) : 'Indefinite'}
													</TableCell>
													<TableCell>{row.HoldDays || '-'}</TableCell>
													<TableCell>{row.HoldReason}</TableCell>
													<TableCell>
														<Label size="small" variant="ghost" color={getStatusColor(row.Active)} sx={{ minWidth: 75 }}>
															{row.ActiveDesc}
														</Label>
													</TableCell>
												</TableRow>
											);
										})}
							</TableBody>
						)}
					</Table>
				</TableContainer>
				{count > 0 && (
					<TablePagination
						rowsPerPageOptions={[10, 25, 50]}
						component="div"
						count={count}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="Per page:"
						sx={{ mt: 2 }}
					/>
				)}
			</CardContent>
		</Card>
	);
};
