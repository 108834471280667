import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Grid, Typography } from '@mui/material';
import React from 'react';

type PasswordRequirementsProps = {
	length: boolean;
	uppercase: boolean;
	number: boolean;
	special: boolean;
};

export const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({ length, uppercase, number, special }) => {
	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="body2" gutterBottom>
						A secure password must include at least:
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item>
					<CheckCircleOutlineIcon color={length ? 'success' : 'action'} />
				</Grid>
				<Grid item>
					<Typography variant="body1">12-50 characters</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item>
					<CheckCircleOutlineIcon color={uppercase ? 'success' : 'action'} />
				</Grid>
				<Grid item>
					<Typography variant="body1">1 uppercase character</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item>
					<CheckCircleOutlineIcon color={number ? 'success' : 'action'} />
				</Grid>
				<Grid item>
					<Typography variant="body1">1 number</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item>
					<CheckCircleOutlineIcon color={special ? 'success' : 'action'} />
				</Grid>
				<Grid item>
					<Typography variant="body1">1 special character</Typography>
				</Grid>
			</Grid>
		</>
	);
};
