import { FCResponse } from '../../../../services/api/base.api.response';

export enum ClientAttachmentsAPI {
	ATTACHMENTS = '/api/client/attachments',
	ATTACHMENT = '/api/client/attachment',
}

export interface IGetClientAttachmentsOutput {
	AttachmentID: number;
	AttachmentName: string;
	AddedDate: string;
	// UpdatedDate: string | null;
	AttachmentSize: number;
}

export type IClientAttachments = FCResponse<{ attachments: IGetClientAttachmentsOutput[] }>;

export interface IWebDownloadAttachmentOutput {
	streamData: string;
	fileExtension: string;
	fileName: string;
}

export type IWebDownloadAttachment = FCResponse<{ attachment: IWebDownloadAttachmentOutput }>;
