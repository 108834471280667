import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { setErrorSnackbarOpen } from '../../app/appSlice';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientDebtAPI, IGetClientDebtAmounts, IGetDebtAmountsOutput } from './types';

export const clientDebtAmountsGet = createAsyncThunk('client/debt/amounts', async (_, { rejectWithValue, dispatch, getState }) => {
	try {
		const { DebtID } = (getState() as RootState).client.viewingClient as ViewingClient;
		const { data } = await fcAPI.get<IGetClientDebtAmounts>(ClientDebtAPI.DEBT_AMOUNTS, {
			params: { DebtID },
		});
		if (data.success) {
			return data.data.amounts;
		}
		dispatch(setErrorSnackbarOpen({ message: data.message }));
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export interface DebtState {
	amounts: IGetDebtAmountsOutput | undefined;
	status: Status;
}

const initialState: DebtState = {
	amounts: undefined,
	status: Status.IDLE,
};

const clientDebtAmountsSlice = createSlice({
	name: 'clientDebtAmounts',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(clientDebtAmountsGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(clientDebtAmountsGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.amounts = action.payload;
			})
			.addCase(clientDebtAmountsGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default clientDebtAmountsSlice.reducer;

export const selectClientDebtAmounts = (state: RootState) => state.clientDebtAmounts.amounts;
export const selectClientDebtAmountsStatus = (state: RootState) => state.clientDebtAmounts.status;
