import { FCResponse } from '../../../services/api/base.api.response';

export enum SignUpAPI {
	SIGN_UP = '/api/signup',
	CONFIRM = '/api/signup/confirm',
	LOGIN = '/api/login',
}

export enum AuthAPI {
	LOGIN = '/api/login',
	RESET_PASSWORD = '/api/reset/request',
	CONFIRM_PASSWORD = '/api/reset/confirm',
}

export type ISignupConfirm = FCResponse<{ jwt: string }>;

export type ILogin = FCResponse<{ jwt?: string; forceReset?: boolean }>;

export type IResetConfirm = FCResponse<{ jwt: string }>;
