import { useIdleTimer } from 'react-idle-timer';
import { selectAuthenticated, timedOut } from '../store/features/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const timeout = (process.env.NODE_ENV === 'development' ? 300 : 15) * 1000 * 60;

export const SessionTimeout = () => {
	const dispatch = useAppDispatch();
	const authenticated = useAppSelector(selectAuthenticated);

	const handleOnIdle = () => {
		if (authenticated) {
			dispatch(timedOut());
		}
	};

	useIdleTimer({
		onIdle: handleOnIdle,
		timeout,
		eventsThrottle: 1000,
		crossTab: true,
	});

	return null;
};
