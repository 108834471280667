import { Box, Card, CardContent, CardHeader, CircularProgress, lighten, Palette, PaletteColor, Typography, useTheme } from '@mui/material';
import { ArcElement, Chart as ChartJS, ChartData, ChartOptions, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getStateColor } from './TableResults';
import { IReportHardship } from './types';

type ChartResultsProps = {
	data: IReportHardship[] | undefined;
	loading: boolean;
};

ChartJS.register(ArcElement, Tooltip, Legend);

interface IChartData {
	label: string;
	value: number;
	background: string;
}

export const StateChartResults: React.FC<ChartResultsProps> = ({ data, loading }) => {
	const theme = useTheme();
	let chartData: IChartData[] | null = null;
	let chartDoughnutData: ChartData<'doughnut'> | null = null;

	const options: ChartOptions<'doughnut'> = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: true,
				position: 'right',
			},
		},
	};

	if (data && data.length > 0) {
		const sortedState = data
			.map((hardship) => {
				let sortNum = 2;
				if (['Finalised', 'Accepted'].includes(hardship.State)) {
					sortNum = 0;
				} else if (['Pending', 'In Review'].includes(hardship.State)) {
					sortNum = 1;
				}
				return {
					...hardship,
					sortNum,
				};
			})
			.slice()
			.sort((a, b) => (a.sortNum > b.sortNum ? 1 : -1));
		chartData = Object.values(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			sortedState.reduce((group: any, { State }) => {
				const bgColour =
					getStateColor(State) === 'default'
						? theme.palette.grey[400]
						: (theme.palette[getStateColor(State) as keyof Palette] as PaletteColor).light;
				group[State] = group[State] || {
					label: State,
					value: 0,
					background: lighten(bgColour, 0.7),
				};
				group[State].value += 1;
				return group;
			}, {}),
		);

		chartDoughnutData = {
			labels: chartData.map((c) => c.label),
			datasets: [
				{
					data: chartData.map((c) => c.value),
					backgroundColor: chartData.map((c) => c.background),
					borderWidth: 2,
				},
			],
		};
	}

	return (
		<Card elevation={0}>
			<CardHeader title="State Summary" />
			<CardContent sx={{ pt: 0, height: 300 }}>
				{loading && (
					<Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, minHeight: '100%', width: '100%', justifyContent: 'center' }}>
						<CircularProgress color="secondary" size={150} />
					</Box>
				)}
				{!loading && (data?.length ?? 0) === 0 && (
					<Typography component="p" variant="subtitle1" width="100%" textAlign="center" sx={{ py: 3 }}>
						No summary to display
					</Typography>
				)}
				{chartData && chartDoughnutData && <Doughnut data={chartDoughnutData} options={options} />}
			</CardContent>
		</Card>
	);
};
