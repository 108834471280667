import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Button, Card, Container, Grid, Tab, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Page } from '../../components/Page';
import { StyledTabs } from '../../components/StyledTabs';
import { TabPanel } from '../../components/TabPanel';
import { PortalRoutes } from '../../constants/routes.constant';
import { resetAddClient } from '../../store/features/clients/addClientSlice';
import { pendingClientsGet } from '../../store/features/clients/pendingClients';
import { useAppDispatch } from '../../store/hooks';
import { AddClientLockedModal } from './components/AddClientModal';
import { AvailableClients } from './components/AvailableClients';
import { PendingClients } from './components/PendingClients';

export enum MyClientTabs {
	Available = 'available',
	Pending = 'pending',
}

export const MyClients = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'));
	const [addClientOpen, setAddClientOpen] = useState(false);
	const { tab } = useParams<'tab'>();

	const navigateToPending = () => {
		dispatch(resetAddClient());
		setAddClientOpen(false);
		if (tab !== MyClientTabs.Pending) {
			navigate(`${PortalRoutes.MY_CLIENTS}/${MyClientTabs.Pending}`);
		} else {
			dispatch(pendingClientsGet());
		}
	};

	const modalClose = () => {
		dispatch(resetAddClient());
		setAddClientOpen(false);
	};

	return (
		<Page title="My Clients">
			<Container maxWidth="xl">
				<Box sx={{ pb: 5 }}>
					<Grid container spacing={3} alignItems="center" justifyContent="space-between">
						<Grid item>
							<Typography variant="h5">My Clients</Typography>
						</Grid>
						<Grid item>
							<Button color="secondary" variant="contained" startIcon={<AddOutlinedIcon />} onClick={() => setAddClientOpen(true)}>
								Add New Client
							</Button>
						</Grid>
					</Grid>
				</Box>
				<Grid container>
					<Grid item xs={12}>
						<Card elevation={0}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<StyledTabs value={tab || MyClientTabs.Available} textColor="secondary" indicatorColor="secondary">
									<Tab
										label={`Available${smUp ? ' Clients' : ''}`}
										value={MyClientTabs.Available}
										to={`${PortalRoutes.MY_CLIENTS}/${MyClientTabs.Available}`}
										component={Link}
									/>
									<Tab
										label={`Pending ${smUp ? ' Clients' : ''}`}
										value={MyClientTabs.Pending}
										to={`${PortalRoutes.MY_CLIENTS}/${MyClientTabs.Pending}`}
										component={Link}
									/>
								</StyledTabs>
							</Box>
							<TabPanel value={tab || MyClientTabs.Available} index={MyClientTabs.Available}>
								<AvailableClients />
							</TabPanel>
							<TabPanel value={tab} index={MyClientTabs.Pending}>
								<PendingClients />
							</TabPanel>
						</Card>
					</Grid>
				</Grid>
			</Container>
			{addClientOpen && <AddClientLockedModal onClose={modalClose} goToPending={navigateToPending} />}
		</Page>
	);
};
