import React from 'react';

export const Arrow = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg width={107} height={106} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={53.5} cy={53} transform="rotate(180 53.5 53)" fill="#fff" r={53} />
		<path d="M1 53C1 24.005 24.505.5 53.5.5S106 24.005 106 53s-23.505 52.5-52.5 52.5S1 81.995 1 53Z" stroke="#000" strokeOpacity={0.87} />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M39.991 52.303a.915.915 0 0 0 .916.915h21.588l-5.761 5.759a.918.918 0 0 0 .297 1.495.915.915 0 0 0 .999-.199l7.322-7.322a.915.915 0 0 0 0-1.296l-7.322-7.323a.917.917 0 0 0-1.296 1.296l5.76 5.76H40.908a.915.915 0 0 0-.916.915Z"
			fill="#000"
			fillOpacity={0.87}
		/>
	</svg>
);
