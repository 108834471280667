import { Box, Card, CardContent, CardHeader, CircularProgress, lighten, Typography } from '@mui/material';
import { ArcElement, Chart as ChartJS, ChartData, ChartOptions, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { IReportHardship } from './types';

type ChartResultsProps = {
	data: IReportHardship[] | undefined;
	loading: boolean;
};

ChartJS.register(ArcElement, Tooltip, Legend);

interface IChartData {
	label: string;
	value: number;
	background: string;
}

export const ResolutionChartResults: React.FC<ChartResultsProps> = ({ data, loading }) => {
	let chartData: IChartData[] | null = null;
	let chartDoughnutData: ChartData<'doughnut'> | null = null;

	const options: ChartOptions<'doughnut'> = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: true,
				position: 'right',
			},
		},
	};

	const palette = [
		'#2c69b0',
		'#b5c8e2',
		'#f02720',
		'#ffb6b0',
		'#ac613c',
		'#e9c39b',
		'#6ba3d6',
		'#b5dffd',
		'#ac8763',
		'#ddc9b4',
		'#bd0a36',
		'#f4737a',
	];

	if (data && data.length > 0) {
		chartData = Object.values(
			data
				.slice()
				.sort((a, b) => (a.Resolution > b.Resolution ? 1 : -1))
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.reduce((group: any, { Resolution }) => {
					group[Resolution] = group[Resolution] || {
						label: Resolution,
						value: 0,
					};
					group[Resolution].value += 1;
					return group;
				}, {}),
		);

		chartData = chartData.map((cData, index) => ({ ...cData, background: lighten(palette[index], 0.6) }));

		chartDoughnutData = {
			labels: chartData.map((c) => c.label),
			datasets: [
				{
					data: chartData.map((c) => c.value),
					backgroundColor: chartData.map((c) => c.background),
					borderWidth: 2,
				},
			],
		};
	}

	return (
		<Card elevation={0}>
			<CardHeader title="Resolution Summary" />
			<CardContent sx={{ pt: 0, height: 300 }}>
				{loading && (
					<Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, minHeight: '100%', width: '100%', justifyContent: 'center' }}>
						<CircularProgress color="secondary" size={150} />
					</Box>
				)}
				{!loading && (data?.length ?? 0) === 0 && (
					<Typography component="p" variant="subtitle1" width="100%" textAlign="center" sx={{ py: 3 }}>
						No summary to display
					</Typography>
				)}
				{chartData && chartDoughnutData && <Doughnut data={chartDoughnutData} options={options} />}
			</CardContent>
		</Card>
	);
};
