import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface ViewingClient {
	FCAccessID: number;
	DebtID: number;
	DebtorEntityID: number;
}

export interface ClientState {
	viewingClient: ViewingClient | undefined;
}

const initialState: ClientState = {
	viewingClient: undefined,
};

const clientSlice = createSlice({
	name: 'client',
	initialState,
	reducers: {
		setViewingClient: (state, action: PayloadAction<ViewingClient>) => {
			state.viewingClient = action.payload;
		},
		resetViewingClient: () => {
			return initialState;
		},
	},
});

export const { setViewingClient, resetViewingClient } = clientSlice.actions;

export default clientSlice.reducer;

export const selectViewingClient = (state: RootState) => state.client.viewingClient as ViewingClient;
export const selectIsClientSelected = (state: RootState) => !!state.client.viewingClient;
