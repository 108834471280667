import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Container,
	Grid,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Stack,
	styled,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addDays, addMonths, differenceInDays, differenceInMonths, format, isAfter, isDate, isPast, isValid, parseISO, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Link as routerLink } from 'react-router-dom';
import * as Yup from 'yup';
import { FullScreenModal } from '../../components/FullScreenModal';
import { HorizontalStepper } from '../../components/HorizontalStepper';
import { Page } from '../../components/Page';
import {
	checkIfFilesAreCorrectType,
	checkIfFilesAreNamedCorrectly,
	checkIfFilesAreTooBig,
	FileUpload,
	NO_FILES,
	UploadComponent,
} from '../../components/UploadComponent';
import { VerticalStepper } from '../../components/VerticalStepper';
import { dateFormats } from '../../constants/dateFormat.constant';
import { ClientRoutes } from '../../constants/routes.constant';
import { PeriodicFrequencyID } from '../../enums/arrangement.enum';
import { HardshipCategory, HardshipResult } from '../../enums/hardship.enum';
import { Status } from '../../enums/status.enum';
import { ChampionsCupIcon } from '../../icons/ChampionsIcon';
import { ClockIcon } from '../../icons/ClockIcon';
import { PageIcon } from '../../icons/PageIcon';
import { WarningIcon } from '../../icons/WarningIcon';
import {
	getClientArrangementsActive,
	selectActiveArrangements,
	selectArrangementsStatus,
} from '../../store/features/client/arrangements/arrangementsSlice';
import {
	getUpcomingPaymentSchedule,
	selectUpcomingPayments,
	selectUpcomingPaymentsStatus,
} from '../../store/features/client/arrangements/scheduleSlice';
import { selectClientDebt } from '../../store/features/client/debt/debtSlice';
import { clientHardshipGet, selectClientHardship, selectClientHardshipStatus } from '../../store/features/client/hardship/hardshipSlice';
import { clientHoldsGet, selectClientContactHold, selectClientHoldsStatus } from '../../store/features/client/holds/holdsSlice';
import {
	clientMoratoriumPost,
	MoratoriumHardshipDetails,
	MoratoriumLengthDetails,
	MoratoriumPaymentDetails,
	resetMoratorium,
	selectClientMoratoriumActiveStep,
	selectClientMoratoriumDetails,
	selectClientMoratoriumOutcome,
	selectClientMoratoriumStatus,
	setMoratoriumActiveStep,
	setMoratoriumHardshipDetails,
	setMoratoriumLengthDetails,
	setMoratoriumPaymentDetails,
} from '../../store/features/client/moratorium/moratoriumSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { convertHardshipCategoryToString } from '../../utils/text.utill';
import { AccountInformationSidebar } from './components/AccountInformationSidebar';
import { ClientBreadCrumbs } from './components/ClientBreadCrumbs';

const StyledList = styled('ul')(({ theme }) => ({
	li: {
		paddingBottom: theme.spacing(2),
	},
}));

const pageTitle = 'Moratorium Request';

const MIN_DATE = startOfDay(addDays(new Date(), 1));

export const ClientMoratorium = () => {
	const dispatch = useAppDispatch();
	const activeStep = useAppSelector(selectClientMoratoriumActiveStep);
	const moratoriumStatus = useAppSelector(selectClientMoratoriumStatus);
	const hardshipStatus = useAppSelector(selectClientHardshipStatus);
	const arrangementStatus = useAppSelector(selectArrangementsStatus);
	const hardship = useAppSelector(selectClientHardship);
	const activeHold = useAppSelector(selectClientContactHold);
	const holdsStatus = useAppSelector(selectClientHoldsStatus);
	const arrangements = useAppSelector(selectActiveArrangements);
	const upcomingPayments = useAppSelector(selectUpcomingPayments);
	const upcomingStatus = useAppSelector(selectUpcomingPaymentsStatus);
	const moratoriumOutcome = useAppSelector(selectClientMoratoriumOutcome);
	const debt = useAppSelector(selectClientDebt);
	const { moratoriumMonths, endDate, hardshipCategoryID, note, pausePayments, arrangementID } = useAppSelector(selectClientMoratoriumDetails);
	const theme = useTheme();
	const displayStepperHorizontal = useMediaQuery(theme.breakpoints.down('md'));

	const [fileWarningModalOpen, SetFileWarningModalOpen] = useState(false);
	const [files, SetFiles] = useState<File[] | null>(null);
	const [tempEndDate, SetTempEndDate] = useState('');

	const isLoading =
		(hardshipStatus !== Status.SUCCEEDED && arrangementStatus !== Status.SUCCEEDED && holdsStatus !== Status.SUCCEEDED) ||
		upcomingStatus === Status.LOADING;
	const recurringArrangements = arrangements.filter((arrangement) => arrangement.PeriodicFrequencyID !== PeriodicFrequencyID.ONE_OFF);
	const steps = ['Start application', 'Reason', 'Documents', ...(arrangements.length > 0 ? ['Payment Plan'] : []), 'Summary', 'Outcome'];
	const isBlocked = (hardship || activeHold) && !moratoriumOutcome;

	useEffect(() => {
		if (hardshipStatus === Status.IDLE) {
			dispatch(clientHardshipGet());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hardshipStatus, dispatch]);

	useEffect(() => {
		if (arrangementStatus === Status.IDLE) {
			dispatch(getClientArrangementsActive());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arrangementStatus, dispatch]);

	useEffect(() => {
		if (holdsStatus === Status.IDLE) {
			dispatch(clientHoldsGet());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [holdsStatus, dispatch]);

	useEffect(() => {
		// Only allow one recurring arrangement to be paused
		if (upcomingStatus !== Status.LOADING && recurringArrangements.length === 1) {
			dispatch(
				getUpcomingPaymentSchedule({
					arrangementIDs: [recurringArrangements[0].ArrangementID],
					pageNumber: 0,
					pageSize: 20,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, recurringArrangements.length]);

	// Reset Moratorium on Unmount
	useLayoutEffect(() => {
		return () => {
			dispatch(resetMoratorium());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const menuItemFor = (category: HardshipCategory): JSX.Element => {
		return <MenuItem value={category.toString()}>{convertHardshipCategoryToString(category)}</MenuItem>;
	};

	const handleContinue = () => {
		dispatch(setMoratoriumActiveStep(activeStep + 1));
	};

	const handleBack = () => {
		dispatch(setMoratoriumActiveStep(activeStep - 1));
	};

	const moratoriumLengthDetailsSubmit = (values: MoratoriumLengthDetails) => {
		SetTempEndDate(startOfDay(addMonths(new Date(), Number(values.moratoriumMonths))).toISOString());
		dispatch(setMoratoriumLengthDetails(values));
		handleContinue();
	};

	const moratoriumHardshipDetailsSubmit = (values: MoratoriumHardshipDetails) => {
		dispatch(setMoratoriumHardshipDetails(values));
		handleContinue();
	};

	const moratoriumPaymentDetailsSubmit = (values: MoratoriumPaymentDetails) => {
		dispatch(setMoratoriumPaymentDetails(values));
		handleContinue();
	};

	const moratoriumSubmit = async () => {
		try {
			const clientMoratoriumSuccess = await dispatch(
				clientMoratoriumPost({ hardshipCategoryID, endDate, note, files, pausePayments, arrangementID }),
			).unwrap();
			if (clientMoratoriumSuccess) {
				dispatch(clientHardshipGet());
				handleContinue();
			}
		} catch {
			/* Handled in Thunk */
		}
	};

	const moratoriumDocumentsSubmit = (values: FileUpload) => {
		SetFiles(values.files);
		if (!values.files?.length) {
			SetFileWarningModalOpen(true);
		} else {
			handleContinue();
		}
	};

	const Start = () => {
		const initialValues: MoratoriumLengthDetails = {
			moratoriumMonths,
		};
		const moratoriumlengthDetailsSchema = Yup.object().shape({
			moratoriumMonths: Yup.string().required('Please select moratorium length'),
		});
		return (
			<Stack spacing={3}>
				<Typography variant="h5" gutterBottom>
					Moratorium Request
				</Typography>
				<Typography variant="body1">
					If you believe your client will benefit from a temporary hold on any and all account action, please proceed to the follow steps to
					get started on a Moratorium request.
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={moratoriumlengthDetailsSchema}
					onSubmit={moratoriumLengthDetailsSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, handleChange, touched, values }) => (
						<Form autoComplete="off">
							<Stack spacing={3}>
								<Box>
									<Typography variant="subtitle2" gutterBottom>
										Select desired moratorium length
									</Typography>
									<TextField
										select
										error={Boolean(touched.moratoriumMonths && errors.moratoriumMonths)}
										fullWidth
										helperText={touched.moratoriumMonths && errors.moratoriumMonths}
										value={values.moratoriumMonths}
										name="moratoriumMonths"
										onBlur={handleBlur}
										onChange={handleChange}
										SelectProps={{
											displayEmpty: true,
										}}
									>
										<MenuItem disabled style={{ display: 'none' }} value="">
											Please select one
										</MenuItem>
										<MenuItem value="3">3 months</MenuItem>
										<MenuItem value="6">6 months</MenuItem>
										<MenuItem value="12">12 months</MenuItem>
										<MenuItem value="13">More than 12 months</MenuItem>
									</TextField>
								</Box>
							</Stack>
							<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
								<Button size="large" color="secondary" variant="contained" type="submit">
									Begin
								</Button>
							</Box>
						</Form>
					)}
				</Formik>
			</Stack>
		);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [activeStep]);

	const checkIfDateValid = (date?: string | null) => {
		let valid = true;
		if (!date) {
			valid = false;
		} else {
			if (!isValid(parseISO(date))) {
				valid = false;
			}
		}
		return valid;
	};

	const checkIfDatePast = (date?: string | null) => {
		let valid = true;
		if (!date) {
			valid = false;
		} else {
			if (isPast(parseISO(date))) {
				valid = false;
			}
		}
		return valid;
	};

	const Reason = () => {
		const initialValues: MoratoriumHardshipDetails = {
			endDate,
			hardshipCategoryID,
			note,
		};
		const moratoriumHardshipDetailsSchema = Yup.object().shape({
			endDate: Yup.string()
				.nullable()
				.required('Please select an end date')
				.test('validDate', 'Invalid end date', (value) => checkIfDateValid(value))
				.test('pastDate', 'End date must be in the future', (value) => checkIfDatePast(value)),
			hardshipCategoryID: Yup.string().required('Please select the reason for moratorium'),
			note: Yup.string().required('Details is required').max(845),
		});
		return (
			<Stack spacing={3}>
				<Box>
					<Typography variant="h5" gutterBottom>
						Moratorium request
					</Typography>
					<Typography variant="subtitle1">Reason</Typography>
				</Box>
				<Typography variant="body1">
					Your moratorium request for {moratoriumMonths === '13' ? 'more than 12' : moratoriumMonths} months will end on the{' '}
					{format(parseISO(tempEndDate), dateFormats.prettyShort)}. You can adjust this timeframe as necessary with calendar below:
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={moratoriumHardshipDetailsSchema}
					onSubmit={moratoriumHardshipDetailsSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, handleChange, setFieldValue, touched, values }) => (
						<Form autoComplete="off">
							<Stack spacing={3}>
								<Box>
									<DatePicker
										disablePast
										label="End Date"
										inputFormat="dd/MM/yyyy"
										value={isDate(parseISO(values.endDate)) ? parseISO(values.endDate) : null}
										minDate={MIN_DATE}
										defaultCalendarMonth={MIN_DATE}
										onChange={(newValue) => {
											setFieldValue('endDate', isValid(newValue) ? newValue?.toISOString() : newValue, true);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(touched.endDate && errors.endDate)}
												helperText={touched.endDate && errors.endDate}
												name="endDate"
												onBlur={handleBlur}
											/>
										)}
									/>
								</Box>
								<Box>
									<Typography variant="subtitle2" gutterBottom>
										Which option best describes why you are applying for moratorium?
									</Typography>
									<TextField
										select
										error={Boolean(touched.hardshipCategoryID && errors.hardshipCategoryID)}
										fullWidth
										helperText={touched.hardshipCategoryID && errors.hardshipCategoryID}
										value={values.hardshipCategoryID}
										name="hardshipCategoryID"
										onBlur={handleBlur}
										onChange={handleChange}
										SelectProps={{
											displayEmpty: true,
										}}
									>
										<MenuItem disabled style={{ display: 'none' }} value="">
											Please select one
										</MenuItem>
										{menuItemFor(HardshipCategory.UNEMPLOYMENT)}
										{menuItemFor(HardshipCategory.OVER_COMMITTED)}
										{menuItemFor(HardshipCategory.ILLNESS)}
										{menuItemFor(HardshipCategory.DISABILITY)}
										{menuItemFor(HardshipCategory.INJURY)}
										{menuItemFor(HardshipCategory.DOMESTIC_FAMILY_VIOLENCE)}
										{menuItemFor(HardshipCategory.COVID)}
										{menuItemFor(HardshipCategory.ELDER_ABUSE)}
										{menuItemFor(HardshipCategory.FINANCIAL_ABUSE)}
										{menuItemFor(HardshipCategory.OTHER)}
									</TextField>
								</Box>
								<Box>
									<Typography variant="subtitle2" gutterBottom>
										Please briefly detail why a moratorium is being requested.
									</Typography>
									<TextField
										error={Boolean(touched.note && errors.note)}
										fullWidth
										helperText={touched.note && errors.note}
										name="note"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.note}
										variant="outlined"
										multiline
										rows={5}
										placeholder="Type in your message in here"
									/>
								</Box>
							</Stack>
							<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
								<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack}>
									Back
								</Button>
								<Button size="large" color="secondary" type="submit" variant="contained" endIcon={<ArrowForwardOutlinedIcon />}>
									Continue
								</Button>
							</Stack>
						</Form>
					)}
				</Formik>
			</Stack>
		);
	};

	const Documents = () => {
		const initialValues: FileUpload = {
			files,
		};
		const moratoriumHardshipDetailsSchema = Yup.object().shape({
			files: Yup.array()
				.nullable()
				.max(
					NO_FILES,
					`Only a maximum of ${NO_FILES} files allowed, please email ${window.config.REACT_APP_CONTACT_EMAIL} any additional documents`,
				)
				.test(
					'fileSize',
					`Individual file size or total is too large, please email ${window.config.REACT_APP_CONTACT_EMAIL} documents`,
					(value) => checkIfFilesAreTooBig(value as File[]),
				)
				.test('fileType', `Unsupported file types, please email ${window.config.REACT_APP_CONTACT_EMAIL} documents`, (value) =>
					checkIfFilesAreCorrectType(value as File[]),
				)
				.test(
					'fileNames',
					`Unsupported file names, file name should only contain alpha numeric, space, underscore, hyphen, period and parentheses characters`,
					(value) => checkIfFilesAreNamedCorrectly(value as File[]),
				),
		});

		return (
			<Stack spacing={3}>
				<Box>
					<Typography variant="h5" gutterBottom>
						Moratorium request
					</Typography>
					<Typography variant="subtitle1">Documents</Typography>
				</Box>
				<Typography variant="body1">Please upload any supporting documentation relevant to your request below.</Typography>
				<Typography variant="body1">
					Note: A decision may be made based on the documents submitted below. Please ensure you provide all relevant documentation
					necessary in substantiating your request.
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={moratoriumHardshipDetailsSchema}
					onSubmit={moratoriumDocumentsSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, setFieldValue, touched, values }) => (
						<Form autoComplete="off">
							<UploadComponent
								setFieldValue={setFieldValue}
								name="files"
								value={values.files}
								error={Boolean(touched.files && errors.files)}
								helperText={touched.files && errors.files}
							/>
							<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
								<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack}>
									Back
								</Button>
								<Button size="large" color="secondary" type="submit" variant="contained">
									Continue
								</Button>
							</Stack>
						</Form>
					)}
				</Formik>
			</Stack>
		);
	};

	const PaymentPlan = () => {
		const numberOfDays = differenceInDays(parseISO(endDate), startOfDay(new Date()));
		const isArrangmentFuture =
			recurringArrangements.length > 0 ? isAfter(parseISO(recurringArrangements[0].CommencementDate), new Date()) : false;
		const isStatBarred = debt?.StatBarredDate ? differenceInDays(startOfDay(parseISO(debt.StatBarredDate)), parseISO(endDate)) <= 60 : false;
		const allowPause =
			recurringArrangements.length > 0 &&
			numberOfDays <= 93 &&
			!isArrangmentFuture &&
			!isStatBarred &&
			upcomingPayments.length > 0 &&
			isAfter(parseISO(upcomingPayments[upcomingPayments.length - 1].Date), parseISO(endDate));
		return (
			<Box>
				<Stack spacing={3} width="100%">
					<Box>
						<Typography variant="h5" gutterBottom>
							Active Payment Plan
						</Typography>
					</Box>
					{!allowPause && (
						<Typography variant="body1">
							This moratorium will not affect the current payment plans or one-off payments scheduled - if you want to adjust these,
							please call in{' '}
							<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF} noWrap>
								{window.config.REACT_APP_CONTACT_NUMBER}
							</Link>{' '}
							for assistance.
						</Typography>
					)}
					{allowPause && (
						<Typography variant="body1">
							Before submitting your request to our hardship team, please advise if you would like to pause or continue the current
							payment plan during moratorium time.
						</Typography>
					)}
				</Stack>
				{!allowPause && (
					<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
						<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack}>
							Back
						</Button>
						<Button
							size="large"
							color="secondary"
							variant="contained"
							onClick={() => moratoriumPaymentDetailsSubmit({ pausePayments: false, arrangementID: undefined })}
						>
							Continue
						</Button>
					</Stack>
				)}
				{allowPause && (
					<Box>
						<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
							<Button
								variant="outlined"
								onClick={() =>
									moratoriumPaymentDetailsSubmit({ pausePayments: true, arrangementID: recurringArrangements[0].ArrangementID })
								}
							>
								Pause payment plan
							</Button>
							<Button
								size="large"
								color="secondary"
								variant="contained"
								onClick={() => moratoriumPaymentDetailsSubmit({ pausePayments: false, arrangementID: undefined })}
							>
								Continue payment plan
							</Button>
						</Stack>
						<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
							<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack}>
								Back
							</Button>
						</Box>
					</Box>
				)}
			</Box>
		);
	};

	const Summary = () => {
		return (
			<Stack spacing={3} width="100%">
				<Box>
					<Typography variant="h5" gutterBottom>
						Moratorium request
					</Typography>
					<Typography variant="subtitle1">Summary</Typography>
				</Box>
				<StyledList>
					<li>
						Your Moratorium Request is scheduled to finish on the <strong>{format(parseISO(endDate), dateFormats.prettyShort)}</strong>
					</li>
					<li>
						You are submitting a Moratorium Request due to: <strong>{convertHardshipCategoryToString(Number(hardshipCategoryID))}</strong>
					</li>
					<li>
						You have provided the following additional text to support your request:
						<br />
						<br />
						<span style={{ marginLeft: 16 }}>{note}</span>
						<br />
						<br />
					</li>
					<li>
						You have chosen to provide the following documents:{' '}
						{(files?.length ?? 0) === 0 && (
							<Typography variant="subtitle1">
								<em>No Documents</em>
							</Typography>
						)}
						{files?.map((file) => (
							<Typography key={file.name} variant="subtitle1">
								&#8226; {file.name}
							</Typography>
						))}
					</li>
					{recurringArrangements.length > 0 && (
						<li>
							The customers current payment plan will <strong>{pausePayments ? 'be paused' : 'not be affected'}</strong>
						</li>
					)}
				</StyledList>
				{recurringArrangements.length > 0 && pausePayments && (
					<Typography variant="body1">
						Please ensure all payments within 3 business days of this request have either been processed or are available for process as
						they will not be affected by this moratorium hold. Any proceeding payments will be paused.
					</Typography>
				)}
				<Typography variant="body1">
					<strong>Please click Finish to submit your request through to our Customer Care team.</strong> Alternatively, click back to edit
					your responses.
				</Typography>
				<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
					<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack}>
						Back
					</Button>
					<LoadingButton
						loading={moratoriumStatus === Status.LOADING}
						size="large"
						color="secondary"
						variant="contained"
						onClick={moratoriumSubmit}
					>
						Finish
					</LoadingButton>
				</Stack>
			</Stack>
		);
	};

	const AutoOutcome = () => {
		const numberOfMonths = differenceInMonths(parseISO(endDate), startOfDay(new Date()));
		return (
			<Stack spacing={4} width="100%">
				<Box>
					<Typography variant="h5" gutterBottom>
						{pageTitle}
					</Typography>
					<Typography variant="subtitle1">Outcome</Typography>
				</Box>
				<Typography variant="body1" gutterBottom>
					Great News - Moratorium has been approved! We want to help you get back on track - we are happy to suspend all actions on your
					account and usual communications{' '}
					<strong>
						{numberOfMonths ? `for ${numberOfMonths} months, ` : ''} until {format(parseISO(endDate), dateFormats.prettyShort)}
					</strong>
					.
				</Typography>
				<Box display="flex" justifyContent="center" width="100%">
					<Card square sx={{ maxWidth: 500 }}>
						<CardContent>
							<Stack spacing={2} sx={{ textAlign: 'center' }}>
								<Box>
									<ClockIcon width={95} height={94} />
								</Box>
								<Box>
									<Typography variant="h5" color="primary">
										Moratorium applied until {format(parseISO(endDate), dateFormats.prettyShort)}
									</Typography>
								</Box>
								<Box>
									<List>
										<ListItem>
											<ListItemIcon>
												<CheckCircleOutlineOutlinedIcon color="primary" />
											</ListItemIcon>
											<ListItemText primary="No usual communications" />
										</ListItem>
										<ListItem>
											<ListItemIcon>
												<CheckCircleOutlineOutlinedIcon color="primary" />
											</ListItemIcon>
											<ListItemText primary="No actions taken against the account" />
										</ListItem>
										{(debt?.InterestRate ?? 0) > 0 && (
											<ListItem>
												<ListItemIcon>
													<CheckCircleOutlineOutlinedIcon color="primary" />
												</ListItemIcon>
												<ListItemText primary="Temporary Interest hold throughout moratorium" />
											</ListItem>
										)}
									</List>
								</Box>
							</Stack>
						</CardContent>
					</Card>
				</Box>
				<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
					<Button size="large" color="secondary" variant="contained" component={routerLink} to={ClientRoutes.DASHBOARD}>
						Back to Dashboard
					</Button>
				</Box>
			</Stack>
		);
	};

	const ManualOutcome = () => {
		const numberOfMonths = differenceInMonths(parseISO(endDate), startOfDay(new Date()));
		return (
			<Stack spacing={3}>
				<Typography align="center" variant="h5" component="p">
					Thank you!
				</Typography>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start">
					<ChampionsCupIcon height={121} width={121} />
				</Grid>
				<Typography variant="body1">
					Thank you for submitting a Moratorium Request{' '}
					<strong>
						{numberOfMonths ? `for ${numberOfMonths} months, ` : ''} until {format(parseISO(endDate), dateFormats.prettyShort)}
					</strong>
				</Typography>
				<Typography variant="body1">
					Please be advised, our hardship team will review and advise of a resolution or update within 21 days. In the meantime, feel free
					to contact one of our friendly staff on{' '}
					<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF} noWrap>
						{window.config.REACT_APP_CONTACT_NUMBER}
					</Link>{' '}
					for assistance.
				</Typography>
				<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
					<Button size="large" color="secondary" variant="contained" component={routerLink} to={ClientRoutes.DASHBOARD}>
						Back to Dashboard
					</Button>
				</Box>
			</Stack>
		);
	};

	const Outcome = () => {
		return <>{moratoriumOutcome && moratoriumOutcome === HardshipResult.AUTO ? <AutoOutcome /> : <ManualOutcome />}</>;
	};

	const renderStepContent = (step: number) => {
		let conditionalStep = step;
		if (arrangements.length === 0) {
			conditionalStep = step > 2 ? step + 1 : step;
		}
		switch (conditionalStep) {
			case 0:
				return <Start />;
			case 1:
				return <Reason />;
			case 2:
				return <Documents />;
			case 3:
				return <PaymentPlan />;
			case 4:
				return <Summary />;
			case 5:
				return <Outcome />;
			default:
				return 'unknown step';
		}
	};

	return (
		<Page title={pageTitle}>
			<FullScreenModal
				open={fileWarningModalOpen}
				onClose={() => {
					SetFileWarningModalOpen(false);
				}}
			>
				<Stack spacing={3}>
					<Grid container direction="row" justifyContent="center" alignItems="flex-start">
						<PageIcon width={98} height={110} />
					</Grid>
					<Typography align="center" variant="h5" component="p">
						Warning!
					</Typography>
					<Typography variant="body1" textAlign="center">
						We notice you have not supplied supporting documentation with your Moratorium Request.
					</Typography>
					<Typography variant="body1" textAlign="center">
						Please ensure you are aware that your request will be assessed using the information provided by yourself and that by not
						providing supporting documentation, we may not gain a comprehensive understanding of the customers situation.
					</Typography>
					<Typography variant="body1" textAlign="center">
						Please click continue to confirm you understand the above. Otherwise, feel free to close window and upload the relevant
						documentation.
					</Typography>
				</Stack>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start" mt={6}>
					<Grid item xs={8}>
						<Stack spacing={3}>
							<Button
								size="large"
								color="secondary"
								variant="contained"
								onClick={() => {
									handleContinue();
									SetFileWarningModalOpen(false);
								}}
							>
								Continue
							</Button>
							<Button size="large" color="primary" variant="outlined" onClick={() => SetFileWarningModalOpen(false)}>
								Close
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</FullScreenModal>
			<Container maxWidth="xl" sx={{ ml: 0 }}>
				<ClientBreadCrumbs childCrumb={pageTitle} />
				{isLoading && (
					<Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, minHeight: '100%', width: '100%', justifyContent: 'center' }}>
						<CircularProgress color="secondary" size={150} />
					</Box>
				)}
				{!isLoading && isBlocked && (
					<Card elevation={0}>
						<CardContent>
							<Container maxWidth="sm">
								<Stack spacing={3}>
									<Grid container direction="row" justifyContent="center" alignItems="flex-start">
										<WarningIcon width={121} height={121} />
									</Grid>
									<Typography variant="h5" gutterBottom textAlign="center">
										Warning
									</Typography>
									{hardship && (
										<>
											<Typography variant="subtitle1" textAlign="center">
												Unfortunately, we cannot proceed with your Moratorium Request!
											</Typography>
											<Typography variant="subtitle1" textAlign="center">
												Your clients account already has a hardship request placed and is yet to be resolved. Feel free to
												contact one of our friendly staff on{' '}
												<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
													{window.config.REACT_APP_CONTACT_NUMBER}
												</Link>{' '}
												for further assistance.
											</Typography>
										</>
									)}
									{!hardship && activeHold && (
										<>
											<Typography variant="subtitle1" textAlign="center">
												Unfortunately, we cannot proceed with your Moratorium Request!
											</Typography>
											<Typography variant="subtitle1" textAlign="center">
												Your clients account already has an active hold. Feel free to contact one of our friendly staff on{' '}
												<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
													{window.config.REACT_APP_CONTACT_NUMBER}
												</Link>{' '}
												for further assistance.
											</Typography>
										</>
									)}
								</Stack>
								<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
									<Button size="large" color="secondary" variant="contained" component={routerLink} to={ClientRoutes.DASHBOARD}>
										Back to Dashboard
									</Button>
								</Box>
							</Container>
						</CardContent>
					</Card>
				)}
				{!isLoading && !isBlocked && (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={9} lg={9}>
							<Card elevation={0}>
								<CardContent>
									<Grid container alignItems="stretch" spacing={2}>
										<Grid item xs={12} sm={12} md={4} lg={3} sx={{ display: 'flex', order: { xs: 2, md: 1 } }}>
											{displayStepperHorizontal ? (
												<HorizontalStepper activeStep={activeStep} steps={steps} sx={{ marginTop: 8, width: '100%' }} />
											) : (
												<VerticalStepper
													activeStep={activeStep}
													steps={steps}
													sx={{ maxHeight: arrangements.length ? 450 : 280 }}
												/>
											)}
										</Grid>
										<Grid item xs={12} sm={12} md={8} lg={9} sx={{ display: 'flex', order: { xs: 1, md: 2 } }}>
											{renderStepContent(activeStep)}
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} sm={12} md={3} lg={3}>
							<AccountInformationSidebar />
						</Grid>
					</Grid>
				)}
			</Container>
		</Page>
	);
};
