import { Card, CardContent, TableContainer, Table, TableCell, TableHead, TableRow, TableBody, TablePagination, CardHeader } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { PerfectScrollbar } from '../../../../components/PerfectScrollbar';
import { TableEmpty } from '../../../../components/TableEmpty';
import { TableLoader } from '../../../../components/TableLoader';
import { formatMoney } from '../../../../utils/text.utill';
import { dateFormats } from '../../../../constants/dateFormat.constant';
import { IReportArrangements } from './types';

type TableResultsProps = {
	data: IReportArrangements[] | undefined;
	page: number;
	rowsPerPage: number;
	count: number;
	handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
	handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	loading: boolean;
};

const COL_LENGTH = 9;

export const TableResults: React.FC<TableResultsProps> = ({ data, page, rowsPerPage, count, handleChangePage, handleChangeRowsPerPage, loading }) => {
	return (
		<Card elevation={0}>
			<CardHeader title="Details" />
			<CardContent sx={{ pt: 0 }}>
				<TableContainer component={PerfectScrollbar}>
					<Table sx={{ minWidth: 600 }}>
						<TableHead>
							<TableRow>
								<TableCell>Reference</TableCell>
								<TableCell>Customer</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Frequency</TableCell>
								<TableCell>Method</TableCell>
								<TableCell align="right">Payments Processed</TableCell>
								<TableCell align="right">Payments Remaining</TableCell>
								<TableCell align="right">Next Payment Date</TableCell>
								<TableCell align="right">Last Payment Date</TableCell>
							</TableRow>
						</TableHead>
						{loading && <TableLoader rows={5} colSpan={COL_LENGTH} />}
						{!loading && (data?.length ?? 0) === 0 && <TableEmpty text="No payment plans found." colSpan={COL_LENGTH} />}
						{!loading && (data?.length ?? 0) > 0 && (
							<TableBody>
								{data
									?.slice()
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((arrangement, index) => {
										const isOneOff = arrangement.PaymentFrequency === 'One Off';
										return (
											// eslint-disable-next-line react/no-array-index-key
											<TableRow key={index}>
												<TableCell component="th" scope="row">
													{arrangement.Reference}
												</TableCell>
												<TableCell>{arrangement.Fullname}</TableCell>
												<TableCell>{formatMoney(arrangement.Amount)}</TableCell>
												<TableCell>{arrangement.PaymentFrequency}</TableCell>
												<TableCell>{arrangement.PaymentMethod}</TableCell>
												<TableCell align="right">{isOneOff ? '-' : arrangement.PaymentsProcessed}</TableCell>
												<TableCell align="right">{isOneOff ? '-' : arrangement.PaymentsRemaining}</TableCell>
												<TableCell align="right">
													{format(parseISO(arrangement.NextPaymentDate), dateFormats.prettyShort)}
												</TableCell>
												<TableCell align="right">
													{isOneOff ? '-' : format(parseISO(arrangement.LastPaymentDate), dateFormats.prettyShort)}
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						)}
					</Table>
				</TableContainer>
				{count > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={count}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="Per page:"
						sx={{ mt: 2 }}
					/>
				)}
			</CardContent>
		</Card>
	);
};
