import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../enums/status.enum';
import { fcAPI } from '../../../services/api/api';
import { FCResponse } from '../../../services/api/base.api.response';
import { handleAsyncThunkError } from '../../hooks';
import { RootState } from '../../store';
import { setErrorSnackbarOpen } from '../app/appSlice';
import { setUser } from '../auth/authSlice';
import { IFCUserOutput, IGetUserDetails, UserAPI } from './types';

export const userDetailsGet = createAsyncThunk('user/details', async (_, { rejectWithValue, dispatch }) => {
	try {
		const { data } = await fcAPI.get<IGetUserDetails>(UserAPI.DETAILS);
		if (data.success) {
			if (data.data.jwt) {
				dispatch(setUser(data.data.jwt));
			}
			return data.data.details;
		}
		dispatch(setErrorSnackbarOpen({ message: data.message }));
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export const userUpdatePasswordPost = createAsyncThunk(
	'user/updatePassword',
	async (updatePasswordDetails: { currentPassword: string; password: string }, { rejectWithValue, dispatch }) => {
		try {
			const { data } = await fcAPI.post<FCResponse>(UserAPI.UPDATE_PASSWORD, updatePasswordDetails);
			if (data.success) {
				return data.message;
			}
			dispatch(setErrorSnackbarOpen({ message: data.message }));
			return rejectWithValue(data.message);
		} catch (err) {
			return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
		}
	},
);

export interface UserState {
	details: IFCUserOutput | undefined;
	status: Status;
}

const initialState: UserState = {
	details: undefined,
	status: Status.IDLE,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(userDetailsGet.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(userDetailsGet.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.details = action.payload;
			})
			.addCase(userDetailsGet.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default userSlice.reducer;

export const selectUserDetails = (state: RootState) => state.user.details;
export const selectUserDetailsStatus = (state: RootState) => state.user.status;
