import {
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { PerfectScrollbar } from '../../../components/PerfectScrollbar';
import { TableLoader } from '../../../components/TableLoader';
import { dateFormats } from '../../../constants/dateFormat.constant';
import { Status } from '../../../enums/status.enum';
import {
	getClientArrangementsActive,
	selectActiveArrangements,
	selectArrangementsStatus,
} from '../../../store/features/client/arrangements/arrangementsSlice';
import {
	getUpcomingPaymentSchedule,
	selectUpcomingPayments,
	selectUpcomingPaymentsStatus,
} from '../../../store/features/client/arrangements/scheduleSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { formatMoney } from '../../../utils/text.utill';

const pageTitle = 'Active Payment Plan';

export const PaymentPlansActive: React.FC = () => {
	const dispatch = useAppDispatch();

	const loadStatus = useAppSelector(selectArrangementsStatus);
	const arrangements = useAppSelector(selectActiveArrangements);
	const ids = arrangements.map((x) => x.ArrangementID);

	const upcomingPayments = useAppSelector(selectUpcomingPayments);
	const upcomingStatus = useAppSelector(selectUpcomingPaymentsStatus);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [count, setCount] = useState(0);

	useEffect(() => {
		if (loadStatus !== Status.LOADING) {
			dispatch(getClientArrangementsActive());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (arrangements.length > 0 && ids.length > 0) {
			dispatch(getUpcomingPaymentSchedule({ arrangementIDs: ids, pageNumber: page, pageSize: rowsPerPage }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, arrangements.length]);

	useEffect(() => {
		if (upcomingPayments && upcomingPayments.length > 0) {
			setCount(upcomingPayments[0].TotalRows);
		}
	}, [upcomingPayments, dispatch]);

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
		if (page !== newPage) {
			dispatch(getUpcomingPaymentSchedule({ arrangementIDs: ids, pageNumber: newPage, pageSize: rowsPerPage }));
		}
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newRows = parseInt(event.target.value, 10);
		setRowsPerPage(newRows);
		setPage(0);
		if (rowsPerPage !== newRows) {
			dispatch(getUpcomingPaymentSchedule({ arrangementIDs: ids, pageNumber: 0, pageSize: newRows }));
		}
	};

	return (
		<div>
			{loadStatus === Status.LOADING && <CircularProgress color="secondary" />}
			{loadStatus === Status.SUCCEEDED && arrangements && arrangements.length > 0 && (
				<Grid container spacing={1}>
					<Grid item xs={12} md={6}>
						{arrangements.map((pa, i) => (
							<Grid item key={pa.ArrangementID}>
								<Card elevation={0} sx={{ marginBottom: 1 }}>
									<CardHeader title={pageTitle} subheader={`#${pa.ArrangementID}`} />
									<CardContent sx={{ paddingTop: 0 }}>
										<Table aria-label="simple table">
											<TableBody sx={{ '& td, & th': { border: 0 } }}>
												<TableRow>
													<TableCell>
														<Typography variant="h7">{formatMoney(pa.Amount)}</Typography>
														<Typography variant="body2">Amount</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h7">{pa.AddedByUser}</Typography>
														<Typography variant="body2">Created By</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography variant="h7">{pa.PeriodicFrequencyDesc}</Typography>
														<Typography variant="body2">Frequency</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h7">
															{format(parseISO(pa.AddedDate), dateFormats.prettyDatetime)}
														</Typography>
														<Typography variant="body2">Date Added</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography variant="h7">{pa.PaymentArrangementTypeDesc}</Typography>
														<Typography variant="body2">Arrangement Type</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h7">
															{format(parseISO(pa.CommencementDate), dateFormats.prettyShort)}
														</Typography>
														<Typography variant="body2">Commencement Date</Typography>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography variant="h7">{pa.PaymentMethodDesc}</Typography>
														<Typography variant="body2">Payment Method</Typography>
													</TableCell>
													{pa?.LastPaymentDate && (
														<TableCell>
															<Typography variant="h7">
																{format(parseISO(pa.LastPaymentDate), dateFormats.prettyShort)}
															</Typography>
															<Typography variant="body2">Last Payment Date</Typography>
														</TableCell>
													)}
												</TableRow>
												{pa?.NumberOfPaymentsLeft && (
													<TableRow>
														<TableCell>
															<Typography variant="h7">{pa.NumberOfPaymentsLeft}</Typography>
															<Typography variant="body2">Number of payments left</Typography>
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</CardContent>
								</Card>
							</Grid>
						))}
					</Grid>
					<Grid item xs={12} md={6}>
						<Card elevation={0}>
							<CardContent>
								<Typography variant="h6">Upcoming Payments</Typography>
								<TableContainer component={PerfectScrollbar}>
									<Table>
										{upcomingStatus === Status.LOADING && <TableLoader rows={5} colSpan={4} />}
										{upcomingStatus === Status.SUCCEEDED && count > 0 && (
											<>
												<TableHead>
													<TableRow>
														<TableCell>
															<strong>Amount</strong>
														</TableCell>
														<TableCell>
															<strong>Date</strong>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{upcomingPayments.map((item, i) => (
														<TableRow key={item.ScheduleID}>
															<TableCell>{formatMoney(item.Amount)}</TableCell>
															<TableCell component="th" scope="row">
																{format(parseISO(item.Date.toString()), dateFormats.prettyShort)}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</>
										)}
									</Table>
								</TableContainer>
								{count > 0 && (
									<TablePagination
										rowsPerPageOptions={[10, 25, 50]}
										component="div"
										count={count}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										labelRowsPerPage="Per page:"
										sx={{ mt: 2 }}
									/>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			)}
			{(loadStatus === Status.SUCCEEDED || loadStatus === Status.FAILED) && arrangements.length < 1 && (
				<Card elevation={0}>
					<CardHeader title={pageTitle} />
					<CardContent sx={{ pt: 0 }}>
						<Typography variant="body1">No active payment plans found.</Typography>
					</CardContent>
				</Card>
			)}
		</div>
	);
};
