import { FCResponse } from '../../../services/api/base.api.response';

export enum DashboardAPI {
	RECENT_ACTIVITY = '/api/clients/activity',
}

export interface IRecentActivityOutput {
	DebtID: number;
	DebtorEntityID: number;
	FCAccessID: number;
	ActivityType: string;
	ActivityDate: string;
	ActivityNote: string;
	TotalCount: number;
}

export type IRecentActivity = FCResponse<{ activity: IRecentActivityOutput[] }>;
