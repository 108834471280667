import { styled, Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
	minHeight: 60,
	paddingLeft: theme.spacing(3),
	paddingRight: theme.spacing(3),
	'& .MuiButtonBase-root': {
		minHeight: 60,
		fontSize: theme.typography.body1.fontSize,
		textTransform: 'none',
		'&.Mui-selected': {
			color: theme.palette.secondary.contrastText,
		},
		paddingLeft: 0,
		paddingRight: 0,
		'&:not(:last-of-type)': {
			marginRight: theme.spacing(4),
		},
	},
	'& .MuiTabs-indicator': {
		height: 3,
	},
}));
