import { FCResponse } from '../../../../services/api/base.api.response';

export enum ClientHoldsAPI {
	HOLD = '/api/client/holds',
}

export enum HoldType {
	CONTACT = 'Contact',
	INTEREST = 'Interest',
}

export interface IAllHoldsOutput {
	HoldType: string;
	AddedDate: string;
	EffectiveDate: string;
	EndDate: string;
	HoldDays: number;
	HoldReason: string;
	Active: boolean;
	ActiveDesc: string;
}

export type IGetClientHolds = FCResponse<{ holds: IAllHoldsOutput[] }>;
