import React from 'react';

export const WarningIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
	<svg fill="none" viewBox="0 0 121 121" {...props}>
		<path
			fill="#FFE082"
			d="M116.803 111.736L63.866 5.861a3.781 3.781 0 00-6.731 0L4.197 111.736a3.783 3.783 0 003.366 5.483h105.875a3.786 3.786 0 003.766-3.623 3.783 3.783 0 00-.401-1.86z"
		></path>
		<path
			fill="#22D07D"
			d="M60.5 86.969a3.781 3.781 0 01-3.781-3.781V49.156a3.78 3.78 0 117.562 0v34.032a3.782 3.782 0 01-3.781 3.78zM60.5 102.094a3.781 3.781 0 100-7.563 3.781 3.781 0 000 7.563z"
		></path>
	</svg>
);
