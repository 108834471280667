import { Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Page } from '../../components/Page';
import { AuthRoutes, PortalRoutes } from '../../constants/routes.constant';
import { AuthWrapper } from './components/AuthWrapper';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../store/hooks';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { loginPost } from '../../store/features/auth/authSlice';
import { loadReCaptchaScript, ReCaptchaAction } from '../../services/recaptcha/recaptcha';

interface LoginDetails {
	email: string;
	password: string;
}

export const LogIn = () => {
	const storageRememberMe: boolean = localStorage.getItem('rememberMe') ? !!JSON.parse(localStorage.getItem('rememberMe') as string) : false;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [rememberMe, setRememberMe] = useState(storageRememberMe);

	useEffect(() => {
		loadReCaptchaScript();
	}, []);

	const initialValues: LoginDetails = {
		email: '',
		password: '',
	};

	const loginDetailsSchema = Yup.object().shape({
		email: Yup.string().email('Must be a valid email').max(512).required('Email is required'),
		password: Yup.string().required('Password is required'),
	});

	const handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRememberMe(event.target.checked);
		localStorage.setItem('rememberMe', event.target.checked.toString());
	};

	const loginSubmit = async (values: LoginDetails) => {
		const captchaToken = await new Promise<string>((resolve) => {
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(window.config.REACT_APP_RECAPTCHA, { action: ReCaptchaAction.LOGIN }).then((token) => resolve(token));
			});
		});
		try {
			const loginSuccess = await dispatch(
				loginPost({ email: values.email, password: values.password, captchaToken, submittedRememberMe: rememberMe }),
			).unwrap();
			if (loginSuccess) {
				navigate(PortalRoutes.DASHBOARD);
			} else {
				navigate(AuthRoutes.FORGOT_PASSWORD, { state: { forceReset: true } });
			}
		} catch {
			/* Handled in Thunk */
		}
	};

	return (
		<Page title="Login">
			<AuthWrapper
				helperLink={
					<Typography variant="subtitle1" component="p">
						Don't have an account? &nbsp;
						<Link underline="hover" component={RouterLink} to={AuthRoutes.SIGN_UP}>
							Sign up
						</Link>
					</Typography>
				}
			>
				<Typography variant="h4" textAlign="center" sx={{ mb: 8 }}>
					Welcome back!
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={loginDetailsSchema}
					onSubmit={loginSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
						<Form>
							<TextField
								error={Boolean(touched.email && errors.email)}
								fullWidth
								helperText={touched.email && errors.email}
								label="Email Address"
								margin="normal"
								name="email"
								onBlur={handleBlur}
								onChange={handleChange}
								type="email"
								value={values.email}
								variant="outlined"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<MailOutlinedIcon />
										</InputAdornment>
									),
									sx: { pr: 1.2 },
								}}
							/>
							<TextField
								error={Boolean(touched.password && errors.password)}
								fullWidth
								helperText={touched.password && errors.password}
								label="Password"
								margin="normal"
								name="password"
								onBlur={handleBlur}
								onChange={handleChange}
								type={showPassword ? 'text' : 'password'}
								value={values.password}
								variant="outlined"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword(!showPassword)}
												onMouseDown={(event) => event.preventDefault()}
												edge="end"
											>
												{showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<Grid container alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
								<Grid item>
									<FormControlLabel
										control={
											<Checkbox
												checked={rememberMe}
												color="primary"
												onChange={handleRememberMe}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										componentsProps={{ typography: { variant: 'body2' } }}
										label="Remember me"
									/>
								</Grid>
								<Grid item>
									<Link variant="body2" underline="hover" component={RouterLink} to={AuthRoutes.FORGOT_PASSWORD}>
										Forgot your password?
									</Link>
								</Grid>
							</Grid>
							<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
								<LoadingButton loading={isSubmitting} size="large" color="secondary" type="submit" variant="contained">
									Login
								</LoadingButton>
							</Stack>
						</Form>
					)}
				</Formik>
			</AuthWrapper>
		</Page>
	);
};
