import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from '../../../../enums/status.enum';
import { fcAPI } from '../../../../services/api/api';
import { handleAsyncThunkError } from '../../../hooks';
import { RootState } from '../../../store';
import { resetViewingClient, ViewingClient } from '../clientSlice';
import { ClientAttachmentsAPI, IClientAttachments, IGetClientAttachmentsOutput } from './types';

export const getClientAttachments = createAsyncThunk('client/attachments', async (_, { rejectWithValue, dispatch, getState }) => {
	try {
		const { DebtID, DebtorEntityID } = (getState() as RootState).client.viewingClient as ViewingClient;
		const { data } = await fcAPI.get<IClientAttachments>(ClientAttachmentsAPI.ATTACHMENTS, {
			params: { debtID: DebtID, entityID: DebtorEntityID },
		});
		if (data.success) {
			return data.data.attachments;
		}
		return rejectWithValue(data.message);
	} catch (err) {
		return rejectWithValue(handleAsyncThunkError(err as Error, dispatch));
	}
});

export interface ClientAttachmentsState {
	attachments: IGetClientAttachmentsOutput[];
	status: Status;
}

const initialState: ClientAttachmentsState = {
	attachments: [],
	status: Status.IDLE,
};

const clientAttachmentsSlice = createSlice({
	name: 'clientAttachments',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(resetViewingClient, () => {
				return initialState;
			})
			.addCase(getClientAttachments.pending, (state) => {
				state.status = Status.LOADING;
			})
			.addCase(getClientAttachments.fulfilled, (state, action) => {
				state.status = Status.SUCCEEDED;
				state.attachments = action.payload;
			})
			.addCase(getClientAttachments.rejected, (state) => {
				state.status = Status.FAILED;
			});
	},
});

export default clientAttachmentsSlice.reducer;

export const selectClientAttachments = (state: RootState) => state.clientAttachments.attachments;
export const selectClientAttachmentsStatus = (state: RootState) => state.clientAttachments.status;
