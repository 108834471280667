import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { LoadingButton } from '@mui/lab';
import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	Checkbox,
	CircularProgress,
	Container,
	Divider,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Grid,
	InputAdornment,
	Link,
	Stack,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addDays, format, isAfter, isDate, isPast, isValid as isValidDate, isWeekend, parseISO, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Link as routerLink } from 'react-router-dom';
import * as Yup from 'yup';
import { FullScreenModal } from '../../components/FullScreenModal';
import { HorizontalStepper } from '../../components/HorizontalStepper';
import { Page } from '../../components/Page';
import { VerticalStepper } from '../../components/VerticalStepper';
import { dateFormats } from '../../constants/dateFormat.constant';
import { ClientRoutes } from '../../constants/routes.constant';
import { PaymentMethodID, PeriodicFrequencyID } from '../../enums/arrangement.enum';
import { RegExEnum } from '../../enums/regex.enum';
import { Status } from '../../enums/status.enum';
import { BpayIcon } from '../../icons/BpayIcon';
import { ChampionsCupIcon } from '../../icons/ChampionsIcon';
import { DepositIcon } from '../../icons/DepositIcon';
import { DirectDebitIcon } from '../../icons/DirectDebitIcon';
import { WarningIcon } from '../../icons/WarningIcon';
import { fcAPI } from '../../services/api/api';
import { FCResponse } from '../../services/api/base.api.response';
import {
	ArrangementPaymentDetails,
	ArrangementScheduleDetails,
	clientArrangementPost,
	IDirectDebit,
	resetArrangement,
	selectClientArrangementActiveStep,
	selectClientArrangementDetails,
	selectClientArrangementOutcome,
	selectClientArrangementStatus,
	setArrangementActiveStep,
	setPaymentDetails,
	setPaymentScheduleDetails,
} from '../../store/features/client/arrangement/arrangementSlice';
import {
	getClientArrangementsActive,
	selectActiveArrangements,
	selectArrangementsStatus,
} from '../../store/features/client/arrangements/arrangementsSlice';
import { selectClientDebtAmounts } from '../../store/features/client/debt/amountSlice';
import { clientDebtGet, selectClientDebt } from '../../store/features/client/debt/debtSlice';
import { selectClientDebtor } from '../../store/features/client/debtor/debtorSlice';
import { clientHardshipGet, selectClientHardship, selectClientHardshipStatus } from '../../store/features/client/hardship/hardshipSlice';
import { selectUserDetails } from '../../store/features/user/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { calculatePaymentInYears } from '../../utils/payments.util';
import { convertPaymentFreqToDescString, convertPaymentMethodToString, formatMoney, getFullName } from '../../utils/text.utill';
import { ClientBreadCrumbs } from './components/ClientBreadCrumbs';
import { PaymentTermsConditionsDialog } from './components/PaymentTermsModal';

const MAX_DATE = startOfDay(addDays(new Date(), 30));
const MIN_DATE = startOfDay(addDays(new Date(), 1));

const pageTitle = 'Setup Payments';

type IBSB = FCResponse<{ bsb: string }>;

export const validateBsbAsync = async (value: string | null | undefined) => {
	if (!value || !/^\d{3}-\d{3}$/.test(value)) return false;
	const formattedBsb = value.replace(/-/g, '');
	const result = await fcAPI.get<IBSB>('/api/client/bankbranch', {
		params: {
			bsb: formattedBsb,
		},
	});
	return result.data.success;
};

// https://github.com/formium/formik/issues/512#issuecomment-666549238
export const cacheTest = (asyncValidate: (value: string | null | undefined) => Promise<boolean>) => {
	let valid = false;
	let cacheValue: string | null | undefined;

	return async (value: string | null | undefined) => {
		if (value !== cacheValue) {
			const response = await asyncValidate(value);
			cacheValue = value;
			valid = response;
			return response;
		}
		return valid;
	};
};

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const BSBMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function BSBMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="000-000"
			placeholderChar="x"
			lazy={false}
			overwrite={true}
			inputRef={ref as (instance: HTMLElement | null) => void}
			onAccept={(value: string) => onChange({ target: { name: props.name, value } })}
		/>
	);
});

export const BSBAlert = () => (
	<Alert severity="error" sx={{ marginBottom: 16 }}>
		Sorry we do not recognise that BSB as belonging to an Australian banking association. Please check the details entered and contact us via if
		you need further assistance with setting up payment.
	</Alert>
);

export const BSBInvalidMsg = 'Invalid BSB';

export const ClientPayments = () => {
	const dispatch = useAppDispatch();
	const bsbValidator = useRef(cacheTest(validateBsbAsync));
	const activeStep = useAppSelector(selectClientArrangementActiveStep);
	const arrangementStatus = useAppSelector(selectClientArrangementStatus);
	const arrangementOutcome = useAppSelector(selectClientArrangementOutcome);
	const hardshipStatus = useAppSelector(selectClientHardshipStatus);
	const arrangementsStatus = useAppSelector(selectArrangementsStatus);
	const hardship = useAppSelector(selectClientHardship);
	const arrangements = useAppSelector(selectActiveArrangements);
	const {
		paymentAmount,
		periodicFrequencyID,
		paymentDate,
		paymentMethodID,
		termsAccepted,
		authorityAccepted,
		authorityBankAccepted,
		directDebit,
		outcome,
	} = useAppSelector(selectClientArrangementDetails);
	const outstanding = useAppSelector(selectClientDebtAmounts)?.Outstanding;
	const debtor = useAppSelector(selectClientDebtor);
	const debt = useAppSelector(selectClientDebt);
	const user = useAppSelector(selectUserDetails);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [paymentLengthModalOpen, SetPaymentLengthModalOpen] = useState(false);

	const isLoading = hardshipStatus !== Status.SUCCEEDED && arrangementsStatus !== Status.SUCCEEDED;
	const steps = ['Payment Schedule', 'Agreement', 'Outcome'];
	const isBlocked = (hardship || arrangements.length > 0) && !arrangementOutcome;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [activeStep]);

	useEffect(() => {
		if (hardshipStatus === Status.IDLE) {
			dispatch(clientHardshipGet());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hardshipStatus, dispatch]);

	useEffect(() => {
		if (arrangementsStatus === Status.IDLE) {
			dispatch(getClientArrangementsActive());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arrangementStatus, dispatch]);

	// Reset Arrangement on Unmount
	useLayoutEffect(() => {
		return () => {
			dispatch(resetArrangement());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleContinue = () => {
		dispatch(setArrangementActiveStep(activeStep + 1));
	};

	const handleBack = () => {
		dispatch(setArrangementActiveStep(activeStep - 1));
	};

	const arrangementScheduleDetailsSubmit = (values: ArrangementScheduleDetails) => {
		dispatch(setPaymentScheduleDetails(values));
		if (
			values.periodicFrequencyID &&
			values.periodicFrequencyID !== PeriodicFrequencyID.ONE_OFF &&
			(outstanding ?? 0) > 0 &&
			calculatePaymentInYears(outstanding ?? 0, parseFloat(values.paymentAmount), values.periodicFrequencyID) >= 7
		) {
			SetPaymentLengthModalOpen(true);
		}
		handleContinue();
	};

	const arrangementSubmit = async (values: ArrangementPaymentDetails & IDirectDebit & { paymentMethodID: PaymentMethodID | undefined }) => {
		try {
			const { termsAccepted: terms, authorityAccepted: authority, authorityBankAccepted: authorityBank } = values;
			const directDebitDetails: IDirectDebit | undefined =
				values.paymentMethodID === PaymentMethodID.DIRECT_DEBIT
					? { bsb: values.bsb.replace(/-/g, ''), accountNumber: values.accountNumber, accountName: values.accountName, suffix: '' }
					: undefined;
			dispatch(
				setPaymentDetails({
					termsAccepted: terms,
					authorityAccepted: authority,
					authorityBankAccepted: authorityBank,
					directDebit: directDebitDetails,
				}),
			);
			const clientArrangementSuccess = await dispatch(
				clientArrangementPost({ paymentAmount, paymentDate, paymentMethodID, periodicFrequencyID, directDebit: directDebitDetails }),
			).unwrap();
			if (clientArrangementSuccess) {
				dispatch(clientDebtGet());
				dispatch(getClientArrangementsActive());
				handleContinue();
			}
		} catch {
			/* Handled in Thunk */
		}
	};

	const checkIfDateValid = (date?: string | null) => {
		let valid = true;
		if (!date) {
			valid = false;
		} else {
			if (!isValidDate(parseISO(date))) {
				valid = false;
			}
		}
		return valid;
	};

	const checkIfDatePast = (date?: string | null) => {
		let valid = true;
		if (!date) {
			valid = false;
		} else {
			if (isPast(parseISO(date))) {
				valid = false;
			}
		}
		return valid;
	};

	const checkIfMaxDate = (date?: string | null) => {
		let valid = true;
		if (!date) {
			valid = false;
		} else {
			if (isAfter(startOfDay(parseISO(date)), MAX_DATE)) {
				valid = false;
			}
		}
		return valid;
	};

	const checkIfWeekend = (date?: string | null) => {
		let valid = true;
		if (!date) {
			valid = false;
		} else {
			if (isWeekend(parseISO(date))) {
				valid = false;
			}
		}
		return valid;
	};

	const disableWeekends = (day: Date) => isWeekend(day);

	const ScheduleDetails = () => {
		const [isPIF, setIsPIF] = React.useState(false);
		const initialValues: ArrangementScheduleDetails = {
			paymentAmount,
			periodicFrequencyID,
			paymentDate,
			paymentMethodID,
		};
		const paymentScheduleDetailsSchema = Yup.object().shape({
			paymentAmount: Yup.string()
				.required('Amount is required')
				.matches(RegExEnum.DECIMAL, 'Amount is not valid')
				.test('minAmount', 'Amount must be at least $5', (value) => Number(value) >= 5)
				.test(
					'outstandingAmount',
					`Amount must not be greater than the outstanding balance ${formatMoney(outstanding ?? 100000)}`,
					(value) => Number(value) <= (outstanding || 100000),
				),
			periodicFrequencyID: Yup.number().nullable().required('Please select a payment frequency'),
			paymentDate: Yup.string()
				.nullable()
				.required('Please select a payment date')
				.test('validDate', 'Invalid payment date', (value) => checkIfDateValid(value))
				.test('pastDate', 'Payment date must be in the future', (value) => checkIfDatePast(value))
				.test('maxDate', 'Payment date must be within the next 30 days', (value) => checkIfMaxDate(value))
				.test('weekend', 'Payment date must be a business day', (value) => checkIfWeekend(value)),
			paymentMethodID: Yup.number().nullable().required('Please select a payment method'),
		});

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handlePIF = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
			setFieldValue('paymentAmount', (outstanding ?? 0).toFixed(2), true);
			setFieldValue('periodicFrequencyID', PeriodicFrequencyID.ONE_OFF, true);
			setIsPIF(true);
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handlePaymentAmount = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, value: string) => {
			const pifInput = parseFloat(value) === (outstanding ?? 0);
			setFieldValue('paymentAmount', value, true);
			setIsPIF(pifInput);
			if (pifInput) {
				setFieldValue('periodicFrequencyID', PeriodicFrequencyID.ONE_OFF, true);
			}
		};

		return (
			<Stack spacing={3} width="100%">
				<Typography variant="h5" gutterBottom>
					Setup Payments
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={paymentScheduleDetailsSchema}
					onSubmit={arrangementScheduleDetailsSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, setFieldValue, handleChange, touched, values }) => (
						<Form autoComplete="off">
							<Stack spacing={3}>
								<Box>
									<Typography variant="subtitle2" gutterBottom>
										Payment Amount <span style={{ fontWeight: 400, fontStyle: 'italic' }}>or you can</span>{' '}
										<Link onClick={(e) => handlePIF(setFieldValue)} sx={{ cursor: 'pointer' }}>
											Pay Total Amount
										</Link>
									</Typography>
									<TextField
										error={Boolean(touched.paymentAmount && errors.paymentAmount)}
										helperText={touched.paymentAmount && errors.paymentAmount}
										name="paymentAmount"
										fullWidth
										onBlur={handleBlur}
										onChange={(e) => handlePaymentAmount(setFieldValue, e.currentTarget.value.replace(/[^0-9.]+/g, ''))}
										value={values.paymentAmount}
										variant="outlined"
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
										}}
										inputProps={{
											min: 0,
											max: outstanding,
											step: 0.01,
										}}
									/>
								</Box>
								<Box>
									<Typography variant="subtitle2" gutterBottom>
										Payment Frequency
									</Typography>
									<ToggleButtonGroup
										color="primary"
										value={values.periodicFrequencyID}
										exclusive
										onChange={(e, value) => setFieldValue('periodicFrequencyID', value, true)}
										size={isMobile ? 'small' : 'medium'}
										fullWidth
									>
										<ToggleButton value={PeriodicFrequencyID.ONE_OFF}>
											{convertPaymentFreqToDescString(PeriodicFrequencyID.ONE_OFF)}
										</ToggleButton>
										<ToggleButton value={PeriodicFrequencyID.WEEKLY} disabled={isPIF}>
											{convertPaymentFreqToDescString(PeriodicFrequencyID.WEEKLY)}
										</ToggleButton>
										<ToggleButton value={PeriodicFrequencyID.FORTNIGHTLY} disabled={isPIF}>
											{convertPaymentFreqToDescString(PeriodicFrequencyID.FORTNIGHTLY)}
										</ToggleButton>
										<ToggleButton value={PeriodicFrequencyID.MONTHLY} disabled={isPIF}>
											{convertPaymentFreqToDescString(PeriodicFrequencyID.MONTHLY)}
										</ToggleButton>
									</ToggleButtonGroup>
									{Boolean(touched.periodicFrequencyID && errors.periodicFrequencyID) && (
										<FormHelperText error>{errors.periodicFrequencyID}</FormHelperText>
									)}
								</Box>
								<Box>
									<Typography variant="subtitle2" gutterBottom>
										Payment Date
									</Typography>
									<DatePicker
										disablePast
										inputFormat="dd/MM/yyyy"
										value={isDate(parseISO(values.paymentDate)) ? parseISO(values.paymentDate) : null}
										defaultCalendarMonth={MIN_DATE}
										minDate={MIN_DATE}
										maxDate={MAX_DATE}
										onChange={(newValue) => {
											setFieldValue('paymentDate', isValidDate(newValue) ? newValue?.toISOString() : newValue, true);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(touched.paymentDate && errors.paymentDate)}
												helperText={touched.paymentDate && errors.paymentDate}
												name="paymentDate"
												onBlur={handleBlur}
												fullWidth
											/>
										)}
										shouldDisableDate={disableWeekends}
									/>
								</Box>
								<Box>
									<Typography variant="subtitle2" gutterBottom>
										Select payment method
									</Typography>
									<Grid container spacing={4} alignItems="stretch">
										<Grid item xs={12} md={4}>
											<ToggleButton
												fullWidth
												color="primary"
												value={PaymentMethodID.DIRECT_DEBIT}
												selected={values.paymentMethodID === PaymentMethodID.DIRECT_DEBIT}
												onChange={() => setFieldValue('paymentMethodID', PaymentMethodID.DIRECT_DEBIT, true)}
												sx={{ height: '100%', minHeight: 100 }}
											>
												<Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
													<DirectDebitIcon width={64} />
													<Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
														{convertPaymentMethodToString(PaymentMethodID.DIRECT_DEBIT)}
													</Typography>
												</Box>
											</ToggleButton>
										</Grid>
										<Grid item xs={12} md={4}>
											<ToggleButton
												fullWidth
												color="primary"
												value={PaymentMethodID.BPAY}
												selected={values.paymentMethodID === PaymentMethodID.BPAY}
												onChange={() => setFieldValue('paymentMethodID', PaymentMethodID.BPAY, true)}
												sx={{ height: '100%', minHeight: 100 }}
											>
												<Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
													<BpayIcon width={57} />
													<Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
														{convertPaymentMethodToString(PaymentMethodID.BPAY)}
													</Typography>
												</Box>
											</ToggleButton>
										</Grid>
										<Grid item xs={12} md={4}>
											<ToggleButton
												fullWidth
												color="primary"
												value={PaymentMethodID.DEPOSIT}
												selected={values.paymentMethodID === PaymentMethodID.DEPOSIT}
												onChange={() => setFieldValue('paymentMethodID', PaymentMethodID.DEPOSIT, true)}
												sx={{ height: '100%', minHeight: 100 }}
											>
												<Box alignItems="center" display="flex" flexDirection="column">
													<DepositIcon width={33} />
													<Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
														{convertPaymentMethodToString(PaymentMethodID.DEPOSIT)}
													</Typography>
												</Box>
											</ToggleButton>
										</Grid>
									</Grid>
									{Boolean(touched.paymentMethodID && errors.paymentMethodID) && (
										<FormHelperText error>{errors.paymentMethodID}</FormHelperText>
									)}
								</Box>
							</Stack>
							<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
								<Button size="large" color="secondary" type="submit" variant="contained" endIcon={<ArrowForwardOutlinedIcon />}>
									Continue
								</Button>
							</Box>
						</Form>
					)}
				</Formik>
			</Stack>
		);
	};

	const Agreement = () => {
		const [termsOpen, setTermsOpen] = React.useState(false);
		const isDirectDebit = paymentMethodID === PaymentMethodID.DIRECT_DEBIT;
		const initialValues: ArrangementPaymentDetails & IDirectDebit & { paymentMethodID: PaymentMethodID | undefined } = {
			paymentMethodID,
			termsAccepted,
			authorityAccepted,
			authorityBankAccepted,
			bsb: directDebit?.bsb ?? '',
			accountName: directDebit?.accountName ?? '',
			accountNumber: directDebit?.accountNumber ?? '',
			suffix: directDebit?.suffix ?? '',
		};
		const paymentDetailsSchema = (testFunction: React.MutableRefObject<(value: string | null | undefined) => Promise<boolean>>) =>
			Yup.object().shape({
				paymentMethodID: Yup.number(),
				termsAccepted: Yup.boolean().oneOf([true]),
				authorityAccepted: Yup.boolean().oneOf([true]),
				authorityBankAccepted: Yup.boolean().when('paymentMethodID', { is: PaymentMethodID.DIRECT_DEBIT, then: Yup.boolean().oneOf([true]) }),
				accountNumber: Yup.string().when('paymentMethodID', {
					is: PaymentMethodID.DIRECT_DEBIT,
					then: Yup.string()
						.required('Enter a valid account number')
						.matches(/^[0-9]+$/, 'Must be only digits')
						.min(3, 'Invalid account number Length')
						.max(9, 'Invalid account number Length'),
				}),
				accountName: Yup.string().when('paymentMethodID', {
					is: PaymentMethodID.DIRECT_DEBIT,
					then: Yup.string()
						.required('Enter a valid account name')
						.min(3, 'Invalid account name Length')
						.max(128, 'Invalid account name Length'),
				}),
				bsb: Yup.string().when('paymentMethodID', {
					is: PaymentMethodID.DIRECT_DEBIT,
					then: Yup.string()
						.required('Enter a valid BSB')
						.matches(/^\d{3}-\d{3}$/, 'BSB does not match the required format')
						.test('validBSB', BSBInvalidMsg, testFunction.current),
				}),
			});
		return (
			<Stack spacing={3} width="100%">
				<Typography variant="h5" gutterBottom>
					Setup Payments
				</Typography>
				<Typography variant="subtitle1">Agreement</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={paymentDetailsSchema(bsbValidator)}
					onSubmit={arrangementSubmit}
					enableReinitialize={true}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, setFieldValue, handleChange, touched, values, isValid, dirty }) => (
						<>
							<PaymentTermsConditionsDialog
								open={termsOpen}
								onClose={() => setTermsOpen(false)}
								onAgree={() => {
									setFieldValue('termsAccepted', true, true);
									setTermsOpen(false);
								}}
								onDisagree={() => {
									setFieldValue('termsAccepted', false, true);
									setTermsOpen(false);
								}}
								paymentMethodID={paymentMethodID}
							/>
							<Form autoComplete="off">
								<Stack spacing={3}>
									{isDirectDebit && (
										<Box>
											<Typography variant="subtitle2" gutterBottom>
												BSB
											</Typography>
											<TextField
												error={Boolean(touched.bsb && errors.bsb)}
												fullWidth
												helperText={touched.bsb && errors.bsb}
												name="bsb"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.bsb}
												variant="outlined"
												InputProps={{
													// eslint-disable-next-line @typescript-eslint/no-explicit-any
													inputComponent: BSBMaskCustom as any,
												}}
											/>
											{errors.bsb === BSBInvalidMsg && !!touched.bsb && <BSBAlert />}
										</Box>
									)}
									{isDirectDebit && (
										<Box>
											<Typography variant="subtitle2" gutterBottom>
												Account Number
											</Typography>
											<TextField
												error={Boolean(touched.accountNumber && errors.accountNumber)}
												fullWidth
												helperText={touched.accountNumber && errors.accountNumber}
												name="accountNumber"
												onBlur={handleBlur}
												onChange={(e) => setFieldValue(e.currentTarget.name, e.currentTarget.value.replace(/[^0-9]+/g, ''))}
												value={values.accountNumber}
												variant="outlined"
												inputProps={{
													maxLength: 9,
												}}
											/>
										</Box>
									)}
									{isDirectDebit && (
										<Box>
											<Typography variant="subtitle2" gutterBottom>
												Account Name
											</Typography>
											<TextField
												error={Boolean(touched.accountName && errors.accountName)}
												fullWidth
												helperText={touched.accountName && errors.accountName}
												name="accountName"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.accountName}
												variant="outlined"
												inputProps={{
													maxLength: 128,
												}}
											/>
										</Box>
									)}
									<Typography variant="body1">
										Please note, the terms and conditions of {debtor?.Fullname} will be emailed through to yourself. Should you
										wish for the documentation to be forwarded to {debtor?.Fullname}, please ensure this is manually completed on
										your end.
									</Typography>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													name="authorityAccepted"
													checked={values.authorityAccepted}
													onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
														setFieldValue(event.target.name, event.target.checked, true)
													}
													inputProps={{ 'aria-label': 'controlled' }}
												/>
											}
											label={
												<>
													I, {getFullName(user?.FirstName, user?.Surname)}, acknowledge that I have been granted authority
													to initiate payment arrangements/payments on behalf of {debtor?.Fullname}.
												</>
											}
										/>
									</FormGroup>
									{isDirectDebit && (
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														name="authorityBankAccepted"
														checked={values.authorityBankAccepted}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
															setFieldValue(event.target.name, event.target.checked, true)
														}
														inputProps={{ 'aria-label': 'controlled' }}
													/>
												}
												label={
													<>
														I, {getFullName(user?.FirstName, user?.Surname)}, acknowledge that I have been granted
														authority to initiate payment arrangements/payments using {debtor?.Fullname} bank details.
													</>
												}
											/>
										</FormGroup>
									)}
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													name="termsAccepted"
													checked={values.termsAccepted}
													onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
														setFieldValue(event.target.name, event.target.checked, true)
													}
													inputProps={{ 'aria-label': 'controlled' }}
												/>
											}
											label={
												<>
													Agree with the{' '}
													<Link
														onClick={(e) => {
															e.preventDefault();
															setTermsOpen(true);
														}}
													>
														Terms and Conditions
													</Link>{' '}
													of this plan
												</>
											}
										/>
									</FormGroup>
								</Stack>
								<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
									<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack}>
										Back
									</Button>
									<LoadingButton
										loading={arrangementStatus === Status.LOADING}
										size="large"
										color="secondary"
										variant="contained"
										type="submit"
										disabled={!isValid || !dirty}
									>
										Complete set up
									</LoadingButton>
								</Stack>
							</Form>
						</>
					)}
				</Formik>
			</Stack>
		);
	};

	const Outcome = () => {
		return (
			<Stack spacing={3} width="100%">
				<Grid container direction="row" justifyContent="center" alignItems="flex-start">
					<ChampionsCupIcon height={121} width={121} />
				</Grid>
				<Typography align="center" variant="h5" component="p" gutterBottom>
					Thank you!
				</Typography>
				{periodicFrequencyID === PeriodicFrequencyID.ONE_OFF && paymentMethodID === PaymentMethodID.DIRECT_DEBIT && (
					<Typography variant="body1" gutterBottom>
						Direct debit has been set up, the funds may take up to 5 days to transfer.
					</Typography>
				)}
				{periodicFrequencyID !== PeriodicFrequencyID.ONE_OFF && paymentMethodID === PaymentMethodID.DIRECT_DEBIT && !!outcome && (
					<Typography variant="body1" gutterBottom>
						Successfully set up a recurring payment plan. The first payment will be on the{' '}
						{format(parseISO(outcome?.commencementDate), dateFormats.prettyShort)}. To prevent any issues from occurring please make sure
						the account has the correct amount of funds on the due date.
					</Typography>
				)}
				{!!outcome?.bpayDetails && (
					<>
						<Typography variant="subtitle1" gutterBottom>
							BPay payment details.
						</Typography>
						<Card square sx={{ maxWidth: 500 }}>
							<CardContent>
								<Grid container spacing={3}>
									<Grid item xs={5}>
										<Typography variant="body2">Biller code</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="subtitle2">{outcome.bpayDetails.BillerCode}</Typography>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="body2">BPAY reference</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="subtitle2">{outcome.bpayDetails.PaymentReferenceNumber}</Typography>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="body2">
											{periodicFrequencyID === PeriodicFrequencyID.ONE_OFF ? 'Due' : 'First payment'}
										</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="subtitle2">
											{format(parseISO(outcome?.commencementDate), dateFormats.prettyShort)}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</>
				)}
				{!!outcome?.depositDetails && (
					<>
						<Typography variant="subtitle1" gutterBottom>
							Deposit payment details.
						</Typography>
						<Card square sx={{ maxWidth: 500 }}>
							<CardContent>
								<Grid container spacing={3}>
									<Grid item xs={5}>
										<Typography variant="body2">Account Name</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="subtitle2">{outcome.depositDetails.AccountName}</Typography>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="body2">BSB Number</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="subtitle2">{outcome.depositDetails.BSB}</Typography>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="body2">Account Number</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="subtitle2">{outcome.depositDetails.AccountNumber}</Typography>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="body2">Reference</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="subtitle2">{debt?.DebtID}</Typography>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="body2">
											{periodicFrequencyID === PeriodicFrequencyID.ONE_OFF ? 'Due' : 'First payment'}
										</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="subtitle2">
											{format(parseISO(outcome?.commencementDate), dateFormats.prettyShort)}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</>
				)}
				<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
					<Button size="large" color="secondary" variant="contained" component={routerLink} to={ClientRoutes.DASHBOARD}>
						Back to Dashboard
					</Button>
				</Box>
			</Stack>
		);
	};

	const renderStepContent = (step: number) => {
		switch (step) {
			case 0:
				return <ScheduleDetails />;
			case 1:
				return <Agreement />;
			case 2:
				return <Outcome />;
			default:
				return 'unknown step';
		}
	};

	return (
		<Page title={pageTitle}>
			<FullScreenModal
				open={paymentLengthModalOpen}
				onClose={() => {
					SetPaymentLengthModalOpen(false);
				}}
			>
				<Stack spacing={3}>
					<Grid container direction="row" justifyContent="center" alignItems="flex-start">
						<WarningIcon width={121} height={121} />
					</Grid>
					<Typography align="center" variant="h5" component="p">
						Warning!
					</Typography>
					<Typography variant="body1">
						Please be advised that this payment arrangement will last more than 7 years. You may wish to revisit this arrangement at a
						later date or contact our Customer Care Team for further assistance.
					</Typography>
				</Stack>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start" mt={6}>
					<Grid item xs={8}>
						<Button size="large" color="primary" variant="outlined" onClick={() => SetPaymentLengthModalOpen(false)}>
							Close
						</Button>
					</Grid>
				</Grid>
			</FullScreenModal>
			<Container maxWidth="xl" sx={{ ml: 0 }}>
				<ClientBreadCrumbs childCrumb={pageTitle} />
				{isLoading && (
					<Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, minHeight: '100%', width: '100%', justifyContent: 'center' }}>
						<CircularProgress color="secondary" size={150} />
					</Box>
				)}
				{!isLoading && isBlocked && (
					<Card elevation={0}>
						<CardContent>
							<Container maxWidth="sm">
								<Stack spacing={3}>
									<Grid container direction="row" justifyContent="center" alignItems="flex-start">
										<WarningIcon width={121} height={121} />
									</Grid>
									<Typography variant="h5" gutterBottom textAlign="center">
										Warning
									</Typography>
									{hardship && (
										<>
											<Typography variant="subtitle1" textAlign="center">
												Unfortunately, we cannot proceed with Setting up a Payment!
											</Typography>
											<Typography variant="subtitle1" textAlign="center">
												Your clients account already has a hardship request placed and is yet to be resolved. Feel free to
												contact one of our friendly staff on{' '}
												<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
													{window.config.REACT_APP_CONTACT_NUMBER}
												</Link>{' '}
												for further assistance.
											</Typography>
										</>
									)}
									{!hardship && arrangements.length > 0 && (
										<>
											<Typography variant="subtitle1" textAlign="center">
												Unfortunately, we cannot proceed with Setting up a Payment!
											</Typography>
											<Typography variant="subtitle1" textAlign="center">
												Your clients account has an active payment plan. Feel free to contact one of our friendly staff on{' '}
												<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
													{window.config.REACT_APP_CONTACT_NUMBER}
												</Link>{' '}
												for further assistance.
											</Typography>
										</>
									)}
								</Stack>
								<Box sx={{ mt: 6 }} justifyContent="center" display="flex">
									<Button size="large" color="secondary" variant="contained" component={routerLink} to={ClientRoutes.DASHBOARD}>
										Back to Dashboard
									</Button>
								</Box>
							</Container>
						</CardContent>
					</Card>
				)}
				{!isLoading && !isBlocked && (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={9} lg={9}>
							<Card elevation={0}>
								<CardContent>
									<Grid container alignItems="stretch" spacing={2}>
										<Grid item xs={12} sm={12} md={4} lg={3} sx={{ display: 'flex', order: { xs: 2, md: 1 } }}>
											{isMobile ? (
												<HorizontalStepper activeStep={activeStep} steps={steps} sx={{ marginTop: 8, width: '100%' }} />
											) : (
												<VerticalStepper activeStep={activeStep} steps={steps} sx={{ maxHeight: 280 }} />
											)}
										</Grid>
										<Grid item xs={12} sm={12} md={8} lg={9} sx={{ display: 'flex', order: { xs: 1, md: 2 } }}>
											{renderStepContent(activeStep)}
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						{activeStep >= 1 && (
							<Grid item xs={12} sm={12} md={3} lg={3}>
								<Card elevation={0} sx={{ backgroundColor: '#FEF2E8' }}>
									<CardContent>
										<Typography variant="h6" gutterBottom>
											Summary
										</Typography>
										<Divider />
										<Stack direction="column" spacing={2} mt={2}>
											<Box>
												<Typography variant="subtitle1">{formatMoney(parseFloat(paymentAmount))}</Typography>
												{periodicFrequencyID !== PeriodicFrequencyID.ONE_OFF && (debt?.InterestRate ?? 0) > 0 && (
													<Typography variant="subtitle2" marginRight={1}>
														(All interest held whilst payment plan remains active)
													</Typography>
												)}
												<Typography variant="body2">Payment</Typography>
											</Box>
											<Box>
												<Typography variant="subtitle1">{convertPaymentFreqToDescString(periodicFrequencyID)}</Typography>
												<Typography variant="body2">Frequency</Typography>
											</Box>
											<Box>
												<Typography variant="subtitle1">{format(parseISO(paymentDate), dateFormats.prettyShort)}</Typography>
												<Typography variant="body2">Date</Typography>
											</Box>
											<Box>
												<Box display="flex" flexDirection="row" alignItems="center">
													{(() => {
														switch (paymentMethodID) {
															case PaymentMethodID.DIRECT_DEBIT:
																return (
																	<>
																		<Typography variant="subtitle1" marginRight={1}>
																			{convertPaymentMethodToString(PaymentMethodID.DIRECT_DEBIT)}
																		</Typography>
																		<DirectDebitIcon height={24} />
																	</>
																);
															case PaymentMethodID.BPAY:
																return (
																	<>
																		<Typography variant="subtitle1" marginRight={1}>
																			{convertPaymentMethodToString(PaymentMethodID.BPAY)}
																		</Typography>
																		<BpayIcon height={24} />
																	</>
																);
															case PaymentMethodID.DEPOSIT:
																return (
																	<>
																		<Typography variant="subtitle1" marginRight={1}>
																			{convertPaymentMethodToString(PaymentMethodID.DEPOSIT)}
																		</Typography>
																		<DepositIcon height={24} />
																	</>
																);
															default:
																return <></>;
														}
													})()}
												</Box>
												{(paymentMethodID === PaymentMethodID.DEPOSIT || paymentMethodID === PaymentMethodID.BPAY) && (
													<Typography variant="subtitle2" marginRight={1}>
														(Details will be supplied)
													</Typography>
												)}
												<Typography variant="body2">Payment method</Typography>
											</Box>
										</Stack>
									</CardContent>
								</Card>
							</Grid>
						)}
					</Grid>
				)}
			</Container>
		</Page>
	);
};
