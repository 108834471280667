import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { PaymentMethodID } from '../../../enums/arrangement.enum';

type TermsConditionsDialogProps = {
	open: boolean;
	onClose: () => void;
	onAgree: () => void;
	onDisagree: () => void;
	paymentMethodID: PaymentMethodID | undefined;
};

const BpayTerms = () => (
	<Stack spacing={1.5}>
		<DialogContentText color="text.primary">
			In the terms of the Bpay arrangements made between us and acknowledged by you, you will be required to make your payment/s promptly on or
			before the due date/s. By accepting this method of payment you agree to receive reminders for the scheduled payments.
		</DialogContentText>
		<DialogContentText color="text.primary">
			If the payment is not received your payment plan may be cancelled and we may contact you seeking the full balance.
		</DialogContentText>
		<DialogContentText color="text.primary">
			If you want to make changes to the drawing arrangements, please contact us at least 3 business days before the scheduled payment date.
		</DialogContentText>
		<DialogContentText color="text.primary">
			All personal customer information held by us will be kept confidential except that information provided to our financial institution to
			initiate the drawing to your nominated account.
		</DialogContentText>
	</Stack>
);
const DirectDebitTerms = () => (
	<Stack spacing={1.5}>
		<DialogContentText color="text.primary">
			In the terms of the Direct Debit Request arrangements made between us and acknowledged by you,we undertake to periodically charge your
			nominated account for the agreed amount for payment of your outstanding debt.
		</DialogContentText>
		<DialogContentText color="text.primary">
			The first drawing under this agreement will be drawn on the nominated day. If the drawing falls on a non-business day, it will be debited
			to your account the previous business day before the scheduled drawing date.
		</DialogContentText>
		<DialogContentText color="text.primary">
			If you want to make changes to the drawing arrangements,please contact us at least 3 business days before the scheduled payment date.
		</DialogContentText>
		<DialogContentText color="text.primary">
			If the debit is returned unpaid your payment plan may be cancelled and we may contact you seeking the full balance. Any drawing that is
			returned or dishonoured can incur a $10 administration fee payable to Panthera Finance and you may be liable for other related fees that
			you may incur fromyour financial institution.
		</DialogContentText>
		<DialogContentText color="text.primary">
			All personal customer information held by us will be kept confidential except that information provided to our financial institution to
			initiate the drav1ing to your nominated account.
		</DialogContentText>
	</Stack>
);
const DepositTerms = () => (
	<Stack spacing={1.5}>
		<DialogContentText color="text.primary">
			In the terms of the bank Deposit Request arrangements made between us and acknowledged by you, we undertake to periodically charge your
			nominated account for the agreed amount for payment of your outstanding debt.
		</DialogContentText>
		<DialogContentText color="text.primary">
			The first drawing under this agreement will be drawn on the nominated day. If the drawing falls on a non-business day, it will be debited
			to your account the previous business day before the scheduled drawing date.
		</DialogContentText>
		<DialogContentText color="text.primary">
			If you want to make changes to the drawing arrangements,please contact us at least 3 business days before the scheduled payment date.
		</DialogContentText>
		<DialogContentText color="text.primary">
			If the debit is returned unpaid your payment plan may be cancelled and we may contact you seeking the full balance. Any drawing that is
			returned or dishonoured can incur a $10 administration fee payable to Panthera Finance and you may be liable for other related fees that
			you may incur fromyour financial institution.
		</DialogContentText>
		<DialogContentText color="text.primary">
			All personal customer information held by us will be kept confidential except that information provided to our financial institution to
			initiate the drawing to your nominated account.
		</DialogContentText>
	</Stack>
);

const renderTerms = (paymentMethodID: PaymentMethodID | undefined) => {
	switch (paymentMethodID) {
		case PaymentMethodID.BPAY:
			return <BpayTerms />;
		case PaymentMethodID.DIRECT_DEBIT:
			return <DirectDebitTerms />;
		case PaymentMethodID.DEPOSIT:
			return <DepositTerms />;
		default:
			return 'unknown method';
	}
};

export const PaymentTermsConditionsDialog: React.FC<TermsConditionsDialogProps> = ({ open, onClose, onAgree, onDisagree, paymentMethodID }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<Dialog fullScreen={isMobile} open={open} scroll="paper" onClose={onClose} aria-labelledby="terms-dialog-title">
			<DialogTitle id="terms-dialog-title">Terms and Conditions</DialogTitle>
			<DialogContent>{renderTerms(paymentMethodID)}</DialogContent>
			<DialogActions>
				<Button onClick={onDisagree}>Disagree</Button>
				<Button onClick={onAgree}>Agree</Button>
			</DialogActions>
		</Dialog>
	);
};
