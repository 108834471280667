import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardCard, DashboardCardNoLink } from '../../components/DashboardCard';
import { Page } from '../../components/Page';
import { PortalRoutes } from '../../constants/routes.constant';
import { AccountSettingsImg } from '../../static/images/AccountSettings';
import { ContactUsImg } from '../../static/images/ContactUs';
import { MyClientsImg } from '../../static/images/MyClients';
import { NewClientImg } from '../../static/images/NewClient';
import { resetAddClient } from '../../store/features/clients/addClientSlice';
import { useAppDispatch } from '../../store/hooks';
import { AddClientLockedModal } from './components/AddClientModal';
import { RecentActivity } from './components/RecentActivity';
import { MyClientTabs } from './MyClients';

export const Dashboard = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [addClientOpen, setAddClientOpen] = useState(false);

	const navigateToPending = () => {
		dispatch(resetAddClient());
		setAddClientOpen(false);
		navigate(`${PortalRoutes.MY_CLIENTS}/${MyClientTabs.Pending}`);
	};

	const modalClose = () => {
		dispatch(resetAddClient());
		setAddClientOpen(false);
	};

	return (
		<Page title="Dashboard">
			<Container maxWidth="xl">
				<Box sx={{ pb: 5 }}>
					<Typography variant="h5">Financial Counsellor Dashboard</Typography>
				</Box>
				<Stack spacing={4}>
					<Grid container alignItems="stretch" spacing={4}>
						<Grid item xs={12} sm={12} md={6} lg={3} sx={{ display: 'flex' }}>
							<DashboardCard
								title="My Clients"
								text="View and search through your current list of clients."
								to={PortalRoutes.MY_CLIENTS}
								backgroundColour="#F5005714"
								icon={<MyClientsImg width="100%" height="100%" />}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={3} sx={{ display: 'flex' }}>
							<DashboardCardNoLink
								title="Add a new client"
								text="Easily add a new client to manage through the portal."
								onClick={() => setAddClientOpen(true)}
								backgroundColour="#BBC5FF14"
								icon={<NewClientImg width="100%" height="100%" />}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={3} sx={{ display: 'flex' }}>
							<DashboardCard
								title="Account settings"
								text="View your account settings and manage your security preferences."
								to={PortalRoutes.ACCOUNT_SETTINGS}
								backgroundColour="#FAD3B34D"
								icon={<AccountSettingsImg width="100%" height="100%" />}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={3} sx={{ display: 'flex' }}>
							<DashboardCard
								title="Contact us"
								text="Contact us to discuss any queries or concerns you may have."
								to={PortalRoutes.CONTACT}
								backgroundColour="#2196F314"
								icon={<ContactUsImg width="100%" height="100%" />}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<RecentActivity />
						</Grid>
					</Grid>
				</Stack>
				{addClientOpen && <AddClientLockedModal onClose={modalClose} goToPending={navigateToPending} />}
			</Container>
		</Page>
	);
};
