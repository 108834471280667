import { Box, Card, CardContent, CardHeader, CircularProgress, darken, lighten, Typography, useTheme } from '@mui/material';
import { BarElement, CategoryScale, Chart as ChartJS, ChartData, ChartOptions, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DebtStatusGroup } from '../../../../enums/debtStatus.enum';
import { IReportDebtStatus } from './types';

type ChartResultsProps = {
	data: IReportDebtStatus[] | undefined;
	loading: boolean;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const ChartResults: React.FC<ChartResultsProps> = ({ data, loading }) => {
	const theme = useTheme();
	const chartData = [
		{
			label: DebtStatusGroup.ACTIVE,
			value: data?.filter((client) => client.Status.toUpperCase() === DebtStatusGroup.ACTIVE.toUpperCase()).length ?? 0,
			background: lighten(theme.palette.success.light, 0.9),
			border: darken(theme.palette.success.light, 0.2),
		},
		{
			label: DebtStatusGroup.ARRANGEMENT,
			value: data?.filter((client) => client.Status.toUpperCase() === DebtStatusGroup.ARRANGEMENT.toUpperCase()).length ?? 0,
			background: lighten(theme.palette.info.light, 0.9),
			border: darken(theme.palette.info.light, 0.2),
		},
		{
			label: DebtStatusGroup.CLOSED,
			value: data?.filter((client) => client.Status.toUpperCase() === DebtStatusGroup.CLOSED.toUpperCase()).length ?? 0,
			background: lighten(theme.palette.grey[500], 0.9),
			border: darken(theme.palette.grey[500], 0.2),
		},
		{
			label: DebtStatusGroup.REVIEW,
			value: data?.filter((client) => client.Status.toUpperCase() === DebtStatusGroup.REVIEW.toUpperCase()).length ?? 0,
			background: lighten(theme.palette.warning.light, 0.9),
			border: darken(theme.palette.warning.light, 0.2),
		},
	];

	const options: ChartOptions<'bar'> = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
			},
		},
	};
	const chartBarData: ChartData<'bar'> = {
		labels: chartData.map((c) => c.label),
		datasets: [
			{
				data: chartData.map((c) => c.value),
				backgroundColor: chartData.map((c) => c.background),
				borderColor: chartData.map((c) => c.border),
				borderWidth: 1,
			},
		],
	};
	return (
		<Card elevation={0}>
			<CardHeader title="Summary" />
			<CardContent sx={{ pt: 0 }}>
				{loading && (
					<Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, minHeight: '100%', width: '100%', justifyContent: 'center' }}>
						<CircularProgress color="secondary" size={150} />
					</Box>
				)}
				{!loading && (data?.length ?? 0) === 0 && (
					<Typography component="p" variant="subtitle1" width="100%" textAlign="center" sx={{ py: 3 }}>
						No summary to display
					</Typography>
				)}
				{!loading && (data?.length ?? 0) > 0 && <Bar options={options} data={chartBarData} style={{ minHeight: 400 }} />}
			</CardContent>
		</Card>
	);
};
