import { Box, Card, CardActionArea, CardContent, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { LinkButton } from './StyledLinkButton';

type BaseCardProps = {
	title: string;
	text: string;
	backgroundColour: React.CSSProperties['backgroundColor'];
	icon: React.ReactNode;
};

interface DashboardCardProps extends BaseCardProps {
	to: string;
}

interface DashboardCardNoLinkProps extends BaseCardProps {
	onClick: () => void;
}

const RootStyle = styled(Card)(({ theme }) => ({
	width: '100%',
	boxShadow: 'none',
	'& .MuiCardActionArea-root': {
		height: '100%',
	},
	'& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight': {
		height: '100%',
		opacity: 0.03,
	},
}));

const ImgWrapperStyle = styled(Box)<{ backgroundColor: React.CSSProperties['backgroundColor'] }>(({ theme, backgroundColor }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	backgroundColor,
}));

const IconBox = styled(Box)({
	alignItems: 'center',
	display: 'flex',
	flexGrow: 1,
	width: 106,
	height: 106,
});

export const DashboardCard: FC<DashboardCardProps> = ({ title, text, backgroundColour, icon, to }) => {
	return (
		<RootStyle>
			<CardActionArea component={LinkButton} to={to}>
				<ImgWrapperStyle backgroundColor={backgroundColour}>
					<IconBox>{icon}</IconBox>
				</ImgWrapperStyle>
				<CardContent>
					<Typography gutterBottom variant="h6" component="div">
						{title}
					</Typography>
					<Typography variant="body1" color="text.secondary">
						{text}
					</Typography>
				</CardContent>
			</CardActionArea>
		</RootStyle>
	);
};

export const DashboardCardNoLink: FC<DashboardCardNoLinkProps> = ({ title, text, backgroundColour, icon, onClick }) => {
	return (
		<RootStyle>
			<CardActionArea component={'div'} onClick={onClick}>
				<ImgWrapperStyle backgroundColor={backgroundColour}>
					<IconBox>{icon}</IconBox>
				</ImgWrapperStyle>
				<CardContent>
					<Typography gutterBottom variant="h6" component="div">
						{title}
					</Typography>
					<Typography variant="body1" color="text.secondary">
						{text}
					</Typography>
				</CardContent>
			</CardActionArea>
		</RootStyle>
	);
};
