import { Container, Grid, Box, Typography, Link } from '@mui/material';
import { LogoHorizontal } from '../../../icons/branding/LogoHorizontal';
import { PublicRoutes } from '../../../constants/routes.constant';
import { styled } from '@mui/material/styles';

export const Footer = () => {
	const WhiteTypography = styled(Typography)(({ theme }) => ({
		root: {
			color: '#FFFFFF',
		},
	}));

	const WhiteLink = styled(Link)(({ theme }) => ({
		color: 'rgba(255, 255, 255, 0.8)',
		display: 'inline-block',
		textDecoration: 'none',

		'&:hover': {
			textDecoration: 'underline',
			textDecorationColor: '#34C07D',
		},
	}));

	const UnderlinedLink = styled(Link)(({ theme }) => ({
		color: 'rgba(255, 255, 255, 0.8)',
		display: 'inline-block',
		textDecorationColor: '#34C07D',

		'&:hover': {
			textDecoration: 'none',
		},
	}));

	return (
		<>
			<Box
				sx={{
					backgroundColor: '#062239',
					color: 'white',
					paddingTop: '10vh',
					paddingBottom: '5vh',
				}}
			>
				<Container>
					<Box sx={{ paddingBottom: '5vh', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
						<Box>
							<LogoHorizontal height={46} fill="#FFFFFF" />
						</Box>

						<Grid container columns={{ md: 2 }} spacing={{ md: 6, sm: 4, lg: 10 }}>
							<Grid md={1} item sx={{ display: 'flex', flexDirection: 'column' }}>
								<WhiteTypography variant="h6" sx={{ mt: 1, mb: 1, color: '#ffffff' }}>
									Quick links
								</WhiteTypography>
								<WhiteLink href={`${PublicRoutes.HOME}#how`}>How it works</WhiteLink>
								<WhiteLink href={PublicRoutes.CONTACTS}>Contact us</WhiteLink>
								<WhiteLink href={PublicRoutes.TERMS_CONDITIONS}>Terms and conditions</WhiteLink>
								<WhiteLink href={PublicRoutes.POLICY}>Privacy Policy</WhiteLink>
							</Grid>

							<Grid md={1} item>
								<WhiteTypography variant="h6" sx={{ mt: 1, color: '#ffffff' }}>
									Contacts
								</WhiteTypography>
								<Box>
									<WhiteTypography variant="body1" sx={{ mt: 1, color: 'rgba(255, 255, 255, 0.8)' }}>
										Email:{' '}
										<UnderlinedLink href={window.config.REACT_APP_CONTACT_EMAIL_HREF}>
											{window.config.REACT_APP_CONTACT_EMAIL}
										</UnderlinedLink>
									</WhiteTypography>

									<WhiteTypography variant="body1" sx={{ mt: 1, color: 'rgba(255, 255, 255, 0.8)' }}>
										Phone:{' '}
										<UnderlinedLink href={window.config.REACT_APP_CONTACT_NUMBER_HREF}>
											{window.config.REACT_APP_CONTACT_NUMBER}
										</UnderlinedLink>
									</WhiteTypography>

									<WhiteTypography variant="body1" sx={{ mt: 1, color: 'rgba(255, 255, 255, 0.8)' }}>
										Address: PO BOX 388, Toowong DC, QLD, 4066
									</WhiteTypography>
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Box>
						<WhiteTypography variant="subtitle2" sx={{ mt: 2, color: '#C4C4C4', textAlign: 'center' }}>
							©2022 - {window.config.REACT_APP_COMPANY} - All Rights reserved
						</WhiteTypography>
					</Box>
				</Container>
			</Box>
		</>
	);
};
