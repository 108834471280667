import React, { useEffect } from 'react';
import { IMaskInput } from 'react-imask';
import {
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	InputAdornment,
	Link,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { HorizontalStepper } from '../../components/HorizontalStepper';
import { Page } from '../../components/Page';
import { AuthRoutes } from '../../constants/routes.constant';
import {
	RegisterBusinessDetails,
	RegisterContactDetails,
	RegisterPersonDetails,
	selectRegisterActiveStep,
	selectRegisterDetails,
	setRegisterActiveStep,
	setRegisterBusinessDetails,
	setRegisterContactDetails,
	setRegisterPersonDetails,
	signUpPost,
} from '../../store/features/auth/registerSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AuthWrapper } from './components/AuthWrapper';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { REGEX_FCA } from '../../constants/regex.constant';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import { fcAPI } from '../../services/api/api';
import { FCResponse } from '../../services/api/base.api.response';
import axios from 'axios';
import { setErrorSnackbarOpen } from '../../store/features/app/appSlice';
import { useCacheAsync } from '../../utils/cache.util';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { LoadingButton } from '@mui/lab';
import { loadReCaptchaScript, ReCaptchaAction } from '../../services/recaptcha/recaptcha';

const steps = ['Who you are', 'Who do you work for', 'How do we contact you'];

type IABN = FCResponse<{ abn: AbnResult }>;

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}
interface AbnResult {
	Abn: string;
	AbnStatus: string;
	AbnStatusEffectiveFrom: string;
	Acn: string;
	AddressDate: string;
	AddressPostcode: string;
	AddressState: string;
	BusinessName: string[];
	EntityName: string;
	EntityTypeCode: string;
	EntityTypeName: string;
	Gst: string;
	Message: string;
}

const FCAMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function FCAMaskCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="FC00000"
			inputRef={ref as (instance: HTMLElement | null) => void}
			onAccept={(value: string) => onChange({ target: { name: props.name, value } })}
			placeholder="FC#####"
		/>
	);
});

export const SignUp = () => {
	const dispatch = useAppDispatch();
	const activeStep = useAppSelector(selectRegisterActiveStep);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const { firstName, surname, fcaNumber, abn, companyName, email, phoneNumber, termsAccepted } = useAppSelector(selectRegisterDetails);

	useEffect(() => {
		loadReCaptchaScript();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [activeStep]);

	const handleContinue = () => {
		dispatch(setRegisterActiveStep(activeStep + 1));
	};

	const handleBack = () => {
		dispatch(setRegisterActiveStep(activeStep - 1));
	};

	const personDetailsSubmit = (values: RegisterPersonDetails) => {
		dispatch(setRegisterPersonDetails(values));
		handleContinue();
	};

	const businessDetailsSubmit = (values: RegisterBusinessDetails) => {
		dispatch(setRegisterBusinessDetails(values));
		handleContinue();
	};

	const contactDetailsSubmit = async (values: RegisterContactDetails) => {
		const captchaToken = await new Promise<string>((resolve) => {
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(window.config.REACT_APP_RECAPTCHA, { action: ReCaptchaAction.SIGN_UP }).then((token) => resolve(token));
			});
		});
		try {
			const signUpSuccess = await dispatch(
				signUpPost({ firstName, surname, fcaNumber, abn, companyName, email: values.email, phoneNumber: values.phoneNumber, captchaToken }),
			).unwrap();
			if (signUpSuccess) {
				dispatch(setRegisterContactDetails(values));
				handleContinue();
			}
		} catch {
			/* Handled in Thunk */
		}
	};

	const PersonDetailsForm = () => {
		const initialValues: RegisterPersonDetails = {
			firstName,
			surname,
			fcaNumber,
		};
		const personDetailsSchema = Yup.object().shape({
			firstName: Yup.string().required('First name is required').max(64),
			surname: Yup.string().required('Last name is required').max(64),
			fcaNumber: Yup.string().required('FCA registration number is required').max(7).matches(REGEX_FCA, 'Invalid FCA number'),
		});
		return (
			<>
				<Typography variant="h4" textAlign="center" sx={{ mb: 8 }}>
					Who you are
				</Typography>
				<Typography variant="body1" sx={{ mb: 2 }}>
					See how Panthera Financial Counsellor Portal can help you improve your clients financial situation. At first step provide your
					full name.
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={personDetailsSchema}
					onSubmit={personDetailsSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, handleChange, touched, values }) => (
						<Form autoComplete="off">
							<TextField
								error={Boolean(touched.firstName && errors.firstName)}
								fullWidth
								helperText={touched.firstName && errors.firstName}
								label="First Name"
								margin="normal"
								name="firstName"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.firstName}
								variant="outlined"
							/>
							<TextField
								error={Boolean(touched.surname && errors.surname)}
								fullWidth
								helperText={touched.surname && errors.surname}
								label="Last Name"
								margin="normal"
								name="surname"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.surname}
								variant="outlined"
							/>
							<TextField
								error={Boolean(touched.fcaNumber && errors.fcaNumber)}
								fullWidth
								helperText={touched.fcaNumber && errors.fcaNumber}
								label="FCA Registration Number"
								margin="normal"
								name="fcaNumber"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fcaNumber}
								variant="outlined"
								InputProps={{
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									inputComponent: FCAMaskCustom as any,
								}}
							/>
							<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
								<Button size="large" color="secondary" type="submit" variant="contained" endIcon={<ArrowForwardOutlinedIcon />}>
									Continue
								</Button>
							</Stack>
						</Form>
					)}
				</Formik>
			</>
		);
	};

	const BusinessDetailsForm = () => {
		const [loadingABN, setLoadingABN] = React.useState(false);
		const initialValues: RegisterBusinessDetails = {
			abn,
			companyName,
		};
		const fetchABNAsync = async (value: string) => {
			if (!value || !/^\d{11}$/.test(value)) return false;
			try {
				const { data } = await fcAPI.get<IABN>('/api/abn', {
					params: {
						abn: value,
					},
				});
				if (data.success && data.data.abn.EntityName) {
					return data.data.abn.EntityName;
				} else {
					dispatch(setErrorSnackbarOpen({ message: data.data.abn.Message ?? 'An unknown error occurred.' }));
				}
			} catch (err) {
				if (axios.isAxiosError(err)) {
					dispatch(setErrorSnackbarOpen({ message: err.response?.data.message ?? 'An unknown error occurred.' }));
				}
				dispatch(setErrorSnackbarOpen({ message: 'An unknown error occurred.' }));
				return false;
			}
		};
		const abnLookup = useCacheAsync(fetchABNAsync);
		const businessDetailsSchema = Yup.object().shape({
			abn: Yup.string().required('ABN is required').max(11),
			companyName: Yup.string().required('Valid ABN is required').max(64),
		});
		return (
			<>
				<Typography variant="h4" textAlign="center" sx={{ mb: 8 }}>
					Who do you work for?
				</Typography>
				<Typography variant="body1" sx={{ mb: 2 }}>
					Please provide your ABN so we can understand which company you represent.
				</Typography>
				<Formik
					validationSchema={businessDetailsSchema}
					initialValues={initialValues}
					onSubmit={businessDetailsSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, setFieldValue, touched, values }) => (
						<Form autoComplete="off">
							<TextField
								error={Boolean(touched.abn && (errors.abn || errors.companyName))}
								fullWidth
								helperText={touched.abn && (errors.abn || errors.companyName)}
								label="ABN Number"
								margin="normal"
								name="abn"
								type="tel"
								onBlur={handleBlur}
								onChange={async (e) => {
									const numValue = e.target.value.replace(/[^0-9]/g, '');
									setFieldValue(e.target.name, numValue, true);
									if (/^\d{11}$/.test(numValue)) {
										setLoadingABN(true);
										setFieldValue('companyName', '');
										const companyNameLookup = await abnLookup(numValue);
										setLoadingABN(false);
										if (companyNameLookup) {
											setFieldValue('companyName', companyNameLookup, true);
										}
									}
								}}
								onPaste={async (e) => {
									const numValue = e.clipboardData.getData('Text').replace(/[^0-9]/g, '');
									setFieldValue('abn', numValue, true);
									if (/^\d{11}$/.test(numValue)) {
										setLoadingABN(true);
										setFieldValue('companyName', '');
										const companyNameLookup = await abnLookup(numValue);
										setLoadingABN(false);
										if (companyNameLookup) {
											setFieldValue('companyName', companyNameLookup, true);
										}
									}
								}}
								value={values.abn}
								variant="outlined"
								inputProps={{ maxLength: 11 }}
								InputProps={{
									endAdornment: <>{loadingABN ? <CircularProgress color="primary" size={20} /> : null}</>,
								}}
							/>
							{values.companyName !== '' && (
								<TextField
									fullWidth
									label="Business Name"
									margin="normal"
									name="companyName"
									value={values.companyName}
									variant="outlined"
									InputProps={{
										readOnly: true,
									}}
								/>
							)}
							<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
								<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack}>
									Back
								</Button>
								<Button
									disabled={loadingABN}
									size="large"
									color="secondary"
									type="submit"
									variant="contained"
									endIcon={<ArrowForwardOutlinedIcon />}
								>
									Continue
								</Button>
							</Stack>
						</Form>
					)}
				</Formik>
			</>
		);
	};

	const TermsConditionsDialog: React.FC<{ open: boolean; onClose: () => void; onAgree: () => void; onDisagree: () => void }> = ({
		open,
		onClose,
		onAgree,
		onDisagree,
	}) => {
		return (
			<Dialog fullScreen={fullScreen} open={open} scroll="paper" onClose={onClose} aria-labelledby="terms-dialog-title">
				<DialogTitle id="terms-dialog-title">Terms and Conditions</DialogTitle>
				<DialogContent>
					<Stack spacing={1.5}>
						<DialogContentText color="text.primary">
							By signing up for and using this platform you consent to Panthera Finance collecting and holding personal information
							about you. This data will be stored and used in conjunction with our privacy policy which can be viewed{' '}
							<Link underline="hover" href="https://pantherafinance.com.au/privacy" rel="noreferrer" target="_blank">
								here
							</Link>
							. This data is stored for the purposes of identification and enabling access to your client's data. You also understand
							and agree to Panthera Finances{' '}
							<Link underline="hover" href="https://pantherafinance.com.au/terms" rel="noreferrer" target="_blank">
								Terms and Conditions
							</Link>
							.
						</DialogContentText>
						<DialogContentText color="text.primary">
							You agree that you are duly authorised to act on behalf of any clients assigned to your portfolio, and your clients have
							provided confirmation that they consent to Panthera Finance providing you with access to their personal information.
						</DialogContentText>
						<DialogContentText color="text.primary">
							You agree that you will only upload information and documentation belonging to your clients which contain sensitive
							information if your clients have confirmed that they consent to Panthera Finance collecting and holding this information.
							Please refer to our Privacy Policy for the definition of sensitive information.
						</DialogContentText>
						<DialogContentText color="text.primary">
							You agree that any arrangement that you organise will constitute the acknowledgment of the debt, and the limitation period
							being restarted.
						</DialogContentText>
						<DialogContentText color="text.primary">
							You agree that any payment arrangements or settlements that you organise on behalf of your clients are authorised and
							agreed to by your clients.
						</DialogContentText>
						<DialogContentText color="text.primary">
							You agree that you will provide your clients with the terms and conditions of any payment arrangement or settlement that
							you organise on behalf of your clients.
						</DialogContentText>
						<DialogContentText color="text.primary">
							You agree that access to your portal space will be validated by your specific Financial Counsellors Identification number,
							confirmation of your email address and your own Password. No liability is accepted for any data loss event or harm caused
							by unauthorised access via your credentials.
						</DialogContentText>
						<DialogContentText color="text.primary">
							Panthera Finance reserve the right, at its absolute discretion, to remove or suspend access to the portal at any time
							without any notice being provided to you.
						</DialogContentText>
						<DialogContentText color="text.primary">
							Any questions or concerns should be raised by contacting{' '}
							<Link underline="hover" href={window.config.REACT_APP_CONTACT_EMAIL_HREF} rel="noreferrer" target="_blank">
								{window.config.REACT_APP_CONTACT_EMAIL}
							</Link>{' '}
							or calling{' '}
							<Link underline="hover" href={window.config.REACT_APP_CONTACT_NUMBER_HREF} rel="noreferrer" target="_blank">
								{window.config.REACT_APP_CONTACT_NUMBER}
							</Link>
							.
						</DialogContentText>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDisagree}>Disagree</Button>
					<Button onClick={onAgree}>Agree</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const ContactDetailsForm = () => {
		const [termsOpen, setTermsOpen] = React.useState(false);
		const initialValues: RegisterContactDetails = {
			email,
			phoneNumber,
			termsAccepted,
		};
		const phoneUtil = PhoneNumberUtil.getInstance();
		const validatePhone = (phone: string) => {
			try {
				return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput(phone, 'AU'), 'AU');
			} catch (err) {
				return false;
			}
		};
		const contactDetailsSchema = Yup.object().shape({
			email: Yup.string().required('Email is required').email('Invalid email').max(512),
			phoneNumber: Yup.string()
				.required('Phone number is required')
				.test('validatePhone', `Invalid phone number`, (value) => (value ? validatePhone(value) : false)),
			termsAccepted: Yup.boolean().oneOf([true]),
		});

		return (
			<>
				<Typography variant="h4" textAlign="center" sx={{ mb: 8 }}>
					How do we contact you
				</Typography>
				<Typography variant="body1" gutterBottom width="100%">
					Please provide your email and phone number.
				</Typography>
				<Typography variant="body1" textAlign="left" sx={{ mb: 2 }} width="100%">
					An account holder is identified by the account's email address. Make sure your email address is always up to date so we can reach
					you with important info and so our support team is authorized to help you.
				</Typography>
				<Formik
					initialValues={initialValues}
					validationSchema={contactDetailsSchema}
					onSubmit={contactDetailsSubmit}
					validateOnChange={true}
					validateOnBlur={true}
				>
					{({ errors, handleBlur, handleChange, setFieldValue, isSubmitting, touched, values }) => (
						<>
							<TermsConditionsDialog
								open={termsOpen}
								onClose={() => setTermsOpen(false)}
								onAgree={() => {
									setFieldValue('termsAccepted', true, true);
									setTermsOpen(false);
								}}
								onDisagree={() => {
									setFieldValue('termsAccepted', false, true);
									setTermsOpen(false);
								}}
							/>
							<Form autoComplete="off">
								<TextField
									error={Boolean(touched.email && errors.email)}
									fullWidth
									helperText={touched.email && errors.email}
									label="Email"
									margin="normal"
									name="email"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.email}
									variant="outlined"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<MailOutlinedIcon />
											</InputAdornment>
										),
									}}
								/>
								<TextField
									error={Boolean(touched.phoneNumber && errors.phoneNumber)}
									fullWidth
									helperText={touched.phoneNumber && errors.phoneNumber}
									label="Phone Number"
									margin="normal"
									name="phoneNumber"
									type="tel"
									onBlur={handleBlur}
									onChange={(e) => setFieldValue(e.target.name, e.target.value.replace(/[^0-9]/g, ''), true)}
									value={values.phoneNumber}
									variant="outlined"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<PhoneEnabledOutlinedIcon />
											</InputAdornment>
										),
									}}
									inputProps={{ maxLength: 10 }}
								/>
								<FormGroup sx={{ mt: 2 }}>
									<FormControlLabel
										control={
											<Checkbox
												name="termsAccepted"
												checked={values.termsAccepted}
												onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
													setFieldValue(event.target.name, event.target.checked, true)
												}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										label={
											<>
												Agree with our{' '}
												<Link
													onClick={(e) => {
														e.preventDefault();
														setTermsOpen(true);
													}}
												>
													Terms and Conditions
												</Link>
											</>
										}
										sx={{ justifyContent: 'center' }}
									/>
								</FormGroup>
								<Stack spacing={4} direction="row" sx={{ mt: 6 }} justifyContent="center">
									<Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBack} disabled={isSubmitting}>
										Back
									</Button>
									<LoadingButton
										loading={isSubmitting}
										size="large"
										color="secondary"
										type="submit"
										variant="contained"
										disabled={!values.termsAccepted}
									>
										Create account
									</LoadingButton>
								</Stack>
							</Form>
						</>
					)}
				</Formik>
			</>
		);
	};

	const Success = () => {
		return (
			<>
				<Typography variant="h4" textAlign="center" sx={{ mb: 8 }}>
					You are successfully signed up!
				</Typography>
				<Typography variant="body1" gutterBottom>
					We have sent a confirmation email to
				</Typography>
				<Typography variant="h6" gutterBottom>
					{email}
				</Typography>
			</>
		);
	};

	const renderStepContent = (step: number) => {
		switch (step) {
			case 0:
				return <PersonDetailsForm />;
			case 1:
				return <BusinessDetailsForm />;
			case 2:
				return <ContactDetailsForm />;
			case 3:
				return <Success />;
			default:
				return 'unknown step';
		}
	};

	return (
		<Page title="Sign up">
			<AuthWrapper
				helperLink={
					<Typography variant="subtitle1" component="p" marginTop={2}>
						Already a member? &nbsp;
						<Link underline="hover" component={RouterLink} to={AuthRoutes.LOGIN}>
							Login
						</Link>
					</Typography>
				}
			>
				{renderStepContent(activeStep)}
				{activeStep !== 3 && (
					<HorizontalStepper steps={steps} activeStep={activeStep} sx={{ marginTop: { xs: 8, sm: 'auto' }, width: '100%' }} />
				)}
			</AuthWrapper>
		</Page>
	);
};
