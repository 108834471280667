import { FCResponse } from '../../../services/api/base.api.response';

export enum ClientsAPI {
	ACTIVE = '/api/clients/active',
	PENDING = '/api/clients/pending',
	ADD = '/api/clients/add',
}

export interface IActiveClientsOutput {
	FCAccessID: number;
	DebtID: number;
	DebtorEntityID: number;
	EffectiveDate: string;
	ClientName: string;
	DebtStatus: string;
	Outstanding: number;
	Fullname: string;
}

export interface IPendingClientsOutput {
	DebtID: number;
	AddedDate: string;
}

export type IActiveClients = FCResponse<{ clients: IActiveClientsOutput[] }>;
export type IPendingClients = FCResponse<{ pending: IPendingClientsOutput[] }>;
export type IClientAdd = FCResponse;
